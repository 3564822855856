import { Tabs, Tab, Row, Card } from "react-bootstrap";
import config from "../../config/config";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ImageModal from '../../components/ImageModal'
import { useEffect, useState } from "react";
import { getCampaignAssetsApiCall } from "../../services/campaignApproval/getCampaignAssetsApiCall";
import { useDispatch, useSelector } from "react-redux";
import { campaignApprovalSelectors } from "../../redux/campaignApproval";

export default function CampaignAssets(data) {
    const dispatch = useDispatch()
    const images = []
    const [showViewModal, setshowViewModal] = useState({ showViewModal: false, selectedEntity: {} });

    const campaignAssets = useSelector(
        campaignApprovalSelectors.getCampaignAssetsStatus
    );

    useEffect(() => {
        if (data.data.brandId)
            dispatch(getCampaignAssetsApiCall(data.data.brandId))
    }, []);

    const onCloseViewModal = () => {
        setshowViewModal({ showViewModal: false, selectedEntity: {} })
    }
    const onOpenViewModal = (selectedEntity) => {
        setshowViewModal({ showViewModal: true, selectedEntity })
    }

    const noRecordFound = () => {
        return (images.length == 0 &&
            <div className="row p-4 d-flex justify-content-center">
                <b> No Record Found!</b>
            </div>)
    }
    return <>
        <div>
            <Tabs defaultActiveKey="Images" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="Images" title="Images">
                    <Row className='ml-0 mr-0'>
                        {campaignAssets.data.docs.map((item, index) =>
                            <div className="pl-0 pr-0 col-lg-3 col-md-3 col-sm-4">
                                <div className='center_align'><Card
                                    className="Img__card"
                                >
                                    <img src={`${config.imgUrl}docs/${item.fileName}`} className="list_img" />
                                    <div className='overlay'>
                                        <VisibilityIcon className="icondisplay2" onClick={() => onOpenViewModal(item)} />
                                        <img src={`${config.imgUrl}docs/${item.fileName}`} className="list_img" />
                                    </div>

                                </Card>
                                </div>
                            </div>
                        )}
                    </Row>
                    <ImageModal close={onCloseViewModal}
                        show={showViewModal.showViewModal}
                        title={"mc"}
                        message={<img src={`${config.imgUrl}docs/${showViewModal.selectedEntity.fileName}`} alt={showViewModal.selectedEntity.fileName} style={{ maxHeight: 700, objectFit: 'contain' }} className='w-100' />}
                        cancelText={false}
                        size={'xl'}
                    />
                    {campaignAssets.data.docs.length == 0 && noRecordFound()}
                </Tab>
                <Tab eventKey="Copy" title="Copy">
                    {campaignAssets.data.brands.copy ?
                        <div className="p-4">
                            <h3>
                                {campaignAssets.data.brands.copy}
                            </h3>
                        </div>

                        : noRecordFound()}

                </Tab>
            </Tabs>
        </div>
    </>
}