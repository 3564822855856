import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import CampaignOverviewLayout from '../CampaignOverviewLayout'
import { getCampaignOverviewFromLocalAction } from '../../redux/actions'

class CampaignOverviewPreviewPage extends Component {
  componentDidMount() {
    let { getCampaignOverviewFromLocal, match } = this.props;
    getCampaignOverviewFromLocal(match.params.id)
  }
  componentDidUpdate(prevProps) {

  }
  handleFilterChange = value => {

  }

  getTitle = () => {
    let { campaignOverview } = this.props;
    return `Campaign Overview ${campaignOverview ? '- ' + campaignOverview.name : ''}`
  }
  render() {
    let { campaignOverview } = this.props;
    
    console.log(campaignOverview)
    return (
      <CampaignOverviewLayout
        campaignOverview={campaignOverview}
        title={this.getTitle()}
        displayFilter={false}
        hideSearch={true}
      />
    );
  }
}
export default connect(
  state => ({
    campaignOverview: state.campaignOverview.localData
  }),
  dispatch => ({
    getCampaignOverviewFromLocal: bindActionCreators(getCampaignOverviewFromLocalAction, dispatch)
  })
)(CampaignOverviewPreviewPage)




