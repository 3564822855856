import React, { createRef, useEffect, useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { useDispatch } from 'react-redux';
import { getSignupUsersApicall, exportSignupUsersApicall } from '../../services/brandActionCenter/createBrandActionCenter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BootstrapTable from 'react-bootstrap-table-next';
import Select from 'react-select';
import * as XLSX from "xlsx";
import moment from 'moment';

export default function SignupUsers() {

    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);
    const [userList, setUserList] = useState([]);
    const [searchField, setSearchField] = useState("");
    const [signUpTypes, setSignUpTypes] = useState([{ label: "Amex Dining", value: "amexdining" }, { label: "Krowdit", value: "krowdit" }]);
    const [selectedSignUpTypes, setSelectedSignUpTypes] = useState({ label: "Amex Dining", value: "amexdining" });

    const [pageAndLimitValue, setPageAndLimitValue] = useState({
        limit: 10,
        page: 1,
        totalCount: 10
    });
    const [pagination, setPagination] = useState();



    const amexDiningColumns = [
        {
            dataField: 'name',
            sort: false,
            text: 'Name',
            classes: 'vhalign'
        },
        {
            dataField: 'brandName',
            sort: false,
            text: 'Restaurant Name',
            classes: 'vhalign'
        },
        {
            dataField: 'legalName',
            sort: false,
            text: 'Legal Name',
            classes: 'vhalign'
        },
        {
            dataField: 'email',
            sort: false,
            text: 'Email',
            classes: 'vhalign'
        },
        {
            dataField: 'phone',
            sort: false,
            text: 'Phone',
            classes: 'vhalign'
        },
        {
            dataField: 'website',
            sort: false,
            text: 'Website',
            classes: 'vhalign'
        },
        {
            dataField: 'address',
            sort: false,
            text: 'Address',
            classes: 'vhalign min-col-width-200'
        },
        {
            dataField: 'amexMerchantID',
            sort: false,
            text: 'Amex MerchantID',
            classes: 'vhalign'
        },
        {
            dataField: 'hear_from',
            sort: false,
            text: 'Hear From',
            classes: 'vhalign'
        },
        {
            dataField: 'hear_from_other',
            sort: false,
            text: 'Hear from other',
            classes: 'vhalign'
        }
    ]

    const krowditColumns = [
        {
            dataField: 'registerType',
            sort: false,
            text: 'Register Type',
            classes: 'vhalign',
            formatter: (col, row) => {
                let type = "";
                if (row.registerType == "retailer") {
                    type = "Restaurants";
                } else if (row.registerType == "bank") {
                    type = "Bank";
                } else if (row.registerType == "non_financial_partner") {
                    type = "Non Financial Partner";
                }
                return type;
            }
        },
        {
            dataField: 'organisation_name',
            sort: false,
            text: 'Organisation Name',
            classes: 'vhalign'
        },
        {
            dataField: 'partner_type',
            sort: false,
            text: 'Partner Type',
            classes: 'vhalign min-col-width-200'
        },
        {
            dataField: 'organisation_country',
            sort: false,
            text: 'Organisation Country',
            classes: 'vhalign'
        },
        {
            dataField: 'contact_name',
            sort: false,
            text: 'Name',
            classes: 'vhalign'
        },
        {
            dataField: 'contact_email',
            sort: false,
            text: 'Email',
            classes: 'vhalign'
        },
        {
            dataField: 'contact_phone',
            sort: false,
            text: 'Phone Number',
            classes: 'vhalign'
        },
        {
            dataField: 'address',
            sort: false,
            text: 'Address',
            classes: 'vhalign min-col-width-200'
        },
        {
            dataField: 'website',
            sort: false,
            text: 'Website',
            classes: 'vhalign'
        },
        {
            dataField: 'hear_from',
            sort: false,
            text: 'Hear From',
            classes: 'vhalign'
        },
        {
            dataField: 'hear_from_other',
            sort: false,
            text: 'Hear from other',
            classes: 'vhalign'
        }
    ]

    useEffect(async () => {
        getData(true, "", "amexdining");
    }, []);

    const getData = (showLoader, searchField, listFor) => {

        let filterCriteria = {
            limit: pageAndLimitValue.limit, page: pageAndLimitValue.page, searchField: searchField,
            direction: "desc", sortfield: "created"
        };
        if (showLoader) { setIsLoader(true); }
        dispatch(getSignupUsersApicall(filterCriteria, { listFor: listFor || selectedSignUpTypes.value }, response => {
            setIsLoader(false);
            if (response.respCode) {
                setUserList(response.userList);
                setPageAndLimitValue(response.pagination);
                setPagination(paginationFactory(
                    {
                        sizePerPageRenderer: sizePerPageRenderer,
                        page: response.pagination.page,
                        sizePerPage: response.pagination.limit,
                        totalSize: response.pagination.totalCount,
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: true,
                        showTotal: true,
                        alwaysShowAllBtns: true,
                        show: true,
                        onPageChange: (page, sizePerPage) => {
                            pageChange(page)
                        }
                    }
                ));
                setIsLoader(false);
            }
        }));
    }

    const exportSignupUsers = () => {
        setIsLoader(true);
        dispatch(exportSignupUsersApicall({ listFor: selectedSignUpTypes.value }, response => {
            setIsLoader(false);
            if (response.respCode && response.userList) {
                const workbook = XLSX.utils.book_new();
                let exportData = [];
                response.userList.forEach(e1 => {
                    let columns = selectedSignUpTypes.value == "amexdining" ? amexDiningColumns : krowditColumns;
                    let obj = {};
                    columns.forEach(element => {
                        if(element.dataField != "registerType") {
                            obj[element.text] = e1[element.dataField];
                        } else if(element.dataField == "registerType") {
                            if (e1[element.dataField] == "retailer") {
                                obj[element.text] = "Restaurants";
                            } else if (e1[element.dataField] == "bank") {
                                obj[element.text] = "Bank";
                            } else if (e1[element.dataField] == "non_financial_partner") {
                                obj[element.text] = "Non Financial Partner";
                            }
                        }
                    });

                    obj["Registered date"] = e1.created ? moment(e1.created).format('DD-MM-YYYY') : "";
                    exportData.push(obj);
                });
                const sheet = XLSX.utils.json_to_sheet(exportData);
                XLSX.utils.book_append_sheet(workbook, sheet, "Users");
                XLSX.writeFile(workbook, new Date().getTime() + "-" + selectedSignUpTypes.value + ".xlsx");
                setIsLoader(false);
            }
        }));
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group pt-1" role="group">
            <div className="dropdown">
                <button className="btn btn-primary dropbtn">{pageAndLimitValue.limit}  <ExpandMoreIcon /></button>
                <div className="dropdown-content">
                    {
                        options.map((option, index) => {
                            const isSelect = currSizePerPage === `${option.page}`;
                            return (
                                <a href="#" onClick={() => setPageLimit(option.page)} key={index}> {option.text}</a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )

    const pageChange = (page) => {
        let pageData = pageAndLimitValue;
        pageData.page = page
        setPageAndLimitValue(pageData);
        getData();
    }

    const setPageLimit = (limit) => {
        let pageData = pageAndLimitValue;
        pageData.limit = limit
        setPageAndLimitValue(pageData);
        getData("");
    }

    const handleSearchInputChange = (event) => {
        console.log(event.target.value);
        setSearchField(event.target.value);
        getData(false, event.target.value);
    }

    const onChangeSignupUser = async (event) => {
        setSelectedSignUpTypes(event);
        getData(true, "", event.value);
    }


    return (
        <>
            <div>
                <div className="row action-center">
                    <div className="col-md-12">
                        <div className="heading">
                            <div className="row">
                                <div className="col-md-8 d-flex align-items-center">
                                    <h2 className="m-0 ">
                                        <span>
                                            <Icon className="heading-icon">person</Icon>
                                            Signup Users
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>


                        <div className="card m-t-25">

                            <div className="row col-12 m-t-15 p-r-0 m-r-0">
                                <div className="col-6 h-20">

                                    <div className='ml-auto m-l-0 ib-block'
                                        style={{ width: '30%' }}>
                                        <Select
                                            options={signUpTypes}
                                            value={selectedSignUpTypes}
                                            onChange={(e) => { onChangeSignupUser(e) }}
                                            className='action_Selecter'
                                        />
                                    </div>
                                    <div className='ml-auto ib-block'>
                                        <button className="btn btn-primary m-l-10" onClick={() => { exportSignupUsers() }} >  Export  </button>
                                    </div>

                                </div>
                                <div className="row col-6 action-center-search">
                                    <div className="row col-6 action-center-search-box">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Search User"
                                                onChange={(event) => { handleSearchInputChange(event) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                        {searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 m-t-25 m-b-25">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField='id'
                                    data={userList}
                                    columns={selectedSignUpTypes.value == "amexdining" ? amexDiningColumns : krowditColumns}
                                    wrapperClasses="table-responsive"
                                    remote
                                    noDataIndication={() => <span>No data found.</span>}
                                    pagination={pagination}
                                />
                            </div>

                        </div>


                    </div>

                </div>

                {isLoader &&
                    <div className="loading">Loading...</div>}
            </div>

        </>
    )
};

