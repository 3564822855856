import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions' 
import { DistributorForm } from '../../components/Forms/Distributor'
import moment from 'moment';

class Distributors extends Component {

  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'distributors_name',
      sort: true,
      text: 'Distributors Name',
      classes: 'vhalign'
    },
    {
      dataField: 'distributors_display_name',
      sort: true,
      text: 'Distributors Display Name',
      classes: 'vhalign'
    },
    {
      dataField: 'default_cashback_percentage',
      sort: true,
      text: 'Default Cashback Percentage',
      classes: 'vhalign'
    },
    {
      dataField: 'ref_date',
      sort: true,
      text: 'Next Upload Date',
      classes: 'vhalign',
      formatter: (col, row) => {
        return  row.ref_date ? moment(new Date(row.ref_date)).format('YYYY-MM-DD') : "";
      }
    },
    {
      dataField: 'frequently',
      sort: true,
      text: 'Frequently',
      classes: 'vhalign',
      formatter: (col, row) => {
        
        let value = "";
        if(row.ref_date && row.frequently) {
          let day = moment(row.ref_date).format('dddd');
          if(row.frequently == "daily") value = "Daily";
          if(row.frequently == "weekdays") value = "Daily";
          if(row.frequently == "weekly") value = "Weekly on " + day;
          if(row.frequently == "fortnightly") value = "Every Two Weeks on the " + day;
          if(row.frequently == "monthly") value = "Monthly on the first " + day;
        }
        
        return  value;
      }
    }
  ]

  render() {
    return <EntityList
      heading='distributor'
      entityType="user"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="group"
      EntityForm={DistributorForm}
      columns={this.columns}
      apiUrl={'distributors'}
      globalsearchPlaceHolder='Search distributor'
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Distributors);

