import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import moment from 'moment'
import config from './../../config/config'
class Activities extends Component {

  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'created',
      sort: true,
      text: 'Created',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          let date = moment(col);
          return date.format(config.dateTimeFormat)
        }
      }
    },
    {
      dataField: 'brandName',
      sort: true,
      text: 'Brand Name',
      classes: 'vhalign'
    },
    {
      dataField: 'context',
      sort: true,
      text: 'Context',
      classes: 'vhalign'
    },
    {
      dataField: 'contextType',
      sort: true,
      text: 'Context Type',
      classes: 'vhalign'
    },
    {
      dataField: 'userName',
      sort: true,
      text: 'User Name',
      classes: 'vhalign'
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      classes: 'vhalign'
    },
    {
      dataField: 'desc',
      sort: true,
      text: 'Description',
      classes: 'vhalign',
      // formatter: cell => {
      //   console.log('cell', cell)
      //   return cell && cell.length && cell[0]
      // }
    },

    {
      dataField: 'affectedUser',
      sort: true,
      text: 'Affected Object',
      classes: 'vhalign'
    },
    {
      dataField: 'ipAddress',
      text: 'Ip Address',
      classes: 'vhalign'
    },
    {
      dataField: 'deviceType',
      text: 'Device Type',
      classes: 'vhalign'
    },
    {
      dataField: 'browserName',
      text: 'Browser Name',
      classes: 'vhalign'
    },
    {
      dataField: 'osName',
      text: 'Os Name',
      classes: 'vhalign'
    },
    {
      dataField: 'osVersion',
      text: 'Os Version',
      classes: 'vhalign'
    },

    // {
    //   dataField: 'time',
    //   text: 'Time',
    //   classes: 'vhalign'
    // },
  ]

  componentDidMount() {
    let { getEntityForSelect } = this.props;
    getEntityForSelect('bank')
    getEntityForSelect('brand')
  }

  viewFields = [
    { name: 'brandName', label: 'Brand Name' },
    { name: 'context', label: 'Context' },
    { name: 'contextType', label: 'Context Type' },
    { name: 'userName', label: 'User Name' },
    { name: 'email', label: 'Email' },
    { name: 'desc', label: 'Description' },
    { name: 'affectedUser', label: 'Affected User' },
    { name: 'ipAddress', label: 'Ip Address' },
    { name: 'deviceType', label: 'Device Type' },
    { name: 'browserName', label: 'Browser Name' },
    { name: 'osName', label: 'OS Name' },
    { name: 'osVersion', label: 'OS Version' },
    { name: 'created', label: 'Created' },
    { name: 'description', label: 'Description' },
  ]

  render() {
    return <EntityList
      heading='activities'
      entityType="user"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="domain"
      apiUrl="activities"
      columns={this.columns}
      showPasswordReset={true}
      globalsearchPlaceHolder='Search Context / Context Type / User Name / Email'
      viewFields={this.viewFields}
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Activities);

