import React, { Component } from "react";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import capitalize from 'lodash/capitalize'
import { UserForm } from '../../components/Forms/User'
import { Badge } from "react-bootstrap";
import config from "../../config/config";

class Users extends Component {
  tableData = []
  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (row.guestUser && !row.proceesCompleted) {
          return <a style={{ color: "blue" }} href={`${config.guestUrl}${row.email}`} target='_blank'>  {`${row.email}`}</a>
        } else {
          return row.email
        }
      }
    },
    {
      dataField: 'displayName',
      sort: true,
      text: 'Display Name',
      classes: 'vhalign'
    },

    // {
    //   dataField: 'role',
    //   sort: true,
    //   text: 'Role',
    //   classes: 'vhalign',
    //   formatter: (col, row) => {
    //     if (col == 'user') {
    //       return <Badge style={{ color: 'white', background: 'blue', padding: 6, borderRadius: 35 }}>{col}</Badge>
    //     } else if (col == 'companyAdmin') {
    //       return <Badge style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}>{col}</Badge>
    //     }
    //   }
    // },
    {
      dataField: 'brandName',
      sort: true,
      text: 'Group/Brand Name',
      classes: 'vhalign'
    },
    {
      dataField: 'phone',
      sort: true,
      text: 'Contact Phone',
      classes: 'vhalign'
    },
    {
      dataField: 'activatedDate',
      sort: true,
      text: 'Activated Date',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          let date = new Date(col);

          // adjust 0 before single digit date
          let dd = ("0" + date.getDate()).slice(-2);

          // current month
          let mm = ("0" + (date.getMonth() + 1)).slice(-2);

          // current year
          let yy = date.getFullYear();
          // var d = new Date(col);
          // var n = d.toDateString();
          // return capitalize(n)
          return `${dd}/${mm}/${yy}`
        }
      }
    },
    {
      dataField: 'created',
      sort: true,
      text: 'Created',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          let date = new Date(col);

          // adjust 0 before single digit date
          let dd = ("0" + date.getDate()).slice(-2);

          // current month
          let mm = ("0" + (date.getMonth() + 1)).slice(-2);

          // current year
          let yy = date.getFullYear();
          // var d = new Date(col);
          // var n = d.toDateString();
          // return capitalize(n)
          return `${dd}/${mm}/${yy}`
        }
      }
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col == 'Active') {
          return <Badge style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'Rejected') {
          return <Badge style={{ color: 'white', background: 'red', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'Pending') {
          return <Badge style={{ color: 'white', background: '#ff9900', padding: 6, borderRadius: 35 }}>{col}</Badge>
        }

      }

    }
  ]
  componentDidMount = () => {
    let { getEntityForSelect } = this.props;
    // getEntityForSelect('bank')
    // getEntityForSelect('brand')
    // getEntityForSelect('user', '', res => {
    //   this.tableData = res
    // })
  }
  render() {
    return <EntityList
      entityType="user"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="domain"
      heading='user'
      EntityForm={UserForm}
      apiUrl='users'
      globalsearchPlaceHolder='Search Email / Display Name / Contact No'
      // onSubmit={this.submit}
      columns={this.columns}
      showPasswordReset={true}
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Users);

