import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
import axios from 'axios';
import { bindActionCreators } from 'redux'
import { getEntityDetailAction, getBrandDataAction, getBrandDataByFilterAction, getBranchListByBrandIdAction } from '../../redux/actions/index'
import config from '../../config/config'
import configMessages from '../../config/configMessages'
import Select from 'react-select';
import { postcodeRegex, onlycharRegex, phoneRegex } from '../../utils/constants/constant';
class Merchantform extends Component {

  state = {
    users: [],
    brands: [],
    selectedBrand: {},
    selectedBranch: {},
    branchList: []
  }

  componentDidMount = () => {
    let { options, values } = this.props;
    let { getBrandData } = options
    let brandId = {}
    //get brand data
    getBrandData({}, res => {
      if (res && res.length) {
        res.forEach((item, index) => {
          item.label = item.name
          item.value = item.brandId
          if (item.brandId == values.brandId) {
            brandId.value = item.brandId
            brandId.label = item.name
          }
        })
        this.setState({ brands: res, selectedBrand: brandId })
        if (values.brandId)
          this.getBranchByBrandId(brandId.value)
      }
    })
  }
  // usersArray = () => {
  //   let userDetails = GetSessionValue.getItem('user')
  //   let url = ''
  //   let filterCriteria = {};
  //   filterCriteria['criteria'] = [
  //     { key: 'active', value: true, type: 'eq' }, { key: 'role', value: 'companyAdmin', type: 'eq' },
  //   ];
  //   filterCriteria['direction'] = 'desc';
  //   filterCriteria.sortfield = 'created';
  //   url = `${config.apiUrl}users?filter=${JSON.stringify(filterCriteria)}`;
  //   axios.get(url, {
  //     headers: {
  //       Authorization: `Bearer ${userDetails.accessToken}`
  //     },
  //   }).then((res) => {
  //     if (res && res.data && res.data.respCode) {
  //       this.setState({ users: res.data.users })
  //     }
  //   })
  // }
  getBrand = (value) => {
    let { options, values } = this.props;
    let { getBrandDataByFilter, getBrandData } = options
    let brandId = {}
    let filterCriteria = {
      'criteria': [
        {
          'key': 'name',
          'value': value,
          'type': 'regexOr'
        }
      ]
    }
    if (value && value.length >= 3) {
      //get brand data
      getBrandDataByFilter(filterCriteria, res => {
        if (res && res.length) {
          res.forEach((item, index) => {
            item.label = item.name
            item.value = item.brandId
            if (item.brandId == values.brandId) {
              brandId.value = item.brandId
              brandId.label = item.name
            }
          })
          this.setState({
            brands: res,
            selectedBrand: brandId
          })
        }
      })
    } else {
      getBrandData({}, res => {
        if (res && res.length) {
          res.forEach((item, index) => {
            item.label = item.name
            item.value = item.brandId
            if (item.brandId == values.brandId) {
              brandId.value = item.brandId
              brandId.label = item.name
            }
          })
          this.setState({
            brands: res,
            // selectedBrand: brandId
          })
        }
      })
    }

  }

  getBranchByBrandId = (brand_id) => {
    let { values } = this.props;
    let { options } = this.props;
    let { getBranchListByBrandId } = options
    console.log(brand_id)
    let entity_metadata_id = {}
    getBranchListByBrandId(brand_id, (res) => {
      console.log(res)
      if (res && res.length) {
        res.forEach((item, index) => {
          item.label = item.name
          item.value = item._id
          if (item._id == values.entity_metadata_id) {
            entity_metadata_id.value = item.brandId
            entity_metadata_id.label = item.name
          }
        })
        this.setState({ branchList: res, selectedBranch: entity_metadata_id })
      }
      if (res.length == 0) {
        this.setState({ branchList: [], selectedBranch: {} })
      }
    })
  }

  render() {
    let { status, isSubmitting, values, handleChange, handleBlur, options, setFieldValue } = this.props;
    let { prefix, mode, users, formType } = options
    let { brands, selectedBrand, selectedBranch, branchList } = this.state;
    if (values && selectedBrand.value) {
      values.brandId = selectedBrand.value
    }
    return (
      <Form>
        <div className="form-group">
          <label>Brand </label>
          <Select
            options={brands}
            onChange={value => {
              this.setState({ selectedBrand: value })
              this.getBranchByBrandId(value.brandId)
            }}
            onInputChange={(value) => {
              this.getBrand(value)
            }}
            value={selectedBrand}
          />
          <ErrorMessage name="brandId" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Branch Name </label>
          {/* <Field name="name" className="form-control" disabled={isSubmitting} maxLength='30' /> */}
          <Select
            options={branchList}
            onChange={value => {
              setFieldValue('name', value?.name)
              setFieldValue('entity_metadata_id', value?._id)
              setFieldValue("address", value?.address || "")
              setFieldValue("contactEmail", value?.email || "")
              setFieldValue("contactPhone", value?.phone_no?.replace(/ /g, "") || "")
              setFieldValue("tripadvisorUrl", "https://www.tripadvisor.com" + value.entity_url || "")
              this.setState({ selectedBranch: value })
            }}
            value={selectedBranch}
          />
          <ErrorMessage name="name" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Visa/MasterCard Merchant Id </label>
          <Field name="merchantId" className="form-control" disabled={isSubmitting || (formType && formType == 'edit')} />
          <ErrorMessage name="merchantId" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Amex/Zettle Merchant ID </label>
          <Field name="amexMerchantID" className="form-control" />
          <ErrorMessage name="amexMerchantID" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Tripadvisor Url</label>
          <Field name="tripadvisorUrl" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="tripadvisorUrl" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Name</label>
          <Field name="contactName" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="contactName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Phone</label>
          <Field name="contactPhone" className="form-control" maxLength='12'
            disabled={isSubmitting} />
          <ErrorMessage name="contactPhone" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Email</label>
          <Field name="contactEmail" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="contactEmail" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Address</label>
          <Field name="address" component='textarea' className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="address" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Post Code</label>
          <Field name="postcode" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="postcode" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

class FormikForm extends Component {

  initialValues = {
    'address': '',
    'contactName': '',
    'contactPhone': '',
    'contactEmail': '',
    'merchantId': '',
    'entity_metadata_id': '',
    'amexMerchantID': '',
    'name': '',
    'brandId': undefined,
    'tripadvisorUrl': '',
    'postcode': ''
  }

  validationSchema = YupObject({
    address: YupString(),
    contactName: YupString()
      .matches(onlycharRegex, `Invalid Contact Name`)
      .min(3, `Contact ${configMessages.minName}`)
      .max(20, `Contact ${configMessages.maxName}`),
    contactPhone: YupString()
      .matches(phoneRegex, `Enter a valid contact number`),
    contactEmail: YupString()
      .email(configMessages.validEmail),
    merchantId: YupString()
      .required(configMessages.brandId),
    amexMerchantID: YupString()
      .required(configMessages.amexMerchantID),
    name: YupString()
      .required(`Branch ${configMessages.name}`),
    brandId: YupNumber()
      .required(configMessages.brand),
    postcode: YupString()
      .matches(postcodeRegex, `Invalid Postcode`)
      .required(configMessages.postCode)
  })

  noop = () => { }

  componentDidMount() {
    let { getUserData } = this.props
    getUserData('users', 'users')
  }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options, users, getBrandData, getBrandDataByFilter, getBranchListByBrandId, formType } = this.props;
    console.log('props', this.props);
    if (initialValues && !initialValues?.amexMerchantID) {
      initialValues.amexMerchantID = ""
    }
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <Merchantform {...props} options={{ banks, ...options, users, getBrandDataByFilter, getBranchListByBrandId, getBrandData, formType }} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

export const MerchantForm = connect(
  state => ({
    users: state.selectEntities.users
  }),
  dispatch => ({
    getUserData: bindActionCreators(getEntityDetailAction, dispatch),
    getBrandData: bindActionCreators(getBrandDataAction, dispatch),
    getBrandDataByFilter: bindActionCreators(getBrandDataByFilterAction, dispatch),
    getBranchListByBrandId: bindActionCreators(getBranchListByBrandIdAction, dispatch)

  }),
  null
)(FormikForm)


