import React, { Component } from "react";
import StatCard from './StatCard'
import { withRouter } from 'react-router-dom'

import Performance from './Performance'
import Demographic from './Demographic'
import classNames from 'classnames'
import './campaign-overview.scss'
import StatsCardConfig  from './statsconfig'
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import { DashboadToolTips }  from '../../i18n/tooltips'



import moment from 'moment'


// console.log(statistics) 
class CampaignOverview extends Component {
  state = {
    dateValue : '',
    dateLabel : 'Date',
    overviewType : 'aggregate',
    dateToolTip : ''
  }
  componentDidMount() {
    // let { fetchCampaignOverview, match } = this.props;
    // let id = match.params.id || undefined
    // fetchCampaignOverview(id)
    
    let dateLabel, dateValue, overviewType, dateToolTip = '';
    let {campaignOverview} = this.props
    if (!campaignOverview)
      return;
    if (campaignOverview.count > 1) {
      dateValue = campaignOverview.count
      dateLabel = 'Campaigns'
      overviewType = 'aggregate'
      dateToolTip = DashboadToolTips.statistics['date'][overviewType]
    } else {
      let current = moment() 
      let start_date = moment(campaignOverview.start_date, 'YYYY-MM-DD')
      let end_date = moment(campaignOverview.end_date, 'YYYY-MM-DD')
      overviewType = 'single'
      if (current.diff(start_date) >= 0 && current.diff(end_date) <= 0) {
        // current campaign
        dateValue = end_date.diff(current, 'days')
        dateLabel = 'Days Left'
        dateToolTip = DashboadToolTips.statistics['date'][overviewType]['current']
      } else if (current.diff(start_date) < 0) {
        // Future
        dateValue = current.diff(start_date, 'days')
        dateLabel = 'Days To Start'
        dateToolTip = DashboadToolTips.statistics['date'][overviewType]['future']
      } else if (current.diff(end_date) > 0) {
        // Past
        dateValue = start_date.format('MM/YY')
        dateLabel = 'Date'
        dateToolTip = DashboadToolTips.statistics['date'][overviewType]['past']
      }
      
    }
    this.setState({dateValue, dateLabel, overviewType, dateToolTip})

  }
  componentDidUpdate(nextProps) {
    let dateLabel, dateValue, overviewType, dateToolTip = '';
    let {campaignOverview} = this.props
    let {campaignOverview : nCampaignOverview} = nextProps
    if (!campaignOverview || nCampaignOverview === campaignOverview)
      return;
    if (campaignOverview.count > 1) {
      dateValue = campaignOverview.count
      dateLabel = 'Campaigns'
      overviewType = 'aggregate'
      dateToolTip = DashboadToolTips.statistics['date'][overviewType]
    } else {
      let current = moment() 
      let start_date = moment(campaignOverview.start_date, 'YYYY-MM-DD')
      let end_date = moment(campaignOverview.end_date, 'YYYY-MM-DD')
      overviewType = 'single'
      if (current.diff(start_date) >= 0 && current.diff(end_date) <= 0) {
        // current campaign
        dateValue = end_date.diff(current, 'days')
        dateLabel = 'Days Left'
        dateToolTip = DashboadToolTips.statistics['date'][overviewType]['current']

      } else if (current.diff(start_date) < 0) {
        // Future
        dateValue = current.diff(start_date, 'days')
        dateLabel = 'Days To Start'
        dateToolTip = DashboadToolTips.statistics['date'][overviewType]['future']

      } else if (current.diff(end_date) > 0) {
        // Past
        dateValue = start_date.format('MM/YY')
        dateLabel = 'Month/Year'
        dateToolTip = DashboadToolTips.statistics['date'][overviewType]['past']

      }
      
    }
    this.setState({dateValue, dateLabel, overviewType, dateToolTip})
  }
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.campaignOverview !== nextProps.campaignOverview || this.state !== nextState
  }
  render() {
    let { campaignOverview } = this.props
    let {
      overviewType
    } = this.state

    let statisticsConfig = StatsCardConfig.statistics
    let insightsConfig = StatsCardConfig.insights
    let statisticsToolTips = DashboadToolTips.statistics
    let insightsToolTips = DashboadToolTips.insights
    // let statisticsToolTips = {t: {}}
    return (
      campaignOverview && Object.keys(campaignOverview).length
      ? (
          <div className="content">
            <div>
              <div className="row">
                {
                  campaignOverview.statistics 
                  ? (
                    <React.Fragment>
                    {
                      campaignOverview.statistics.map((stat, i) => {
                        return (
                            <div className="col-xl-2 col-lg-2" key={`statistics-${i}`}>
                              <div className="card pill">
                                <ToolTipContainer 
                                  containerClassNames="card-body text-center pr-0 pl-0"
                                  toolTipText={statisticsToolTips[stat.name][overviewType]}
                                  id={stat.name}
                                >
                                  <StatCard 
                                    {...statisticsConfig[stat.name]}
                                    value={stat.value}
                                    key={stat.name}
                                  />
                                </ToolTipContainer>
                              </div>
                            </div>
                          )
                      })
                    }
                    <div className="col-xl-2 col-lg-2" key={`statistics-date`}>
                      <div className="card pill">
                        <ToolTipContainer 
                          containerClassNames="card-body text-center pr-0 pl-0"
                          toolTipText={this.state.dateToolTip}
                          id={'date'}
                        >
                          <StatCard {...statisticsConfig['date']} label={this.state.dateLabel} value={this.state.dateValue} type="string"/>
                        </ToolTipContainer>
                      </div>
                    </div>
                    </React.Fragment>
                  ) : ''
                }
              </div>
              <div className="row">
                <div className="col-xl-6 d-flex">
                  <Performance data={campaignOverview.performance}/>
                </div>
                <div className="col-xl-6 d-flex">
                  <Demographic data={campaignOverview.demographic}/>
                </div>
              </div>
              <div className="row">
              {
                campaignOverview.insights && campaignOverview.insights.map((insight, i) => {
                  return (
                      <div key={`insights-${i}`} className={classNames("col-xl-0 col-lg-4", {'offset-xl-0 offset-lg-4' : i === 0})}>
                        <div className="card pill">
                          <ToolTipContainer 
                            containerClassNames="card-body text-center"
                            toolTipText={insightsToolTips[insight.name][overviewType]}
                            id={insight.name}
                          >
                            <StatCard {...insightsConfig[insight.name]} value={insight.value} />
                          </ToolTipContainer>
                        </div>
                      </div>
                    )
                })
              }
              </div>
            </div>
          </div>
        )
      : (campaignOverview && !Object.keys(campaignOverview).length ? (<div className="content"><div><div className="row"><h4 className="text-center col-md-12">No Campaign overview data found.</h4></div></div></div>) : '')
    )
  }
}
export default withRouter(CampaignOverview);

