import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom'
import { signupAction } from '../../redux/actions/authActions'
import ForgotPassword from '../../components/ForgotPassword/Forgot.component';
import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
const imgStyle = {
  'display': 'block',
  'height': "9rem",
  'textAlign': "center",
  'margin': '0 auto 2rem auto',
}

class ForgotPasswordForm extends Component {
  onSubmit = (values, actions) => {
    this.props.signup(values, err => {
      if (err) {
        actions.setSubmitting(false)
        actions.setStatus(err.response.data.error)
        return
      }
    })
  }
  render() {
    return (
      <div className="row form justify-content-center">
        <div className="col-md-6 col-lg-4" >
          <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          <div className="card">
            <div className="header">
              <h3 className="text-center">Forgot Password</h3>
            </div>
            <div className="content">
              <ForgotPassword onSubmit={this.onSubmit} />
            </div>
          </div>
          <div className="text-center mb-4">
            <Link to='/login' className="text-primary">Login</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  dispatch => ({
    signup: bindActionCreators(signupAction, dispatch)
  })
)(ForgotPasswordForm);

