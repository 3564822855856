export const passwordRegex = /^(?=.*[0-9])(?=.*[!@#;$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%;^&*]{8,16}$/
export const onlycharRegex = /^[A-Za-z_-]*$/
export const onlycharWithSpaceRegex = /^[A-Za-z_ -]*$/
export const charandnumericRegex = /^[A-Za-z0-9_,-]*$/
export const brandNameRegex = /^[A-Za-z0-9'_ -]*$/
export const branchNameRegex = /^[A-Za-z0-9'_ '-]*$/
export const contactNameRegex = /^[A-Za-z0-9_ ]*$/
export const postcodeRegex = /^[A-Za-z0-9]*$/
export const onlyNumberRegex = /^[0-9]*$/
// export const phoneRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
export const phoneRegex = /^\+?\d*$/
export const websiteRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const Roles = {
    bank: "bank"
}

export const bankCampaignApprovalRole = ["Bank Approval Pending"]
export const FutureCampaignRole = ["Bank Approved"]