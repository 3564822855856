import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import { MerchantForm } from '../../components/Forms/Merchant'
import { Badge } from "react-bootstrap";
import { UploadForm } from '../../components/Forms/FileUpload'
import { MerchantCommentsForm } from '../../components/Forms/MerchantComments'

class Merchants extends Component {
  // Branch Name, Brand Name, Merchant Id, Status
  columns = [
    // {
    //   dataField: 'checkBox',
    //   isDummyField: true,
    //   text:'Select',
    //   // style: { whiteSpace: 'nowrap' ,textAlign:'center',paddingTop:12}
    //   classes:'vhalign'
    // },
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },

    {
      dataField: 'name',
      sort: true,
      text: 'Branch Name',
      classes: 'vhalign'
    },
    {
      dataField: 'brandName',
      sort: true,
      text: 'Brand Name',
      classes: 'vhalign'
    },
    {
      dataField: 'merchantId',
      sort: true,
      text: 'Merchant Id',
      classes: 'vhalign'
    },
    // {
    //   dataField: 'comments',
    //   sort: true,
    //   text: 'Comments',
    //   classes: 'vhalign'
    // },
    // {
    //   dataField: 'contactName',
    //   sort: true,
    //   text: 'Contact Name',
    //   classes: 'vhalign'
    // },
    // {
    //   dataField: 'contactPhone',
    //   sort: true,
    //   text: 'Contact Phone',
    //   classes: 'vhalign'
    // },
    // {
    //   dataField: 'userName',
    //   sort: true,
    //   text: 'User Name',
    //   classes: 'vhalign'
    // },
    // {
    //   dataField: 'activatedDate',
    //   sort: true,
    //   text: 'Activated Date',
    //   classes: 'vhalign',
    //   formatter: (col, row) => {
    //     if (col) {
    //       var d = new Date(col);
    //       var n = d.toDateString();
    //       return n
    //     }
    //   }
    // },
    // {
    //   dataField: 'created',
    //   sort: true,
    //   text: 'Created',
    //   classes: 'vhalign',
    //   formatter: (col, row) => {
    //     if (col) {
    //       let date = new Date(col);
    //       // adjust 0 before single digit date
    //       let dd = ("0" + date.getDate()).slice(-2);
    //       // current month
    //       let mm = ("0" + (date.getMonth() + 1)).slice(-2);
    //       // current year
    //       let yy = date.getFullYear();
    //       return `${dd}/${mm}/${yy}`
    //     }
    //   }
    // },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      classes: 'vhalign',
      formatter: (col, row) => {

        if (col == 'Approved') {
          return <Badge
            className='approvedBadge'
          // style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Rejected') {
          return <Badge
            className='rejectedBadge'
          // style={{ color: 'white', background: 'red', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Pending') {
          return <Badge
            className='pendingBadge'
          // style={{ color: 'white', background: 'orange', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Deleted') {
          return <Badge
            className='deletedBadge'
          // style={{ color: 'white', background: 'red', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        } else if (col == 'Updated') {
          return <Badge
            className='updatedBadge'
          // style={{ color: 'white', background: 'yellow', padding: 6, borderRadius: 35 }}
          >{col}</Badge>
        }

      }

    }
  ]
  viewFields = [
    { name: 'name', label: 'Branch Name' },
    { name: 'merchantId', label: 'Merchant Id' },
    { name: 'block', label: 'Block Name' },
    { name: 'contactName', label: 'Contact Name' },
    { name: 'contactPhone', label: 'Contact Phone' },
    { name: 'contactEmail', label: 'Contact Email' },
    { name: 'address', label: 'Address' },
    { name: 'brandName', label: 'Brand Name' },
    { name: 'brandId', label: 'Brand Id' },
    { name: 'created', label: 'Created' },
    { name: 'activatedDate', label: 'Activated Date' },
    { name: 'comments', label: 'Comments' },
    { name: 'approvedBy', label: 'Approved By' },
    { name: 'status', label: 'Status' },
  ]
  componentDidMount() {
    let { getEntityForSelect } = this.props;
    getEntityForSelect('bank')
    getEntityForSelect('brand')
  }

  render() {
    return <EntityList
      heading='branch'
      entityType="user"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="domain"
      apiUrl="merchants"
      EntityForm={MerchantForm}
      columns={this.columns}
      showPasswordReset={true}
      globalsearchPlaceHolder='Search Brand Name / Branch Name'
      uploadCSV={true}
      EntityUploadForm={UploadForm}
      CommentsForm={MerchantCommentsForm}
      viewFields={this.viewFields}
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Merchants);

