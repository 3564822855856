import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';

const Modal = ({
    close,
    show,
    size = "xl",
    cancelText = "Cancel",
    centered = false,
    data
}) => {

    const dispatch = useDispatch();
    const [activityList, setActivityList] = useState([]);
    const [campaignDetail, setCampaignDetail] = useState({});
    const [brandDetail, setBrandDetail] = useState({});

    const columns = [
        // {
        //     dataField: 'brandName',
        //     sort: false,
        //     text: 'Brand Name',
        //     classes: 'vhalign'
        // },
        {
            dataField: 'context',
            sort: false,
            text: 'Context',
            classes: 'vhalign'
        },
        {
            dataField: 'contextType',
            sort: false,
            text: 'Context Type',
            classes: 'vhalign'
        },
        {
            dataField: 'userName',
            sort: false,
            text: 'User Name',
            classes: 'vhalign'
        },
        {
            dataField: 'email',
            sort: false,
            text: 'Email',
            classes: 'vhalign'
        },
        {
            dataField: 'desc',
            sort: false,
            text: 'Context Description',
            classes: 'vhalign'
        },
        {
            dataField: 'affectedUser',
            sort: false,
            text: 'Affected User',
            classes: 'vhalign'
        },
        {
            dataField: 'ipAddress',
            sort: false,
            text: 'Ip Address',
            classes: 'vhalign'
        },
        {
            dataField: 'deviceType',
            sort: false,
            text: 'Device Type',
            classes: 'vhalign'
        },
        {
            dataField: 'browserName',
            sort: false,
            text: 'Browser Name',
            classes: 'vhalign'
        },
        {
            dataField: 'osName',
            sort: false,
            text: 'OS Name',
            classes: 'vhalign'
        },
        {
            dataField: 'osVersion',
            sort: false,
            text: 'OS Version',
            classes: 'vhalign'
        },
        {
            dataField: 'created',
            sort: false,
            text: 'Created',
            classes: 'vhalign'
        },
        {
            dataField: 'description',
            sort: false,
            text: 'Description',
            classes: 'vhalign'
        },
    ];

    useEffect(() => {
        setActivityList(data.activityList);
        if (data.campaignDetail) {
            setCampaignDetail(data.campaignDetail);
        } else {
            setCampaignDetail({});
        }
        setBrandDetail(data.brandDetail);
    }, [data.activityList]);



    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> Brand Details </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>


                <div className="row col-12 p-r-0 m-l--5 brand-activity-modal">

                    <div className="row col-12 m-t-10 p-r-0 title">
                        Brand Details
                    </div>
                    <div className='col-12 m-t-5'>
                        Copy : {brandDetail.copy}
                    </div>
                    <div className='col-12 m-t-5'>
                        TP Rank : {brandDetail.tp_rank}
                    </div>

                    {campaignDetail && Object.keys(campaignDetail).length > 0 &&
                        <>
                            <div className="row col-12 m-t-10 p-r-0 title">
                                Campaign Details
                            </div>

                            <div className="row col-12 align-items-start">

                                <div className="row col-6 p-r-0"> 

                                    {campaignDetail.distributors && campaignDetail.distributors.length > 0 &&
                                        <div className="row col-12 p-r-0 discount-table m-t-10">
                                            <div className="row col-12 p-r-0">
                                                <div className='col-4 border-line'>
                                                    Distributor name
                                                </div>
                                                <div className='col-4 border-line'>
                                                    Cashback %
                                                </div>
                                                <div className='col-4 border-line'>
                                                    Success Fee %
                                                </div>
                                            </div>

                                            {campaignDetail.distributors.map((e) => {
                                                return (
                                                    <div className="row col-12 p-r-0">
                                                        <div className='col-4 border-line'>
                                                            {e.distributors_display_name}
                                                        </div>
                                                        <div className='col-4 border-line'>
                                                            {e.percentage}
                                                        </div>
                                                        <div className='col-4 border-line'>
                                                            {e.successFee}
                                                        </div>
                                                    </div>
                                                )
                                            })}

                                        </div>
                                    }

                                </div>

                                {campaignDetail.pausedDateWithPublisher && campaignDetail.pausedDateWithPublisher.length > 0 &&
                                    <div className="row col-6 p-r-0 discount-table">
                                        <div className="col-12">
                                            Campaign Pause Date
                                        </div>
                                        <div className="row col-12 m-t-10">
                                            <div className="col-4 border-line">
                                                Distributor name
                                            </div>
                                            <div className="col-6 border-line">
                                                Date
                                            </div>
                                        </div>

                                        {campaignDetail.pausedDateWithPublisher.map((e) => {
                                            return (
                                                <div className="row col-12">
                                                    <div className="col-4 border-line">
                                                        {e.selectedPublisher && e.selectedPublisher.join(", ")}
                                                    </div>
                                                    <div className="col-6 border-line">
                                                        {e.daterange}
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                }

                            </div>
                        </>
                    }


                    <div className="row col-12 p-r-0 title m-t-10">
                        Activity
                    </div>
                    <div className="row col-12 p-r-0">
                        <BootstrapTable
                            bootstrap4={true}
                            keyField='id'
                            data={activityList}
                            columns={columns}
                            wrapperClasses="table-responsive"
                            remote
                            noDataIndication={() => <span>No data found.</span>}
                        // pagination={pagination}
                        />
                    </div>

                </div>


            </ModalComponent.Body>

            {/* <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    {cancelText}
                </Button>
                <Button variant="success">
                    Save
                </Button>
            </ModalComponent.Footer> */}

        </ModalComponent>
    )
}

export default Modal