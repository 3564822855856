import React from 'react'

const SplitButton = (props) => {
	let { text='', primary='', className='', icon='', onClick=() =>{} } = props;
	return (
		<div className={`btn-group ${className} split-button`} onClick={onClick}>
			<button className={`btn p-0 d-flex align-items-center justify-content-center ${primary}`}>
				<span className="text">{text}</span>
				<span className="icon">{icon}</span>
			</button>
		</div>
	)
}

export default SplitButton;