import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

const PrivateRoute = ({ component: Component, isAuthenticated, to, ...rest }) => {
 
  const query = new URLSearchParams(window.location.search);
  const from = query.get('from');
  if (from == "publisher" && !isAuthenticated) {
    sessionStorage.setItem('publisherURL', window.location.href);
  }
  
 return (
  <Route
    {...rest}
    render={props =>
      isAuthenticated ? (
        <Component {...props}/>
      ) : (
        <Redirect
          to={{
            pathname: to,
            state: { redirect: props.location.pathname, isAuthenticated },
          }}
        />
      )
    }
  />
)};


PrivateRoute.defaultProps = {
  to: '/login',
};

export default connect(
  state => ({
    isAuthenticated : !!state.user
  }),
  null
)(PrivateRoute);