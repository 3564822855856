
import { combineReducers } from 'redux';

const banks = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_BANKS_DATA`:
      return action.payload;
    default:
      return state;
  }
}

const brands = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_BRANDS_DATA`:
      return action.payload;
    default:
      return state;
  }
}
const branches = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_BRANCHES_DATA`:
      return action.payload;
    default:
      return state;
  }
}
const users = (state = [], action) => {
  switch (action.type) {
    case `SET_USERS_DETAIL_DATA`:
      return action.payload;
    default:
      return state;
  }
}
const projections = (state = [], action) => {
  switch (action.type) {
    case 'SET_PROJECTIONS_DATA':
      return action.payload;
    default:
      return state;
  }
}
const distributors = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_DISTRIBUTORS_DATA`:
      return action.payload;
    default:
      return state;
  }
}

const groupBrands = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_GROUP_BRAND_DATA`:
      return action.payload;
    default:
      return state;
  }
}

const ingestionLogs = (state = [], action) => {
  switch (action.type) {
    case `SET_SELECT_ENTITIES_INGESTION_LOGS_DATA`:
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  banks,
  brands,
  branches,
  projections,
  users,
  distributors,
  groupBrands,
  ingestionLogs
});