import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import { GroupBrandForm } from '../../components/Forms/GroupBrandForm'
import Icon from '@material-ui/core/Icon'
import config from '../../config/config';
import {
  success as successNotificationAction
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config'

class GroupBrands extends Component {

  copyLink = (from,row) => {
    let urlLink = `${config.guestUrl}${row.email}?type=campaign`;
    if(from === "AMEX") {
      urlLink = urlLink + "&user=amex";
    }
    const el = document.createElement('textarea');
    el.value = urlLink;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.showMessage("Copied..!", "success");
  }

  showMessage = (message, type) => {
    let notification = { ...notificationOpts };
    let { successNotification } = this.props;
    notification.message = message;
    successNotification(notification);
  }

  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'Onboarding',
      isDummyField: true,
      text: 'Onboarding',
      classes: 'vhalign',
      formatter: (col, row) => {
        // if(!row.proceesCompleted) {
          return <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.copyLink("Onboarding", row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-success" title="Copy link">content_copy</Icon>
          </span>
        // } else {
        //   <></>
        // }
      }
    },
    {
      dataField: 'AMEX',
      isDummyField: true,
      text: 'AMEX',
      classes: 'vhalign',
      formatter: (col, row) => {
        // if(!row.proceesCompleted) {
        return <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.copyLink("AMEX", row)} >
          <Icon style={{ fontSize: "1.5rem" }} className="text-success" title="Copy link">content_copy</Icon>
        </span>
        // } else {
        //   <></>
        // }
      }
    },
    {
      dataField: 'group_name',
      sort: true,
      text: 'Group name',
      classes: 'vhalign'
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      classes: 'vhalign'
    }
  ]

  render() {
    return <EntityList
      heading='groupBrand'
      entityType="groupBrand"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="groups"
      EntityForm={GroupBrandForm}
      columns={this.columns}
      apiUrl={'groupBrand'}
      globalsearchPlaceHolder='Search Group'
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch)
  })
)(GroupBrands);

