import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LayoutWithSidebar from './LayoutWithSidebar'
import CampaignOverviewPage from '../CampaignOverviewPrintPage'

const Main = props => {
  let { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/campaigns/print/:id/:start_date?/:end_date?`} component={CampaignOverviewPage} />
      <Route path={`${match.url}`} component={LayoutWithSidebar} />
    </Switch>
  )
}


export default Main





