import React, { Component } from 'react';
import { Bar, HorizontalBar } from 'react-chartjs-2';

class BarChart extends Component {
  getChartData = () => {
    let { data, dontSort } = this.props;
    let labelsData = [];
    let valueData = [];
    if(!dontSort)
      data = data.sort((a, b) => (a.label > b.label) ? 1 : -1)
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        labelsData.push(data[i]['label'])
        valueData.push(data[i]['value'])
      }
    }
    return {
      labels: labelsData,
      datasets: [{
        fill: false,
        backgroundColor: '#1c62f0',
        borderWidth: 1,
        hoverBackgroundColor: '#3967c4',
        borderColor: '#1c62f0',
        data: valueData
      }]
    }
  }

  getChartOptions = () => {
    let { data, options = {}, xLabel, yLabel,dontSort } = this.props;
    // let nData = [...data.data].sort()
    let nData = [...data];
    if(!dontSort)
      nData = nData.sort((a, b) => a.value - b.value);
    let max = nData && nData.length > 0 && parseInt(nData[nData.length - 1]['value'] / 10);
    let suggestedMin = 0
    let suggestedMax = nData && nData.length > 0 && parseInt(nData[nData.length - 1]['value']) + max
    return {
      legend: {
        display: false
      },
      scales: {
        yAxes: [{
          beginAtZero: true,
          ticks: {
            suggestedMax,
            suggestedMin,
            callback: function (value, index, values) {
              value = value.toLocaleString('en-US');
              return value;
            },
          },
          barThickness: 15,
          scaleLabel: {
            display: !!yLabel,
            labelString: yLabel,
          }
        }],
        xAxes: [{
          barThickness: 15,
          scaleLabel: {
            display: !!xLabel,
            labelString: xLabel
          },
        }]
      },
      plugins: {
        datalabels: {
          color: '#666',
          anchor: 'end',
          align: 'top',
          offset: 3,
          formatter: function (value, index, values) {
            value = value.toLocaleString('en-US');
            return "";
          },
        },
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem.yLabel.toLocaleString('en-US');
          }
        }
      },
      ...options
    }
  }

  render() {
    return <Bar data={this.getChartData()} options={this.getChartOptions()} />
  }
}

export default BarChart;

