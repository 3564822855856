import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, bool as YupBool } from 'yup';
import configMessages from '../../config/configMessages';
export class SettingsForm extends Component {

  initialValues = {
    "adminExpireTokenTime": '',
    "adminExpireTokenTimeInMin": '',
    "expireTokenTime": '',
    "expireTokenTimeInMin": '',
    "enableMails": '',
    'gocardlessToken': '',
    "sendGridEmail": '',
    "sendGridApiKey": '',
    "disableMultipleLogin": '',
    "enableTerminalLogs": '',
    "merchantApproveByAdmin": '',
    "deActivateMailId": '',
    "campaignDiscountCount": '',
    "campaignSuccessFeeCount": '',
    "campaignDiscountStep": '',
    "campaignSuccessFeeStep": '',
    "campaignOverviewDelay": ''
  }

  validationSchema = YupObject({
    adminExpireTokenTimeInMin: YupString()
      .required(configMessages.expireTokenTime),
    expireTokenTimeInMin: YupString()
      .required(configMessages.expireTokenTime),
    enableMails: YupString()
      .required(configMessages.enableMails),
    sendGridEmail: YupString()
      .required(configMessages.sendGridMails),
    gocardlessToken: YupString()
      .required(configMessages.gocardlessTokens),
    sendGridApiKey: YupString()
      .required(configMessages.sendGridApiKey),
    disableMultipleLogin: YupString()
      .required(configMessages.multipleLogin),
    merchantApproveByAdmin: YupBool()
      .required(configMessages.merchantApproveByAdmin),
    deActivateMailId: YupString()
      .required(configMessages.deactivationMail)
  })

  getForm = (props, options = {}) => {
    let { status, isSubmitting, values } = props;
    let disabled = this.props.disabled
    return (
      <Form>
        <div className='row'>

          <div className='col-sm-6'>
            <div className="row create-header">
              <h4 className="col-md-6 offset-3 text-center py-3">Token Time Settings</h4>
            </div>
            <div className="form-group">
              <label>Admin Expire Token Time (min)</label>
              <Field name="adminExpireTokenTimeInMin" className="form-control" disabled={disabled} />
              <ErrorMessage name="adminExpireTokenTimeInMin" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Expire Token Time (min) </label>
              <Field name="expireTokenTimeInMin" className="form-control" disabled={disabled} />
              <ErrorMessage name="expireTokenTimeInMin" component="label" className="error" />
            </div>
            <div className="row create-header">
              <h4 className="col-md-6 offset-3 text-center py-3">Mail Settings</h4>
            </div>
            <div className="form-group">
              <label>Enable Mails</label>
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <Field
                    checked={values.enableMails === true || values.enableMails === 'true'}
                    name="enableMails" type="radio" id="enableMails1" className="form-check-input mr-2" value={true} disabled={disabled} />
                  <label className="form-check-label" htmlFor="enableMails1">
                    True
                </label>
                </div>
                <div className="form-check form-check-inline custom-align">
                  <Field
                    checked={values.enableMails === false || values.enableMails === 'false'}
                    name="enableMails" type="radio" id="enableMails2" className="form-check-input mr-2" value={false} disabled={disabled} />
                  <label className="form-check-label" htmlFor="enableMails2">
                    False
                </label>
                </div>
              </div>
              <ErrorMessage name="enableMails" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Go Cardless Token </label>
              <Field name="gocardlessToken" className="form-control" disabled={disabled} />
              <ErrorMessage name="gocardlessToken" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Send Grid Mail </label>
              <Field name="sendGridEmail" className="form-control" disabled={disabled} />
              <ErrorMessage name="sendGridEmail" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Send Grid Api Key </label>
              <Field name="sendGridApiKey" className="form-control" disabled={disabled} />
              <ErrorMessage name="sendGridApiKey" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Deactivate Mail </label>
              <Field name="deActivateMailId" className="form-control" disabled={disabled} />
              <ErrorMessage name="deActivateMailId" component="label" className="error" />
            </div>
          </div>
          <div className='col-sm-6'>
            <div className="row create-header">
              <h4 className="col-md-6 offset-3 text-center py-3">General Settings</h4>
            </div>
            <div className="form-group">
              <label>Disable Multiple Login </label>
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <Field
                    checked={values.disableMultipleLogin === true || values.disableMultipleLogin === 'true'}
                    name="disableMultipleLogin" type="radio" id="disableMultipleLogin1" className="form-check-input mr-2" value={true} disabled={disabled} />
                  <label className="form-check-label" htmlFor="disableMultipleLogin1">
                    True
                </label>
                </div>
                <div className="form-check form-check-inline custom-align">
                  <Field
                    checked={values.disableMultipleLogin === false || values.disableMultipleLogin === 'false'}
                    name="disableMultipleLogin" type="radio" id="disableMultipleLogin2" className="form-check-input mr-2" value={false} disabled={disabled} />
                  <label className="form-check-label" htmlFor="disableMultipleLogin2">
                    False

                </label>
                </div>
              </div>
              <ErrorMessage name="disableMultipleLogin" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Merchant Approved By Admin </label>
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <Field
                    checked={values.merchantApproveByAdmin === true || values.merchantApproveByAdmin === 'true'}
                    name="merchantApproveByAdmin" type="radio" id="enableMails1" className="form-check-input mr-2" value={true} disabled={disabled} />
                  <label className="form-check-label" htmlFor="merchantApproveByAdmin1">
                    True
                </label>
                </div>
                <div className="form-check form-check-inline custom-align">
                  <Field
                    checked={values.merchantApproveByAdmin === false || values.merchantApproveByAdmin === 'false'}
                    name="merchantApproveByAdmin" type="radio" id="merchantApproveByAdmin2" className="form-check-input mr-2" value={false} disabled={disabled} />
                  <label className="form-check-label" htmlFor="merchantApproveByAdmin2">
                    False

                </label>
                </div>
              </div>
              <ErrorMessage name="merchantApproveByAdmin" component="label" className="error" />
            </div>
            {/* <div className="form-group">
              <label>Merchant Approved By User </label>
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <Field
                    checked={values.merchantApproveByUser === true || values.merchantApproveByUser === 'true'}
                    name="merchantApproveByUser" type="radio" id="enableMails1" className="form-check-input mr-2" value={true} disabled={disabled} />
                  <label className="form-check-label" htmlFor="merchantApproveByUser1">
                    True
                </label>
                </div>
                <div className="form-check form-check-inline custom-align">
                  <Field
                    checked={values.merchantApproveByUser === false || values.merchantApproveByUser === 'false'}
                    name="merchantApproveByUser" type="radio" id="merchantApproveByUser2" className="form-check-input mr-2" value={false} disabled={disabled} />
                  <label className="form-check-label" htmlFor="merchantApproveByUser2">
                    False
                </label>
                </div>
              </div>
              <ErrorMessage name="merchantApproveByUser" component="label" className="error" />
            </div> */}
            <div className="form-group">
              <label>Merchant Display </label>
              <div className="d-block d-sm-block d-md-flex radio-container">
                <div className="form-check form-check-inline custom-align mr-4">
                  <Field
                    checked={values.merchantDisplay === true || values.merchantDisplay === 'true'}
                    name="merchantDisplay" type="radio" id="merchantDisplay1" className="form-check-input mr-2" value={true} disabled={disabled} />
                  <label className="form-check-label" htmlFor="merchantDisplay1">
                    All
                </label>
                </div>
                <div className="form-check form-check-inline custom-align">
                  <Field
                    checked={values.merchantDisplay === false || values.merchantDisplay === 'false'}
                    name="merchantDisplay" type="radio" id="merchantDisplay2" className="form-check-input mr-2" value={false} disabled={disabled} />
                  <label className="form-check-label" htmlFor="merchantDisplay2">
                    Only Approved
                </label>
                </div>
              </div>
              <ErrorMessage name="merchantApproveByAdmin" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Campaign min Discount </label>
              <Field name="campaignDiscountCount" className="form-control" disabled={disabled} type='number' />
              <ErrorMessage name="campaignDiscountCount" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Campaign Discount step </label>
              <Field name="campaignDiscountStep" className="form-control" disabled={disabled} type='number' />
              <ErrorMessage name="campaignDiscountStep" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>min Success Fee </label>
              <Field name="campaignSuccessFeeCount" className="form-control" disabled={disabled} type='number' />
              <ErrorMessage name="campaignSuccessFeeCount" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Success Fee step</label>
              <Field name="campaignSuccessFeeStep" className="form-control" disabled={disabled} type='number' />
              <ErrorMessage name="campaignSuccessFeeStep" component="label" className="error" />
            </div>
            <div className="form-group">
              <label>Campaign OverView Delay </label>
              <Field name="campaignOverviewDelay" className="form-control" disabled={disabled} type='number' />
              <ErrorMessage name="campaignOverviewDelay" component="label" className="error" />
            </div>
          </div>
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, disabled } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}






