import React, { Component } from "react";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import capitalize from 'lodash/capitalize'
import Icon from '@material-ui/core/Icon'
import moment from 'moment'
import {
  getCampaignOverviewRawAction,
  updateCampaignOverviewAction,
  setCampaignOverviewToLocalAction,
  getCampaignByIdAction,
  setSelectedCampaignAction
} from '../../redux/actions'

import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'



// class CampaignOverviewEdit extends Component {

//   componentDidMount() {
//     let { getCampaignOverviewRaw, match } = this.props;
//     let id = match.params.id ? match.params.id : ''
//     getCampaignOverviewRaw({id})
//   }
//   componentDidUpdate() {

//   }

//   onSubmit = (values, actions) => {
//     let { updateCampaignOverview, successNotification } = this.props;
//     let data = {
//       co : {
//         ...values,
//         graphData : undefined
//       },
//       coPerformance : {
//         reach : values.graphData.reach,
//         revenue : values.graphData.revenue,
//         conversions : values.graphData.conversions
//       },
//       coStats : {
//         age : values.graphData.age,
//         branches : values.graphData.branches,
//         nationality : values.graphData.nationality,
//         gender : values.graphData.gender
//       },
//       campaign_id : 1
//     }
//     updateCampaignOverview(data, err => {
//       actions.setSubmitting(false)
//       if (err) {
//         actions.setStatus(err.response.data.error)
//         return;  
//       }
//       setTimeout(() => {
//         let notification = {...notificationOpts}
//         notification.message = `Success`
//         successNotification(notification)
//       })
//       this.setState({isDisabled : true})
//     })
//   }

//   formikRef = (e) => {
//     this.formik = e
//   }
//   formikSubmit = () => {
//     if(this.formik)
//       this.formik.submitForm()
//   }


//   render() {
//     let { campaignOverviewRaw } = this.props
//     return (
//       <div className="row">
//         <div className="col-md-12">
//           <div className="heading">
//             <div className="row">
//               <div className="col-md-8 d-flex align-items-center">
//                 <h2 className="m-0">
//                   <span>
//                     <Icon className="heading-icon">bar_chart</Icon>
//                   </span> Campaign Overview Edit
//                 </h2>
//               </div>
//             </div>
//           </div>
//           <div className="card">
//             <div className="content mt-4">
//               <CampaignOverviewEditForm 
//                 initialValues={campaignOverviewRaw} 
//                 formikRef={this.formikRef}
//                 onSubmit={this.onSubmit}
//               />
//             </div>
//             <div className="row mt-4 justify-content-center pb-3">
//               <button className="btn btn-secondary mr-4">Preview</button>
//               <button className="btn btn-primary" onClick={this.formikSubmit}>Submit</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }

// export default connect(
//   state => ({
//     campaignOverviewRaw : state.campaignOverview.rawData,  
//   }),  
//   dispatch => ({
//     getCampaignOverviewRaw : bindActionCreators(getCampaignOverviewRawAction, dispatch),
//     updateCampaignOverview : bindActionCreators(updateCampaignOverviewAction, dispatch),
//     successNotification : bindActionCreators(successNotificationAction, dispatch),
//     errorNotification : bindActionCreators(errorNotificationAction, dispatch),
//   })
// )(CampaignOverviewEdit);

import { SummaryForm } from '../../components/CampaignOverviewEdit/SummaryForm'
import { PerformanceForm } from '../../components/CampaignOverviewEdit/PerformanceForm'
import { StatsForm } from '../../components/CampaignOverviewEdit/StatsForm'
import { STATS_TYPE } from '../../components/CampaignOverviewEdit/constants'

class CampaignOverviewEdit extends Component {
  state = {
    summaryForm: {
      totalCost: '',
      cpa: '',
      highestSpend: '',
      lowestSpend: '',
      averageSpend: '',
    },
    performanceForm: {
      performance: [],
      date: '',
      reach: '',
      conversions: '',
      revenue: ''
    },
    statsForm: {
      stats: [],
      label: '',
      value: '',
      tempData: ''
    }
  }
  formik = {}
  formHasError = true
  isPreviewSubmit = false

  componentDidMount() {
    let { match, getCampaignOverviewRaw, getCampaignById } = this.props
    let id = match.params.id
    if (id) {
      getCampaignOverviewRaw({ id })
      getCampaignById(id)
    }
  }

  componentDidUpdate(prevProps, nextState) {
    let { match, getCampaignOverviewRaw, campaignOverviewRaw, getCampaignById } = this.props
    let { match: oMatch, campaignOverviewRaw: oCampaignOverviewRaw } = prevProps
    if (match.params.id && oMatch.params.id !== match.params.id) {
      let id = match.params.id
      getCampaignOverviewRaw({ id })
      getCampaignById(id)
      return;
    }
    if (campaignOverviewRaw && oCampaignOverviewRaw != campaignOverviewRaw) {
      let newState = { ...this.state }
      let {
        performance = [],
        stats = [],
        co = {
          totalCost: '',
          cpa: '',
          highestSpend: '',
          lowestSpend: '',
          averageSpend: '',
        }
      } = campaignOverviewRaw
      let performanceForm = { ...this.state.performanceForm, ...performance }
      let statsForm = { ...this.state.statsForm, ...stats }
      let summaryForm = { ...this.state.summaryForm, ...co }
      this.setState({ performanceForm, statsForm, summaryForm })
    }
  }

  componentWillUnmount() {
    this.props.setSelectedCampaign(null)
  }

  formikRef = name => (e) => {
    this.formik[name] = e
  }
  formikSubmit = () => {
    this.formHasError = false
    if (this.formik['statsForm']) {
      let statusMessage = ''
      let missing = STATS_TYPE.map(type => {
        if (!this.state.statsForm.stats.filter(s => s.type == type).length)
          return capitalize(type)
      })
      missing = missing.filter(Boolean)
      if (missing.length) {
        let statusMessage =
          missing.length == 1 ? `${missing.join(',')} list values are missing`
            : `${missing.join(' list,')} list values are missing`
        this.formik['statsForm'].setStatus(statusMessage)
        this.formHasError = true
      }
    }
    if (!this.state.performanceForm.performance.length && this.formik['performanceForm']) {
      let statusMessage = 'Performance list is missing'
      this.formik['performanceForm'].setStatus(statusMessage)
      this.formHasError = true
    }
    if (this.formik['summaryForm'])
      this.formik['summaryForm'].submitForm()
  }
  onSubmit = () => {

    if (this.formHasError)
      return
    if (this.isPreviewSubmit) {
      this.preview()
      this.formik['summaryForm'].setSubmitting(false)
      return
    }
    let { updateCampaignOverview, match, errorNotification, successNotification } = this.props;
    let co = {
      ...this.state.summaryForm,
      reach: this.state.performanceForm.performance.reduce((result, item) => {
        result += item.reach
        return result
      }, 0),
      conversions: this.state.performanceForm.performance.reduce((result, item) => {
        result += item.conversions
        return result
      }, 0),
      revenue: this.state.performanceForm.performance.reduce((result, item) => {
        result += item.revenue
        return result
      }, 0)
    }

    let data = {
      co,
      performance: this.state.performanceForm.performance,
      stats: this.state.statsForm.stats,
      campaign_id: match.params.id
    }
    updateCampaignOverview(data, err => {
      Object.keys(this.formik).forEach(key => {
        if (this.formik[key]) {
          this.formik[key].setSubmitting(false)
        }

      })
      if (err) {
        let error = err.response ? err.response.error : (err.data.error ? err.data.error : '')
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = `Error : ${error}`
          errorNotification(notification)
        })
        return;
      }
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = "Campaign Overview data saved successfully"
        successNotification(notification)
      })

    })
  }
  onSubmitSummary = (values, actions) => {
    let state = this.state
    let newState = {
      ...state,
      summaryForm: {
        ...values
      }
    }
    this.setState(newState, () => {
      this.onSubmit()
    })
  }
  onSubmitPerformance = (values, actions) => {
    let state = this.state
    let newState = {
      ...state,
      performanceForm: {
        ...state.performanceForm,
        performance: values.performance
      }
    }
    this.setState(newState, () => {
      actions.resetForm(this.state.performanceForm)
    })
  }
  onSubmitStats = (values, actions) => {
    let state = this.state
    let newState = {
      ...state,
      statsForm: {
        ...state.statsForm,
        stats: values.stats
      }
    }
    this.setState(newState, () => {
      actions.resetForm(this.state.statsForm)
    })
  }
  previewSubmit = () => {
    this.isPreviewSubmit = true
    this.formikSubmit()
  }
  preview = () => {
    // save data in same format as server
    let insights = [
      { name: 'highest_spend', value: this.state.summaryForm.highestSpend },
      { name: 'lowest_spend', value: this.state.summaryForm.lowestSpend },
      { name: 'average_spend', value: this.state.summaryForm.averageSpend },
    ]
    let reach = this.state.performanceForm.performance.reduce((result, item) => {
      result += item.reach
      return result
    }, 0)
    let conversions = this.state.performanceForm.performance.reduce((result, item) => {
      result += item.conversions
      return result
    }, 0)
    let revenue = this.state.performanceForm.performance.reduce((result, item) => {
      result += item.revenue
      return result
    }, 0)
    let statistics = [
      { name: 'reach', value: reach },
      { name: 'conversions', value: conversions },
      { name: 'revenue', value: revenue },
      { name: 'total_cost', value: this.state.summaryForm.totalCost },
      { name: 'cpa', value: conversions ? (this.state.summaryForm.totalCost / conversions) : 'N/A' },
    ]
    let performance_reach = this.state.performanceForm.performance.map(item => {
      return {
        label: `${moment(item.date).format('YYYY')}-${moment(item.date).week()}`,
        value: item.reach
      }
    })
    performance_reach = performance_reach.filter(Boolean)
    let performance_conversions = this.state.performanceForm.performance.map(item => {
      return {
        label: `${moment(item.date).format('YYYY')}-${moment(item.date).week()}`,
        value: item.conversions
      }
    })
    performance_conversions = performance_conversions.filter(Boolean)
    let performance_revenue = this.state.performanceForm.performance.map(item => {
      return {
        label: `${moment(item.date).format('YYYY')}-${moment(item.date).week()}`,
        value: item.revenue
      }
    })
    performance_revenue = performance_revenue.filter(Boolean)
    let performance = {
      reach: performance_reach,
      conversions: performance_conversions,
      revenue: performance_revenue,
    }


    let demographics_age = this.state.statsForm.stats.map(item => {
      if (item.type == 'age') {
        return {
          label: item.label,
          value: item.value
        }
      }

    })
    demographics_age = demographics_age.filter(Boolean)
    let demographics_branches = this.state.statsForm.stats.map(item => {
      if (item.type == 'branches') {
        return {
          label: item.label,
          value: item.value
        }
      }
    })
    demographics_branches = demographics_branches.filter(Boolean)
    let demographics_nationality = this.state.statsForm.stats.map(item => {
      if (item.type == 'nationality') {
        return {
          label: item.label,
          value: item.value
        }
      }
    })
    demographics_nationality = demographics_nationality.filter(Boolean)
    let demographics_gender = this.state.statsForm.stats.map(item => {
      if (item.type == 'gender') {
        return {
          label: item.label,
          value: item.value
        }
      }
    })
    demographics_gender = demographics_gender.filter(Boolean)

    let demographic = {
      gender: demographics_gender,
      nationality: demographics_nationality,
      branches: demographics_branches,
      age: demographics_age,
    }

    let { setCampaignOverviewToLocal, selectedCampaign, history } = this.props;
    setCampaignOverviewToLocal({
      demographic,
      performance,
      insights,
      statistics,
      count: 1,
      start_date: moment(selectedCampaign.startDate).format('YYYY-MM-DD'),
      end_date: moment(selectedCampaign.endDate).format('YYYY-MM-DD'),
      name: selectedCampaign.name,
    }, selectedCampaign.id)
    this.isPreviewSubmit = false
    window.open(`/user/campaigns/preview/${selectedCampaign.id}`, '_blank')
    // history.push(`/user/campaigns/preview/${selectedCampaign.id}`)
  }
  render() {
    let { campaignOverviewRaw, selectedCampaign } = this.props
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="heading">
            <div className="row">
              <div className="col-md-8 d-flex align-items-center">
                <h2 className="m-0">
                  <span>
                    <Icon className="heading-icon">bar_chart</Icon>
                  </span> Campaign Overview Edit
                </h2>
              </div>
            </div>
          </div>
          {
            campaignOverviewRaw && selectedCampaign && (
              <div className="card">
                <div className="content mt-4">
                  <SummaryForm
                    onSubmit={this.onSubmitSummary}
                    formikRef={this.formikRef('summaryForm')}
                    initialValues={campaignOverviewRaw.co}
                  />
                  <hr />
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="form-group">
                        <h5 className="mb-3">Performance</h5>
                        <PerformanceForm
                          onSubmit={this.onSubmitPerformance}
                          formikRef={this.formikRef('performanceForm')}
                          initialValues={{
                            performance: campaignOverviewRaw.performance,
                            startDate: moment(selectedCampaign.start_date).format('YYYY-MM-DD'),
                            endDate: moment(selectedCampaign.end_date).format('YYYY-MM-DD')

                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <div className="form-group">
                        <h5 className="mb-3">Conversion Statistics</h5>
                        <StatsForm
                          onSubmit={this.onSubmitStats}
                          formikRef={this.formikRef('statsForm')}
                          initialValues={{ stats: campaignOverviewRaw.stats }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 justify-content-center pb-3">
                  <button className="btn btn-secondary mr-4" onClick={this.previewSubmit}>Preview</button>
                  <button className="btn btn-primary" onClick={this.formikSubmit}>Submit</button>
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default connect(
  state => ({
    campaignOverviewRaw: state.campaignOverview.rawData,
    selectedCampaign: state.campaigns.selectedCampaign
  }),
  dispatch => ({
    getCampaignOverviewRaw: bindActionCreators(getCampaignOverviewRawAction, dispatch),
    updateCampaignOverview: bindActionCreators(updateCampaignOverviewAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    setCampaignOverviewToLocal: bindActionCreators(setCampaignOverviewToLocalAction, dispatch),
    getCampaignById: bindActionCreators(getCampaignByIdAction, dispatch),
    setSelectedCampaign: bindActionCreators(setSelectedCampaignAction, dispatch),
  })
)(CampaignOverviewEdit);

