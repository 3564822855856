
import axios from '../../utils/AxiosWrapper'
import { pluralize } from '../../utils/helpers'
import axiosMethodRequest from '../../config/service'
import apiCalls from '../../config/apiCalls'

const setEntityData = (type, data) => {
  return {
    type: `SET_SELECT_ENTITIES_${pluralize(type).toUpperCase()}_DATA`,
    payload: data
  }
}

const setProjections = data => {
  return {
    type: `SET_PROJECTIONS_DATA`,
    payload: data
  }
}
const setCampaignTemplates = data => {

  return {
    type: `SET_CAMPAIGN_TEMPLATE_DATA`,
    payload: data
  }
}
// const setBrandListData = (data) => {
//   return {
//     type: `SET_BRAND_LIST_DATA`,
//     payload: data
//   }
// }
export const getEntityForSelectAction = (type, params = {}, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `${apiCalls.users}`).then((res) => {
      if (res && res.data && res.data.respCode) {
        let data = res.data.users
        dispatch(setEntityData(type, data))
      }
    })
  }
}
export const getCampaignTemplateData = (type, callback) => {
  return dispatch => {
    let url = ''
    let filterCriteria = {};
    filterCriteria['criteria'] = [{ key: 'type', value: type, type: 'eq' }];
    filterCriteria['direction'] = 'desc';
    filterCriteria.sortfield = 'created';
    url = `campaignTemplates?filter=${JSON.stringify(filterCriteria)}`;
    return axiosMethodRequest('GET', url).then((res) => {
      if (res && res.data && res.data.respCode) {
        let data = res.data.campaignTemplates
        if (callback) {
          callback(data)
        }


        dispatch(setCampaignTemplates(data))
      }
    })
  }
}
export const getProjectionsForUserAction = () => {
  return dispatch => {
    return axios
      .get('/brands/projections/12')
      .then(res => {
        let data = res.data
        dispatch(setProjections(data))
      })
      .catch(err => {
      })
  }
}
const setCsvData = data => {
  return {
    type: `SET_CSV_DATA`,
    payload: data
  }
}
const setBrandData = data => {
  return {
    type: `SET_SELECT_ENTITIES_BRANDS_DATA`,
    payload: data
  }
}
export const clearCampaignTemplateData = () => {
  return dispatch => {
    return dispatch(setCampaignTemplates(null))
  }
}
export const getProjectionsForUserActionTrain = (id, callback) => {
  return dispatch => {
    // setEntityLoadingState(type, true)
    // return axiosMethodRequest('GET',)
    return axiosMethodRequest('GET', `brands/${id}`).then((res) => {
      if (res && res.data && res.data.respCode) {
        let data = res.data.details.json
        let details = res.data.details
        dispatch(setProjections(data))
        dispatch(setBrandData(details))
        if (callback) {
          callback(details)
        }
      }
    })
  }
}

export const getBrandListAction = (callback) => {
  return dispatch => {
    let url = `brands/getBrandList?from=groupBrand`; 
    return axiosMethodRequest('GET', url).then((res) => {
      if (res && res.data && res.data.respCode) {
        let data = res.data.allBrands;
        // dispatch(setBrandListData(data));
        callback(data)
      }
    })
  }
}

export const getUserDetailsAction = (user_id,callback) => {
  return dispatch => {
    let url = `groupBrand/userDetails/` + user_id; 
    return axiosMethodRequest('GET', url).then((res) => {
      if (res && res.data && res.data.respCode) {
        let data = res.data.usersDetails; 
        callback(data)
      }
    })
  }
}

const setPreviewData = data => {
  return {
    type: `SET_PREVIEW_DATA`,
    payload: data
  }
}
const setInitialData = data => {
  return {
    type: `SET_INITIAL_DATA`,
    payload: data
  }
}
const setTabData = data => {
  return {
    type: `SET_TAB_DATA`,
    payload: data
  }
}
export const loadBrandData = (details) => {
  console.log('pppppp')
  return dispatch => {
    dispatch(setBrandData(details))
  }
}
export const saveCsvAction = (data) => {
  return dispatch => {
    return dispatch(setCsvData(data))
  }
}
export const saveTabData = (data) => {
  return dispatch => {
    return dispatch(setTabData(data))
  }
}
export const saveinitialValuesAction = (data) => {
  return dispatch => {
    return dispatch(setInitialData(data))
  }
}
export const setPreviewDataAction = (data) => {
  return dispatch => {
    return dispatch(setPreviewData(data))
  }
}