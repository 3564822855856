import React, { Component } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import capitalize from 'lodash/capitalize'
import DoughnutChart from '../Charts/DoughnutChart'
import ToolTipContainer from '../../utils/components/ToolTipContainer'
import { DemographicTips } from '../../i18n/tooltips'
import BarChart from '../Charts/BarChart'

class Demographic extends Component {

  state = {
    activeTab: 0,
    marks: {},
    labels: [],
    chartData : [],
    numberCustomersPerMonths : [],
    revenuePerPublisher : []
  }

  categories = [ 
    {key : 'Revenue Distribution', xLabel : 'Year-Month', yLabel : 'Branch Distribution'},
    {key : 'Brand Count', xLabel : 'Year-Month', yLabel : 'Brand Count'},
    {key : 'Revenue Per Publisher', xLabel : 'Year-Month', yLabel : 'Publisher'}
  ]

  categories1 = [ 
    {key : 'Revenue Distribution', xLabel : 'Year-Month', yLabel : 'Branch Distribution'},
    {key : 'Brand Count', xLabel : 'Year-Month', yLabel : 'Brand Count'},
    {key : 'Unique customer', xLabel : 'Year-Month', yLabel : 'Unique customer'}
  ]

  onClickTabItem = (tab) => {
    this.setState({ activeTab: tab });
  }

  componentDidMount() {
    let { data } = this.props
    console.log("Chart Data     :    "+JSON.stringify(data));
    let marks = this.getSliderMarks()
    let labels = Object.keys(data)
    let activeTab = 0; 

    let chartData = [];
    if(data.brandsCountPerMonth) {
      data.brandsCountPerMonth.forEach(element => {
        chartData.push({label: element._id,
        value: element.count,
        _id: element._id});
      });  
    }
    // let numberCustomersPerMonths = [];
    // if(data.numberCustomersPerMonths) {
    //   data.numberCustomersPerMonths.forEach(element => {
    //     numberCustomersPerMonths.push({label: element._id.transMonth,
    //     value: element.numberOfUniqueCustomer,
    //     _id: element._id.transMonth});
    //   });
    // }
    let numberCustomersPerMonths = data.numberCustomersPerMonths;
    let revenuePerPublisher = data.revenuePerPublisher;
   
    this.setState({
      marks,
      labels,
      activeTab,
      chartData,
      numberCustomersPerMonths,
      revenuePerPublisher
    })
  }
  getSliderMarks = () => {
    let { data } = this.props
    let labels = Object.keys(data)
    return labels.reduce((result, label, index) => {
      result[index] = (<ToolTipContainer toolTipText={DemographicTips[label.toLowerCase()]} id={DemographicTips[label.toLowerCase()]}><span style={{ marginLeft: '18px' }}>{capitalize(label)}</span></ToolTipContainer>)
      return result
    }, {})
  } 

  render() {
    const { activeTab, labels,chartData, numberCustomersPerMonths, revenuePerPublisher } = this.state;
    // console.log("revenuePerPublisher",revenuePerPublisher);
    let { data } = this.props;
    let xLabel = "";
    if(this.props.data.filterData && this.props.data.filterData.year) {
      xLabel = "Year-Week";
    } else {
      xLabel = "Year-Month";
    }

    let categories = [], isShowPublisher = false;
    if(this.props.data.filterData && (!this.props.data.filterData.data_source || !this.props.data.filterData.data_source == "All")) {
      categories = this.categories;
      isShowPublisher = true;
    } else {
      categories = this.categories1;
    }

    return (
      labels.length === 0
        ? ''
        : (
          <div className="card col demographic">
            <div className="row h-100">
              {/* <div className="col-md-4 col-sm-5 col-5 v-divider p-0" style={{ borderRight: '1px solid #eee' }}>
                <div className="card-title">
                  Conversion Statistics
                </div>
                <div className="timeline">
                  <Slider
                    min={0}
                    max={labels.length - 1}
                    step={1}
                    value={activeTab}
                    vertical
                    marks={this.getSliderMarks()}
                    onChange={(value) => { this.onClickTabItem(value) }}
                  />
                </div>
              </div> */}
              <div className="w-100 card-title">
                <div className="row align-items-baseline m-0">
                 
                  <div className="col-md-12">
                    <ul className="mt-2 mb-3 nav nav-pills">
                      {
                        categories.map((category, i) => {
                          return (
                            <li key={i} className="nav-item col pl-1 pr-1" onClick={() => this.onClickTabItem(i)}>
                              <button className={`btn btn-sm nav-link tab-selector w-100 ${activeTab === i ? 'active btn-primary' : ''}`}>
                                {category.key == "Revenue Distribution" ? "GMV Distribution" : 
                                   category.key == "Revenue Per Publisher" ? "Publisher" :  capitalize(category.key)}
                              </button>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                  
                </div>
                
              </div>
              <div className="col-md-12 col-sm-7 col-7 d-flex align-items-center">
                {activeTab === 0 ? <DoughnutChart data={data[labels[activeTab]]} height={250} /> : "" }
                {activeTab === 1 ? <BarChart xLabel={xLabel} dontSort={true}  yLabel='Brand Count'  data={chartData} /> : "" }

                {activeTab === 2 && !isShowPublisher ? <BarChart xLabel={xLabel} dontSort={true}  yLabel='Unique customer'  data={numberCustomersPerMonths} /> : "" }
                {activeTab === 2 && isShowPublisher ? <DoughnutChart data={revenuePerPublisher} height={250} /> : "" }
              </div> 
              </div>
          </div>
        )
    )
  }
}


export default Demographic
