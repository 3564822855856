import React, { Component } from "react";
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

import LoginPage from './LoginPage'
import SignupPage from './SignupPage'
import ForgotPassword from './ForgotPassword';
import CreatePassword from './CreatePassword'
import Notifications from 'react-notification-system-redux';
class PublicPage extends Component {
  render() {
    let {notifications}=this.props
    return (
      <div className="container-fluid overflow-auto h-100">
        <Switch>
          <Redirect exact path='/' to='/login' />
          <Route path="/login" component={LoginPage} />
          <Route path="/signup" component={SignupPage} />
          <Route path='/forgotpassword' component={ForgotPassword} />
          <Route path='/changerecoverpassword/:enEmail' component={CreatePassword} />
        </Switch>
        <Notifications 
          notifications={notifications}
        />
      </div>
    );
  }
}

const mapStateToProp = state => ({
  user: state.user,
  notifications:state.notifications
});

export default connect(mapStateToProp)(PublicPage)

