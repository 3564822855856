import React, { useState } from "react";
import { Button, FormControl, InputLabel, Select, MenuItem, Switch, FormControlLabel, TextField } from "@mui/material";

// Days of the week array
const daysOfWeek = [
    { label: "M", value: "Mon" },
    { label: "T", value: "Tue" },
    { label: "W", value: "Wed" },
    { label: "T", value: "Thu" },
    { label: "F", value: "Fri" },
    { label: "S", value: "Sat" },
    { label: "S", value: "Sun" }
];

const DayPicker = ({ txnFrequency, setTxnFrequency, selectedDays, setSelectedDays }) => {
    const toggleDay = (day) => {
        setSelectedDays((prev) =>
            prev.includes(day) ? prev.filter((d) => d !== day) : [...prev, day]
        );
    };
    console.log("Toggle Day    :  "+JSON.stringify(selectedDays))

    // Handle transaction frequency change
    const handleTxnFrequencyChange = (event) => {
        setTxnFrequency(event.target.value);
    };

    return (
        <div style={{ paddingTop: "20px" }}>
            <TextField
                label="Transaction Frequency"
                type="number"
                value={txnFrequency}
                onChange={handleTxnFrequencyChange}
                fullWidth
                style={{ marginBottom: "20px" }}
                inputProps={{ min: 0 }} // Optional: Prevent negative numbers
            />


            {/* Days of the Week Selection */}
            <h3 style={{ fontSize: 'larger' }}>Select Days of the Week</h3>
            <div style={{ display: "flex", justifyContent: "space-between", maxWidth: "200px" }}>
                {daysOfWeek.map((day) => (
                    <Button
                        key={day.value}
                        variant={selectedDays.includes(day.value) ? "contained" : "outlined"}
                        onClick={() => toggleDay(day.value)}
                        style={{
                            minWidth: "30px",
                            borderRadius: "50%",
                            margin: "5px",
                        }}
                    >
                        {day.label}
                    </Button>
                ))}
            </div>
        </div>
    );
};

export default DayPicker;
