import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Card, CardBody, Col, Container, Row, Table } from "reactstrap";
// import { load as loadAccount } from './../../../redux/reducers/commonReducer';
// import { Field, reduxForm } from 'redux-form';
// import { connect } from 'react-redux';
import classNames from 'classnames';

// import fetchMethodRequest from '../../../config/service';

// Loader
// import Loader from '../../App/Loader';

// Slect
// import Select from '../../../shared/components/form/Select'

export default class ViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowData: {},
      label: '',
      status: '',
      type: null
    };
  }

  componentDidMount = () => {
    this.props.onRef(this);
  };

  componentWillUnmount() {
    this.props.onRef(null);
  }

  getRowData = async (rowData) => {
    this.setState({
      rowData: rowData,
    });
  };

  render() {
    const modalClass = classNames({
      'modal-dialog--colored': this.state.colored,
      'modal-dialog--header': this.state.header,
    });
    return (
      <div style={{ display: 'flex' }}>
       {this.state.rowData && this.state.rowData.length > 0 ?
              <div className="row form" >
                {this.state.rowData.map((item, i) => {
                  return (
                    item.value ?
                      <div className="col-sm-6" key={i}>
                        <div className="row" style={{ margin: "auto" }}>
                          <div
                            className="col-sm-5"
                            style={{ textAlign: "left", padding: "5px 5px" }}>
                            <span style={{ fontWeight: "bold", textTransform: "capitalize" }}>
                              {item.label}
                            </span>
                          </div>

                          <div className="col-sm-7"
                            style={{ textAlign: "left", padding: "5px 0px" }}>
                            <span 
                            className={item.name && item.name=='status' ? `badge viewInfoBadge ${item.value=='Approved'?'approvedBadge':item.value=='Rejected'?'rejectedBadge':item.value=='Pending'?'pendingBadge':item.value=='Deleted'?'deletedBadge':item.value=='Updated'?'updatedBadge':''}`
                             :''}
                             >{item.value}</span>
                          </div>
                        </div>
                      </div> : null
                  );
                })}
              </div>
              : null}

      </div >
    );

  }
}
// export default ViewModal
// ViewModal = reduxForm({
//   form: "View Form", // a unique identifier for this form
// })(ViewModal);

// // You have to connect() to any reducers that you wish to connect to yourself
// ViewModal = connect(
//   state => ({
//     initialValues: state.commonData.data // pull initial values from account reducer
//   }),

//   { load: loadAccount } // bind account loading action creator
// )(ViewModal);

