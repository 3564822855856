import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserProfileAction, getEntityDetails } from '../../redux/actions'
import { SimpleUserForm } from '../../components/Forms/User'
import UserInfo from '../../components/SideBar/UserInfo.component';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import config from './../../config/config'
import { notificationOpts } from '../../config'
import ProfileImg from './updateProfileImg';
//session expiry modal
import SessionExpiryModal from './../../components/sessionExpiryModal';
import { GetSessionValue } from '../../utils/sessionStorage';
class UserProfile extends Component {
  state = {
    isDisabled: true,
    btnLabel: 'Update Profile',
    userDetails: '',
    sessionExpiryModal: false
  }
  onSubmit = (values, actions) => {
    let { updateUserProfile, successNotification } = this.props;
    if (values && values.merchantIds && typeof (values.merchantIds) == 'string') {
      let merchantIds = values.merchantIds
      values.merchantIds = merchantIds.split(',')
    }
    let body = {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      brandName: '',
      address: '',
    }
    if (values) {
      body['email'] = values['email'];
      body['firstName'] = values['firstName'];
      body['lastName'] = values['lastName'];
      body['phone'] = values['phone'];
      body['brandName'] = values['brandName'];
      body['address'] = values['address'];
    }

    if (values['_id']) {
      body['_id'] = values['_id']
      body['brandId'] = values['brandId']
    }
    updateUserProfile(body, (res, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal })
      actions.setSubmitting(false)
      if (type == 'error') {
        actions.setStatus(res)
        return;
      }
      this.onToggleEdit()
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = 'User profile updated successfully'
        successNotification(notification)
      })
      this.setState({ isDisabled: true })
    })
  }

  formikRef = (e) => {
    this.formik = e
  }
  formikSubmit = () => {
    if (this.formik)
      this.formik.submitForm()
  }
  onToggleEdit = () => {
    let isDisabled = !this.state.isDisabled
    // if (isDisabled && this.formikRef) {
    //   this.formikRef.resetForm()
    // }
    this.setState({ isDisabled: isDisabled })
  }
  getActionBtns = () => {

    if (this.state.isDisabled) {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" onClick={this.onToggleEdit}>{/*<Icon fontSize="small">edit</Icon>*/} Edit </button>
        </div>
      )
    } else {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" onClick={this.formikSubmit}>Update Details</button>
        </div>
      )
    }
  }
  componentDidMount = () => {
    this.updateUserProfile()
  }
  updateUserProfile = async () => {
    let { getEntityDetails } = this.props
    getEntityDetails(config.entityType, false, async (res, type) => {
      if (type == 'success') {
        await this.setState({ userDetails: res })
      } else {
        let sessionExpiryModal = false
        let sessionExpired = GetSessionValue('sessionExpired')
        if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
          sessionExpiryModal = true
        }
        await this.setState({ sessionExpiryModal: sessionExpiryModal })
      }
    })
  }
  render() {
    let { user } = this.props;
    return (
      <div>
        <div className="row create-header">
          <h3 className="col-md-6 offset-3 text-center py-3">User Profile</h3>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-6">
            <div className="card">
              <div className="">
                {/* <UserInfo 
                user={user} 
                screenType="profile"
                onRef={(ref)=>this.userInforef=(ref)}
                /> */}

                {/* <button onClick={e=>this.userInforef && this.userInforef.updateProfileImage?this.userInforef.updateProfileImage():console.log('sorry')}>Hiiiii</button> */}
                <ProfileImg
                  //  name="profile"
                  //  field="name"
                  user={user}
                  update={this && this.userInforef && this.userInforef.updateProfileImage ? this.userInforef.updateProfileImage() : ''}
                />
                <SimpleUserForm
                  onSubmit={this.onSubmit}
                  initialValues={user}
                  options={{ ...this.state }}
                  formikRef={this.formikRef}
                />
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                />
                {this.getActionBtns()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default connect(
  state => ({
    user: state.user
  }),
  dispatch => ({
    updateUserProfile: bindActionCreators(updateUserProfileAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    getEntityDetails: bindActionCreators(getEntityDetails, dispatch)
  })
)(UserProfile);

