import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getCampaignOverviewAction } from '../../redux/actions'

import CampaignOverviewLayout from '../CampaignOverviewLayout'
import moment from 'moment'

class CampaignOverviewPage extends Component {

  handleFilterChange = filter => {
    let { getCampaignOverview } = this.props;
    if (filter.id) {
      let { history } = this.props;
      history.push(`/user/campaigns/reports/${filter.id}`)
      return;
    }
    getCampaignOverview(filter)
  }
  componentDidMount() {
    let { getCampaignOverview } = this.props;
    getCampaignOverview({
      end_date: moment().startOf('month').toISOString()
    })
  }

  render() {
    let { campaignOverview } = this.props;
    return (
      <CampaignOverviewLayout
        campaignOverview={campaignOverview}
        title={'Campaign Overview'}
        handleFilterChange={this.handleFilterChange}
        displayFilter={true}
      />
    );
  }
}
export default connect(
  state => ({
    campaignOverview: state.campaignOverview.data
  }),
  dispatch => ({
    getCampaignOverview: bindActionCreators(getCampaignOverviewAction, dispatch),
  })
)(CampaignOverviewPage)




