import axiosMethodRequest from '../../config/service'
import { DELETE_BRANCH } from '../../redux/newBrandBranch/actionTypes'
export const deleteBranchByIdApiCall = (id) => {
    return dispatch => {
        const url = `merchants/${id}`
        return axiosMethodRequest('DELETE', url).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: DELETE_BRANCH,
                    payload: { data: response.data, loader: false }
                })
            }
        })
    }
}