import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import CreatableSelect from 'react-select/lib/Creatable';
import { newBrandBranchSelectors } from '../../redux/newBrandBranch';
import { getEntityBranchApicall } from '../../services/newBrandBranch/getBranchListApiCall';
import { getUniqueArray } from '../../utils/helpers';

// export default function BranchSelect(props) {
const BranchSelect = (props) => {
    console.log("---------BranchSelect-----------", props)

    const dispatch = useDispatch();

    const [branchList, setbranchList] = useState([]);
    const [defaultBranchList, setdefaultBranchList] = useState([]);
    // const [selectedBranchFromDB, setSelectedBranchFromDB] = useState([]);

    const Branchstate = useSelector(
        newBrandBranchSelectors.getBranchstate
    );
    const existingBrand = useSelector(
        newBrandBranchSelectors.getExistingBrandState
    );

    const deleteBranchstate = useSelector(
        newBrandBranchSelectors.deleteBranchstate
    );

    const Brandstate = useSelector(
        newBrandBranchSelectors.getBrandstate
    );

    const getWhiteLabelPlatformList = (from) => {
        let list = [];
        if (props.brandRowData) {
            props.brandRowData.selectedWhiteLabelPlatforms.forEach(element => {
                let obj = {
                    phases: element.phases,
                    label: element.label,
                    partnerName: element.partnerName,
                    partnerSerialNo: element.partnerSerialNo,
                    value: element.value,
                    _id: element._id,
                    offerStartDate: element.offerStartDate ? new Date(element.offerStartDate) : "",
                    offerEndDate: element.offerEndDate ? new Date(element.offerEndDate) : ""
                }

                if (from == "select") {
                    obj.branchName = "";
                    obj.branchCopy = "";
                    obj.displayAddress = "";
                    obj.gmap_address = "";
                    // obj.branchStartDate = element.offerStartDate ? new Date(element.offerStartDate) : "";
                    // obj.branchEndDate = element.offerEndDate ? new Date(element.offerEndDate) : "";
                    // obj.minStartDate = element.offerStartDate ? new Date(element.offerStartDate) : "";
                    // obj.maxEndDate = element.offerEndDate ? new Date(element.offerEndDate) : "";
                    obj.bookingLink = "";
                    obj.cuisines_list = [];
                    obj.isFeatured = false;
                    obj.isBranchLive = false;
                    obj.branchPhase = [];
                    obj.branchAssets = [
                        { branchImage: "", branchVideo: "" }
                    ];
                    // obj.branchExperience = {
                    //     expStartDate: element.offerStartDate ? new Date(element.offerStartDate) : "",
                    //     expEndDate: element.offerEndDate ? new Date(element.offerEndDate) : "",
                    //     expName: "",
                    //     expCopy: "",
                    //     expAssets: [
                    //         { "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": "" }
                    //     ]
                    // }
                }
                list.push(obj);
            });
        }
        return list;
    }


    useEffect(() => {
        let { brands, branch } = existingBrand.data
        if (brands.length > 0) {
            let { name } = brands[0]
            getBranchData(name, "brandname")
        } else {
            getBranchData("", "name")
        }
        if (branch.length > 0) {
            let finalBranch = branch.map((res, index) => {
                res.isExisting = true;
                if (res.whitelabel) {
                    res.selectedWhiteLabelPlatforms = [];
                } else {
                    res.selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("");
                    res.selectedWhiteLabelPlatforms.forEach(element => {
                        element.branchAssets = [
                            { branchImage: "",  branchVideo: "" }
                        ];
                        // element.branchExperience = {
                        //     expStartDate: "",
                        //     expEndDate: "",
                        //     expName: "",
                        //     expCopy: "",
                        //     expAssets: [
                        //         { "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": "" }
                        //     ]
                        // }
                    });
                }

                let whitelabels = getWhiteLabelPlatformList("");
                if (res.whitelabel) {
                    Object.keys(res.whitelabel).forEach(element => {
                        let w_filter = whitelabels.filter(aa => aa.partnerSerialNo == element)[0] || {};
                        let whitelabelValues = res.whitelabel[element];

                        if(Object.keys(w_filter).length == 0) {
                            return;
                        }

                        let phase = [];
                        if (w_filter.phases) {
                            w_filter.phases.forEach(e2 => {
                                if (whitelabelValues.branchPhase && whitelabelValues.branchPhase.includes(e2.phaseNumber)) {
                                    phase.push({
                                        ...e2,
                                        value: e2.phaseNumber,
                                        label: e2.phaseNumber
                                    });
                                }
                            });
                        }

                        if (!whitelabelValues.branchAssets) {
                            whitelabelValues.branchAssets = [
                                { branchImage: "", branchVideo: "" }
                            ]
                        }

                        // if (!whitelabelValues.branchExperience) {
                        //     whitelabelValues.branchExperience = {
                        //         expStartDate: "",
                        //         expEndDate: "",
                        //         expName: "",
                        //         expCopy: "",
                        //         expAssets: [
                        //             { "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": "" }
                        //         ]
                        //     }
                        //     whitelabelValues.isBranchExperience = false;
                        // } else {
                        //     whitelabelValues.branchExperience.expStartDate = whitelabelValues.branchExperience.expStartDate ? new Date(whitelabelValues.branchExperience.expStartDate) : "";
                        //     whitelabelValues.branchExperience.expEndDate = whitelabelValues.branchExperience.expEndDate ? new Date(whitelabelValues.branchExperience.expEndDate) : "";
                        //     whitelabelValues.isBranchExperience = true;
                        // }

                        res.selectedWhiteLabelPlatforms.push({
                            ...w_filter,
                            value: w_filter.partnerSerialNo,
                            label: w_filter.partnerName,
                            branchName: whitelabelValues.branchName,
                            branchCopy: whitelabelValues.branchCopy,
                            displayAddress: whitelabelValues.displayAddress,
                            gmap_address: whitelabelValues.gmap_address,
                            // branchStartDate: new Date(whitelabelValues.branchStartDate),
                            // branchEndDate: new Date(whitelabelValues.branchEndDate),
                            // minStartDate: new Date(w_filter.offerStartDate),
                            // maxEndDate: new Date(w_filter.offerEndDate),
                            bookingLink: whitelabelValues.bookingLink,
                            cuisines_list: whitelabelValues.cuisines_list || [],
                            // branchBannerVideo: whitelabelValues.branchBannerVideo,
                            isFeatured: whitelabelValues.isFeatured,
                            isBranchLive: whitelabelValues.isBranchLive,
                            // branchBannerImg: whitelabelValues.branchBannerImage.branchBannerImg,
                            // branchBannerThumbnail: whitelabelValues.branchBannerImage.branchBannerThumbnail,
                            branchPhase: phase,
                            branchAssets: whitelabelValues.branchAssets,
                            // branchExperience: whitelabelValues.branchExperience,
                            // isBranchExperience: whitelabelValues.isBranchExperience
                        });
                    });
                } else if(!res.whitelabel && whitelabels.length > 0) {
                    res.selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("select");
                }

                return res
            });
            props.setSelectedBranchList([...finalBranch])
        }
        if (branch.length == 0 && deleteBranchstate.data.respCode) {
            props.setSelectedBranchList([])
        }

    }, [existingBrand.data]);


    useEffect(() => {
        if (Brandstate.data.length > 0) {
            let selectData = [];
            const uniqueBranch = getUniqueArray(Brandstate.data, 'merchant_name'); //merchant_name
            uniqueBranch.forEach((item, index) => {
                let brand_id = "";
                if (props.selectedBrand && props.selectedBrand.details && typeof props.selectedBrand.details == "object" && props.selectedBrand.details.brands &&
                    props.selectedBrand.details.brands.brand_id) {
                    brand_id = props.selectedBrand.details.brands.brand_id;
                } else if (existingBrand && existingBrand.data && existingBrand.data.brands && existingBrand.data.brands[0] &&
                    existingBrand.data.brands[0].brandId) {
                    brand_id = existingBrand.data.brands[0].brandId;
                }
                if (item.merchant_name && item.brand_id == brand_id) {
                    selectData.push({ label: item.merchant_name, value: item._id || index, item })
                }
            })
            if (selectData.length == 0) {
                getBranchData("", "name")
            } else {
                if (existingBrand.data.branch.length == 0 && Branchstate.type == "brandname")
                    setdefaultBranchList(selectData)
                setbranchList(selectData)
            }
        }
    }, [Brandstate.data]);

    useEffect(() => {
        if (defaultBranchList.length > 0) {
            let defaultSelectedData = []
            const uniqueBranch = getUniqueArray(Brandstate.data, 'merchant_name'); //merchant_name

            uniqueBranch.forEach((item, index) => {
                if (item.merchant_name) {
                    if (!item.selectedWhiteLabelPlatforms) item.selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("");
                    defaultSelectedData.push(parseObj(item));
                }
            })
            props.setSelectedBranchList([...defaultSelectedData])
        }
    }, [defaultBranchList]);

    const getBranchData = (value, key) => {
        let filterCriteria = {
            'criteria': [
                {
                    'key': key,
                    'value': value,
                    'type': 'regexOr',
                },
                { "key": "active", "value": null, "type": "eq" },
            ]
        }
        dispatch(getEntityBranchApicall(filterCriteria))
    }

    const getColWidht = () => {
        let width = "col-md-4";
        let widthList = { 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: 10 };
        Object.keys(widthList).forEach(res => {
            if (props.selectedBranchList.length == widthList[res]) {
                width = `col-md-${widthList[res]}`
                return width
            }
            if (defaultBranchList.length == widthList[res]) {
                width = `col-md-${widthList[res]}`
                return width
            }
            return width
        })
        if (width != "col-md-4") {
            return width
        }
        if (props.selectedBranchList.length <= 4 || defaultBranchList.length <= 4) {
            width = "col-md-4"
            return width
        }
        if (props.selectedBranchList.length >= 11 || defaultBranchList.length >= 11) {
            width = "col-md-11"
        }
        return width
    }

    const parseObj = (items) => {
        items.tripadvisorUrl = items.tripadvisorUrl || items.entity_url;
        items.amexMerchantID = items.amexMerchantID || "";
        items.merchantId = items.merchantId || "";
        items.name = items.merchant_name || "";
        items.contactEmail = items.email || "";
        items.contactPhone = items.phone_no ? items.phone_no.replace(/ /g, "") : "";
        items.brandName = items.brandname;
        items.brandId = items.brand_id;
        return items
    }

    return (
        <div className="row align-items-center">
            <div className={getColWidht()}>
                <div className="form-group">
                    <label><b>Select Branch {props.selectedBranchList.length > 0 && `( ${props.selectedBranchList.length} )`}</b></label>
                    {/* {branchList.length > 0 && */}
                    <CreatableSelect
                        defaultValue={existingBrand.data.branch.length > 0 ? existingBrand.data.branch.map(item => {
                            return { label: item.name, value: item.kmid, item }
                        }) :
                            defaultBranchList.map(item => {
                                return { label: item.label, value: item.value, item }
                            })
                        }
                        key={`${existingBrand.data.branch.length}branchselect`}
                        // key={`${selectedBranchFromDB.length}branchselect`}
                        isMulti
                        name="branch"
                        options={branchList}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onInputChange={(value, actionMeta) => {
                            if (actionMeta.action == "input-change") {
                                if (value.length >= 3)
                                    getBranchData(value, "name")
                            }
                        }}
                        onChange={(value, data) => {
                            // select-option
                            // remove-value
                            if (value.length == 0) {
                                props.setSelectedBranchList([])
                                return
                            }

                            if (data.action == "pop-value") { return }
                            if (data.action == "remove-value") {
                                let index = props.selectedBranchList.findIndex(res => res.name == data.removedValue.label)
                                props.selectedBranchList.splice(index, 1)
                                props.setSelectedBranchList([...props.selectedBranchList])
                                return
                            }
                            if (data.action == "create-option") {
                                let brandId, brandName, brandObjId;
                                brandId = existingBrand.data.brands[0].brandId
                                brandName = existingBrand.data.brands[0].brandname
                                brandObjId = existingBrand.data.brands[0]._id
                                let object = {
                                    address: "",
                                    brandId: brandId,
                                    brand_id: brandId,
                                    brandObjId: brandObjId,
                                    brandName: brandName,
                                    created_by: "ui",
                                    created_date: new Date(),
                                    datasource: "ui",
                                    email: "",
                                    entity_id: "",
                                    entity_url: "",
                                    latlong: "",
                                    logo_url: "",
                                    merchant_name: "",
                                    name: value[value.length - 1].value,
                                    phone_no: "",
                                    website_url: "",
                                    isNew: true,
                                    cuisines_list: []
                                    // brandObjId: brandDetails.brandDetails.details.brands._id
                                }

                                object.selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("");
                                object.selectedWhiteLabelPlatforms.forEach(element => {
                                    element.branchName = "";
                                    element.branchCopy = "";
                                    element.displayAddress = "";
                                    element.gmap_address = "";
                                    // element.branchStartDate = element.offerStartDate ? new Date(element.offerStartDate) : "";
                                    // element.branchEndDate = element.offerEndDate ? new Date(element.offerEndDate) : "";
                                    // element.minStartDate = element.offerStartDate ? new Date(element.offerStartDate) : "";
                                    // element.maxEndDate = element.offerEndDate ? new Date(element.offerEndDate) : "";
                                    element.bookingLink = "";
                                    element.cuisines_list = [];
                                    // element.branchBannerVideo = "";
                                    element.isFeatured = false;
                                    element.isBranchLive = false;
                                    // element.branchBannerImg = "";
                                    // element.branchBannerThumbnail = "";
                                    element.branchPhase = [];
                                    element.branchAssets = [
                                        { branchImage: "", branchVideo: "" }
                                    ];
                                    // element.branchExperience = {
                                    //     expStartDate: element.offerStartDate ? new Date(element.offerStartDate) : "",
                                    //     expEndDate: element.offerEndDate ? new Date(element.offerEndDate) : "",
                                    //     expName: "",
                                    //     expCopy: "",
                                    //     expAssets: [
                                    //         { "branchExpImage": "", "branchExpThumbnail": "", "branchExpVideo": "" }
                                    //     ]
                                    // }
                                });

                                const list = [...props.selectedBranchList]
                                list.push(object)
                                props.setSelectedBranchList(list)
                            } else {

                                if (existingBrand.data.branch.length > 0) {
                                    let selectedOpt = existingBrand.data.branch.filter(res2 => {
                                        if (data.option.value == res2._id)
                                            return res2
                                    })
                                    // selectedOpt
                                    if (selectedOpt.length > 0) {
                                        let data1 = [...props.selectedBranchList]
                                        if (!selectedOpt[0].selectedWhiteLabelPlatforms) {
                                            selectedOpt[0].selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("select");
                                        }
                                        selectedOpt[0].cuisines_list = selectedOpt[0].cuisines_list || [];
                                        data1.push(selectedOpt[0])
                                        props.setSelectedBranchList([...data1])
                                    } else {
                                        let items = parseObj(data.option.item)
                                        if (!items.selectedWhiteLabelPlatforms) {
                                            items.selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("select");
                                        }
                                        let data1 = [...props.selectedBranchList]
                                        items.cuisines_list = items.cuisines_list || [];
                                        data1.push(items);
                                        props.setSelectedBranchList([...data1])
                                    }
                                } else {
                                    let items = parseObj(data.option.item);
                                    if (!items.selectedWhiteLabelPlatforms) {
                                        items.selectedWhiteLabelPlatforms = getWhiteLabelPlatformList("select");
                                    }
                                    let data1 = [...props.selectedBranchList]
                                    items.cuisines_list = items.cuisines_list || [];
                                    data1.push(items);
                                    console.log("data1", data1);
                                    props.setSelectedBranchList([...data1])
                                }
                            }
                        }}
                    />

                </div>
            </div>
        </div>
    )
}


export default React.memo(BranchSelect);
