import React, { Component } from 'react'
import uniqueId from 'lodash/uniqueId'
import placeHolderImg from '../../assets/images/placeholder150.png'
import config from '../../config/config'
import axiosMethodRequest from '../../config/service'
import { GetSessionValue } from '../sessionStorage'
class FormikImageUpload extends Component {
  constructor(props) {
    super(props)
    this.id = uniqueId('imageupload-')
    this.state = {
      image: props.image,
      error: null
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      this.setState({ image: this.props.image })
    }
  }
  handleChange = e => {
    let { onChange, uploadType } = this.props;
    let file = e.target.files[0]
    let image = '';
    if (file) {
      file['preview'] = URL.createObjectURL(file);
      image = URL.createObjectURL(file)
      let loginData = GetSessionValue('loginCredentials');
      let headers = {
        'Accept': 'application/json',
        'Authorization': `Bearer ${loginData.accessToken}`,
      };
      let appendFielData = {};
      let fileUrl = ''
      appendFielData = {
        logUserId: loginData.userId ? loginData.userId : '',
      }
      let fileId = `${file.name}-${file.lastModified}`;
      fileUrl = `${config.apiUrl}uploads/files?preview=${file.preview}&size=${file.size.toString()}&fileId=${fileId}&mimetype=${file.type}&name=${file.name}&uploadPath=${uploadType ? uploadType : "brands"}`
      let body = file.slice(0, file.size + 1)
      let url = fileUrl
      // axiosMethodRequest('POST',url,body).then((response)=>{
      //   console.log('response',response)
      // })
      // return
      let request = fetch(url, { method: 'POST', headers: headers, body: body })
      return request.then(res => res.json())
        .then(async responseJson => {
          // this.fetchData()
          if (responseJson && responseJson.respCode) {
            this.setState({ error: null })
          } else {
            this.setState({ error: responseJson.errorMessage })
          }
          console.log('repsonsen json', responseJson)
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            onChange(responseJson.fileName)
          };
          reader.onerror = function (error) {
          };

          // return responseJson;
        }).catch(err => {
          return 'serverError';
        })


    } else {
      onChange(null)
    }

    this.setState({ image })

  }
  render() {
    let { image, uploadType } = this.props;
    let { disabled } = this.props;
    let { error } = this.state

    return (
      <div className="file-upload">
        <label htmlFor={this.id} >
          {
            image
              ? <img src={`${config.imgUrl}${uploadType ? uploadType : "brands"}/${image}`} alt="logo"
                onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImg }} />
              : <img src={placeHolderImg} alt="placeholder" />
          }
        </label>
        <input id={this.id} accept="image/*" type="file" disabled={disabled} onChange={this.handleChange} />
        <div>
          {error && (<span className='error'>error</span>)}
        </div>
      </div>
    )
  }
}

export default FormikImageUpload;