

const INITIAL_STATE_ENTITY_LIST = {
  page: 1,
  sizePerPage: 0,
  items: [],
  isLoading: false,
  totalCount: 0,
  filters: {}
}

const entities = type => {
  return (state = INITIAL_STATE_ENTITY_LIST, action) => {
    switch (action.type) {
      case `SET_${type.toUpperCase()}_DATA`:
        return { ...state, ...action.payload, items: action.payload, totalCount: action.pagination.totalCount, isLoading: false };
      case `SET_${type.toUpperCase()}_PAGE`: {
        return { ...state, ...action.payload }
      }
      case `SET_${type.toUpperCase()}_SORT`: {
        return { ...state, filters: { ...state.filters, ...action.payload } }
      }
      case `SET_${type.toUpperCase()}_SEARCH`: {
        let newState = { ...state, filters: { ...state.filters, ...action.payload } }
        if (!action.payload.search)
          delete newState.filters.search
        return newState
      }
      case `SET_${type.toUpperCase()}_FILTERS`: {
        let newState = { ...state, filters: { ...state.filters, ...action.payload } }
        let filters = newState.filters
        Object.keys(filters).forEach(key => {
          if (!filters[key])
            delete filters[key]
        })
        return newState
      }
      case `CLEAR_${type.toUpperCase()}_FILTERS`: {
        return {}
      }
      case `SET_${type.toUpperCase()}_DATA_LOADINGSTATE`: {
        return { ...state, isLoading: action.payload }
      }
      default:
        return state;
    }
  }
}

export default entities;