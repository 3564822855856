const configMessages = {
    name: 'Name is required',
    minName: 'Name should at least contain 3 characters',
    maxName: 'Name should at most contain 100 characters',
    email: 'Email is required',
    validEmail: 'Please provide a valid email',
    phone: 'Contact Phone number is required',
    phoneValidation: "Contact Phone number doesn't contain alphabets and special characters",
    role: 'Role is required',
    brandId: 'Merchant Id is required',
    amexMerchantID: 'Amex/Zettle Merchant ID is required',
    password: 'Password is required',
    minPassword: 'Password should at least contain 8 characters',
    maxPassword: 'Password should at most contain 20 characters',
    passwordMatch: 'Passwords must match',
    expireTokenTime: 'Expire token time is required',
    enableMails: 'Enable mails is required',
    sendGridMails: 'Send Grid Mail is required',
    sendGridApi: 'Send Grid Api key is required',
    multipleLogin: 'Multiple Login is required',
    merchantApproveByAdmin: 'Merchant approve by admin is required',
    merchantApproveByUser: 'Merchant approve by user is required',
    userRequired: 'User is required',
    subject: 'Subject is required',
    templateText: 'Template Text is requried',
    address: 'Addresss is required',
    registration: 'Registration Id is required',
    website: 'Website is required',
    description: 'Description is required',
    brand: 'Brand is required',
    deactivationMail: 'Mail is required',
    gocardlessTokens: 'Token is required',
    postCode: 'Post Code is required'
}
export default configMessages;