export const getCampaignApprovalstate = (state) => {
  return state.campaignApproval.campaignList
}; 

export const getCampaignAssetsStatus = (state) => {
  return state.campaignApproval.campaignAssets
};

export const getUserstate = (state) => {
  return state.user
};
