import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { getPublisherBrandList, updatePublisherActionCenterCall } from './../services/brandActionCenter/createBrandActionCenter';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { success as successNotificationAction } from 'react-notification-system-redux';
import { notificationOpts } from './../config';
import { GetSessionValue } from './../utils/sessionStorage';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    cancelText = "Cancel",
    centered = false,
    modalData
}) => {

    const dispatch = useDispatch();
    const [brandList, setBrandList] = useState([]);
    const [effectiveFrom, setEffectiveFrom] = useState(new Date());
    const [distributorDetail, setDistributorDetail] = useState({});
    const [comments, setComments] = useState("");

    useEffect(() => {
        let distr = GetSessionValue('distributor');
        setDistributorDetail(distr);
        let reqData = {
            id: modalData._id || "all",
            distributors_name: distr.distributors_name
        };
        dispatch(getPublisherBrandList(reqData, response => {
            if (response.respCode && response.brandList) {
                response.brandList.forEach(element => {
                    element.checked = true;
                });
                setBrandList(response.brandList);
            }
        }));
    }, []);

    const updateBrandList = (index, value) => {
        let temp = JSON.parse(JSON.stringify(brandList));
        temp[index].checked = value;
        setBrandList(temp);
    }

    const updatePublisherActionCenter = () => {
        let reqData = {
            id: modalData._id || "all",
            effectiveFrom: effectiveFrom,
            distributors_name: distributorDetail.distributors_name,
            comments: comments
        };
        dispatch(updatePublisherActionCenterCall(reqData, response => {
            if (response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = response.message;
                dispatch(successNotificationAction(notification));
                callback()
            }
        }));
    }

    return (
        <>
            <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                <ModalComponent.Header closeButton>
                    <ModalComponent.Title>
                        Onboard Brand
                    </ModalComponent.Title>
                </ModalComponent.Header>
                <ModalComponent.Body>

                    <div className="row col-12 form edit-action-center-modal">

                        <div className="row col-4 m-l-0">
                            <div className="form-group next-upload-date">
                                <label>Effective From</label> <br />
                                {effectiveFrom && <span className='date-value'> {moment(effectiveFrom).format('YYYY-MM-DD').toString()}</span>}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        inputVariant="outlined"
                                        inputFormat="yyyy-MM-dd"
                                        views={['year', 'month', 'date']}
                                        value={effectiveFrom}
                                        InputAdornmentProps={{
                                            position: "start"
                                        }}
                                        onChange={(newValue, e) => {
                                            setEffectiveFrom(newValue);
                                        }}
                                    >
                                    </KeyboardDatePicker>
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className="row col-5 m-l-0">
                            <FormControl className='w-100 p-l-r-10' variant="standard">
                                <TextField
                                    id='comments'
                                    name='comments'
                                    value={comments}
                                    type="text" label="Comments"
                                    onChange={e => { setComments(e.target.value) }}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>

                        <div className="row col-12 publisher-box">
                            {brandList.map((res, index2) =>
                                <div className="col-3 p-0" key={"brand" + index2}>
                                    <input type='checkbox' name={"brand" + res.name}
                                        defaultChecked={res.checked} className="distributor-checkbox"
                                        id={"brand" + res.name}
                                        onChange={(e) => {
                                            updateBrandList(index2, e.target.checked);
                                        }}
                                    />
                                    <span className='m-l-5'> {res.name} </span>
                                </div>
                            )}
                        </div>

                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button variant="default" onClick={close}>
                        {cancelText}
                    </Button>
                    <Button variant="success" onClick={() => updatePublisherActionCenter()} >
                        Mark All brand(s) Onboarded
                    </Button>
                </ModalComponent.Footer>
            </ModalComponent>
        </>
    )
}

export default Modal