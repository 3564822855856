import {
  GET_CAMPAIGN_APPROVAL_DATA,
  GET_CAMPAIGN_ASSETS_DATA
} from './actionTypes';

export const initialState = {
  campaignList: {
    loader: false,
    data: [],
    bankEmployees: []
  },
  campaignAssets: {
    loader: false,
    data: { docs: [], brands: {} }
  }
};

export default (
  state = initialState,
  action
) => {
  switch (action.type) {

    case GET_CAMPAIGN_APPROVAL_DATA: {
      return {
        ...state,
        campaignList: {
          ...state.campaignList,
          loader: action.payload.loader,
          data: action.payload.data,
          bankEmployees: action.payload.bankEmployees
        },
      };
    }
    case GET_CAMPAIGN_ASSETS_DATA: {
      return {
        ...state,
        campaignAssets: {
          ...state.campaignAssets,
          loader: action.payload.loader,
          data: action.payload.data
        },
      };
    }
    default:
      return state;
  }
};
