import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getCampaignOverviewAction } from '../../redux/actions'

import CampaignOverviewLayout from '../CampaignOverviewLayout'
import moment from 'moment'

class Home extends Component {

  handleFilterChange = filter => {
    let { history, getCampaignOverview } = this.props;
    if (filter.id) {
      history.push(`/user/campaigns/reports/${filter.id}`)
      return
    }
    getCampaignOverview(filter)
  }

  componentDidMount() {
    let { getCampaignOverview } = this.props;
    getCampaignOverview({
      start_date: moment().startOf('month').toISOString(),
      end_date: moment().startOf('month').toISOString() /* server will make sure it is correct date */
    })
  }
  render() {
    let { campaignOverview } = this.props;
    return (
      <CampaignOverviewLayout
        campaignOverview={campaignOverview}
        title={'Dashboard'}
        handleFilterChange={this.handleFilterChange} />
    );
  }
}
export default connect(
  state => ({
    campaignOverview: state.campaignOverview.data
  }),
  dispatch => ({
    getCampaignOverview: bindActionCreators(getCampaignOverviewAction, dispatch),
  })
)(Home)

