
const apiCalls = {
    loginUser: 'auth/login',
    ForgotPassword: 'auth/forgotPassword',
    changePassword: 'auth/changePassword',
    ChangeRecoveryPassword: 'auth/changeRecoverPassword',
    users: 'users',
    updateUserProfile: 'uploads?uploadPath=employee',
    uploaduserprofile: 'users/picture',
    screenRecordUploadUrl: 'uploads/files?uploadPath=video',
    //
    createCampaign: 'campaigns',
    getCampaignName: 'campaigns/campaignName',
    logoutUser: 'auth/logout',
};
export default apiCalls;
