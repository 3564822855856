// import KrowdLogo from '../../assets/images/Krowd_logo.jpg';
// import BarclaysLogo from '../../assets/images/barclays-logo.png';
// import CajunCrabLogo from '../../assets/images/cajuncrab-logo.jpg';

import { DeleteSessionValue, GetSessionValue, SetSessionValue } from "../../utils/sessionStorage"

let User = GetSessionValue('user') ? GetSessionValue('user') : null
// if (User) {
//   if (User['type'] == 'bank')
//     User['logo'] = BarclaysLogo
//   else
//     User['logo'] = CajunCrabLogo  
// }
let loader = false

export default function (state = User, action) {
  switch (action.type) {
    case 'SET_USER': {
      let user = action.payload ? { ...state, ...action.payload } : null
      if (!user) {
        DeleteSessionValue('user')
      }
      else {
        // delete user.logo
        //SetSessionValue('user', user)
        SetSessionValue('user', user)
        // if (user['type'] == 'bank')
        //   user['logo'] = BarclaysLogo
        // else
        //   user['logo'] = CajunCrabLogo
      }
      return user
    }
    case 'SET_USER_ENTITY_DETAILS': {
      let user = action.payload ? { ...state, entity: { ...action.payload } } : null
      if (!user)
        DeleteSessionValue('user')
      else {
        SetSessionValue('user', user)
      }
      return user
    }
    case 'SET_LOADER': {
      loader = action.payload ? { ...state, ...action.payload } : null
      return loader
    }
    case 'SET_SETTINGS_DATA': {
      let data = action.payload ? { ...state, ...action.payload } : null
      return data
    }
    default:
      return state
  }
}

