import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import rootReducer from "./reducers";
import { createLogger } from 'redux-logger';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const logger = createLogger({
  diff: true
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware,
    //logger
  ))
);

export default store;
