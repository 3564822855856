import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import { DistributorForm } from '../../components/Forms/Distributor'

class IngestionLogs extends Component {

    columns = [
        {
            dataField: 'data_source',
            sort: true,
            text: 'Data Source',
            classes: 'vhalign'
        },
        {
            dataField: 'uploadedDate',
            sort: true,
            text: 'Uploaded Date',
            classes: 'vhalign'
        },
        {
            dataField: 'next_ingestion_date',
            sort: true,
            text: 'Next ingestion date',
            classes: 'vhalign'
        },
        {
            dataField: 'GMV',
            sort: true,
            text: 'GMV',
            classes: 'vhalign'
        },
        {
            dataField: 'txnCount',
            sort: true,
            text: 'Transaction count',
            classes: 'vhalign'
        }
    ]

    render() {
        return <EntityList
            heading='ingestionLog'
            entityType="ingestionLog"
            preset={{ type: 'data_source' }}
            prefix="brand"
            icon="account_tree"
            columns={this.columns}
            apiUrl={'ingestionLogs'}
            globalsearchPlaceHolder='Search ingestion logs'
        />
    }
}

export default connect(
    null,
    dispatch => ({
        getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
    })
)(IngestionLogs);

