
import { combineReducers } from 'redux';

const campaignOverviewSearch = (state = null, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGNOVERVIEW_SEARCH':
      return action.payload;
    default:
      return state;
  }
}

const campaignOverviewSearchOptions = (state = null, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGNOVERVIEW_SEARCH_OPTIONS':
      return action.payload;
    default:
      return state;
  }
}

const campaignOverviewData = (state = null, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGNOVERVIEW_DATA':
      return action.payload
    default:
      return state;
  }
}

const campaignOverviewDataLoadingstate = (state = null, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGNOVERVIEW_DATA_LOADINGSTATE':
      return action.payload;
    default:
      return state;
  }
}

const campaignOverviewRawData = (state = null, action) => {
  switch (action.type) {
    case 'SET_RAW_CAMPAIGNOVERVIEW_RAW_DATA':
      return action.payload;
    default:
      return state;
  }
}

const localData = (state = null, action) => {
  switch (action.type) {
    case 'SET_CAMPAIGNOVERVIEW_FROM_LOCAL':
      return action.payload;
    default:
      return state;
  }
}

export default combineReducers({
  search: campaignOverviewSearch,
  searchOptions: campaignOverviewSearchOptions,
  data: campaignOverviewData,
  loadingState: campaignOverviewDataLoadingstate,
  rawData: campaignOverviewRawData,
  localData
})

