import React, { Component } from "react";
import moment from 'moment'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import { CampaignTemplateForm } from '../../components/Forms/CampaignTemplateForm'

class CampaignTemplateComponent extends Component {

  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'type',
      sort: true,
      text: 'Type',
      classes: 'vhalign',
      formatter: (cell, row) => {
        return <strong>{cell}</strong>
      }
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      classes: 'vhalign',
    },
    {
      dataField: 'created',
      sort: true,
      text: 'Created',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        return moment(cell).format('DD/MM/YYYY')
      }
    },
    {
      dataField: 'updated',
      sort: true,
      text: 'Updated',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        return moment(cell).format('DD/MM/YYYY')
      }
    }
  ]

  componentDidMount() {
    let { getEntityForSelect } = this.props;
    getEntityForSelect('bank')
    getEntityForSelect('brand')
  }

  render() {
    return <EntityList
      heading='campaignTemplate'
      entityType="user"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="domain"
      EntityForm={CampaignTemplateForm}
      columns={this.columns}
      showPasswordReset={true}
      apiUrl={'campaignTemplates'}
      globalsearchPlaceHolder='Search Email'
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(CampaignTemplateComponent);

