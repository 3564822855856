
import axios from '../../utils/AxiosWrapper'
import axiosMethodRequest from '../../config/service'
import apiCalls from '../../config/apiCalls'
const setCampaignData = (type, data, pagination, bankEmployees) => {
	return {
		type: `SET_${type.toUpperCase()}_CAMPAIGN_DATA`,
		payload: data,
		pagination: pagination,
		bankEmployees: bankEmployees
	}
}
const setCampaignLoadingState = (type, loadingState) => {
	return {
		type: `SET_${type.toUpperCase()}_CAMPAIGN_DATA_LOADINGSTATE`,
		payload: loadingState
	}
}

const setCurrentCampaignCount = count => {
	return {
		type: 'SET_CURRENT_CAMPAIGN_COUNT',
		payload: count
	}
}

export const setCampaignsPageAction = type => {
	return (page, sizePerPage) => {
		return {
			type: `SET_${type.toUpperCase()}_CAMPAIGN_PAGE`,
			payload: {
				page,
				sizePerPage
			}
		}
	}
}
export const setCampaignsSortAction = type => {
	return (sortField, sortOrder) => {
		return {
			type: `SET_${type.toUpperCase()}_CAMPAIGN_SORT`,
			payload: {
				sortField,
				sortOrder
			}
		}
	}
}

export const setCampaignsSearchAction = type => {
	return (search) => {
		return {
			type: `SET_${type.toUpperCase()}_CAMPAIGN_SEARCH`,
			payload: { search }
		}
	}
}

export const setCampaignsFiltersAction = type => {
	return (filters) => {
		return {
			type: `SET_${type.toUpperCase()}_CAMPAIGN_FILTERS`,
			payload: filters
		}
	}
}

export const setCampaignForEditAction = campaign => {
	return {
		type: 'SET_CAMPAIGN_EDIT',
		payload: campaign
	}
}


export const setSelectedCampaignAction = campaign => {
	return {
		type: 'SET_SELECTED_CAMPAIGN',
		payload: campaign
	}
}

export const getCampaignAction = (campaignType) => {

	return (page, pageSize, filters, type, customerType, status, callback) => {
		return dispatch => {
			// setCampaignLoadingState(type, true)
			let url = ''
			let filterCriteria = {};
			filterCriteria['limit'] = pageSize;
			filterCriteria['page'] = page;
			filterCriteria['criteria'] = [];
			filterCriteria['direction'] = 'desc';
			filterCriteria.sortfield = 'created';
			filterCriteria.customerType = customerType;
			filterCriteria.status = status || []
			///////global search
			if (filters && filters.globalSearch && filters.globalSearch.value && filters.globalSearch.value.length > 3) {
				filterCriteria.globalSearch = filters.globalSearch
			}
			if (!page) {
				filterCriteria['page'] = 1;
			}
			if (!pageSize) {
				filterCriteria['limit'] = 10;
			}
			////////sorting 
			if (type == "sort" && filters.sortField && filters.sortField.direction) {
				filterCriteria['limit'] = pageSize;
				filterCriteria['page'] = page;
				filterCriteria['criteria'] = [];
				filterCriteria['direction'] = filters.sortField.direction
				filterCriteria.sortfield = filters.sortField.sortfield;
			}
			url = `${apiCalls.createCampaign}?filter=${JSON.stringify(filterCriteria)}${campaignType ? `&campaignType=${campaignType}` : ''}`;
			dispatch(setCampaignLoadingState(campaignType, true))
			return axiosMethodRequest('GET', url).then((response) => {
				console.log('response', response)
				if (response && response.data && response.data.respCode) {
					let data = response.data.campaigns;
					let pagination = response.data.pagination;
					let bankEmployees = response.data.bankEmployees;
					if (callback)
						callback(data, pagination)
					dispatch(setCampaignData(campaignType, data, pagination, bankEmployees))
					dispatch(setCampaignLoadingState(campaignType, false))
				} else {
					if (callback)
						callback()
				}
			})
		}
	}
}

export const getCampaignByIdAction = id => {
	return dispatch => {
		return axios
			.get(`/campaigns/${id}`)
			.then(res => {
				let data = res.data
				dispatch(setSelectedCampaignAction(data))
			})
	}
}


export const deleteCampaignAction = (campaign, callback) => {
	return dispatch => {
		return axiosMethodRequest('DELETE', `campaigns/${campaign._id}`).then((response) => {
			if (response && response.data && response.data.respCode) {
				if (callback)
					callback()
			} else {
				if (callback)
					callback(response.data.errorMessage)
			}
		})

		// return axios
		// 	.delete(`/campaigns/${campaign.id}`)
		// 	.then(res => {
		// 		if (callback)
		// 			callback()
		// 	})
		// 	.catch(err => {
		// 		if (callback)
		// 			callback(err)
		// 	})
	}
}

export const recurringCampaignAction = (campaign, callback) => {
	return dispatch => {
		return axiosMethodRequest('PUT', `campaigns/recurring/${campaign._id}/${campaign.isrecurring}`, campaign.campaign).then((response) => {
			if (response && response.data && response.data.respCode) {
				if (callback)
					callback(response.data, "")
			} else {
				if (callback)
					callback("", response.data.errorMessage)
			}
		})
	}
}


export const getCampaignNameAction = (callback) => {
	return dispatch => {
		return axiosMethodRequest('GET', apiCalls.getCampaignName).then((response) => {
			if (response && response.data && response.data.respCode) {
				callback(response.data, 'success')
			} else {
				callback(response.data.errorMessage, 'error')
			}
		})
	}
}

export const createCampaignAction = (data, callback) => {
	return dispatch => {
		return axiosMethodRequest('POST', apiCalls.createCampaign, data).then((response) => {
			if (response && response.data && response.data.respCode) {
				// callback(response.data.respMessage)
				callback(null)
			} else {
				callback(response.data.errorMessage)
			}
		})
		// return axios.post('/campaigns', data)
		// 	.then(res => {
		// 		callback(null)
		// 	})
		// 	.catch(err => {
		// 		callback(err)
		// 	})
	}
}
export const bulkCreateCampaignAction = (data, callback) => {
	return dispatch => {
		return axios.post('/campaigns/bulk', data)
			.then(res => {
				callback(null)
			})
			.catch(err => {
				callback(err)
			})
	}
}

export const editCampaignAction = (data, callback) => {
	return dispatch => {
		// console.log(data)
		// return axios.put(`/campaigns/${data.id}`, data)
		// 	.then(res => {
		// 		callback(null)
		// 	})
		// 	.catch(err => {
		// 		callback(err)
		// 	})
	}
}

export const getCurrentCampaignCountAction = () => {
	return dispatch => {
		return axios.get(`/campaigns/current/count`)
			.then(res => {
				dispatch(setCurrentCampaignCount(res.data))
			})
			.catch(err => {

			})
	}
}
