import {
  GET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA,
  GET_GROUP_BRANDLIST_DATA,
  GET_PARENT_CAMPAIGN_DATA
} from './actionTypes';

export const initialState = {
  dashboardData: {
    loader: false,
    data: []
  },
  groupBrand: {
    data: [],
    errorCode : "",
    errorMessage: ""
  },
  parentCampaignDetails: {
    data: {}
  }
};

export default (
  state = initialState,
  action
) => {
  switch (action.type) {

    case GET_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          loader: action.payload.loader,
          data: action.payload.data
        },
      };
    }
    case CLEAR_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          loader: false,
          data:  []
        },
      };
    }
    case GET_GROUP_BRANDLIST_DATA: {
      return {
        ...state,
        groupBrand: {
          ...state.groupBrand,
          data: action.payload.data,
          errorCode : action.payload.errorCode,
          errorMessage: action.payload.errorMessage
        },
      };
    }
    case GET_PARENT_CAMPAIGN_DATA: {
      return {
        ...state,
        parentCampaignDetails: {
          ...state.parentCampaignDetails,
          data: action.payload.data,
          is_two_customer: action.payload.is_two_customer
        },
      };
    }
    default:
      return state;
  }
};
