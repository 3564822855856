import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
import configMessages from './../../config/configMessages';
import { onlycharWithSpaceRegex, phoneRegex } from '../../utils/constants/constant';
import { bindActionCreators } from 'redux'
import { getBrandListAction } from '../../redux/actions'
import MultiSelect from "react-multi-select-component";
import ToolTipContainer from '../../utils/components/ToolTipContainer'

class Group_BrandForm extends Component {

  state = {
    // group_brand_ids: [],
    brandList: [],
    overrideStrings: {
      "selectSomeItems": "Select Brands",
      "allItemsAreSelected": "All brands are selected.",
      "selectAll": "Select All Brands",
      "search": "Search",
      "clearSearch": "Clear Search"
    }
  }

  componentDidMount() {
    this.getBrands();
  }

  getBrands = () => {
    let { options, values } = this.props;
    let { getBrandList } = options;

    if (getBrandList) {
      getBrandList(res => {
        if (res) {
          this.setState({ brandList: res });
        }
      })
    }
  }

  customValueRenderer = (selected, _options) => {
    if (selected.length != _options.length) {
      return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "multiple brand selected" : "No Items Selected"
    }
  };

  onSelect = (selectedItem, props) => {
    props.setFieldValue("group_brand_ids", selectedItem);
    // this.setState({ group_brand_ids : selectedItem  });
  }

  // handleInputChange = (e, filed, value) => {

  // }

  render() {
    let { status, values, handleChange, handleBlur, setFieldValue } = this.props;

    return (
      <Form>

        <div className="form-group">
          <label>Role</label>
          <select name="role" className="form-control" value={values.role}
            onChange={(e) => {
              setFieldValue("role", e.target.value)
            }}>
            <option value={"groupAdmin"}>Group Admin</option>
          </select>
          <ErrorMessage name="role" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Group Name </label>
          <Field name="group_name" className="form-control" />
          <ErrorMessage name="group_name" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Group type</label>
          <select name="group_type" className="form-control" value={values.group_type}
            onChange={(e) => {
              setFieldValue("group_type", e.target.value)
            }}>
            <option value={"ownBrands"}>Own Brands</option>
            <option value={"aggregator"}>Aggregator</option>
            <option value={"franchise"}>Franchise</option>
          </select>
          <ErrorMessage name="group_type" component="label" className="error" />
        </div>

        <div className="form-group dropdown w-100">

          <label>
            Select Brands
          </label>

          <MultiSelect
            className='multi-select'
            options={this.state.brandList}
            value={values.group_brand_ids}
            onChange={(value) => {
              this.onSelect(value, this.props);
              // setFieldValue('branches', value)
            }}
            labelledBy={"Select brand"}
            required={true}
            overrideStrings={this.state.overrideStrings}
            valueRenderer={this.customValueRenderer}
          />
          {/* {this.state.group_brand_ids ? <label className='error'>Please select Brand</label> : ''} */}
          <ErrorMessage name="group_brand_ids" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>First name </label>
          <Field name="firstName" className="form-control" />
          <ErrorMessage name="firstName" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Last name </label>
          <Field name="lastName" className="form-control" />
          <ErrorMessage name="lastName" component="label" className="error" />
        </div>


        <div className="form-group">
          <label>Email </label>
          <Field name="email" className="form-control" />
          <ErrorMessage name="email" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Phone </label>
          <Field name="phone" className="form-control" />
          {/* <ErrorMessage name="phone" component="label" className="error" /> */}
        </div>

        <div className="form-group">
          <label>Invoice Preference</label>
          <select name="invoice_preference" value={values.invoice_preference} className="form-control"
            onChange={(e) => {
              setFieldValue("invoice_preference", e.target.value)
            }}>
            <option value=""> Select Invoice Preference </option>
            <option value={"group"}>Group</option>
            <option value={"brand"}>Brand</option>
          </select>
          <ErrorMessage name="invoice_preference" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Invoice Preference Level 2</label>
          <select name="invoice_preference_level_2" value={values.invoice_preference_level_2} className="form-control"
            onChange={(e) => {
              setFieldValue("invoice_preference_level_2", e.target.value)
            }}>
            <option value=""> Select Invoice Preference Level 2</option>
            {values.invoice_preference == "group" &&
              <>
                <option value={"brand"}>Brand</option>
                <option value={"publisher"}>Publisher</option>
              </>
            }
            {values.invoice_preference == "brand" &&
              <>
                <option value={"publisher"}>Publisher</option>
                <option value={"branch"}>Branch</option>
              </>
            }


          </select>
          <ErrorMessage name="invoice_preference_level_2" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Separate Invoice
            {values.invoice_preference_level_2 == "brand" &&
              " for each publisher"
            }
            {values.invoice_preference_level_2 == "branch" &&
              " for each publisher"
            }
            {values.invoice_preference == "group" && values.invoice_preference_level_2 == "publisher" &&
              " for each brand"
            }
            {values.invoice_preference == "brand" && values.invoice_preference_level_2 == "publisher" &&
              " for each branch"
            }
          </label>
          <select name="separateInvoice" value={values.separateInvoice} className="form-control"
            onChange={(e) => {
              setFieldValue("separateInvoice", e.target.value)
            }}>
            <option value=""> Select Separate Invoice </option>
            <option value={"Yes"}>Yes</option>
            <option value={"No"}>No</option>
          </select>
          <ErrorMessage name="separateInvoice" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Payment Terms Days</label>
          <Field name="payment_terms_days" className="form-control" />
          <ErrorMessage name="payment_terms_days" component="label" className="error" />
        </div>
        
        <div className="form-group">          
          <div className="row col-12 m-t-10">
            <div className="col-1">
            <input type='checkbox' name="two_invoice_per_month"
              defaultChecked={values.two_invoice_per_month} className="distributor-checkbox"
              id="two_invoice_per_month"
              onChange={(e) => {
                setFieldValue("two_invoice_per_month", e.target.checked);
              }}
            />
            </div>
            <div className="col-11 p-l-0">
              Two Invoice Per month
            </div>
          </div>          
        </div> 

        <input
          value={'prefix'}
          onChange={handleChange}
          onBlur={handleBlur}
          name="entityType"
          type="hidden"
          className="form-control" />
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

class FormikForm extends Component {
  initialValues = {
    'role': 'groupAdmin',
    'group_name': '',
    'group_type': 'ownBrands',
    'email': '',
    'group_brand_ids': [],
    'firstName': '',
    'lastName': '',
    'phone': '',
    'invoice_preference': '',
    'invoice_preference_level_2': '',
    'separateInvoice': '',
    'two_invoice_per_month': true,
    'payment_terms_days' : ''
  }
  validationSchema = YupObject({
    role: YupString()
      .required("Please select role"),
    group_name: YupString()
      .required(configMessages.name),
    // .matches(onlycharWithSpaceRegex, 'Group name can only contain [A-Za-z_ -\s@\.]')
    // .min(3, configMessages.minName)
    // .max(100, configMessages.maxName),
    group_type: YupString().required("Please select group type"),
    email: YupString()
      .required(configMessages.email)
      .email(configMessages.validEmail),
    group_brand_ids: YupString()
      .required("Please select the group"),
    firstName: YupString()
      .required("First name is required"),
    // .matches(onlycharWithSpaceRegex, 'First name can only contain [A-Za-z_ -\s@\.]'),
    lastName: YupString()
      .required("Last name is required"),
    // .matches(onlycharWithSpaceRegex, 'Last name can only contain [A-Za-z_ -\s@\.]'),
    // phone: YupString()
    //   .matches(phoneRegex, `Enter a valid contact number`)
    //   .required(configMessages.phone)
    invoice_preference: YupString()
      .required("Please select Invoice Preference"),
    invoice_preference_level_2: YupString()
      .required("Please select Invoice Preference level 2"),
    separateInvoice: YupString()
      .required("Please select Separate Invoice"),
      payment_terms_days: YupNumber()
  })

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options, getBrandList } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <Group_BrandForm {...props} options={{ banks, getBrandList, ...options }} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

export const GroupBrandForm = connect(
  null,
  dispatch => ({
    getBrandList: bindActionCreators(getBrandListAction, dispatch)
  })
)(FormikForm)