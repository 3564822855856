import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import UserInfo from './UserInfo.component';
import Nav from './Nav.component';
import Settings from './Settings.component'
import { setMobileNavVisibility, toggleSidebarVisibility } from '../../redux/reducers/layout';
import Icon from '@material-ui/core/Icon'
class SideBar extends Component {

  render() {
    let {
      backgroundColor,
      backgroundImage,
      user,
      hideMobileMenu,
      mobileNavVisibility,
      toggleSidebarMenu,
      sidebarNavVisibility
    } = this.props;

    return (
      <div className="sidebar" data-color={backgroundColor} data-image={backgroundImage}>
        <div className="sidebar-wrapper">
          <div
            className="desktop-sidebar-close"
            onClick={toggleSidebarMenu}>
            {
              sidebarNavVisibility ?
                <i><Icon className="left-circle-icon" style={{ fontSize: "30px" }}>remove_circle_outline</Icon></i> :
                <i><Icon className="menu-icon" style={{ fontSize: "30px" }}>reorder</Icon></i>
            }
          </div>
          {mobileNavVisibility && <div className="mobile-sidebar-close text-right mr-2" onClick={hideMobileMenu}>
            <i><Icon className="menu-icon" style={{ fontSize: "30px" }}>play_circle_outline</Icon></i>
          </div>}
          <UserInfo user={user} />
          <Nav user={user} />
          <Settings user={user} />
        </div>
      </div>
    )
  }
}

export default withRouter(
  connect(
    state => ({
      enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
      backgroundColor: state.ThemeOptions.backgroundColor,
      backgroundImage: state.ThemeOptions.backgroundImage,
      user: state.user,
      mobileNavVisibility: state.Layout.mobileNavVisibility,
      sidebarNavVisibility: state.Layout.sidebarNavVisibility
    }),
    (dispatch, ownProps) => ({
      hideMobileMenu: () => dispatch(setMobileNavVisibility(false)),
      toggleSidebarMenu: () => dispatch(toggleSidebarVisibility())
    })
  )(SideBar)
);