import React from "react";
const TnCComponent = () => {
	return (
		<div className="p-3">
			<h2 className="text-center">Krowd Terms and Conditions</h2>
			<p className='my-2'>
				THESE TERMS AND CONDITIONS APPLY TO AND GOVERN YOUR ACCESS TO AND USE OF KROWD OFFER PLATFORM
			</p>
			<h5 className='my-2'>
				A Card Linked Offer is an offer Customers initiate by using a qualifying bank card in connection with an offer. The Customer consequently makes a Qualifying Purchase from a participating merchant (an “Merchant”).
				 The Customer receives a rebate (cashback) after an appointed term has expired.
			</h5>
			<p>
				Krowd matches cashback offers (<b>Offers</b>)
				from participating merchants (<b>Reward Partners</b>)
				 with participating bank customers (<b>Customers</b>) via
				 Krowd Offer Bidding Platform (<b>Platform</b>).
	 Reward Partners are merchants who accept these
	 Terms and Conditions and make their offer available via the Platform.
				Participating banks and financial partners (<b>Partner Banks</b>)
			   distribute offers to Customers.
			</p>
			<h4>Making the Offer Available</h4>
			<p>
				A request to make an offer available to Customers is made by a
				Reward Partner through the Platform merchant application (<b>Krowd App</b>),
				which allows the merchant to define the target Customer group, qualifying offer criteria,
				total available offer budget as well as desired level of discount and Krowd fee
				(Fee) as well as upload Reward Partner’s logo and any other images.
				Reward Partner Offer submissions can only be amended up to 5 calendar
				days before the end of the month prior to the month in which an offer is ran
				(<b>Campaign</b>). All Offers are made available to Customers on or after the first of a
				month in which the Offer Campaign applies. Once executed, the offer
				request cannot be withdrawn and the Reward Partner must honour offers made available to Customer
				through the App in accordance with the submitted request.

			</p>
			<h4>Offer Redemption</h4>
			<p>
				Offers on the Platform can only be made available to
				Partner Bank customers and are not transferable. To redeem an offer
				made available through the Platform, the Customer must:
			</p>
			<ol>
				<li>
					View the offer sent by email, inApp or text (SMS) to the Customer by
					the Partner Bank along with any additional offer terms and conditions, including validity date. In certain instances,
					 inApp offers may need to be activated by the Customer.
				</li>
				<li>
					The Customer users a qualifying Partner Bank payment card to make a payment when redeeming the Offer at the Reward Partner store.
				</li>
				<p className='my-2'>The Reward Partner must:</p>

				<li>
					Honour all offers made available through the Platform
				</li>
				<li>
					Ensure technical capability and adequate staff training which will enable the Reward Partner to apply the offer at checkout
				</li>
				<li>
					Mandate payment with Partner Bank card at offer redemption
				</li>
			</ol>
			<h4>Personal Data</h4>
			<p>
				Krowd does not store or process personal data and will never ask the
				Reward Partner to share such data with Krowd. If the offer redemption
				requires a unique code, the Reward Partner agrees to share the full list or
				redeemed codes only. Krowd assumes no
				liability for any customer data shared accidentally or intentionally with Krowd.
			</p>

			<h4>Krowd Fee</h4>
			<p>
				The Reward Partner will pay Krowd a percentage fee for each successful redemption of
				an Offer placed on the Platform by the merchant. The value of the fee will
				in all circumstances be agreed between Krowd and the Reward Partner prior to
				making the Offer available – either through a dedicated portal or directly in writing
			</p>

			<h4>Licenses</h4>
			<p>
				The Reward Partner grants Krowd and Bank Partners a royalty-free,
				non-exclusive license to use the Reward Partner’s trademarks
				submitted by the Reward Partner through the Platform for the purposes of
				making the Offer available and to be used in
				Krowd’s marketing materials, including Krowd’s website.
			</p>

			Additionally, the following conditions apply:
			<ol>
				<li>
					Customers will receive the Offers at Reward Partners on the
					total amount of the gross bill including any tax or service charges.
				</li>
				<li>
					A mobile device with the original offer email or text (SMS) must be presented at redemption.
					A Reward Partner has the right to deny the discount if the offer is presented in any other way.
				</li>
				<li>
					The use of Offers in conjunction with any other
					publicly announced promotions or reductions offered is at the discretion of Reward Partners.
				</li>
				<li>
					Krowd has the right to terminate an Offer at any time without reason or
					notice and Krowd will not be liable for any loss of damage that may arise from
					any suspension or termination.
				</li>
				<li>
					Krowd reserves the right to withdraw the privileges of Krowd if any
					Customer is in breach of the terms and conditions of Krowd.
				</li>
				<li>
					Reward Partner status may be revoked at any time at the sole discretion of
					Krowd at any time with or without notification. Reward Partner will remain liable for any Offers
					communicated to Customers even after the Reward Partner’s status is revoked.
				</li>
				<li>
					If a Reward Partner does not honour the valid Offer, the instance can be reported within
					10 working days by contacting the Partner Bank customer service.
				</li>
				<li>
					The Customer must provide complete details to Partner Bank about the merchant’s name and location,
					date of purchase, discount denied and purchase information.
				</li>
				<li>
					Krowd will use all reasonable endeavours to claim the missing
					discount value from the Reward Partner and refund it to the Customer.
				</li>
				<li>
					Krowd will not be liable for any errors, omissions, delays, or disruptions in
					the operation of Krowd or its Partners.
				</li>
				<li>
					Krowd assumes no liability for the accuracy of
					its predictions of expected revenue and Offer conversions.
				</li>
				<li>
					At certain Reward Partners, Offers may be subject to
					availability and may be altered or withdrawn without notice.
				</li>
				<li>
					Krowd is not responsible if a Reward Partner changes its
					 discount due to new ownership or acquisition.
				</li>
				<li>
					Krowd will not be liable in any way if a participating Reward Partner
					fails to provide the Offer on any product or service.
					Krowd does not accept any liability in connection with the failure or refusal of any participating Reward Partner to provide products or services. Krowd does not accept any liability or responsibility for the quality or suitability of
					products or services offered by the Reward Partners.
				</li>
				<li>
					No Reward Partner has any authority, express or implied to make a representation,
					warranty or statement on behalf of Krowd or in relation to Krowd and Krowd accepts no
					liability in respect of any representation,
					warranty or statement made by any Reward Partner.
				</li>
				<li>
					Krowd does not give any guarantee undertaking or warranty
					concerning the products or services supplied or the performance of
					any participating Reward Partner. All conditions and warranties whether express or
					implied and whether arising under statute or otherwise as to the condition, suitability, quality, fitness or safety of any goods or services supplied
					 by any Reward Partner are expressly excluded.
				</li>
				<li>
					Krowd assumes no liability with respect to the
					Customer communications of Partner Banks as well as with respect to the
					products or services provided by any of the Reward Partners.
				</li>

			</ol>
		</div>
	)
}

export default TnCComponent;

