

const entities = type => {
  return (state = null, action) => {
    switch (action.type) {
      case `SET_${type.toUpperCase()}_DETAIL_DATA`:
        return { ...state, ...action.payload, [type]: action.payload, isLoading: false };
      case `SET_${type.toUpperCase()}_DETAIL_DATA_LOADINGSTATE`: {
        return { ...state, isLoading: action.payload }
      }
      default:
        return state;
    }
  }
}

export default entities;