import React from 'react'
import Icon from '@material-ui/core/Icon'
import classNames from 'classnames'

const getCurrency = () => {
  // return <i className="fa fa-usd"></i>
  return "AED"
}

const StatCard = ({icon, value, label, type, isCurrency}) => {
  return (
    <div>
      {icon && <div className="icon-with-bg"><Icon className="text-primary" fontSize="inherit">{icon}</Icon></div>}
      <p className={classNames('bold', {"mb-2" : !icon})}>{type !== 'string' ? parseFloat(value).toLocaleString(undefined, {maximumFractionDigits : 2}) : value}</p>
      <p>
      	{label}
      	{
      		isCurrency
      		? (
      			<span> ({getCurrency()})</span>
      		) : ''
      	}
      </p>
    </div>
  )
}

export const StatsContainer = ({children, className}) => <div className={className}>{children}</div>

export default StatCard