import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
class Merchantform extends Component {
  state = {
    users: []
  }

  render() {
    let { status, isSubmitting } = this.props;
    return (
      <Form>
        <div className="form-group">
          <label>Comments</label>
          <Field name="comments" component='textarea' className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="comments" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

class FormikForm extends Component {

  initialValues = {
    'comments': '',
  }

  validationSchema = YupObject({
    comments: YupString(),
  })

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <Merchantform {...props} options={{ banks, ...options }} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

export const MerchantCommentsForm = connect(
  state => ({
    banks: state.selectEntities.banks
  }),
  null
)(FormikForm)


