import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, mixed as YupMixed } from 'yup';
import { profileImgUpdate } from '../../redux/actions/entityDetailActions'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Loader from '../../components/Loader'
import config from '../../config/config'
import EditIcon from '@material-ui/icons/Edit';
import { Badge } from 'react-bootstrap'
import placeHolderImg from '../../assets/images/placeholder150.png'
export const INITIAL_TX_UPLOAD_VALUES = {
  'transactions': '',
  'impressions': '',
  'profile': ''
}

export const INITIAL_CO_MANAGER_VALUES = {
  start_date: '',
  end_date: ''
}

class ImgUploadForm extends Component {

  state = {
    filesArray: [],
    isLoading: false,
    selectedFile: '',
    userDetails: ''
  }

  validationSchema = YupObject({
    profile: YupMixed()
      .required('Img file is required')
      .test('fileSize', 'File is empty', value => value && value.size > 0),
    impressions: YupMixed()
      .required('Impressions file is required')
      .test('fileSize', 'File is empty', value => value && value.size > 0),
  })

  fileUploadRef = {}

  handleFileRef = (name, ref) => {
    this.fileUploadRef[name] = ref;
  }

  handleFormReset = () => {
    Object.keys(this.fileUploadRef).forEach(it => {
      this.fileUploadRef[it].value = ''
    })
  }

  //after selcting files append recent files to previous files
  appendFiles = (files) => {
    let filesArray = this.state.filesArray ? this.state.filesArray : []
    for (let fl in files) {
      if (files[fl].type) {
        files[fl]['preview'] = URL.createObjectURL(files[fl]);
        filesArray.push(files[fl])
      }
    }
    this.setState({ filesArray })
    let userBody = {
      file: files[0]
    }
   if(files[0]){
    this.setState({ isLoading: true, selectedFile: files[0].preview })
   }
    this.props.profileImageUpdate(files[0], res => {
      this.setState({ isLoading: false })
    })
  }

  getForm = (props, options = {}) => {
    let { status, isSubmitting, setFieldValue, touched, handleBlur, user } = props;
    let { selectedFile } = this.state
    return (
      <Form>
        <Loader loader={this.state.isLoading} />
        <div className="form-group text-center">
          <div className="file-upload">
            <label
              htmlFor={'profile'}
              className='profile_container'
            >
              {
                selectedFile ? <img src={selectedFile} alt="logo" />
                  : this.props.user && this.props.user.photo ?
                    <img onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImg }} src={`${config.imgUrl}employee/${this.props.user.photo}`} alt="logo" className='profile__photo' /> :

                    <img src={placeHolderImg} alt="placeholder" />
              }
              <EditIcon className='icondisplay3' />
            </label>
            <input id={'profile'} accept="image/*" type="file" disabled={false} onChange={(e) => this.appendFiles(e.target.files)} />
          </div>
          <div className="photo-container d-none" id='file' style={{ height: 124 }}>
            <button className="custom-btn-img"> <img src={selectedFile ? selectedFile : `${config.imgUrl}user/${this.props.user.photo}`} className='profile_photo' /></button>
            <input id='file' type="file" className='custom-file-input-profile' onChange={(e) => this.appendFiles(e.target.files)} />
            <Badge className='upload__btn'>
              <EditIcon className='icondisplay3' />
            </Badge>
          </div>
          <ErrorMessage name="profile" component="label" c user={user} lassName="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
  noop = () => { }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options } = this.props;
    return (
      <Formik
        initialValues={initialValues || INITIAL_TX_UPLOAD_VALUES}
        render={props => this.getForm(props, options)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        onReset={this.handleFormReset}
      />
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    profileImageUpdate: bindActionCreators(profileImgUpdate, dispatch),
  }
}

export default connect(null, mapDispatchToProps)(ImgUploadForm);
