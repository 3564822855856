import React, { Component } from "react";
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { logoutAction } from '../../redux/actions/authActions'
import ModalComponent from '../Modal'
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames'
import Icon from '@material-ui/core/Icon'

class Settings extends Component {

  state = {
    showLogoutModal: false,
  };

  onCloseLogoutModal = () => {
    this.setState({ showLogoutModal: false });
  }

  setShowLogoutModal = () => {
    this.setState({ showLogoutModal: true });
  }

  handleLogout = () => {
    this.onCloseLogoutModal()
    this.props.logout()
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    if (this.isPathActive('/user/settings'))
      this.setState({ settingsMenuOpen: true })
  }


  getBrandSettings = () => {
    return (
      <ul className="nav settings flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
            <p>
              Settings
              <b
                className={
                  classNames('caret', {
                    open: this.state.settingsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.settingsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/settings/profile')
                    })
                  }
                >
                  <Link to="/user/settings/profile">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>My Profile</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/users')
                    })
                  }
                >
                  <Link to="/user/list/users">
                    <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
                    <p>Users</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/branches')
                    })
                  }
                >
                  <Link to="/user/list/branches">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Branches</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/distributors')
                    })
                  }
                >
                  <Link to="/user/list/distributors">
                    <i><Icon style={{ fontSize: "20px" }}>group</Icon></i>
                    <p>Distributors</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/settings/settings')
                    })
                  }
                >
                  <Link to="/user/settings/settings">
                    <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
                    <p>Settings</p>
                  </Link>
                </li>
              </ul>
            </div>
          </Collapse>
        </li>
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    )
  }

  getNavForBank = () => {
    return <>
      <ul className="nav settings flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/settings/profile')
            })
          }
        >
          <Link to="/user/settings/profile">
            <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
            <p>My Profile</p>
          </Link>
        </li>
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    </>
  }

  getNavForSalesAdmin = () => {
    return (
      <ul className="nav settings flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
            <p>
              Settings
              <b
                className={
                  classNames('caret', {
                    open: this.state.settingsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.settingsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/settings/profile')
                    })
                  }
                >
                  <Link to="/user/settings/profile">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>My Profile</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/users')
                    })
                  }
                >
                  <Link to="/user/list/users">
                    <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
                    <p>Users</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/branches')
                    })
                  }
                >
                  <Link to="/user/list/branches">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Branches</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/distributors')
                    })
                  }
                >
                  <Link to="/user/list/distributors">
                    <i><Icon style={{ fontSize: "20px" }}>group</Icon></i>
                    <p>Distributors</p>
                  </Link>
                </li> 
              </ul>
            </div>
          </Collapse>
        </li>
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    )
  }

  getNavForOpsAdmin = () => {
    return (
      <ul className="nav settings flex-column">
        <li
          className={
            classNames({
              active: this.isPathActive('/user/settings') || this.state.settingsMenuOpen
            })
          }
        >
          <button onClick={() => this.setState({ settingsMenuOpen: !this.state.settingsMenuOpen })}
            data-toggle="collapse">
            <i><Icon style={{ fontSize: "20px" }}>settings</Icon></i>
            <p>
              Settings
              <b
                className={
                  classNames('caret', {
                    open: this.state.settingsMenuOpen
                  })
                }></b>
            </p>
          </button>
          <Collapse in={this.state.settingsMenuOpen}>
            <div>
              <ul className="nav flex-column">
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/settings/profile')
                    })
                  }
                >
                  <Link to="/user/settings/profile">
                    <i><Icon style={{ fontSize: "20px" }}>person_outline</Icon></i>
                    <p>My Profile</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/users')
                    })
                  }
                >
                  <Link to="/user/list/users">
                    <i><Icon style={{ fontSize: "20px" }}>person</Icon></i>
                    <p>Users</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/branches')
                    })
                  }
                >
                  <Link to="/user/list/branches">
                    <i><Icon style={{ fontSize: "20px" }}>av_timer</Icon></i>
                    <p>Branches</p>
                  </Link>
                </li>
                <li
                  className={
                    classNames({
                      active: this.isPathActive('/user/list/distributors')
                    })
                  }
                >
                  <Link to="/user/list/distributors">
                    <i><Icon style={{ fontSize: "20px" }}>group</Icon></i>
                    <p>Distributors</p>
                  </Link>
                </li> 
              </ul>
            </div>
          </Collapse>
        </li>
        <li>
          <button onClick={this.setShowLogoutModal} >
            <i><Icon style={{ fontSize: "20px" }}>lock</Icon></i>
            <p>Logout</p>
          </button>
        </li>
      </ul>
    )
  }

  getNavs = () => {
    let { user } = this.props;
    if (user.entityType === 'superAdmin')
      return this.getBrandSettings();
    else if (user.entityType === 'bank')
      return this.getNavForBank();
    else if (user.entityType === 'salesAdmin')
      return this.getNavForSalesAdmin();
    else if (user.entityType === 'opsAdmin')
      return this.getNavForOpsAdmin();
    else
      return '';
  }

  render() {
    const { showLogoutModal } = this.state
    return (
      <React.Fragment>
        <ModalComponent
          close={this.onCloseLogoutModal}
          show={showLogoutModal}
          callback={this.handleLogout}
          title={"Logout"}
          message={"Are you sure you want to Logout?"}
          action={"Logout"}
        />
        {
          this.getNavs()
        }
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    null,
    dispatch => ({
      logout: bindActionCreators(logoutAction, dispatch)
    })
  )(Settings)
);

