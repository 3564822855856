
import React, { Component } from 'react'
import CKEditor from 'ckeditor4-react';

class Editor extends Component {
    constructor(props) {
        super(props);
        this.userModified = false;
        this.state = {
            templateText: '',
            userModifiedTemp: false
        }
        this.removedPlugins = {
            allowedContent: true,
            extraPlugins: 'autotag'
        };

    }

    onEditorChange = async (evt) => {
        this.userModified = true;
        await this.setState({ userModifiedTemp: true })
        this.setState({
            templateText: evt.editor.getData()
        });
        if (this.props.editorData) {
            this.props.editorData(evt.editor.getData())
        }
    }
    // // config = null;


    // // Returns the position of the matching text.
    // // It matches a word starting from the '#' character
    // // up to the caret position.
    // itemsArray = [
    //     // (...)
    //     { id: 1703, name: 'Mentions plugin', type: 'feature' },
    //     { id: 1751, name: 'Autocomplete plugin', type: 'feature' },
    //     { id: 1746, name: 'Emoji plugin', type: 'feature' },
    //     { id: 2062, name: 'Emoji list button', type: 'feature' }
    //     // (...)
    // ];
    // config = {
    //     textTestCallback: function textTestCallback(range) {
    //         // You do not want to autocomplete a non-empty selection.
    //         if (!range.collapsed) {
    //             return null;
    //         }

    //         // Use the text match plugin which does the tricky job of performing
    //         // a text search in the DOM. The matchCallback function should return
    //         // a matching fragment of the text.
    //         return CKEDITOR.plugins.textMatch.match(range, matchCallback);
    //     },
    //     matchCallback: function matchCallback(text, offset) {
    //         // Get the text before the caret.
    //         var left = text.slice(0, offset),
    //             // Will look for a '#' character followed by a ticket number.
    //             match = left.match(/#\d*$/);

    //         if (!match) {
    //             return null;
    //         }
    //         return { start: match.index, end: offset };
    //     },
    //     dataCallback: function dataCallback(matchInfo, callback) {
    //         // Remove the '#' tag.
    //         var query = matchInfo.query.substring(1);

    //         // Simple search.
    //         // Filter the entire items array so only the items that start
    //         // with the query remain.
    //         var suggestions = this.itemsArray.filter(function (item) {
    //             return String(item.id).indexOf(query) == 0;
    //         });

    //         // Note: The callback function can also be executed asynchronously
    //         // so dataCallback can do an XHR request or use any other asynchronous API.
    //         callback(suggestions);
    //     },
    //     itemTemplate: '<li data-id="{id}" class="issue-{type}">#{id}: {name}</li>',
    //     throttle: 200


    // }

    handleNamespaceLoaded = (CKEDITOR) => {



        CKEDITOR.plugins.add('autotag', {
            requires: 'autocomplete,textmatch',

            init: function (editor) {
                editor.on('instanceReady', function () {
                    var config = {};

                    // Called when the user types in the editor or moves the caret.
                    // The range represents the caret position. 
                    function textTestCallback(range) {
                        // You do not want to autocomplete a non-empty selection.
                        if (!range.collapsed) {
                            return null;
                        }

                        // Use the text match plugin which does the tricky job of performing
                        // a text search in the DOM. The "matchCallback" function should return
                        // a matching fragment of the text.
                        return CKEDITOR.plugins.textMatch.match(range, matchCallback);
                    }

                    // Returns the position of the matching text.
                    // It matches a word starting from the '#' character
                    // up to the caret position.
                    function matchCallback(text, offset) {
                        // Get the text before the caret.
                        var left = text.slice(0, offset),
                            // Will look for a '<' character followed by a ticket number.
                            match = left.match(/<\d*$/);

                        if (!match) {
                            return null;
                        }
                        return {
                            start: match.index,
                            end: offset
                        };
                    }

                    config.textTestCallback = textTestCallback;

                    // The itemsArray variable is the example "database".
                    var itemsArray = [
                        {
                            id: 7,
                            name: 'Bank'
                        },
                        {
                            id: 8,
                            name: 'Business Name'
                        },
                        {
                            id: 5,
                            name: 'Discount'
                        },
                        {
                            id: 6,
                            name: 'Discount %'
                        },
                        {
                            id: 4,
                            name: 'Minimum Spend'
                        },
                        {
                            id: 3,
                            name: 'Spend'
                        },
                        {
                            id: 1,
                            name: 'Valid For'
                        },
                        {
                            id: 2,
                            name: 'Valid Until'
                        },
                        {
                            id: 9,
                            name: 'Landing Page'
                        },
                    ];

                    // Returns (through its callback) the suggestions for the current query.
                    function dataCallback(matchInfo, callback) {
                        // Remove the '#' tag.
                        var query = matchInfo.query.substring(1);

                        // Simple search.
                        // Filter the entire items array so only the items that start
                        // with the query remain.
                        var suggestions = itemsArray.filter(function (item) {
                            return String(item.id).indexOf(query) == 0;
                        });

                        // Note: The callback function can also be executed asynchronously
                        // so dataCallback can do an XHR request or use any other asynchronous API.
                        callback(suggestions);
                    }

                    config.dataCallback = dataCallback;

                    // Define the templates of the autocomplete suggestions dropdown and output text.
                    config.itemTemplate = '<li data-id="{id}" class="issue-{type}">&#60;{name}&#62;</li>';
                    config.outputTemplate = '&#60;{name}&#62;';

                    // Attach autocomplete to the editor.
                    new CKEDITOR.plugins.autocomplete(editor, config);
                });
            }
        });
    }
    render() {

        return (
            <CKEditor
                name="templateText"
                config={this.removedPlugins}
                data={this.state.templateText ? this.state.templateText : this.props.data}
                onChange={this.onEditorChange}
                onNamespaceLoaded={this.handleNamespaceLoaded}
            />
        )
    }
}
export default Editor;