import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
// import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
// import Icon from '@material-ui/core/Icon';
import { createTicketsApicall, updateTicketsApicall } from '../../services/brandActionCenter/createBrandActionCenter';
import { success as successNotificationAction } from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import { GetSessionValue } from '../../utils/sessionStorage';

const Modal = ({
    close,
    show,
    callback,
    size = "md",
    cancelText = "Cancel",
    centered = false,
    modalData,
    from
}) => {

    const dispatch = useDispatch();
    const [groupName, setGroupName] = useState("");
    const [brandName, setBrandName] = useState("");
    const [comments, setComments] = useState("");
    const [assignRoleTo, setAssignRoleTo] = useState("");
    const [userDetails, setUserDetails] = useState(GetSessionValue('user'));

    useEffect(() => {
        if (modalData) {
            setGroupName(modalData.groupName);
            setBrandName(modalData.brandName);
            setComments(modalData.comments);
            setAssignRoleTo(modalData.assignRoleTo);
            console.log(userDetails);
        }
    }, []);

    // const [updateFor, setUpdateFor] = useState("group");
    // const [fieldList, setFieldList] = useState([
    //     { label : "Brand Name", value : "brandName" },
    //     { label : "Website URL", value : "website" },
    //     { label : "Success Fee%", value : "successFee" },
    //     { label : "Publisher", value : "publisher" },
    //     { label : "Branch Name", value : "branchName" },
    //     { label : "Merchant ID", value : "merchantID" }
    // ]);
    // const [updateValueList, setUpdateValueList] = useState([{
    //     selectBrand: "",
    //     selectField: "",
    //     oldValue: "",
    //     newValue: "",
    //     comments: ""
    // }]);

    // const addRow = () => {
    //     let data = { selectBrand: "", selectField: "", oldValue: "", newValue: "", comments: "" };
    //     let list = JSON.parse(JSON.stringify(updateValueList));
    //     list.push(data);
    //     setUpdateValueList(list);
    // }

    // const removeRow = (index) => {
    //     let list = JSON.parse(JSON.stringify(updateValueList));
    //     list.splice(index, 1);
    //     setUpdateValueList(list);
    // } 

    const saveData = () => {
        let reqData = { groupName: groupName, brandName: brandName, comments: comments, assignRoleTo: assignRoleTo };
        reqData.createdBy = userDetails._id;
        dispatch(createTicketsApicall(reqData, response => {
            if (response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = "Ticket created successfully";
                dispatch(successNotificationAction(notification));
                callback();
            }
        }));
    }

    const updateData = () => {
        let reqData = { id: modalData._id, groupName: groupName, brandName: brandName, comments: comments, assignRoleTo: assignRoleTo };
        reqData.updatedBy = userDetails._id;
        reqData.updateDate = new Date();
        dispatch(updateTicketsApicall(reqData, response => {
            if (response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = "Ticket updated successfully";
                dispatch(successNotificationAction(notification));
                callback();
            }
        }));
    }

    return (
        <>
            <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                <ModalComponent.Header closeButton>
                    <ModalComponent.Title>
                        Create Ticket
                    </ModalComponent.Title>
                </ModalComponent.Header>
                <ModalComponent.Body>

                    <div className="row col-12">

                        <div className="col-6 m-t-10">
                            <label>Assign To</label>
                            <select
                                name="role"
                                className="form-control"
                                value={assignRoleTo}
                                defaultValue={assignRoleTo}
                                onChange={(e) => { setAssignRoleTo(e.target.value) }}>
                                <option value="" disabled>Assign To</option>
                                {userDetails.role !== "superAdmin" &&
                                    <option value="superAdmin">Super Admin</option>
                                }
                                {userDetails.role !== "admin" &&
                                    <option value="admin">Admin</option>
                                }
                                {userDetails.role !== "salesAdmin" &&
                                    <option value="salesAdmin">Sales Admin</option>
                                }
                                {userDetails.role !== "opsAdmin" &&
                                    <option value="opsAdmin">Ops Admin</option>
                                }
                                {/* {userDetails.role !== "bank" &&
                                    <option value="bank">Publisher</option>
                                } */}
                            </select>
                        </div>
                        <div className="col-6 m-t-10"></div>

                        <div className="col-6 m-t-10">
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id='groupName'
                                    name='groupName'
                                    value={groupName}
                                    type="text" label="Group Name"
                                    onChange={e => { setGroupName(e.target.value) }}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>

                        <div className="col-6 m-t-10">
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id='brandName'
                                    name='brandName'
                                    value={brandName}
                                    type="text" label="Brand Name"
                                    onChange={e => { setBrandName(e.target.value) }}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div>

                        <div className="col-12 m-t-10">
                            <FormControl className='w-100' variant="standard">
                                <TextField
                                    id='comments'
                                    name='comments'
                                    value={comments}
                                    type="text" label="Comments"
                                    onChange={e => { setComments(e.target.value) }}
                                    className={`form-control mb-3`} />
                            </FormControl>
                        </div> 

                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button variant="default" onClick={close}>
                        {cancelText}
                    </Button>
                    {!from &&
                        <Button variant="success" onClick={saveData} >
                            Save
                        </Button>
                    }
                    {from &&
                        <Button variant="success" onClick={updateData} >
                            Update
                        </Button>
                    }

                </ModalComponent.Footer>
            </ModalComponent>

        </>
    )
}

export default Modal