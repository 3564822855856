import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
import configMessages from './../../config/configMessages';
import { onlycharRegex, phoneRegex } from '../../utils/constants/constant';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from '@material-ui/pickers';

class Distributorform extends Component {

  state = {
    frequentlyList: []
  }

  setFrequentlyList = (selectedDate, setFieldValue, values) => {
    let day = moment(selectedDate).format('dddd');
    let frequentlyList = [{ value: "daily", label: "Daily" }];
    frequentlyList.push({ value: "weekdays", label: "Daily on working days" });
    frequentlyList.push({ value: "weekly", label: "Weekly on " + day });
    frequentlyList.push({ value: "fortnightly", label: "Every Two Weeks on the " + day });
    frequentlyList.push({ value: "monthly", label: "Monthly on the first " + day });
    this.setState({ frequentlyList: frequentlyList });

    if (values && values.frequently && values.frequently.value) {
      let filter = frequentlyList.filter(a => a.value == values.frequently.value)[0];
      if (filter) {
        setFieldValue({ frequently: { value: filter.value, label: filter.label } });
      }
    }
  }

  componentDidMount() {
    let { values } = this.props;
    if (values.ref_date) {
      this.setFrequentlyList(values.ref_date);
    } else {
      this.setFrequentlyList(new Date());
    }
  }

  render() {
    let { status, values, handleChange, handleBlur, setFieldValue } = this.props;

    if (values.ref_date) {
      values.ref_date = new Date(values.ref_date);
    } else {
      values.ref_date = new Date();
    }
    if (!values.frequently) values.frequently = "";
    if (!values.distributors_name) values.distributors_name = "";
    if (!values.distributors_display_name) values.distributors_display_name = "";
    if (!values.default_cashback_percentage) values.default_cashback_percentage = "";

    if (values.ref_date && (!values.frequently.value && values.frequently)) {
      let day = moment(values.ref_date).format('dddd');
      if (values.frequently == "daily") values.frequently = { value: "daily", label: "Daily" };
      if (values.frequently == "weekdays") values.frequently = { value: "weekdays", label: "Daily on working days" };
      if (values.frequently == "weekly") values.frequently = { value: "weekly", label: "Weekly on " + day };
      if (values.frequently == "fortnightly") values.frequently = { value: "fortnightly", label: "Every Two Weeks on the " + day };
      if (values.frequently == "monthly") values.frequently = { value: "monthly", label: "Monthly on the first " + day };
    }

    return (
      <Form>

        <div className="form-group">
          <label>Email </label>
          <Field name="email" className="form-control" />
          <ErrorMessage name="email" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Distributors Name </label>
          <Field name="distributors_name" className="form-control" />
          <ErrorMessage name="distributors_name" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Distributors Display Name </label>
          <Field name="distributors_display_name" className="form-control" />
          <ErrorMessage name="distributors_display_name" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Default Cashback Percentage </label>
          <Field name="default_cashback_percentage" className="form-control" />
          <ErrorMessage name="default_cashback_percentage" component="label" className="error" />
        </div>

        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="form-group next-upload-date">
            <label>Next Upload Date</label> <br />
            <DatePicker value={values.ref_date}
              onChange={(newValue) => { setFieldValue('ref_date', newValue); this.setFrequentlyList(newValue, setFieldValue, values); }}
              format="yyyy-MM-dd"
            />
          </div>
        </LocalizationProvider> */}

        <div className="form-group next-upload-date">
          <label>Reference Date</label> <br />
          {values && values.ref_date && <span className='date-value'> {moment(values.ref_date).format('YYYY-MM-DD').toString()}</span>}
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker

              inputVariant="outlined"
              inputFormat="yyyy-MM-dd"
              views={['year', 'month', 'date']}
              value={values.ref_date}
              InputAdornmentProps={{
                position: "start"
              }}
              onChange={(newValue, e) => {
                setFieldValue('ref_date', newValue); this.setFrequentlyList(newValue, setFieldValue, values);
              }}
            >

            </KeyboardDatePicker>

          </MuiPickersUtilsProvider>

        </div>


        <div className="form-group">
          <label>Frequently</label>

          <Select
            cacheOptions
            options={this.state.frequentlyList}
            value={values.frequently}
            onChange={(event) => { setFieldValue('frequently', event); }}
            classNamePrefix="reactselect"
          />
        </div>

        {/* <div className="form-group">
          <input type='checkbox' name="is_default" defaultChecked={values.is_default}
            id="is_default" onChange={(e) => { setFieldValue("is_default", e.target.checked); }}
            style={{ verticalAlign: "middle", marginTop : "-2px" }}
          />
          <span className='m-l-5'> Apply Default Cashback </span>
        </div> */}


        <input
          value={'prefix'}
          onChange={handleChange}
          onBlur={handleBlur}
          name="entityType"
          type="hidden"
          className="form-control" />
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

class FormikForm extends Component {
  initialValues = {
    'distributors_name': '',
    'distributors_display_name': '',
    'default_cashback_percentage': '',
    'ref_date': new Date(),
    'frequently': '',
    // 'is_default': false,
    'email': '',
  }
  validationSchema = YupObject({
    distributors_name: YupString()
      .required(configMessages.name),
    distributors_display_name: YupString()
      .required(configMessages.name),
    email: YupString()
      .required(configMessages.email)
      .email(configMessages.validEmail),
    // .matches(onlycharRegex, 'Distributors name can only contain [A-Za-z_-\s@\.]')
    // .min(3, configMessages.minName)
    // .max(100, configMessages.maxName)
  })

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options } = this.props;
    if(initialValues && !initialValues.email) {
       initialValues.email = "";
    }
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <Distributorform {...props} options={{ banks, ...options }} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

export const DistributorForm = connect(
  state => ({}),
  null
)(FormikForm)


