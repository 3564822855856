export const getBrandActionCenterState = (state) => {
    return state.brandActionCenter.getBrandActionCenter
}; 

export const createActionCenterState = (state) => {
    return state.brandActionCenter.createActionCenter
}; 

export const updateActionCenterState = (state) => {
    return state.brandActionCenter.updateActionCenter
};

export const getPublisherBrandActionCenterState = (state) => {
    return state.brandActionCenter.getPublisherBrandActionCenter
};

export const getBrandActivityState = (state) => {
    return state.brandActionCenter.getBrandActivity
};