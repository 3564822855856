import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, number as YupNumber } from 'yup';
import { connect } from 'react-redux'
import configMessages from './../../config/configMessages';
import { onlycharRegex, phoneRegex } from '../../utils/constants/constant';
import FormikImageUpload from '../../utils/components/FormikImageUpload';
import { getDistributorAction } from '../../redux/actions';
import { bindActionCreators } from 'redux';

class Employeeform extends Component {

  state = {
    customerType: [{ name: "All", value: "all" }, { name: "Lapsed", value: "lapsed" }, { name: "New", value: "new" }],
    distributorList : []
  }
  onCheckboxChange = (e, type) => {
    let { values, setFieldValue } = this.props;
    let customerType = values.customerType || []
    if (e.target.checked) {
      customerType.push(type)
    } else {
      if (type != "all" && !e.target.checked) {
        customerType = values.customerType.filter((value) => value != type )
      } else
        customerType = values.customerType.filter((value) => value != type)
    }
    // if (type == "all" && e.target.checked) {
    //   customerType = []
    //   this.state.customerType.forEach(res => {
    //     customerType.push(res.value)
    //   })
    // }
    setFieldValue("customerType", customerType)
  }

  componentDidMount() {
    let { options, values } = this.props;
    let { getDistributor } = options;
    if(getDistributor) {
      let url = "entity/getDistributor";
      getDistributor(url, (res) => {
        this.setState({ distributorList : res });
      });
    }
  }

  render() {
    let { status, isSubmitting, values, handleChange, handleBlur, setFieldValue } = this.props;
    let { distributorList } = this.state;
    return (
      <Form>
        {values.role == "bank" &&
          <div className='text-center pb-3'>
            <FormikImageUpload
              uploadType="employee"
              image={values.photo}
              onChange={setFieldValue.bind(null, 'photo')}
              disabled={isSubmitting}
            />
          </div>
        }
        <div className="form-group">
          <label>Email </label>
          <Field name="email" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>First Name </label>
          <Field name="firstName" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="firstName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Last Name </label>
          <Field name="lastName" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="lastName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Phone</label>
          <Field name="phone" className="form-control" maxLength='12' disabled={isSubmitting} />
          <ErrorMessage name="phone" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Role</label>
          <select
            name="role"
            className="form-control"
            defaultValue={values.role}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="" disabled>Select Role</option>
            <option value="superAdmin">Super Admin</option>
            <option value="admin">Admin</option>
            <option value="salesAdmin">Sales Admin</option>
            <option value="opsAdmin">Ops Admin</option>
            {/* <option value="bank">Bank</option> */}
            <option value="bank">Publisher</option>            
          </select>
          <ErrorMessage name="role" component="label" className="error" />
        </div>

        {values.role == "bank" && distributorList && distributorList.length > 0 &&
            <div className="form-group">
            <label>Publisher</label>
            <select
              name="distributor_id"
              className="form-control"
              defaultValue={values.distributor_id}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <option value="" disabled>Select Publisher</option>
              {distributorList && distributorList.map((dis, index) =>  {
                return ( <option value={dis._id} id={index + 'Publisher'} >{dis.distributors_display_name}</option> )
              })}
            </select>
            <ErrorMessage name="distributor_id" component="label" className="error" />
          </div>
        }

        {values.role == "bank" &&
          <div className="form-group">
            <label>Type of Customer</label>
            <div className='form-group d-flex'>
              {
                this.state.customerType.map((res, index) => {
                  return (
                    <div key={res.name} class="form-check pr-3">
                      <input class="form-check-input" name="customerType" value={res.value} type="checkbox" id={`allCheck${index}`}
                        defaultChecked={values.customerType.includes(res.value)}
                        checked={values.customerType.includes(res.value)}
                        onChange={(e) => {
                          this.onCheckboxChange(e, res.value)
                        }} />
                      <label class="form-check-label" for={`allCheck${index}`}>
                        {res.name}
                      </label>
                    </div>
                  )
                })
              }

              {/* <div class="form-check pr-3">
              <input class="form-check-input" name="customerType" value={"all"} type="checkbox" id="allCheck"
                defaultChecked={values.customerType.includes("lapsed")}
                onChange={(e) => {
                  this.onCheckboxChange(e, "lapsed")
                }} />
              <label class="form-check-label" for="allCheck">
                Lapsed
              </label>
            </div>
            <div class="form-check pr-3">
              <input class="form-check-input" name="customerType" type="checkbox" id="allCheck"
                defaultChecked={values.customerType.includes("new")}
                onChange={(e, data) => {
                  this.onCheckboxChange(e, "new")
                }} />
              <label class="form-check-label" for="allCheck">
                New
              </label>
            </div> */}
            </div>
          </div>
        }
        <input
          value={'prefix'}
          onChange={handleChange}
          onBlur={handleBlur}
          name="entityType"
          type="hidden"
          className="form-control" />
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

class FormikForm extends Component {
  initialValues = {
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    customerType: [],
    'role': 'superAdmin',
  }
  validationSchema = YupObject({
    firstName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    lastName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    email: YupString()
      .required(configMessages.email)
      .email(configMessages.validEmail),
    phone: YupString()
      .matches(phoneRegex, `Enter a valid contact number`),

    // role: YupString()
    //   .required(configMessages.role),
  })

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options, getDistributor } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <Employeeform {...props} options={{ banks,  ...options , getDistributor }} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

export const EmployeeForm = connect(
  state => ({
    banks: state.selectEntities.banks
  }),
  dispatch => ({
    getDistributor: bindActionCreators(getDistributorAction, dispatch),
  })
)(FormikForm)


