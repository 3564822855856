import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef } from 'yup';
import { onlycharRegex, passwordRegex } from '../../utils/constants/constant'

class SignupForm extends Component {

  initialValues = {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    website: '',
    registrationNum: '',
    registeredAddress: '',
    description: '',
  }

  validationSchema = YupObject({
    name: YupString()
      .required('Name is required')
      .matches(onlycharRegex, 'Name can contain only alphabets, "-" and/or space')
      .min(3, 'Name should at least contain 3 characters')
      .max(20, 'Name should at most contain 20 characters'),
    email: YupString()
      .required('Email is required')
      .email('Please provide a valid email'),
    password: YupString()
      .required('Password is required')
      .matches(passwordRegex, 'password must contain One special characters, upper case and number.')
      .min(8, 'Password should at least contain 8 characters')
      .max(20, 'Password should at most contain 20 characters'),
    confirmPassword: YupString()
      .required('Confirm password is required')
      .oneOf([YupRef('password'), null], 'Passwords must match'),
    companyName: YupString()
      .required('Company name is required')
      .matches(onlycharRegex, 'Company name can only contain [A-Za-z_-\s@\.]')
      .min(3, 'Company name should at least contain 3 characters')
      .max(100, 'Company name should at most contain 100 characters'),
    website: YupString()
      .required('Website is required')
      .url('Website url is not valid.'),
    registrationNum: YupString()
      .required('Registration number is required'),
    registeredAddress: YupString()
      .required('Registered address is required'),
    description: YupString()
      .required('Description is required')
      .max(255, 'Description should at most contain 255 characters'),
  })

  getForm = (props, options = { isDisabled: false, btnLabel: 'SignUp' }) => {
    let { status, isSubmitting } = props;
    let { isDisabled, btnLabel } = options
    return (
      <Form className="form">
        <div className="form-group">
          <label>Name</label>
          <Field name="name" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="name" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Email</label>
          <Field name="email" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Password</label>
          <Field name="password" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="password" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Confirm Password</label>
          <Field name="confirmPassword" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="confirmPassword" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Company Name</label>
          <Field name="companyName" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="companyName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Website</label>
          <Field name="website" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="website" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Registration Number</label>
          <Field name="registrationNum" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="registrationNum" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Registered Address</label>
          <Field name="registeredAddress" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="registeredAddress" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Description</label>
          <Field name="description" className="form-control" component="textarea" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="description" component="label" className="error" />
        </div>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" type="submit" disabled={isSubmitting || isDisabled}>{btnLabel}</button>
        </div>
      </Form>
    )
  }

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => this.getForm(props, options)}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}

// Lets do the mapping here going forward we'll split if required
export default SignupForm










