import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField';
import configMessages from '../../config/configMessages';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';
import { emailRegex, onlycharRegex, onlyNumberRegex, phoneRegex } from '../../utils/constants/constant';
import { newBrandBranchSelectors } from '../../redux/newBrandBranch';
import Icon from '@material-ui/core/Icon';
import * as yup from 'yup';
import { createUserApiCall } from '../../services/newBrandBranch/createUserApiCall';
import { checkBrandExistApiCall } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { deleteUserByIdApiCall } from '../../services/newBrandBranch/deleteUserByIdApiCall';
import ModalComponent from '../../components/Modal'

const UserCreate = (props) => {
    console.log("---------UserCreate-----------", props)
    const dispatch = useDispatch();

    const existingBrand = useSelector(
        newBrandBranchSelectors.getExistingBrandState
    );

    const createUserstate = useSelector(
        newBrandBranchSelectors.createUserstate
    );

    const deleteUserstate = useSelector(
        newBrandBranchSelectors.deleteUserstate
    );

    const [usersList, setusersList] = useState([]);
    const [isValidate, setisValidate] = useState(false);
    const [deleteDialog, setdeleteDialog] = useState({ data: {}, isOpen: false, index: null });
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (existingBrand.data.userList.length > 0) {
            existingBrand.data.userList.map(res => {
                res.contactPhone = res.phone
                return res
            })
            setusersList([...existingBrand.data.userList])
        } else {
            if (usersList.length == 0)
                addNewRow()
        }

        if (existingBrand.data.userList.length == 0 && deleteUserstate.data) {
            addNewRow("afterDelete")
        }
    }, [existingBrand]);

    useEffect(() => {
        if (createUserstate.error.errorCode) {
            showMessage(createUserstate.error.errorMessage, "error")
        }
        if (createUserstate.data.respCode) {
            showMessage(createUserstate.data.respMessage, "success")
            if (existingBrand.data.brands.length > 0) {
                let { brandname, brandId } = existingBrand.data.brands[0]
                dispatch(checkBrandExistApiCall({ brandName: brandname, brandId: brandId }))
            }
        }
    }, [createUserstate.data]);

    useEffect(() => {
        if (deleteUserstate.error.errorCode) {
            showMessage(deleteUserstate.error.errorMessage, "error")
        }
        if (deleteUserstate.data.respCode) {
            showMessage(deleteUserstate.data.respMessage, "success")
            if (existingBrand.data.brands.length > 0) {
                let { brandname, brandId } = existingBrand.data.brands[0]
                dispatch(checkBrandExistApiCall({ brandName: brandname, brandId: brandId }))
            }
        }
    }, [deleteUserstate.data]);


    const showMessage = (message, type) => {
        let notification = { ...notificationOpts };
        notification.message = message;
        if (type == "error") {
            dispatch(errorNotificationAction(notification))
        } else {
            dispatch(successNotificationAction(notification))
        }
    }

    const handleInputChangeNew = (e, index, type) => {
        let value = str2bool(e.target.value);
        const data1 = JSON.parse(JSON.stringify(usersList))
        if (type == "email") {
            value = value.toLowerCase()
        }
        const data2 = { ...data1[index], [type]: value }
        data1[index] = data2
        setusersList(JSON.parse(JSON.stringify(data1)))
    };

    const inputField = (row, placeholder, type, regex, index) => {
        return <>
            {type != "contactPhone" &&
                <TextField
                    autoComplete='off'
                    value={row[type]}
                    id={`userid${type}${index}`}
                    name={`username${type}${index}`}
                    type="text" label={placeholder}
                    error={isValidate && (!row[type] || (regex && !regex.test(row[type])))}
                    className={`form-control mb-2`}
                    onChange={e => handleInputChangeNew(e, index, type)}
                />
            }
            {type == "contactPhone" &&
                <TextField
                    autoComplete='off'
                    value={row[type]}
                    id={`userid${type}${index}`}
                    name={`username${type}${index}`}
                    type="text" label={placeholder}
                    className={`form-control mb-2`}
                    onChange={e => handleInputChangeNew(e, index, type)}
                />
            }
        </>
    }

    const refList = useRef(usersList.map(() => createRef()));

    const scrollToBottom = (res, index) => {
        setTimeout(() => {
            refList.current[index].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        }, 200);
    }

    var str2bool = (value) => {
        if (value && typeof value === "string") {
            if (value.toLowerCase() === "true") return true;
            if (value.toLowerCase() === "false") return false;
        }
        return value;
    }

    const checkBoxField = (row, index, list) => {
        return <>
            {list.map((res, index) =>
                <div
                    key={`${index}checkboxUser`}
                    className="form-check form-check-inline custom-align mr-4">
                    <input
                        //  disabled={isBrandCreated}
                        onChange={e => handleInputChangeNew(e, index, res.field)}
                        checked={row[res.field] == res.value} name={res.field + index}
                        type="radio" id={res.lable} className="form-check-input mr-2" value={res.value}
                    />
                    <label className="form-check-label" htmlFor={res.lable}>
                        {res.lable}
                    </label>
                </div>
            )}
        </>
    }

    const addNewRow = (type) => {
        if (existingBrand.data.brands && existingBrand.data.brands[0] && existingBrand.data.brands[0].brandId) {
            let brandId = existingBrand.data.brands[0].brandId;
            let brandName = existingBrand.data.brands[0].brandname;
            let obj = {
                "firstName": "",
                "lastName": "",
                "email": "",
                "phone": "",
                "brandId": brandId,
                "brandName": brandName,
                "guestUser": true,
                "isNew": true,
                revenueCount: 0
            }
            if (type == "afterDelete") {
                let row = []
                row.push(obj)
                setusersList(row)
            } else {
                let row = [...usersList]
                row.push(obj)
                setusersList([...row])
            }
        }
    }

    const createUser = async () => {
        let schema =
            yup.object({
                items: yup.array().of(
                    yup.object().shape({

                        firstName: yup.string()
                            .required(configMessages.name),
                        // .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
                        // .min(3, configMessages.minName)
                        // .max(100, configMessages.maxName),
                        lastName: yup.string()
                            .required(configMessages.name),
                        // .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
                        // .min(3, configMessages.minName)
                        // .max(100, configMessages.maxName),
                        email: yup.string()
                            .required(configMessages.email)
                            .email(configMessages.validEmail),
                        // phone: yup.string()
                        //     .matches(phoneRegex, `Enter a valid contact number`),
                        // .required(configMessages.phone),
                        // revenueCount: yup.string()
                        //     .matches(onlyNumberRegex, `Enter a valid number`)
                        //     .required("Potential Customers Count is required"),
                        brandId: yup.string()
                            .required(configMessages.brandId),
                        guestUser: yup.boolean()
                            .required("User type is required."),
                    })
                )
            })
        let finalArr = []
        usersList.forEach(async (res, index) => {
            let obj = {
                firstName: res.firstName,
                lastName: res.lastName,
                email: res.email,
                phone: res.contactPhone || "",
                brandId: res.brandId,
                guestUser: res.guestUser,
                revenueCount: res.revenueCount
            }
            finalArr.push(obj)
            schema
                .isValid({ items: [obj] }).then(async (res1) => {
                    if (!res1) {
                        console.log(res1, res)
                        scrollToBottom(res, index)
                    }
                })
        })

        await schema
            .isValid({ items: finalArr }).then(async (res) => {
                if (res) {
                    setIsSubmitting(true);
                    let finalList = usersList.map(res => {
                        res.role = 'companyAdmin'
                        res.phone = res.contactPhone
                        return res
                    })
                    dispatch(createUserApiCall(finalList,"createUser"))
                    setIsSubmitting(false);
                } else {
                    setisValidate(true)
                }
            })
        // try {
        //     await validationSchema.validate(finalArr, { abortEarly: false });
        // } catch (e) {
        //     console.log(JSON.stringify(e));
        // }

        // await validationSchema
        //     .isValid(finalArr).then(async (res) => {
        //         if (res) {
        //         }
        //     })
    }

    const handleDeleteBranch = () => {
        if (deleteDialog.data._id) {
            dispatch(deleteUserByIdApiCall(deleteDialog.data._id))
        } else {
            usersList.splice(deleteDialog.index, 1)
            setusersList([...usersList])
        }
        setdeleteDialog({ data: {}, isOpen: false, index: null })
    }

    const deleteUserRow = (row, index) => {
        if (row._id) {
            setdeleteDialog({ data: row, isOpen: true, index: index })
        } else {
            usersList.splice(index, 1)
            setusersList([...usersList])
        }
    }

    return (
        <>
            <ModalComponent
                close={() => {
                    setdeleteDialog({ data: {}, isOpen: false })
                }}
                callback={handleDeleteBranch}
                show={deleteDialog.isOpen}
                title={<span>Delete <b>User</b></span>}
                message={<span>Are you sure you want to delete the <b>User</b> ?</span>}
                action={"Delete"}
                actionType={'danger'}
            />
            <div className="card">
                <div className="newBranchList">
                    {usersList.length > 0 &&
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <th >User Details</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {usersList.map((row, index) =>
                                    <React.Fragment>
                                        <tr key={row._id + "userDet"}>
                                            <td >
                                                <div
                                                    // ref={refList[`${row._id}_ref`]} 
                                                    ref={ref => {
                                                        refList.current[index] = ref; // took this from your guide's example.
                                                    }}
                                                    // ref={refList.current[index]}
                                                    className="m-0 p-0 pt-1 col-12 row d-flex align-items-center no-gutters">

                                                    <div className="p-2 col-lg-2  input-group justify-content-center">
                                                        {inputField(row, 'First Name', 'firstName', onlycharRegex, index)}
                                                    </div>
                                                    <div className="p-2 col-lg-2  input-group justify-content-center">
                                                        {inputField(row, 'Last Name', 'lastName', onlycharRegex, index)}
                                                    </div>
                                                    <div className="p-2 col-lg-3  input-group justify-content-center ">
                                                        {inputField(row, 'email', 'email', emailRegex, index)}
                                                    </div>
                                                    <div className="p-2 col-lg-2  input-group justify-content-center ">
                                                        {inputField(row, 'Cotact Phone', 'contactPhone', phoneRegex, index)}
                                                    </div>
                                                    {/* <div className="p-2 col-lg-2  input-group justify-content-center ">
                                                        {inputField(row, 'Potential Customers Count', 'revenueCount', onlyNumberRegex, index)}
                                                    </div> */}
                                                    <div className="d-flex col-3 ">
                                                        <div className="form-group pl-0 mb-0">
                                                            <label>Is Guest User  </label>
                                                            <div className="d-block d-sm-block d-md-flex radio-container">
                                                                {checkBoxField(row, index,
                                                                    [{ field: "guestUser", value: true, lable: "Yes" },
                                                                    { field: "guestUser", value: false, lable: "No" }])}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center">

                                                    {(row._id || usersList.length > 1) &&
                                                        <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => deleteUserRow(row, index)}   >
                                                            <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Delete">delete</Icon>
                                                        </span>
                                                    }
                                                    {(usersList.length - 1) == index &&
                                                        <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => addNewRow()} >
                                                            <Icon style={{ fontSize: "2rem" }} className="text-info" data-toggle="tool-tip" title="Edit">add_circle</Icon>
                                                        </span>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                )}

                            </tbody>
                        </table>
                    }
                    <div className="row text-right no-gutters col-12 m-0 p-0 justify-content-end">
                        <button
                            disabled={isSubmitting}
                            className="btn btn-sm btn-primary newbrandBtn"
                            onClick={() => createUser()}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>


        </>
    )
};

export default React.memo(UserCreate);