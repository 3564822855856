import React, { createRef, useEffect, useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';
import CreateTicketModal from './CreateTicketModal';
// import { getAllBrandList } from '../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCreateTicketsApicall, updateTicketStatusApicall, deleteTicketApicall } from '../../services/brandActionCenter/createBrandActionCenter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BootstrapTable from 'react-bootstrap-table-next';
import { GetSessionValue } from '../../utils/sessionStorage';
import ModalComponent from '../../components/Modal';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config';

export default function CreateTicket() {

    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState(false);
    const [createdTicketList, setCreatedTicketList] = useState([]);
    const [createTicketModalData, setCreateTicketModalData] = useState({ showModal: false, selectedData: {} });
    const [searchField, setSearchField] = useState("");
    const [userDetails, setUserDetails] = useState({});
    const [approveData, setApproveData] = useState({ showModal: false, selectedData: {} });
    const [showCommentsData, setShowCommentsData] = useState({ showCommentsModal: false, comments: "" });
    const [deleteModalData, setDeleteModalData] = useState({ showDeleteModal: false, selectedEntity: {} });

    // const [brandList, setBrandList] = useState([]);
    const [pageAndLimitValue, setPageAndLimitValue] = useState({
        limit: 10,
        page: 1,
        totalCount: 10
    });
    const [pagination, setPagination] = useState();

    useEffect(async () => {
        // getBrandListApiCall();
        getData();
        let userDetails = GetSessionValue('user');
        setUserDetails(userDetails);
    }, []);

    // const getBrandListApiCall = () => {
    //     dispatch(getAllBrandList(res => {
    //         if (res && res.length) {
    //             setBrandList(res);
    //         }
    //     }));
    // }

    const columns = [
        {
            dataField: 'ticketNumber',
            sort: false,
            text: 'Ticket Number',
            classes: 'vhalign'
        },
        {
            dataField: 'groupName',
            sort: false,
            text: 'Group Name',
            classes: 'vhalign'
        },
        {
            dataField: 'brandName',
            sort: false,
            text: 'Brand Name',
            classes: 'vhalign'
        },
        // {
        //     dataField: 'comments',
        //     sort: false,
        //     text: 'Comments',
        //     classes: 'vhalign'
        // },
        // {
        //     dataField: 'firstName',
        //     sort: false,
        //     text: 'Created By',
        //     classes: 'vhalign',
        //     formatter: (col, row) => {
        //         let name = "";
        //         if (row.firstName) {
        //             name = row.firstName;
        //         } else if (row.lastName) {
        //             name = name + " " + row.lastName;
        //         }
        //         return name;
        //     }
        // },
        {
            dataField: 'role',
            sort: false,
            text: 'Assign By',
            classes: 'vhalign',
            formatter: (col, row) => {
                let role = "";
                if (row.role == "superAdmin") {
                    role = "Super Admin";
                } else if (row.role == "admin") {
                    role = "Admin";
                } else if (row.role == "salesAdmin") {
                    role = "Sales Admin";
                } else if (row.role == "opsAdmin") {
                    role = "Ops Admin";
                } else if (row.role == "bank") {
                    role = "Publisher";
                }
                return role;
            }
        },
        {
            dataField: 'assignRoleTo',
            sort: false,
            text: 'Assign To',
            classes: 'vhalign',
            formatter: (col, row) => {
                let role = "";
                if (row.assignRoleTo == "superAdmin") {
                    role = "Super Admin";
                } else if (row.assignRoleTo == "admin") {
                    role = "Admin";
                } else if (row.assignRoleTo == "salesAdmin") {
                    role = "Sales Admin";
                } else if (row.assignRoleTo == "opsAdmin") {
                    role = "Ops Admin";
                } else if (row.assignRoleTo == "bank") {
                    role = "Publisher";
                }
                return role;
            }
        },
        {
            dataField: 'status',
            sort: false,
            text: 'Status',
            classes: 'vhalign'
        },
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Actions',
            classes: 'vhalign',
            formatter: (cell, row) => {
                return (
                    <React.Fragment>
                        {row.comments &&
                            <div className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setShowCommentsData({ showCommentsModal: true, comments: row.comments })}>
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="View Comments">comment</Icon>
                            </div>
                        }
                        {userDetails.role == row.role && row.status == "Pending" &&
                            <div className="future-action m-l-10" style={{ display: 'inline-block', cursor: 'pointer' }}
                                onClick={() => setCreateTicketModalData({ showModal: true, selectedData: row, from: "Edit" })}>
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
                            </div>
                        }
                        {userDetails.role == row.assignRoleTo && row.status == "Pending" &&
                            <div className="future-action m-l-10" style={{ display: 'inline-block', cursor: 'pointer' }}
                                onClick={() => setApproveData({ showModal: true, selectedData: row })}>
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Approve">done</Icon>
                            </div>
                        }

                        {userDetails.role == row.role && row.status == "Pending" &&
                            <div className="future-action m-l-10" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => setDeleteModalData({ showDeleteModal: true, selectedEntity: row })} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
                            </div>
                        }
                    </React.Fragment>
                )
            }
        }
    ]

    const getData = (showLoader, searchField) => {

        let filterCriteria = {
            limit: pageAndLimitValue.limit, page: pageAndLimitValue.page, searchField: searchField,
            direction: "desc", sortfield: "createdDate"
        };
        if (showLoader) { setIsLoader(true); }
        dispatch(getCreateTicketsApicall(filterCriteria, response => {
            setIsLoader(false);
            if (response.respCode) {
                setCreatedTicketList(response.createTicket);
                setPageAndLimitValue(response.pagination);
                setPagination(paginationFactory(
                    {
                        sizePerPageRenderer: sizePerPageRenderer,
                        page: response.pagination.page,
                        sizePerPage: response.pagination.limit,
                        totalSize: response.pagination.totalCount,
                        hideSizePerPage: false,
                        hidePageListOnlyOnePage: true,
                        showTotal: true,
                        alwaysShowAllBtns: true,
                        show: true,
                        onPageChange: (page, sizePerPage) => {
                            pageChange(page)
                        }
                    }
                ));
                setIsLoader(false);
            }
        }));
    }

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group pt-1" role="group">
            <div className="dropdown">
                <button className="btn btn-primary dropbtn">{pageAndLimitValue.limit}  <ExpandMoreIcon /></button>
                <div className="dropdown-content">
                    {
                        options.map((option, index) => {
                            const isSelect = currSizePerPage === `${option.page}`;
                            return (
                                <a href="#" onClick={() => setPageLimit(option.page)} key={index}> {option.text}</a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )

    const pageChange = (page) => {
        let pageData = pageAndLimitValue;
        pageData.page = page
        setPageAndLimitValue(pageData);
        getData();
    }

    const setPageLimit = (limit) => {
        let pageData = pageAndLimitValue;
        pageData.limit = limit
        setPageAndLimitValue(pageData);
        getData();
    }

    const handleSearchInputChange = (event) => {
        console.log(event.target.value);
        setSearchField(event.target.value);
        getData(false, event.target.value);
    }

    const updateTicketStatusData = () => {
        setIsLoader(true);
        let reqData = {
            id: approveData.selectedData._id
        };
        dispatch(updateTicketStatusApicall(reqData, response => {
            setIsLoader(false);
            if (response.respCode) {
                setApproveData({ showModal: false, selectedData: {} });
                getData();
            }
        }));
    }

    const handleDeleteEntity = () => {
        dispatch(deleteTicketApicall({ id: deleteModalData.selectedEntity._id }, response => {
            let notification = { ...notificationOpts };
            if (response.respCode == 204) {
                notification.message = "Ticket deleted successfully";
                dispatch(successNotificationAction(notification));
                setPageAndLimitValue({ limit: 10, page: 1, totalCount: 10 });
                getData(true);
                clearDeleteModal();
            } else {
                notification.message = response.errorMessage;
                dispatch(errorNotificationAction(notification));
                clearDeleteModal();
            }
        }));
    }

    const clearDeleteModal = () => {
        setDeleteModalData({ showDeleteModal: false, selectedEntity: {} });
    }


    return (
        <>
            <div>
                <div className="row action-center">
                    <div className="col-md-12">
                        <div className="heading">
                            <div className="row">
                                <div className="col-md-8 d-flex align-items-center">
                                    <h2 className="m-0 ">
                                        <span>
                                            <Icon className="heading-icon">create</Icon>
                                            Tickets
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>


                        <div className="card m-t-25">

                            <div className="row col-12 m-t-15 p-r-0 m-r-0">
                                <div className="col-6 h-20">
                                    {/* {userDetails.role == "salesAdmin" && */}
                                    <button
                                        onClick={() => setCreateTicketModalData({ showModal: true, selectedData: {} })}
                                        className="btn btn-primary">  Create  </button>
                                    {/* } */}
                                </div>
                                <div className="row col-6 action-center-search">
                                    <div className="row col-6 action-center-search-box">
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" placeholder="Search Ticket"
                                                onChange={(event) => { handleSearchInputChange(event) }} />
                                            <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                                            </div>
                                        </div>
                                        {searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 m-t-25 m-b-25">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField='id'
                                    data={createdTicketList}
                                    columns={columns}
                                    wrapperClasses="table-responsive"
                                    remote
                                    noDataIndication={() => <span>No data found.</span>}
                                    pagination={pagination}
                                />
                            </div>

                        </div>


                    </div>

                </div>

                {isLoader &&
                    <div className="loading">Loading...</div>}


                {createTicketModalData.showModal &&
                    <CreateTicketModal
                        close={() => { setCreateTicketModalData({ showModal: false, selectedData: {} }) }}
                        callback={(entityData) => { setCreateTicketModalData({ showModal: false, selectedData: {} }); getData(); }}
                        show={createTicketModalData.showModal}
                        modalData={createTicketModalData.selectedData}
                        from={createTicketModalData.from}
                    // brandList={brandList}
                    />
                }

                {approveData.showModal &&
                    <ModalComponent close={() => { setApproveData({ showModal: false, selectedData: {} }) }}
                        callback={() => { updateTicketStatusData() }}
                        show={approveData.showModal}
                        title={"Update Status"}
                        message={"Are you sure you want to update the status?"}
                        action={"Confirm"}
                        actionType={'success'}
                    />
                }

                {showCommentsData.showCommentsModal &&
                    <ModalComponent close={() => { setShowCommentsData({ showCommentsModal: false, comments: {} }) }}
                        show={showCommentsData.showCommentsModal}
                        title={<span>Comments</span>}
                        message={<span> {showCommentsData.comments} </span>}
                        hideActionBtn={true} />
                }

                {deleteModalData.showDeleteModal &&
                    <ModalComponent close={() => { clearDeleteModal() }}
                        callback={handleDeleteEntity}
                        show={deleteModalData.showDeleteModal}
                        title={<span>Delete <b>File</b></span>}
                        message={<span>Are you sure you want to delete the Ticket.</span>}
                        action={"Delete"}
                        actionType={'danger'}
                    />
                }

            </div>

        </>
    )
};
