import React, { Component } from "react";
import CampaignOverview from '../../components/CampaignOverview'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import * as Datetime from 'react-datetime'
import { Collapse } from 'react-bootstrap';
import {
  getCampaignOverviewSearchOptionsAction,
  clearCampaignOverviewAction
} from '../../redux/actions'
import AsyncSelect from 'react-select/lib/Async';
import Icon from '@material-ui/core/Icon'


class CampaignOverviewLayout extends Component {
  // handleInputChange = (value) => {
  //   let { getCampaignOverview } = this.props;
  //   getCampaignOverview({id : value.id})
  // }
  state = {
    showFilter: false,
    start_date: null,
    end_date: null
  }
  handleDateChange = (name, date) => {
    this.setState({ [`${name}`]: date })
  }
  componentWillUnmount() {
    let { clearCampaignOverview } = this.props;
    // console.log('components will unmount called')
    clearCampaignOverview()
  }
  applyFilter = () => {
    let filter = {}
    let { handleFilterChange } = this.props;
    if (this.state.start_date) {
      filter['start_date'] = moment(this.state.start_date).toISOString()
    }
    if (this.state.end_date) {
      filter['end_date'] = moment(this.state.end_date).toISOString()
    }
    if (!Object.keys(filter).length)
      return;
    handleFilterChange(filter)
    this.setState({ showFilter: !this.state.showFilter })
  }
  clearDate = props => {
    props.onChange({ target: { value: '' } })
  }
  onChange = value => {
    // console.log(value)
    let x = this.props
    // console.log(x)
  }
  render() {
    let { campaignOverview, handleFilterChange, title, displayFilter, overviewType = 'aggregate', hideSearch } = this.props;
    let thisMonth = moment().startOf('month')
    return (
      <div>
        <div className="heading">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <h2 className="m-0">
                <span>
                  <Icon className="heading-icon">adjust</Icon>
                </span> {title}
                {
                  displayFilter
                    ? (
                      <button className="btn btn pull-right btn-round-navy ml-2" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                        <i className="fa fa-filter"></i>
                      </button>
                    ) : ''
                }
              </h2>
            </div>
            {
              !hideSearch ? (
                <div className="col-md-4">
                  <div className="search">

                    <AsyncSelect
                      cacheOptions
                      loadOptions={
                        (value, callback) => {
                          let { getCampaignOverviewSearchOptions } = this.props;
                          getCampaignOverviewSearchOptions({ search: value }, callback)
                        }
                      }
                      defaultOptions
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      classNamePrefix="reactselect"
                      onChange={handleFilterChange}
                    // onChange={this.onChange}
                    />
                  </div>
                </div>
              ) : ''
            }

          </div>
          <div className="row">
            <div className="col-md-12">
              {
                displayFilter
                  ? (
                    <Collapse
                      in={this.state.showFilter}
                    >
                      <div className="card font-size-normal">
                        <div className="form-group mb-3">
                          <label>Date Range </label>
                          <br />
                          <div className="row">
                            <div className="col-md-3">
                              <Datetime
                                viewMode="months"
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                isValidDate={current => {
                                  if (this.state.end_date)
                                    return current.isSameOrBefore(this.state.end_date, 'month') && current.isBefore(thisMonth, 'month')
                                  return current.isBefore(thisMonth, 'month')
                                }}
                                onChange={(date) => this.handleDateChange('start_date', date)}
                                renderInput={(props, openCalendar) => {
                                  return (
                                    <div className="input-group mb-3">
                                      <input {...props} type="text" placeholder="Start Month" className="form-control" name="start_month" />
                                      <div className="input-group-append date-dropdown-btn">
                                        {this.state.start_date
                                          ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                          : ''
                                        }
                                        <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                      </div>
                                    </div>
                                  )
                                }}
                              />
                            </div>
                            <div className="col-md-3">
                              <Datetime
                                viewMode="months"
                                timeFormat={false}
                                dateFormat="MM/YYYY"
                                minDate={this.state.end_date}
                                isValidDate={current => {
                                  if (this.state.start_date)
                                    return current.isSameOrAfter(this.state.start_date, 'month') && current.isBefore(thisMonth, 'month')
                                  return current.isBefore(thisMonth, 'month')
                                }}
                                onChange={(date) => this.handleDateChange('end_date', date)}
                                renderInput={(props, openCalendar) => {
                                  return (
                                    <div className="input-group mb-3">
                                      <input {...props} type="text" placeholder="End Month" className="form-control" name="end_month" />
                                      <div className="input-group-append date-dropdown-btn">
                                        {this.state.end_date
                                          ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                          : ''
                                        }
                                        <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                      </div>
                                    </div>
                                  )
                                }}
                              />
                            </div>
                            <div className="col-md-1">
                              <button className="btn btn btn-navy" onClick={this.applyFilter}>
                                <i className="fa fa-check"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  ) : ''
              }
            </div>
          </div>
        </div>
        {
          campaignOverview ? <CampaignOverview campaignOverview={campaignOverview} overviewType={overviewType} /> : ''
        }
      </div>
    );
  }
}
export default connect(
  null,
  dispatch => ({
    getCampaignOverviewSearchOptions: bindActionCreators(getCampaignOverviewSearchOptionsAction, dispatch),
    clearCampaignOverview: bindActionCreators(clearCampaignOverviewAction, dispatch),
  })
)(CampaignOverviewLayout)



