import React, { Fragment } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

const ToolTipContainer = ({placement='auto', toolTipText, id, containerClassNames, children}) => {
  if (!toolTipText)
    return <div className={containerClassNames} key={id}>{children}</div>;
  return (
    <Fragment>
      <OverlayTrigger
        key={id}
        placement={placement}
        overlay={
          <Tooltip id={`tooltip-${id}`}>
            {toolTipText}
          </Tooltip>
        }
      >
        <div className={containerClassNames} key={id}>{children}</div>
      </OverlayTrigger>
    </Fragment>
  )
}
export default ToolTipContainer