import axiosMethodRequest from '../../config/service'
import { GET_CAMPAIGN_ASSETS_DATA } from '../../redux/campaignApproval/actionTypes'
export const getCampaignAssetsApiCall = (brandId) => {
    return dispatch => {
        let url = `brands/brandCampaignAssets/${brandId}`;
        dispatch({
            type: GET_CAMPAIGN_ASSETS_DATA,
            payload: { data: { docs: [], brands: {} }, loader: true }
        })
        return axiosMethodRequest('GET', url).then((response) => {
            if (response && response.data && response.data.respCode) {
                let data = response.data.brandsDetails
                dispatch({
                    type: GET_CAMPAIGN_ASSETS_DATA,
                    payload: { data: data, loader: false }
                })

            }
        })
    }
}
