import React, { Component } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { updateUserProfileAction, getSettingsAction, updateSettingsAction } from '../../redux/actions'
import { SettingsForm } from '../../components/Forms/Settings'
import UserInfo from '../../components/SideBar/UserInfo.component';
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
// import ProfileImg from './updateProfileImg';
import Loader from '../../components/Loader'
//sesssion expiry modal
import SessionExpiryModal from './../../components/sessionExpiryModal'
import { GetSessionValue } from '../../utils/sessionStorage';
class Settings extends Component {
  state = {
    isDisabled: true,
    btnLabel: 'Update Profile',
    settings: {},
    settingsData: false,
    isLoading: false,
    sessionExpiryModal: false
  }
  onSubmit = (values, actions) => {
    let { updateSettings, successNotification } = this.props;
    if (values && values.merchantIds && typeof (values.merchantIds) == 'string') {
      let merchantIds = values.merchantIds
      values.merchantIds = merchantIds.split(',')
    }
    Object.keys(values).forEach((item, index) => {
      if (values[item] == 'true') {
        values[item] = true
      } else if (values[item] == 'false') {
        values[item] = false
      }
    })
    updateSettings(values, (res, type) => {
      actions.setSubmitting(false)
      if (type == 'error') {
        actions.setStatus(res)
        return;
      }
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
      this.setState({ isDisabled: true })
    })
  }

  componentDidMount = async () => {
    let { getSettings } = this.props
    getSettings(async (res) => {
      let sessionExpiryModal = false
      let sessionExpired = await GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ isLoading: false, settings: res, settingsData: true, sessionExpiryModal: sessionExpiryModal })
    })
  }

  formikRef = (e) => {
    this.formik = e
  }
  formikSubmit = () => {
    if (this.formik)
      this.formik.submitForm()
  }
  onToggleEdit = () => {
    let isDisabled = !this.state.isDisabled
    // if (isDisabled && this.formikRef) {
    //   this.formikRef.resetForm()
    // }
    this.setState({ isDisabled })
  }
  getActionBtns = () => {
    if (this.state.isDisabled) {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" onClick={this.onToggleEdit}>{/*<Icon fontSize="small">edit</Icon>*/} Edit </button>
        </div>
      )
    } else {
      return (
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" onClick={this.formikSubmit}>Update Details</button>
        </div>
      )
    }
  }
  render() {
    let { user } = this.props;
    let { settings, settingsData } = this.state
    return (
      <div>
        <Loader loader={this.state.isLoading} />
        <div className="row create-header">
          <h3 className="col-md-6 offset-3 text-center py-3">Settings</h3>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-12">
            <div className="card">
              <div className="">
                {/* <UserInfo
                  user={user}
                  screenType="settings"
                  onRef={(ref) => this.userInforef = (ref)}
                /> */}

                {/* <button onClick={e=>this.userInforef && this.userInforef.updateProfileImage?this.userInforef.updateProfileImage():console.log('sorry')}>Hiiiii</button> */}

                {settingsData ? <SettingsForm
                  onSubmit={this.onSubmit}
                  initialValues={settings}
                  options={{ ...this.state }}
                  formikRef={this.formikRef}
                  disabled={this.state.isDisabled}
                /> : null}
                {this.getActionBtns()}
              </div>
              <SessionExpiryModal
                isOpen={this.state.sessionExpiryModal}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
};

export default connect(
  state => ({
    user: state.user,
    settings: state.settings
  }),
  dispatch => ({
    getSettings: bindActionCreators(getSettingsAction, dispatch),
    updateUserProfile: bindActionCreators(updateUserProfileAction, dispatch),
    updateSettings: bindActionCreators(updateSettingsAction, dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
  })
)(Settings);

