
import axios from '../../utils/AxiosWrapper'
import { pluralize } from '../../utils/helpers'
import axiosMethodRequest from '../../config/service'
import apiCalls from '../../config/apiCalls'
import { GetSessionValue } from '../../utils/sessionStorage'

export const setEntityPageAction = type => {
  return (page, limit) => {
    return {
      type: `SET_${type.toUpperCase()}_PAGE`,
      payload: {
        page,
        limit
      }
    }
  }
}

export const setEntitySortAction = type => {
  return (sortField, sortOrder) => {
    return {
      type: `SET_${type.toUpperCase()}_SORT`,
      payload: {
        sortField,
        sortOrder
      }
    }
  }
}

export const setEntitySearchAction = type => {
  return (search) => {
    return {
      type: `SET_${type.toUpperCase()}_SEARCH`,
      payload: { search }
    }
  }
}
const setManageCreativeData = (type, data) => {
  return {
    type: `SET_MANAGECREATIVE_DATA`,
    payload: data
  }
}

export const setEntityFiltersAction = type => {
  return (filters) => {
    return {
      type: `SET_${type.toUpperCase()}_FILTERS`,
      payload: filters
    }
  }
}

const setEntityData = (type, data, pagination) => {
  return {
    type: `SET_${type.toUpperCase()}_DATA`,
    payload: data,
    pagination: pagination
  }
}

const setEntityLoadingState = (type, loadingState) => {
  return {
    type: `SET_${type.toUpperCase()}_DATA_LOADINGSTATE`,
    payload: loadingState
  }
}

export const getEntityAction = entityType => {
  return (page, pageSize, filters, apiUrl, type, callback) => {
    return dispatch => {
      console.log('filters', page)
      let url = ''
      let filterCriteria = {};
      filterCriteria['limit'] = pageSize;
      filterCriteria['page'] = page;
      filterCriteria['criteria'] = [];
      if (filters && filters.dropDown) {
        filterCriteria['criteria'] = [filters.dropDown];
      }
      if (filters && filters.publisherDropDown) {
        if(filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          filterCriteria['criteria'].push(filters.publisherDropDown);
        } else { 
          filterCriteria['criteria'] = [filters.publisherDropDown];
        }        
      }
      if (filters && filters.whiteLabelDropDown) {
        if(filterCriteria['criteria'] && filterCriteria['criteria'].length > 0) {
          filterCriteria['criteria'].push(filters.whiteLabelDropDown);
        } else { 
          filterCriteria['criteria'] = [filters.whiteLabelDropDown];
        }        
      }
      filterCriteria['direction'] = 'desc';
      filterCriteria.sortfield = 'created';
      if (filters && filters.globalSearch && filters.globalSearch.value) {
        filterCriteria.globalSearch = filters.globalSearch
      }
      ///////global search
      if (type == 'globalsearch' && apiUrl != 'users/getMerchantIds' && filters && filters.globalSearch && filters.globalSearch.value && filters.globalSearch.value.length > 3) {
        filterCriteria.globalSearch = filters.globalSearch
      }
      if (!page) {
        filterCriteria['page'] = 1;
      }
      if (!pageSize) {
        filterCriteria['limit'] = 10;
      }
      ////////sorting 
      if (type == "sort" && filters.sortField && filters.sortField.direction) {
        filterCriteria['limit'] = pageSize;
        filterCriteria['page'] = page;
        // filterCriteria['criteria'] = [];
        filterCriteria['direction'] = filters.sortField.direction
        filterCriteria.sortfield = filters.sortField.sortfield;

      }
      ////////dropdown 
      // if (type == "dropdown" && filters) {
      //   filterCriteria['limit'] = pageSize;
      //   filterCriteria['page'] = page;
      //   if (filters && filters[0] && filters[0].value) {
      //     filterCriteria['criteria'] = filters;
      //   } else {
      //     filterCriteria['criteria'] = []
      //   }
      // }

      if (filters.reqestType == "onBoarding") {
        filterCriteria['reqestType'] = filters.reqestType
      } else {
        filterCriteria['reqestType'] = ""
      }

      if (type == 'globalsearch' && apiUrl == 'users/getMerchantIds' && filters && filters.globalSearch && filters.globalSearch.value && filters.globalSearch.value.length > 3) {
        url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}&search=${filters.globalSearch.value}`;
      } else if (filters && filters.globalSearch && filters.globalSearch.value) {
        url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}&search=${filters.globalSearch.value}`;
      } else {
        url = `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`;
      }

      if (filters.customerType) {
        url = url + `&customerType=${filters.customerType}`
      }

      dispatch(setEntityLoadingState(entityType, true))
      return axiosMethodRequest('GET', url).then((res) => {
        if (res && res.data && res.data.respCode) {
          let data = []
          let pagination = {}
          if (apiUrl == 'users/getMerchantIds') {
            data = res.data.details
            pagination = res.data.pagination
          } else {
            data = res.data[apiUrl]
            pagination = res.data.pagination
          }
          if (apiUrl == 'campaignTemplates') {
            data.emailText = data.campaignTemplateText
          }

          dispatch(setEntityData(entityType, data, pagination))
          if (apiUrl == 'activities') {
            data.forEach((item, index) => {
              item.deviceInfo = item.deviceName && item.deviceName[0] ? JSON.stringify(item.deviceName[0]).substring(1, JSON.stringify(item.deviceName[0]).length - 1) : ''
            })
          }
          callback(data, pagination, 'success')

        } else {
          callback(res.data.errorMessage, '', 'error')
        }
        dispatch(setEntityLoadingState(entityType, false))
      })
    }
  }
}

export const deleteEntityAction = (entity, apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('DELETE', `${apiUrl}/${entity._id}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        if (callback) {
          callback(response.data.respMessage, 'success')
        }
      } else {
        if (callback) {
          callback(response.data.errorMessage, 'error')
        }
      }
    })
  }

}

export const createEntityAction = (data, apiUrl, callback) => {
  return dispatch => {
    if (data && data.entityType && apiUrl == 'users') {
      data.role = 'companyAdmin'
      delete data['entityType']
    }
    if (apiUrl == 'campaignTemplates' && data.type == 'email') {
      data.campaignTemplateText = data.emailText
    }
    if (apiUrl == 'campaignTemplates' && data.type == 'sms') {
      let sampleText = data.campaignTemplateText;
      let count = 0;
      if (sampleText) {
        for (let i in sampleText) {
          if (sampleText[i] == '<') {
            count++;
          }
        }
      }
      for (let i = 0; i <= count; i++) {
        sampleText = sampleText.replace('<', '&lt;');
        sampleText = sampleText.replace('>', '&gt;');
      }
      data.campaignTemplateText = sampleText;
    }
    return axiosMethodRequest('POST', apiUrl, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const confirmMerchantAction = (data, apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', `${apiUrl}`, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const multiAction = (data, actionType, callback) => {
  // return (data,apiUrl, callback) => {
  return dispatch => {
    let apiUrl
    if (actionType == 'APPROVE') {
      apiUrl = 'merchants/multiApprove'
      data.status = 'Approved'
    } else if (actionType == 'REJECT') {
      apiUrl = 'merchants/multiApprove'
      data.status = 'Rejected'
    } else if (actionType == 'DELETE') {
      apiUrl = 'merchants/multiRemove'
    }
    return axiosMethodRequest('POST', `${apiUrl}`, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const sendMail = (data, apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', `${apiUrl}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, "success")
      } else {
        callback(response.data.errorMessage, "error")
      }
    })
  }
}

export const getBrandDataAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `brands`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.brands)
      } else {
        callback()
      }
    })
  }
}
export const getBrandDataByFilterAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `brands?filter=${JSON.stringify(data)}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.brands)
      } else {
        callback()
      }
    })
  }
}

export const getBrandDataByFilterDashboardAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `brands?customerType=${data.customerType}&filter=${JSON.stringify(data)}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.allBrands)
      } else {
        callback()
      }
    })
  }
}

export const getBrandList = (callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `brands/getBrandList`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.allBrands)
      } else {
        callback()
      }
    })
  }
}

export const getAllBrandList = (callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `brands/getBrandList`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.allBrands);
      } else {
        callback();
      }
    })
  }
}

export const getXeroInvoiceAction = (data, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', `distributors/getXeroInvoice/invoice`,data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data)
      } else {
        callback()
      }
    })
  }
}

export const editEntityAction = type => {
  return (data, apiUrl, callback) => {
    return dispatch => {
      delete data['email'];
      delete data['password'];
      if (apiUrl == 'campaignTemplates' && data.type == 'email') {
        data.campaignTemplateText = data.emailText;
      } else if(apiUrl == 'groupBrand' || apiUrl == 'distributors') {
        data.email = data.emailText;
      }
      if (apiUrl == 'campaignTemplates' && data.type == 'sms') {
        let sampleText = data.campaignTemplateText;
        let count = 0;
        if (sampleText) {
          for (let i in sampleText) {
            if (sampleText[i] == '<') {
              count++;
            }
          }
        }
        for (let i = 0; i <= count; i++) {
          sampleText = sampleText.replace('<', '&lt;');
          sampleText = sampleText.replace('>', '&gt;');
        }
        data.campaignTemplateText = sampleText;
      }
      return axiosMethodRequest('PUT', `${apiUrl}/${data._id}`, data).then((response) => {
        if (response && response.data && response.data.respCode) {
          callback(response.data.respMessage, 'success')
        } else {
          callback(response.data.errorMessage, 'error')
        }
      })
    }
  }
}

export const uploadCSVForEntityAction = (file, apiUrl, callback) => {
  return dispatch => {
    let url = `uploads/csvUpload?type=${apiUrl}`
    let formData = new FormData()
    formData.append('file', file)
    return axiosMethodRequest('POST', url, file, 'upload').then((res) => {
      if (res && res.data && res.data.respCode) {
        callback(res.data.respMessage, 'success')
      } else if (res && res.data && res.data.failure) {
        callback(res.data.failure.message, "error")
      } else {
        callback(res.data.errorMessage, "error")
      }
    })
  }
}
export const getManageCreativeAction = (type) => {
  return (file, callback) => {
    return dispatch => {
      dispatch(setEntityLoadingState('SET_MANAGECREATIVE_DATA', true))
      return axiosMethodRequest('GET', 'documents').then((res) => {
        if (res && res.data && res.data.respCode) {
          callback(res.data.documentss, 'success')
          dispatch(setManageCreativeData(type, res.data.documentss))
        } else {
          callback(res.data.errorMessage, 'error')
        }
        dispatch(setEntityLoadingState('SET_MANAGECREATIVE_DATA', false))
      })
    }
  }
}
export const setEntityForEditAction = type => {
  return entity => {
    return {
      type: `SET_${type.toUpperCase()}_EDIT`,
      payload: entity
    }
  }
}

export const getEntityDataByFilterAction = (data, callback) => {
  return dispatch => {
    const url = data ? `entity?filter=${JSON.stringify(data)}` : "entity"
    return axiosMethodRequest('GET', url).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.entity)
      } else {
        callback()
      }
    })
  }
}


export const createEntityMetadataAction = (data, apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', "entity", data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const getBranchListByBrandIdAction = (brand_id, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `entity/getAllEntityByBrandId/${brand_id}`).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.entityList)
      } else {
        callback([])
      }
    })
  }
}

export const updateDistributorAction = (data, apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('POST', `${apiUrl}`, data).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.respMessage, 'success')
      } else {
        callback(response.data.errorMessage, 'error')
      }
    })
  }
}

export const getDistributorAction = (apiUrl, callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `${apiUrl}`, {}).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.distributor, 'success')
      } else {
        callback([], 'error')
      }
    })
  }
}

export const getSettingDetailAction = (callback) => {
  return dispatch => {
    return axiosMethodRequest('GET', `settings/getSettingDetail`, {}).then((response) => {
      if (response && response.data && response.data.respCode) {
        callback(response.data.settings, 'success')
      } else {
        callback([], 'error')
      }
    })
  }
}