import React, { Component } from 'react';
import { connect } from 'react-redux';
import placeHolderImg from '../../assets/images/placeholder150.png'
import krowdLogo from '../../assets/images/Krowd_logo.jpg'
import mastercardLogo from '../../assets/images/mastercard-logo.svg'
import config from '../../config/config'
import { GetSessionValue } from '../../utils/sessionStorage';
const profileImg = {
  display: 'flex',
  justifyContent: 'center',
}
const profileImgStyle = {
  width: '8.33rem',
  height: '8.33rem',
  borderRadius: '50%',
  padding: '0.19rem',
  backgroundColor: '#FFFFFF',
  transition: '1s ease',
  borderStyle: 'solid'

}
class UserInfo extends Component {

  state = {
    isShowingUserMenu: false,
    profileImg: ''
  };
  componentDidMount = async () => {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
    await this.updateProfileImage()
  }
  updateProfileImage = () => {
    let userDetails = GetSessionValue('user')
    this.setState({ profileImg: userDetails.photo })
  }
  static getDerivedStateFromProps(props, state) {
    let userDetails = GetSessionValue('user')
    // this.setState({profileImg:userDetails.photo})
    if (userDetails && userDetails.photo != state.profileImg) {
      return { profileImg: userDetails.photo };
    } else {
      return null
    }
  }
  render() {
    let { user, screenType } = this.props;
    let { entity, role } = user
    let { profileImg } = this.state
    let logo, entityName, logoClasses = '';
    if (entity && !entity.logo && (role === 'systemadmin' || role === 'superAdmin' || role === 'salesAdmin'
    || role === 'opsAdmin')) {
      logo = krowdLogo
    } else if (role === 'cardprovider') {
      logoClasses = 'hrect-photo'
      logo = mastercardLogo
    } else if (entity && entity.logo) {
      logo = entity.logo
    } else {
      logo = placeHolderImg
    }
    if (role === 'systemadmin') {
      entityName = 'Krowd'
    } else {
      entityName = entity && entity.details && entity.details.displayName ? entity.details.displayName : ''
    }
    return (
      <div className="user-wrapper">
        <div className="user">

          <div className={`beta-version-txt`}> Beta </div>
          <div className={`photo-container`}
            style={{ textAlign: 'center' }}
          >
            <img className={`photo ${logoClasses}`}
              //  src={logo}
              src={profileImg ? `${config.imgUrl}employee/${profileImg}` : logo}
              alt={entityName} style={screenType ? profileImgStyle : {}}
              onError={(e) => { e.target.onerror = null; e.target.src = logo }}
            />
          </div>

          <div className="userinfo">
            <div className="username">
              <div className="company-name" style={screenType == 'settings' ? { fontWeight: 'bold', textAlign: 'center', fontSize: 18 } : {}}>
                {screenType != 'profile' ? entityName : ''}
              </div>
            </div>
          </div>

        </div>
        {/* <Collapse in={isShowingUserMenu}>
          <ul className="nav user-nav">
            <li><a href="#">My Profile</a></li>
            <li><a href="#">Edit Profile</a></li>
            <li><a href="#">Settings</a></li>
          </ul>
        </Collapse> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userProfile: state.user,

});

export default connect(mapStateToProps)(UserInfo);