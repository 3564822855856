import axiosMethodRequest from '../../config/service'

export const StartPauseCampaignApiCall = (campaignId, param, callback) => {
    return dispatch => {
        const url = `campaigns/campaignStartPause/${campaignId}`
        return axiosMethodRequest('POST', url, param).then((response) => {
            if (response && response.data && response.data.respCode) {
                var respdata = {}
                if (response.data) {
                    callback(response.data)
                }

            }
        })
    }
}

export const StartPauseAllCampaignApiCall = (param, callback) => {
    return dispatch => {
        const url = `campaigns/allCampaignStartPause`
        return axiosMethodRequest('POST', url, param).then((response) => {
            if (response && response.data && response.data.respCode) {
                var respdata = {}
                if (response.data) {
                    callback(response.data)
                }

            }
        })
    }
}
