import axios from 'axios';
import config from '../../config/config';
// const API_URL = 'https://sb-api.krowdit.com/';
const ACCESS_TOKEN = 'fkIRXQg5rieIDK1P4YgsHvkn37hvECUkgNgCZAzGYHANNG1y4p';

// Function to fetch available offers
export const fetchAvailableOffers = async (pan) => {
  try {
    let endPoint = `${config.dashboardurl}${"FetchAvailableOffers"}`
    const response = await axios.post(
      "https://sb-api.krowdit.com/FetchAvailableOffers",
      { pan },
      {
        headers: {
          'accept': 'application/json',
          'access-token': ACCESS_TOKEN,
          'Content-Type': 'application/json',
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error fetching available offers:', error);
    throw error;
  }
};

export const updateLoyaltyPoints = async (data) => {

  let endPoint = `${config.dashboardurl}${"updateLoyaltyPoints"}`

  try {
    const response = await axios.post(endPoint, data, {
      headers: {
        'accept': 'application/json',
        'access-token': 'fkIRXQg5rieIDK1P4YgsHvkn37hvECUkgNgCZAzGYHANNG1y4p',  // Use your valid access token
        'Content-Type': 'application/json',
      },
    });
    console.log("Update loyal Points    :  " +JSON.stringify(response))
    return response.data
  } catch (error) {
    console.error('Error updating loyalty points:', error.response ? error.response.data : error.message);
  }
};


export const listLoyaltyPoints = async () => {
  
  let endPoint = `${config.dashboardurl}${"ListLoyalPoints"}`
  console.log("List Loyalty Points    :  "+JSON.stringify(endPoint))
  try {
    const response = await axios.get(  endPoint
    , {
      headers: {
        'accept': 'application/json',
        'access-token': 'fkIRXQg5rieIDK1P4YgsHvkn37hvECUkgNgCZAzGYHANNG1y4p',  // Use your valid access token
      },
    });

    console.log('Loyalty Points:', JSON.stringify(response));
    return response.data;
  } catch (error) {
    console.error('Error fetching loyalty points:', error.response ? error.response.data : error.message);
  }
};




// Function to delete loyalty points
export const deleteLoyaltyPoints = async (id) => {
  console.log("Delete Record   :  "+JSON.stringify(id))
  let endPoint = `${config.dashboardurl}${"deleteLoyaltyPoints"}`
  const response = await axios.post(endPoint,id,{
    headers: {
      'Content-Type': 'application/json',
      'access-token': ACCESS_TOKEN,  // Replace with a valid token
    },
  });
  return response.data;
};
