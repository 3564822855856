import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { newBrandBranchSelectors } from '../redux/newBrandBranch';
import { getDistributors } from '../services/newBrandBranch/checkBrandExistApiCall';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import MultiSelect from "react-multi-select-component";
import { sendMailToUserAction, updateActionCenterAction, getBrandListForSendMailAction } from './../services/brandActionCenter/createBrandActionCenter';
import { success as successNotificationAction } from 'react-notification-system-redux';
import { notificationOpts } from './../config';
import config from './../config/config';

const Modal = ({
    close,
    show,
    callback,
    size = "lg",
    cancelText = "Cancel",
    centered = false,
    modalData,
    from
}) => {

    const dispatch = useDispatch();
    const [distributorList, setDistributorList] = useState([]);
    const getDistributorsState = useSelector(newBrandBranchSelectors.getDistributorsState);
    const [copy, setCopy] = useState("");
    const [is_amex_phase, setIs_amex_phase] = useState("");
    const [selectedMailTo, setSelectedMailTo] = useState({});
    const [mailContainDetails, setMailContainDetails] = useState("");
    const [mailToList, setMailToList] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [brandListToSendMail, setBrandListToSendMail] = useState([]);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [templateList, setTemplateList] = useState([
        { value: "onboarding", label: "Onboarding Invite" },
        { value: "onboarding_reminder", label: "Onboarding Reminder" },
        { value: "data_refresh", label: "Data Refreshed" },
        { value: "invoice_generated", label: "Invoice Generated" },
        { value: "due_date_crossed", label: "Due Date Crossed" }
    ]);
    const [amexPhaseList, setAmexPhaseList] = useState([
        { label: 'Amex Phase 3', value: 'amex_phase_3' },
        { label: 'Amex Phase 3.5', value: 'amex_phase_3_5' },
        { label: 'Amex Phase 4', value: 'amex_phase_4' }
    ]);
    const [overridePhaseStrings, setOverridePhaseStrings] = useState({
        "selectSomeItems": "Select Amex Phase",
        "allItemsAreSelected": "All Amex Phase are selected.",
        "selectAll": "Select All Amex Phase",
        "search": "Search",
        "clearSearch": "Clear Search"
    });
    let brandListLabel = "<brand_list>";
    let firstNameLabel = "<user_name>";
    let publisherLabel = "<publisher_name>";
    let overrideStrings = {
        "selectSomeItems": "Select Brands/Groups",
        "allItemsAreSelected": "All brands/groups are selected.",
        "selectAll": "Select All Brands/Groups",
        "search": "Search",
        "clearSearch": "Clear Search"
    }

    useEffect(() => {
        dispatch(getDistributors());

        let name = "<user_name>", email = "<user_email>";
        if (modalData.email) {
            name = modalData.firstName;
            name = name + " " + modalData.lastName;
            email = modalData.email;
        }
        setMailContainDetails({
            name: name,
            OnboardingLink: config.guestUrl + email + "?type=campaign",
            AMEXLink: config.guestUrl + email + "?type=campaign&user=amex"
        });

        setIs_amex_phase(modalData.is_amex_phase);

        if (modalData.is_amex_phase && Array.isArray(modalData.is_amex_phase)) {
            let list_phase = [];
            amexPhaseList.forEach(element => {
                if (modalData.is_amex_phase.includes(element.value)) {
                    list_phase.push(element);
                }
            });
            setIs_amex_phase(list_phase);
        } else {
            setIs_amex_phase([]);
        }


    }, []);

    useEffect(() => {
        if (getDistributorsState.data && getDistributorsState.data.distributors) {
            if (modalData.selectedPublisher) {
                let temp = JSON.parse(JSON.stringify(getDistributorsState.data.distributors));
                temp.forEach(element => {
                    if (modalData.selectedPublisher.includes(element.distributors_name)) {
                        element.checked = true;
                    }
                });
                setDistributorList(temp);
            } else {
                setDistributorList(getDistributorsState.data.distributors);
            }
        }
    }, [getDistributorsState.data]);

    const updatePublisher = (index, value) => {
        let temp = JSON.parse(JSON.stringify(distributorList));
        temp[index].checked = value;
        setDistributorList(temp);
    }

    const sendMailToUser = () => {
        let reqData = {
            selectedMailTo: selectedMailTo.value,
            selectedTemplate: selectedTemplate.value,
            // mailContain: mailContain,
            id: modalData._id
        }
        if (!modalData._id) {
            reqData.selectedBrand = selectedBrands;
        }
        dispatch(sendMailToUserAction(reqData, response => {
            if (response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = response.message;
                dispatch(successNotificationAction(notification));
                callback()
            }
        }));
    }

    const updateActionCenter = () => {
        let list = [];
        distributorList.forEach(element => {
            if (element.checked) list.push(element.distributors_name);
        });
        let reqData = {
            selectedPublisher: list,
            id: modalData._id,
            copy: copy,
            is_amex_phase: []
        }

        if(Array.isArray(is_amex_phase)) {
            is_amex_phase.forEach(element => {
                reqData.is_amex_phase.push(element.value);
            });
        }

        dispatch(updateActionCenterAction(reqData, response => {
            if (response.respCode) {
                let notification = { ...notificationOpts };
                notification.message = response.message;
                dispatch(successNotificationAction(notification));
                callback()
            }
        }));
    }

    const onChangeTemplate = (event) => {
        setSelectedTemplate(event);
        setSelectedMailTo({});
        let list = [
            { value: "brand_user", label: "Brand/Group User" },
            { value: "publisher_user", label: "Publisher User" }
        ];
        if (event.value == "data_refresh") {
            list = [{ value: "brand_user", label: "Brand/Group User" }];
        } else if (modalData.proceesCompleted && (event.value == "onboarding_reminder" || event.value == "onboarding")) {
            list = [{ value: "publisher_user", label: "Publisher User" }];
        } else if (!modalData.proceesCompleted && modalData.isSetup) {
            list = [{ value: "brand_user", label: "Brand/Group User" }];
        }

        if (modalData.proceesCompleted && event.value == "onboarding" && modalData.is_sent_publisher_onboarding_request) {
            list = [];
        }
        if (!modalData.proceesCompleted && event.value == "onboarding" && modalData.is_sent_brand_onboarding_request) {
            list = [];
        }
        setMailToList(list);
    }

    useEffect(() => {
        if (!modalData._id && selectedMailTo && selectedMailTo.value) {
            getBrandListForSendMail();
        }
    }, [selectedMailTo && selectedMailTo.value]);

    const getBrandListForSendMail = () => {
        if (selectedTemplate.value && selectedMailTo.value) {
            let reqData = {
                selectedTemplate: selectedTemplate.value,
                selectedMailTo: selectedMailTo.value
            };
            dispatch(getBrandListForSendMailAction(reqData, response => {
                if (response.respCode) {
                    setBrandListToSendMail(response.brandList);
                    setSelectedBrands(response.brandList);
                }
            }));
        }
    }

    const onChangeSelectedBrands = (value) => {
        setSelectedBrands(value);
    }

    const customValueRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "multiple brand selected" : "No Items Selected"
        }
    };

    const customPhaseValueRenderer = (selected, _options) => {
        if (selected.length != _options.length) {
            return selected.length == 1 ? selected.label : selected.length == 2 ? `${selected[0].label},${selected[1].label}` : selected.length > 1 ? "Multiple Amex Phase selected" : "Select Amex Phase"
        }
    };

    return (
        <>

            {from == "editDetails" &&
                <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                    <ModalComponent.Header closeButton>
                        <ModalComponent.Title>
                            Edit
                        </ModalComponent.Title>
                    </ModalComponent.Header>
                    <ModalComponent.Body>

                        <div className="row col-12 form edit-action-center-modal">

                            <div className="col-12">
                                Publisher
                            </div>

                            <div className="row col-12 publisher-box">
                                {distributorList.map((res, index2) =>
                                    <div className="col-3 p-0" key={"checkbox-distributor" + index2}>
                                        <input type='checkbox' name={"checkbox-distributor" + res.distributors_name}
                                            defaultChecked={res.checked} className="distributor-checkbox"
                                            id={"checkbox-distributor" + res.distributors_name}
                                            onChange={(e) => {
                                                updatePublisher(index2, e.target.checked);
                                            }}
                                        />
                                        <span className='m-l-5'> {res.distributors_display_name} </span>
                                    </div>
                                )}
                            </div>

                            <div className="col-4 m-t-10">
                                <FormControl className='w-100' variant="standard">
                                    <TextField
                                        id='Copy'
                                        name='Copy'
                                        value={copy}
                                        type="text" label="Copy"
                                        onChange={e => { setCopy(e.target.value) }}
                                        className={`form-control mb-3`} />
                                </FormControl>
                            </div>

                            <div className="col-4 m-t-25 dropdown-1-1">
                                {/* <select
                                    name="is_amex_phase"
                                    className="form-control"
                                    value={is_amex_phase}
                                    onChange={(e) => {
                                        setIs_amex_phase(e.target.value);
                                    }}>
                                    <option value="">Select Amex Phase</option>
                                    <option value={"amex_phase_3"} >Amex Phase 3</option>
                                    <option value={"amex_phase_3_5"} >Amex Phase 3.5</option>
                                </select> */}

                                <MultiSelect
                                    className='multi-select'
                                    options={amexPhaseList}
                                    value={is_amex_phase}
                                    onChange={(value) => {
                                        setIs_amex_phase(value);
                                    }}
                                    labelledBy={"Select Amex Phase"} required={true}
                                    overrideStrings={overridePhaseStrings}
                                    valueRenderer={customPhaseValueRenderer} />

                            </div>

                            {/* <div className="col-4 m-t-10">
                                <FormControl className='w-100' variant="standard">
                                    <TextField
                                        id='Cuisines'
                                        name='Cuisines'
                                        value={cuisine_list}
                                        type="text" label="Cuisines"
                                        // onChange={e => { setBrandName(e.target.value) }}
                                        className={`form-control mb-3`} />
                                </FormControl>
                            </div> */}

                        </div>

                    </ModalComponent.Body>
                    <ModalComponent.Footer>
                        <Button variant="default" onClick={close}>
                            {cancelText}
                        </Button>
                        <Button variant="success" onClick={() => { updateActionCenter() }}>
                            Save
                        </Button>
                    </ModalComponent.Footer>
                </ModalComponent>
            }

            {from == "sendMail" &&
                <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                    <ModalComponent.Header closeButton>
                        <ModalComponent.Title>
                            Send Mail
                        </ModalComponent.Title>
                    </ModalComponent.Header>
                    <ModalComponent.Body>

                        <div className="row col-12 form edit-action-center-modal">

                            <div className="col-4">
                                <div className="form-group">
                                    <label>Email Template</label>
                                    <Select
                                        cacheOptions
                                        options={templateList}
                                        value={selectedTemplate}
                                        onChange={(event) => { onChangeTemplate(event); }}
                                        classNamePrefix="reactselect"
                                    />
                                </div>
                            </div>

                            <div className="col-4">
                                <div className="form-group">
                                    <label>Mail To</label>
                                    <Select
                                        cacheOptions
                                        options={mailToList}
                                        value={selectedMailTo}
                                        onChange={(event) => { setSelectedMailTo(event); }}
                                        classNamePrefix="reactselect" />
                                </div>
                            </div>

                            {!modalData._id && selectedTemplate.value && selectedMailTo.value &&
                                <div className="col-4">
                                    <div className="form-group dropdown w-100">
                                        <label>Select Brands/Groups</label>
                                        <MultiSelect
                                            className='multi-select'
                                            options={brandListToSendMail}
                                            value={selectedBrands}
                                            onChange={(value) => {
                                                onChangeSelectedBrands(value);
                                            }}
                                            labelledBy={"Select brands/groups"}
                                            required={true}
                                            overrideStrings={overrideStrings}
                                            valueRenderer={customValueRenderer}
                                        />
                                    </div>
                                </div>
                            }

                            {selectedMailTo && selectedMailTo.value == "brand_user" && selectedTemplate && selectedTemplate.value == "onboarding" &&
                                <div className="col-12 mail-contain">
                                    <span>Hey {mailContainDetails.name},</span> <br /><br />

                                    <span>We have onboarded the brand on Krowd's Customer Acquisition Marketplace. Please use below
                                        link to complete the registration process.</span><br /><br />

                                    <span>Onboarding: {mailContainDetails.OnboardingLink}</span><br />

                                    <span>AMEX: {mailContainDetails.AMEXLink}</span><br /><br />

                                    Best, <br />
                                    Team Krowd
                                </div>
                            }

                            {selectedMailTo && selectedMailTo.value == "brand_user" && selectedTemplate && selectedTemplate.value == "onboarding_reminder" &&
                                <div className="col-12 mail-contain">
                                    <span>Hey {mailContainDetails.name},</span> <br /><br />

                                    <span>We realize that your registration on Krowd's Customer Acquisition Marketplace is incomplete. Please use below
                                        link to complete the registration process.</span><br /><br />

                                    <span>Onboarding: {mailContainDetails.OnboardingLink}</span><br />

                                    <span>AMEX: {mailContainDetails.AMEXLink}</span><br /><br />

                                    Best, <br />
                                    Team Krowd
                                </div>
                            }

                            {selectedMailTo && selectedMailTo.value == "publisher_user" && selectedTemplate && selectedTemplate.value == "onboarding" &&
                                <div className="col-12 mail-contain">
                                    <span>Hey {firstNameLabel},</span> <br /><br />

                                    <span> Below brand(s) added for the {publisherLabel}. Please login using below link to review and update it from the action center.  </span> <br /> <br />
                                    <span>Brand Name(s): {brandListLabel} </span> <br /> <br />

                                    CAM Link : {config.adminUrl}  <br /> <br />

                                    Best, <br />
                                    Team Krowd
                                </div>
                            }

                            {selectedMailTo && selectedMailTo.value == "publisher_user" && selectedTemplate && selectedTemplate.value == "onboarding_reminder" &&
                                <div className="col-12 mail-contain">
                                    <span>Hey {firstNameLabel},</span> <br /><br />

                                    <span> Review is pending for the below brand(s). Please login using below link and review and update it from the action center.  </span> <br /> <br />
                                    <span>Brand Name(s): {brandListLabel} </span> <br /> <br />

                                    CAM Link : {config.adminUrl}  <br /> <br />

                                    Best, <br />
                                    Team Krowd
                                </div>
                            }

                            {selectedMailTo && selectedMailTo.value && selectedTemplate && selectedTemplate.value == "data_refresh" &&
                                <div className="col-12 mail-contain">
                                    <span>Hey {firstNameLabel},</span> <br /><br />

                                    <span>We’re pleased to let you know you can now view your {publisherLabel} campaign report for (period) on your CAM (cam link)</span><br /><br />

                                    <span>Our business development team will be happy to assist you with a full review of the campaign. Please do not
                                        hesitate to contact your account manager stacey@krowdit.com or sales@krowdit.com for any such requests.</span><br /><br />

                                    Thank you for your continued support <br />
                                    Team Krowd
                                </div>
                            }

                        </div>

                    </ModalComponent.Body>
                    <ModalComponent.Footer>
                        <Button variant="default" onClick={close}>
                            {cancelText}
                        </Button>
                        <Button variant="success" disabled={(!selectedMailTo || !selectedMailTo.value || !selectedTemplate ||
                            !selectedTemplate.value || (!modalData._id && selectedBrands.length == 0))} onClick={() => { sendMailToUser() }}>
                            Save
                        </Button>
                    </ModalComponent.Footer>
                </ModalComponent>
            }

        </>
    )
}

export default Modal