import React from 'react';
import { connect } from 'react-redux';
import { toggleMobileNavVisibility } from '../../redux/reducers/layout';
import Icon from '@material-ui/core/Icon'

const Header = ({
  // showMobileMenu,
  toggleMobileNavVisibility,
  user,
  mobileNavVisibility
}) => (
    <nav className="main-nav">
      <div className="navbar-toggle" onClick={toggleMobileNavVisibility}>
        {/* <button type="button" className="navbar-toggle" data-toggle="collapse" onClick={toggleMobileNavVisibility}> */}
        {/* <i className="pe-7s-menu burger"></i> */}
        <i><Icon className="burger" style={{ fontSize: "30px" }}>reorder</Icon></i>
        {/* </button> */}
      </div>
    </nav>
  );

const mapDispatchToProp = dispatch => ({
  toggleMobileNavVisibility: () => dispatch(toggleMobileNavVisibility())
});

const mapStateToProp = state => ({
  user: state.user,
  mobileNavVisibility: state.Layout.mobileNavVisibility,
});

export default connect(mapStateToProp, mapDispatchToProp)(Header);