import React, { Component } from "react";
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import capitalize from 'lodash/capitalize'
import { EmployeeForm } from '../../components/Forms/Employee'
import { Badge } from 'react-bootstrap'

class Employees extends Component {

  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    {
      dataField: 'email',
      sort: true,
      text: 'Email',
      classes: 'vhalign'
    },
    {
      dataField: 'displayName',
      sort: true,
      text: 'Display Name',
      classes: 'vhalign'
    },
    {
      dataField: 'phone',
      sort: true,
      text: 'Contact Phone',
      classes: 'vhalign'
    },
    {
      dataField: 'role',
      sort: true,
      text: 'Role',
      classes: 'vhalign',
      formatter: (col, row) => {
        let label = "";
        if(row.role == "superAdmin") label = "Super Admin";
        else if(row.role == "superAdmin") label = "Super Admin";
        else if(row.role == "admin") label = "Admin";
        else if(row.role == "salesAdmin") label = "Sales Admin";
        else if(row.role == "opsAdmin") label = "Ops Admin";
        else if(row.role == "bank") label = "Publisher";
        else label = row.role;
        return label;
      }
    },
    {
      dataField: 'updated',
      sort: true,
      text: 'Updated Date',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          var d = new Date(col);
          var n = d.toDateString();
          return n
        }
      }
    },
    {
      dataField: 'created',
      sort: true,
      text: 'Created',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          let date = new Date(col);
          // adjust 0 before single digit date
          let dd = ("0" + date.getDate()).slice(-2);
          // current month
          let mm = ("0" + (date.getMonth() + 1)).slice(-2);
          // current year
          let yy = date.getFullYear();
          return `${dd}/${mm}/${yy}`
        }
      }
    },
    {
      dataField: 'status',
      sort: true,
      text: 'Status',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col == 'Active') {
          return <Badge style={{ color: 'white', background: 'green', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'Rejected') {
          return <Badge style={{ color: 'white', background: 'red', padding: 6, borderRadius: 35 }}>{col}</Badge>
        } else if (col == 'Pending') {
          return <Badge style={{ color: 'white', background: '#ff9900', padding: 6, borderRadius: 35 }}>{col}</Badge>
        }

      }

    }
  ]

  render() {
    return <EntityList
      heading='employee'
      entityType="user"
      preset={{ type: 'brand' }}
      prefix="brand"
      icon="domain"
      EntityForm={EmployeeForm}
      columns={this.columns}
      showPasswordReset={true}
      apiUrl={'employees'}
      globalsearchPlaceHolder='Search Email / Display Name / Contact No'
    />
  }
}

export default connect(
  null,
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Employees);

