import React, { useEffect, useState } from 'react';
import placeHolderImg from '../../assets/images/placeholder150.png'
import { Icon } from '@material-ui/core';
import config from '../../config/config';
import BootstrapTable from 'react-bootstrap-table-next';
import CampaignAssets from './campaignAssets';
import ModalComponent from '../../components/Modal';
import BankPartialUserModal from '../../components/BankPartialUserModal';
import { MerchantCommentsForm } from '../../components/Forms/MerchantComments';
import { confirmMerchantAction, getCampaignForApprovalAction } from '../../redux/actions';
import { notificationOpts } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import {
    success as successNotificationAction
} from 'react-notification-system-redux';
import { getCampaignApprovalListApiCall } from '../../services/campaignApproval/getCampaignApprovalListApiCall';
import { campaignApprovalSelectors } from '../../redux/campaignApproval';
import { bankCampaignApprovalRole, Roles } from "../../utils/constants/constant";
import moment from 'moment'

export default function BrandsForBank() {

    const dispatch = useDispatch();

    const [showAssetsModal, setshowAssetsModal] = useState({ row: {}, isOpen: false });
    const [commentModal, setcommentModal] = useState({
        showApproveModal: false, selectedEntity: null, approvalAction: null
    });

    const [bankPartialUserModal, setbankPartialUserModal] = useState({
        showBankPartialUserModal: false, selectedEntity: null
    });

    const userState = useSelector(
        campaignApprovalSelectors.getUserstate
    );
    const campaignApprovalstate = useSelector(
        campaignApprovalSelectors.getCampaignApprovalstate
    );
    console.log("campaignApprovalstate", userState, campaignApprovalstate)

    const columns = [
        {
            dataField: '',
            text: 'Actions',
            classes: 'vhalign',
            formatter: (col, row) => {
                return <>
                    {(row.status == "Bank Approval Pending" || (row.bank_status_approvedby && !row.bank_status_approvedby.includes(userState._id))) ?
                        <div className='d-flex'>
                            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => setApproveModal(row, 'Approve')}>
                                <Icon style={{ fontSize: "1.5rem" }} className="text-success" data-toggle="tool-tip" title="Approve">done</Icon>
                            </span>
                            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => setApproveModal(row, 'Reject')} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Reject">clear</Icon>
                            </span>
                        </div>
                        : ""
                    }
                </>
            }
        },
        {
            dataField: 'brandName',
            text: 'Brand Name',
            classes: 'vhalign'
        },
        {
            dataField: 'name',
            text: 'Campaign Name',
            classes: 'vhalign'
        },
        {
            dataField: '',
            text: 'Campaign Assets',
            classes: 'vhalign',
            formatter: (col, row) => {
                return <a onClick={() => setshowAssetsModal({ row, isOpen: true })} style={{ textDecoration: 'underline', color: 'blue' }}> Preview</a >
            }
        },
        {
            dataField: 'status',
            text: 'Status',
            classes: 'vhalign',
            formatter: (col, row) => {
                if (row.status === "Bank Approved" && row.bank_status_approvedby && campaignApprovalstate.bankEmployees &&
                    row.bank_status_approvedby.length < campaignApprovalstate.bankEmployees.length) {
                    return <a onClick={() => showBankPartialUser(row)} style={{ textDecoration: 'underline', color: 'blue' }}> Bank Approved (Partial)</a >
                } else {
                    return row.status;
                }
            }
        },
        {
            dataField: 'discountPercent',
            text: 'Offer',
            classes: 'vhalign',
            formatter: (col, row) => {
                return `${col}%`
            }
        },
        {
            dataField: 'customerType',
            text: 'Customer Type',
            classes: 'vhalign'
        },
        {
            dataField: 'duration',
            text: 'Duration',
            classes: 'vhalign',
            formatter: (col, row) => {
                return `${moment.utc(row.openingTime).format("DD/MM/YYYY")}
                 - ${moment.utc(row.closingTime).format("DD/MM/YYYY")}`
            }
        },
        {
            dataField: 'recurring',
            text: 'Recurring',
            classes: 'vhalign',
            formatter: (col, row) => {
                return col ? "Yes" : "No"
            }
        },
        {
            dataField: 'created',
            text: 'Created On',
            classes: 'vhalign',
            formatter: (col, row) => {
                return moment(col).format("DD/MM/YYYY")
            }
        },


    ]

    const onCloseDeleteModal = () => {
        setshowAssetsModal({ ...showAssetsModal, isOpen: false })
    }

    const onCloseApproveModal = () => {
        setcommentModal({ showApproveModal: false, selectedEntity: null, approvalAction: null })
    }

    const setApproveModal = (selectedEntity, type) => {
        setcommentModal({ showApproveModal: true, selectedEntity, approvalAction: type })
    } 

    const showBankPartialUser = (selectedEntity) => {
        setbankPartialUserModal({ showBankPartialUserModal: true, selectedEntity })
    }

    const onCloseBankPartialUser = () => {
        setbankPartialUserModal({ showBankPartialUserModal: false, selectedEntity: null })
    }

    let formik;
    const formikRef = async (e) => {
        console.log(e)
        formik = e
    };
    const handleConfirmMerchant = (values, actions) => {
        values.status = commentModal.approvalAction == 'Approve' ? "Bank Approved" : "Bank Rejected"
        values._id = commentModal.selectedEntity._id
        let url = "campaigns/updateStatus"
        if (!commentModal.selectedEntity.bank_status_approvedby) commentModal.selectedEntity.bank_status_approvedby = [];
        commentModal.selectedEntity.bank_status_approvedby.push(userState._id);
        values.bank_status_approvedby = commentModal.selectedEntity.bank_status_approvedby;

        dispatch(confirmMerchantAction(values, url, (res, type) => {
            // notification
            onCloseApproveModal()
            fetchData()
            setTimeout(() => {
                let notification = { ...notificationOpts }
                if (type == 'error') {
                    notification.message = res
                } else {
                    notification.message = `Campaign ${commentModal.approvalAction == 'Approve' ? 'approved' : 'rejected'} successfully`
                }
                dispatch(successNotificationAction(notification))
            })
        }))
    }
    const submitForm = () => {
        if (formik)
            formik.submitForm()
    }

    useEffect(() => {
        fetchData()
    }, []);

    const fetchData = () => {
        // let {
        //     getFutureCampaignData,
        //     filters
        // } = this.props;
        // let { limit, page } = this.pagination
        // let { searchField } = this.state
        // if (searchField) {
        //     filters.globalSearch = {
        //         value: searchField,
        //         type: 'user'
        //     }
        // }
        let customerType = userState.customerType
        let status = ""
        if (userState.role == Roles.bank) {
            status = bankCampaignApprovalRole
        }
        dispatch(getCampaignApprovalListApiCall(1, 10, {}, 'abc', customerType, status, userState._id))
    }

    return (
        <>
            <div className='row'>
                <div className="col-md-12">
                    <div className="heading">
                        <div className="row">
                            <div className="col-md-8 d-flex align-items-center">
                                <h2 className="m-0 ">
                                    <span>
                                        <Icon className="heading-icon ">{"domain"}</Icon>
                                    </span>
                                    Campaign Approval
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="content mb-3">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                data={campaignApprovalstate.data}
                                columns={columns}
                                wrapperClasses="table-responsive"
                                remote
                                // onTableChange={this.handleTableChange}
                                noDataIndication={() => <span>No data found.</span>}
                            // pagination={pagination}
                            // pagination={paginationFactory()}
                            />

                            <ModalComponent close={onCloseDeleteModal}
                                callback={onCloseDeleteModal}
                                show={showAssetsModal.isOpen}
                                title={<span><b>Campaign Assets </b></span>}
                                message={<CampaignAssets data={showAssetsModal.row} />}
                                hideCancelBtn={true}
                                action={"Close"}
                                actionType={'danger'}
                                size={"lg"}
                                key="assets"
                            />

                            <ModalComponent close={onCloseApproveModal}
                                callback={submitForm}
                                show={commentModal.showApproveModal}
                                title={`${commentModal.approvalAction == 'Approve' ? "Approve Campaign" : "Reject Campaign"}`}
                                message={<MerchantCommentsForm
                                    formikRef={formikRef}
                                    onSubmit={handleConfirmMerchant}
                                    prefix={"Campaign"}
                                />}
                                // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                                action={"Confirm"}
                                actionType={commentModal.approvalAction == 'Approve' ? 'success' : 'danger'}
                                key="comment"
                            /> 

                            <BankPartialUserModal close={onCloseBankPartialUser}
                                show={bankPartialUserModal.showBankPartialUserModal}
                                bankPartialUserModal={bankPartialUserModal}
                                bankEmployees={campaignApprovalstate.bankEmployees}
                                key="bankPartialUser"
                            />

                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}