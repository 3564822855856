import axiosMethodRequest from '../../config/service'
import { GET_BRANCH_ENTITY_LIST } from '../../redux/newBrandBranch/actionTypes'
export const getEntityBranchApicall = (data) => {
    return dispatch => {
        // data.criteria[0].key
        const url = data ? `entity/getAllEntityBranch?filter=${JSON.stringify(data)}` : "entity"
        return axiosMethodRequest('GET', url).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: GET_BRANCH_ENTITY_LIST,
                    payload: { data: response.data.entity, loader: false, type: data.criteria[0].key }
                })
            }
        })
    }
}


// export const getBranchListByBrandIdAction = (brand_id, callback) => {
//     return dispatch => {
//         return axiosMethodRequest('GET', `entity/getAllEntityBranch?`).then((response) => {
//             if (response && response.data && response.data.respCode) {
//                 callback(response.data.entityList)
//             } else {
//                 callback([])
//             }
//         })
//     }
// }