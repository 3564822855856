import axiosMethodRequest, { getDetailedInfo } from '../../config/service'
import { GET_DASHBOARD_DATA, GET_GROUP_BRANDLIST_DATA, GET_PARENT_CAMPAIGN_DATA } from '../../redux/dashboard/actionTypes'
import { useDispatch } from 'react-redux';

export const getDashboardDataApiCall = (data, filter) => {
    // console.log("getDashboardDataApiCall   :   "+JSON.stringify(data))
    // logger.info("getDashboardDataApiCall   :   "+JSON.stringify(data))
    return dispatch => {
        dispatch({
            type: GET_DASHBOARD_DATA,
            payload: { data: [], loader: true }
        })
        const url = `transaction${data}`
        return axiosMethodRequest('GET', url).then(async (response) => {
            
            if (response && response.data && response.data.respCode) {
                var respdata = {}
                if (response.data.entity.length > 0) {
                    let { chart, revenue, calculation, brandDistribution, brandsCountPerMonth,
                        numberCustomersPerMonths, exportExcel, cashbackAndCommissions, revenuePerPublisher } = response.data.entity[0];
                    let { revenueCount, conversion, totalCost, Transactions, totalSuccessFee } = calculation[0] || {}
                    respdata.revenueCount = revenueCount || 0;
                    respdata.conversion = conversion || 0;
                    respdata.totalCost = totalCost || 0;
                    respdata.totalSuccessFee = totalSuccessFee || 0;
                    respdata.Transactions = Transactions || [];
                    respdata.exportExcel = exportExcel || [];
                    respdata.is_two_customer = response.data.is_two_customer;
                    respdata.offerTypeList = response.data.offerTypeList || [];
                    // respdata.invoiceList = response.data.invoiceList;
                    respdata.invoiceList = [];
                    if (response.data.invoiceList.length > 0) {
                        respdata.invoiceList = await verifyInvoices(response.data.invoiceList);
                    }
                    // respdata.brandDistribution = brandDistribution.length > 0 ? brandDistribution : [];
                    respdata.brandDistributionList = brandDistribution.length > 0 ? brandDistribution : [];
                    respdata.brandDistribution = [];
                    let numberBrandShow = 10;
                    for (let i = 0; i < respdata.brandDistributionList.length; i++) {
                        let element = respdata.brandDistributionList[i];
                        if (i < numberBrandShow)
                            respdata.brandDistribution.push(element)
                        else {
                            if (respdata.brandDistribution.length == numberBrandShow) {
                                respdata.brandDistribution.push({
                                    label: "Others",
                                    value: element.value,
                                    _id: "Others",
                                });
                            } else {
                                respdata.brandDistribution[numberBrandShow].value += element.value;
                            }
                        }
                    }
                    console.log("respdata.brandDistribution", respdata.brandDistribution);
                    respdata.chart = chart.length > 0 ? getDataInDescOrder(chart, "label") : [];
                    respdata.revenue = revenue.length > 0 ? getDataInDescOrder(revenue, "label") : [];
                    respdata.brandsCountPerMonth = brandsCountPerMonth.length > 0 ? getDataInDescOrder(brandsCountPerMonth, "_id") : [];
                    respdata.cashbackAndCommissions = cashbackAndCommissions.length > 0 ? getDataInDescOrder(cashbackAndCommissions, "_id") : [];
                    respdata.revenuePerPublisher = revenuePerPublisher;
                    // numberCustomersPerMonths = numberCustomersPerMonths.length > 0 ? getDataInDescOrder(numberCustomersPerMonths,"uniqueCustomerPerWeek") : []; 
                    respdata.numberCustomersPerMonths = numberCustomersPerMonths.length > 0 ? getDataInDescOrder(numberCustomersPerMonths, "_id") : [];

                    // if(numberCustomersPerMonths) {
                    //     numberCustomersPerMonths.forEach(e1 => {
                    //         if(e1.transactionPermonth) {
                    //             if(!filter.brandName) {
                    //                 let cardList1 = [];
                    //                 e1.transactionPermonth.forEach(e2 => {                         
                    //                     if(!cardList1.includes(e2.memberId)) {
                    //                         cardList1.push(e2.memberId);
                    //                     }
                    //                 });
                    //                 e1.numberOfUniqueCustomer = cardList1.length;
                    //                 delete e1.transactionPermonth;
                    //             } else {
                    //                 let cardList2 = [];
                    //                 e1.transactionPermonth.forEach(e2 => {
                    //                     if(filter.brandName == e2.brandName) {
                    //                         if(!cardList2.includes(e2.memberId)) {
                    //                             cardList2.push(e2.memberId);
                    //                         }
                    //                     }
                    //                 });
                    //                 e1.numberOfUniqueCustomer = cardList2.length;
                    //                 delete e1.transactionPermonth;                          
                    //             }
                    //         }
                    //     });
                    //     respdata.numberCustomersPerMonths = numberCustomersPerMonths.length > 0 ? numberCustomersPerMonths : [];
                    // }
                    // console.log(respdata);
                }

                dispatch({
                    type: GET_DASHBOARD_DATA,
                    payload: { data: respdata, loader: false }
                })
            }
        })
    }
}

export const getDashboardV2DataApiCall = (data,version, filter) => {

    return dispatch => {
        dispatch({
            type: GET_DASHBOARD_DATA,
            payload: { data: [], loader: true }
        })
        // let url;
        // switch (version) {
        //     case "v1":
        //         url = `transaction${data}`
        //         break;
        //     case "v2":
        //         url = "/dashboardData"
        //         break;
        //     default:
        //         break;
        // }
        const url = "/dashboardData"
        console.log("Send Server getDashboardDataApiCall    :    " + "  :    " + JSON.stringify(data))
        // const url="https://sb-api.krowdit.com/transactions/aggregate/month?stage=final&condition_type=and"
        return axiosMethodRequest('POST', url, data).then(async (response) => {
            console.log("Send Server getDashboardDataApiCall response   :      " + JSON.stringify(response))
            if (response && response.data) {
                // && response.data.respCode) {
                var respdata = {}
                if (response.data.entity!==undefined&&response.data.entity.length > 0) {
                    let { chart, revenue, calculation, brandDistribution, brandsCountPerMonth,
                        cashbackAndCommissions, revenuePerPublisher, invoiceList,logData } = response.data.entity[0];
                    // numberCustomersPerMonths, exportExcel, cashbackAndCommissions, revenuePerPublisher } = response.data.entity[0];
                    // let { revenueCount, conversion, totalCost, Transactions, totalSuccessFee } = calculation[0] || {}
                    let { totalCashbackAmount, conversions, totalAmount, Transactions, totalCommissions } = calculation[0] || {}
                    console.log("Send Server getDashboardDataApiCall response  2  :      " +JSON.stringify(invoiceList))
                    respdata.revenueCount = totalAmount || 0;
                    respdata.conversion = conversions || 0;
                    respdata.totalCost = totalCashbackAmount || 0;
                    respdata.totalSuccessFee = totalCommissions || 0;
                    respdata.Transactions = Transactions || [];
                    respdata.logData = logData || [];
                    // respdata.invoiceList = invoiceList || [];
                    console.log("Send Server getDashboardDataApiCall response  3  :      ")
                    // respdata.exportExcel = exportExcel || [];
                    respdata.is_two_customer = response.data.is_two_customer;
                    respdata.offerTypeList = response.data.offerTypeList || [];
                    console.log("Send Server getDashboardDataApiCall response  4  :      ")
                    // respdata.invoiceList = response.data.invoiceList;
                    respdata.invoiceList = [];
                    console.log("Invoice Verification    :    "+ JSON.stringify(response.data.entity[0].invoiceList))
                    if (response.data.entity[0].invoiceList !== undefined && response.data.entity[0].invoiceList.length > 0) {
                        // respdata.invoiceList = await verifyInvoices(response.data.entity[0].invoiceList);
                        respdata.invoiceList=response.data.entity[0].invoiceList
                    }
                    console.log("Send Server getDashboardDataApiCall response  5  :      ")
                    // respdata.brandDistribution = brandDistribution.length > 0 ? brandDistribution : [];
                    respdata.brandDistributionList = brandDistribution.length > 0 ? brandDistribution : [];
                    respdata.brandDistribution = [];
                    let numberBrandShow = 10;
                    console.log("Send Server getDashboardDataApiCall response  6  :      "+JSON.stringify(respdata.invoiceList))
                    for (let i = 0; i < respdata.brandDistributionList.length; i++) {
                        let element = respdata.brandDistributionList[i];
                        if (i < numberBrandShow)
                            respdata.brandDistribution.push(element)
                        else {
                            if (respdata.brandDistribution.length == numberBrandShow) {
                                respdata.brandDistribution.push({
                                    label: "Others",
                                    value: element.value,
                                    _id: "Others",
                                });
                            } else {
                                respdata.brandDistribution[numberBrandShow].value += element.value;
                            }
                        }
                    }
                    console.log("Send Server getDashboardDataApiCall response  7  :      ")
                    console.log("respdata.brandDistribution", JSON.stringify(respdata));
                    respdata.chart = chart.length > 0 ? getDataInDescOrder(chart, "label") : [];
                    respdata.revenue = revenue.length > 0 ? getDataInDescOrder(revenue, "label") : [];
                    respdata.brandsCountPerMonth = brandsCountPerMonth.length > 0 ? getDataInDescOrder(brandsCountPerMonth, "_id") : [];
                    respdata.cashbackAndCommissions = cashbackAndCommissions.length > 0 ? getDataInDescOrder(cashbackAndCommissions, "_id") : [];
                    respdata.revenuePerPublisher = revenuePerPublisher;
                    // numberCustomersPerMonths = numberCustomersPerMonths.length > 0 ? getDataInDescOrder(numberCustomersPerMonths,"uniqueCustomerPerWeek") : []; 
                    // respdata.numxberCustomersPerMonths = numberCustomersPerMonths.length > 0 ? getDataInDescOrder(numberCustomersPerMonths, "_id") : [];

                    // if(numberCustomersPerMonths) {
                    //     numberCustomersPerMonths.forEach(e1 => {
                    //         if(e1.transactionPermonth) {
                    //             if(!filter.brandName) {
                    //                 let cardList1 = [];
                    //                 e1.transactionPermonth.forEach(e2 => {                         
                    //                     if(!cardList1.includes(e2.memberId)) {
                    //                         cardList1.push(e2.memberId);
                    //                     }
                    //                 });
                    //                 e1.numberOfUniqueCustomer = cardList1.length;
                    //                 delete e1.transactionPermonth;
                    //             } else {
                    //                 let cardList2 = [];
                    //                 e1.transactionPermonth.forEach(e2 => {
                    //                     if(filter.brandName == e2.brandName) {
                    //                         if(!cardList2.includes(e2.memberId)) {
                    //                             cardList2.push(e2.memberId);
                    //                         }
                    //                     }
                    //                 });
                    //                 e1.numberOfUniqueCustomer = cardList2.length;
                    //                 delete e1.transactionPermonth;                          
                    //             }
                    //         }
                    //     });
                    //     respdata.numberCustomersPerMonths = numberCustomersPerMonths.length > 0 ? numberCustomersPerMonths : [];
                    // }
                    // console.log(respdata);
                }
                console.log("Send Server getDashboardDataApiCall response  8  :      "+JSON.stringify(respdata))
                dispatch({
                    type: GET_DASHBOARD_DATA,
                    payload: { data: respdata, loader: false }
                })
            }
            
        })
    }
}


function getDataInDescOrder(list, fieldName) {

    if (fieldName == "uniqueCustomerPerWeek") {
        return list.sort((a, b) => {
            a["_id"]["newTransMonth"] = a["_id"]["transMonth"];
            b["_id"]["newTransMonth"] = b["_id"]["transMonth"];
            a["_id"]["newTransMonth"] = addAdditionalValue(a["_id"]["newTransMonth"]);
            b["_id"]["newTransMonth"] = addAdditionalValue(b["_id"]["newTransMonth"]);
            return parseInt(a["_id"]["newTransMonth"]) - parseInt(b["_id"]["newTransMonth"])
        });
    } else {
        return list.sort((a, b) => {
            a["new" + fieldName] = a[fieldName];
            b["new" + fieldName] = b[fieldName];
            a["new" + fieldName] = addAdditionalValue(a[fieldName]);
            b["new" + fieldName] = addAdditionalValue(b[fieldName]);
            return parseInt(a["new" + fieldName]) - parseInt(b["new" + fieldName])
        });
    }

}

function addAdditionalValue(value) {
    if (value && value.indexOf("-") != -1 && value.split("-")[1].length == 1) {
        value = value.split("-")[0] + "0" + value.split("-")[1];
    }
    return value ? value.replaceAll("-", "") : "";
}


export const getGroupBrandListApiCall = () => {
    return dispatch => {
        dispatch({
            type: GET_GROUP_BRANDLIST_DATA,
            payload: { data: [], loader: true }
        });
        return axiosMethodRequest('GET', `groupBrand?from=brand`).then((response) => {
            if (response && response.data && response.data.groupBrand) {
                dispatch({
                    type: GET_GROUP_BRANDLIST_DATA,
                    payload: { data: response.data.groupBrand, loader: true }
                });
            } else {
                dispatch({
                    type: GET_GROUP_BRANDLIST_DATA,
                    payload: { data: [], loader: true, errorCode: response.data.errorCode, errorMessage: response.data.errorMessage }
                });
            }
        });
    }
}

export const getParentCampaignApiCall = (brandId) => {
    return dispatch => {
        dispatch({
            type: GET_PARENT_CAMPAIGN_DATA,
            payload: { data: [], is_two_customer: false, loader: true }
        });
        return axiosMethodRequest('GET', "campaigns/getParentCampaign/" + brandId).then((response) => {
            if (response && response.data && response.data.details) {
                dispatch({
                    type: GET_PARENT_CAMPAIGN_DATA,
                    payload: { data: response.data.details, is_two_customer: response.data.is_two_customer, loader: true }
                });
            }
        });
    }
}

export const exportTransactionApiCall = (data,email, callBack) => {
    console.log("exportTransactionApiCall    :  " +JSON.stringify(email))
    return dispatch => {
        const url='/export'
        // const url = `transaction/exportTransaction${data}`
        return getDetailedInfo(url,data,email).then((response) => {
            callBack(response);
        })
    }
}

export const verifyInvoices = (invoiceList) => {

    const promise = new Promise(async (resolve, reject) => {
        let invoiceNumber = [];
        invoiceList.forEach(element => {
            invoiceNumber.push(element.xero_invoiceNumber)
        });
        invoiceNumber = invoiceNumber.join(",");
        console.log("Verifiying Invoices    : 1   "+JSON.stringify(invoiceList))
        const url = `transaction/verifyInvoices?InvoiceNumbers=` + invoiceNumber
        return axiosMethodRequest('GET', url).then((response) => {
            console.log("Verifiying Invoices    : 2  "+JSON.stringify(response))
            if (response.data && response.data.invoiceList) {
                invoiceList.forEach(element => {
                    let filter = response.data.invoiceList.filter(a => a == element.xero_invoiceNumber);
                    if (filter && filter.length > 0) {
                        element.canView = true;
                    }
                });
                invoiceList = invoiceList.length > 1 ? [{ xero_invoiceNumber: "All", _id: "All" }].concat(invoiceList) : invoiceList;
            }
            resolve(invoiceList);
        })
    });
    return promise;
}
