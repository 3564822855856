import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as Datetime from 'react-datetime'
import { Collapse } from 'react-bootstrap';
import Icon from '@material-ui/core/Icon'
import {
  getCampaignAction,
  deleteCampaignAction,
  setCampaignForEditAction,
  setCampaignsPageAction,
  setCampaignsSortAction,
  setCampaignsSearchAction,
  setCampaignsFiltersAction
} from '../../redux/actions/campaignActions'
import {
  success as successNotificationAction,
} from 'react-notification-system-redux';
import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//session expiry modal
import SessionExpiryModal from './../../components/sessionExpiryModal'
import { GetSessionValue } from "../../utils/sessionStorage";
import { Roles } from "../../utils/constants/constant";
import { getDistributorAction } from "../../redux/actions";
import DistributorModal from '../../components/DistributorModal';
import { getAllCampaignAction } from '../../services/newBrandBranch/createUserApiCall';
import * as XLSX from "xlsx";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const getCurrency = () => {
  // return <i className="fa fa-usd"></i>
  return 'AED'
}
class PastCampaign extends Component {

  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    start_date: '',
    end_date: '',
    sortCount: 0,
    isLoading: false,
    sessionExpiryModal: false,
    distributorModalData: { isOpenDistributorModal: false, selectedEntity: null },
    allDistributors: [],
    campaignList: [],
    disableExport: false,
    selectedMonth: null,
    previousMonth: new Date()
  };

  pagination = {
    limit: 10,
    page: 1
  }

  fetchData = () => {
    let {
      getPastCampaignData,
      filters
    } = this.props;
    let { sizePerPage } = this.pagination
    let { limit, page } = this.pagination
    let { searchField } = this.state
    if (searchField) {
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    let customerType = this.props.user.customerType
    getPastCampaignData(page, limit, filters, 'abc', customerType, "", (res, pagination) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal })
    })
  }

  componentDidMount() {
    this.fetchData(this.props.page || this.pagination.page);
    this.getDistributor();

    let cr = new Date();
    let pr = new Date(cr.getFullYear(), cr.getMonth() - 1, 1);
    this.setState({ selectedMonth: pr, previousMonth: pr });
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      this.fetchData()
    }
  }

  onExportCampaignClick = () => {
    let { getAllCampaign } = this.props;
    let query = "?campaignType=past";

    if(this.state.selectedMonth) {
      query = query + "&month=" + (new Date(this.state.selectedMonth).getMonth() + 1)
      query = query + "&year=" + new Date(this.state.selectedMonth).getFullYear()
    } 

    this.setState({ disableExport: true });
    getAllCampaign(query, response => {
      console.log("Get Campaigns   :  "+JSON.stringify(response))
      if (response.campaigns) {
        let list = [];
        response.campaigns.forEach(element => {
          let aa = {
            brandName: element.brandName,
            campaignName: element.campaignName,
            campaignStart: moment(element.openingTime).format('DD-MM-YYYY').toString(),
            campaignEnd: moment(element.closingTime).format('DD-MM-YYYY').toString(),
            discountPercent: element.discountPercent,
            successFee: element.successFee
          };
          console.log("AA Details   :  "+JSON.stringify(aa))
          if (element.distributors) {
            element.distributors.forEach(e2 => {
              aa[e2.distributors_name + " Discount"] = e2.percentage;
              aa[e2.distributors_name + " SuccessFee"] = e2.successFee;
            });
          }
          list.push(aa);
        });
        this.setState({ disableExport: false, campaignList: list });
        this.exportCampaigns();
      }
    })
  }

  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }

  getMonthText = last_transaction => {
    return last_transaction <= 1 ? `${last_transaction} Month` : `${last_transaction} Months`
  }

  showHideDistributorModal = (row, modelState) => {
    this.setState({
      distributorModalData: {
        isOpenDistributorModal: modelState, selectedEntity: modelState ? row : {}
      }
    })
  }

  getDistributor = () => {
    let url = "entity/getDistributor";
    let { getDistributor } = this.props;
    getDistributor(url, (res) => {
      let list = [];
      res.forEach(element => {
        list.push({ _id: element._id, distributors_name: element.distributors_name, distributors_display_name: element.distributors_display_name });
      });
      this.setState({ allDistributors: list })
    })
  }


  // columns = [
  //   // {
  //   //   dataField: 'actions',
  //   //   isDummyField: true,
  //   //   text: 'Actions',
  //   //   classes: 'vhalign',
  //   //   formatter: (cell, row) => {
  //   //     return (
  //   //       <React.Fragment>
  //   //         {/* <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)} >
  //   //           <Icon style={{ fontSize: "1.5rem" }} className="text-danger">delete</Icon>
  //   //         </span> */}
  //   //         {/* <span className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.handleEditCampaign(row)} >
  //   //           <Icon style={{ fontSize: "1.5rem" }} className="text-info">open_in_new</Icon>
  //   //         </span> */}
  //   //       </React.Fragment>
  //   //     )
  //   //   }
  //   // },
  //   {
  //     dataField: 'name',
  //     sort: true,
  //     text: 'Campaign Name',
  //     classes: 'vhalign'
  //   },
  //   {
  //     dataField: 'suggestions',
  //     sort: true,
  //     text: 'Description',
  //     classes: 'vhalign',
  //     formatter: cell => {
  //       return cell.toLocaleString()
  //     }
  //   },
  //   {
  //     dataField: 'brandName',
  //     sort: true,
  //     text: 'Brand Name',
  //     classes: 'vhalign',
  //     formatter: cell => {
  //       if (cell) {
  //         return cell.toLocaleString()
  //       }
  //     }
  //   },
  //   {
  //     dataField: '',
  //     text: 'Campaign Parameters',
  //     classes: 'vhalign',
  //     formatter: (col, row) => {
  //       return <a onClick={() => this.showHideDistributorModal(row, true)} style={{ textDecoration: 'underline', color: 'blue' }}>View</a >
  //     }
  //   },
  //   {
  //     dataField: 'merchantNames',
  //     sort: true,
  //     text: 'Branch Name',
  //     classes: 'vhalign',
  //     formatter: cell => {
  //       if (cell!==undefined&&cell) {
  //         return cell.toLocaleString()
  //       }
  //     }
  //   },
  //   {
  //     dataField: 'customerType',
  //     sort: true,
  //     text: 'Customer Type',
  //     classes: 'vhalign',
  //     formatter: (col, row) => {
  //       return col === 'new' ? 'New Customer' : `Existing Customer (${this.getMonthText(row.lastTransaction)})`
  //     }
  //   },
  //   {
  //     dataField: 'discountType',
  //     sort: true,
  //     text: 'Discount Type',
  //     classes: 'vhalign',
  //     formatter: (cell, row, rowIndex) => {
  //       switch (cell) {
  //         case 'dp': {
  //           return 'Flat Percentage'
  //         }
  //         case 'dv': {
  //           return 'Discount Value'
  //         }
  //         case 'ss': {
  //           return 'Stretch Spend'
  //         }
  //         default: return ''
  //       }
  //     }
  //   },
  //   {
  //     dataField: 'discountMinAmount',
  //     sort: true,
  //     isDummyField: true,
  //     text: 'Minimum Spend',
  //     classes: 'vhalign',
  //     formatter: (cell, row, rowIndex) => {
  //       switch (row.discount_type) {
  //         case 'dp': {
  //           return 'none'
  //         }
  //         case 'dv': {
  //           return <span> {row.discount_amount} {getCurrency()}</span>
  //         }
  //         case 'ss': {
  //           return (
  //             <span> {row.discount_spread_amount}% <br /> <small>Avg. Spend : {row.spread_avg_spend} {getCurrency()}</small></span>
  //           )
  //         }
  //         default: return 'none'
  //       }
  //     }
  //   },
  //   // {
  //   //   dataField: 'discountPercent',
  //   //   sort: true,
  //   //   isDummyField: true,
  //   //   text: 'Discount',
  //   //   classes: 'vhalign',
  //   //   formatter: (cell, row, rowIndex) => {
  //   //     switch (row.discountType) {
  //   //       case 'dp': {
  //   //         return row.discountPercent && <span>{row.discountPercent}%</span>
  //   //       }
  //   //       case 'dv': {
  //   //         return <span> {row.discountValue} {getCurrency()}</span>
  //   //       }
  //   //       case 'ss': {
  //   //         return (
  //   //           <span> {row.discount_spread_value}% </span>
  //   //         )
  //   //       }
  //   //       default: return 'none'
  //   //     }
  //   //   }
  //   // },
  //   // {
  //   //   dataField: 'successFee',
  //   //   sort: true,
  //   //   text: 'Success Fee',
  //   //   classes: 'vhalign',
  //   //   formatter: (cell, row) => {
  //   //     if (cell)
  //   //       return cell + '%'
  //   //     return cell
  //   //   }
  //   // },
  //   {
  //     dataField: 'openingTime',
  //     sort: true,
  //     text: 'Start Date',
  //     classes: 'vhalign',
  //     formatter: (cell, row, rowIndex) => {
  //       return moment(cell).format('DD/MM/YYYY')
  //     }
  //   },
  //   {
  //     dataField: 'closingTime',
  //     sort: true,
  //     text: 'End Date',
  //     classes: 'vhalign',
  //     formatter: (cell, row, rowIndex) => {
  //       return moment(cell).subtract(1, 'days').format('DD/MM/YYYY')
  //     }
  //   }
  // ]
  columns = [
    {
      dataField: 'name',
      sort: true,
      text: 'Campaign Name',
      classes: 'vhalign'
    },
    {
      dataField: 'suggestions',
      sort: true,
      text: 'Description',
      classes: 'vhalign',
      formatter: cell => {
        return cell ? cell.toLocaleString() : 'N/A'; // Handle undefined or null cells
      }
    },
    {
      dataField: 'brandName',
      sort: true,
      text: 'Brand Name',
      classes: 'vhalign',
      formatter: cell => {
        return cell ? cell.toLocaleString() : 'N/A'; // Handle undefined or null cells
      }
    },
    {
      dataField: '',
      text: 'Campaign Parameters',
      classes: 'vhalign',
      formatter: (col, row) => {
        return (
          <a onClick={() => this.showHideDistributorModal(row, true)} style={{ textDecoration: 'underline', color: 'blue' }}>
            View
          </a>
        );
      }
    },
    {
      dataField: 'merchantNames',
      sort: true,
      text: 'Branch Name',
      classes: 'vhalign',
      formatter: cell => {
        return cell ? cell.toLocaleString() : 'N/A'; // Handle undefined or null cells
      }
    },
    {
      dataField: 'customerType',
      sort: true,
      text: 'Customer Type',
      classes: 'vhalign',
      formatter: (col, row) => {
        return col === 'new' ? 'New Customer' : `Existing Customer (${this.getMonthText(row.lastTransaction)})`;
      }
    },
    {
      dataField: 'discountType',
      sort: true,
      text: 'Discount Type',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        switch (cell) {
          case 'dp': return 'Flat Percentage';
          case 'dv': return 'Discount Value';
          case 'ss': return 'Stretch Spend';
          default: return 'N/A'; // Default case to handle undefined or unexpected values
        }
      }
    },
    {
      dataField: 'discountMinAmount',
      sort: true,
      isDummyField: true,
      text: 'Minimum Spend',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        switch (row.discount_type) {
          case 'dp': return 'none';
          case 'dv': return <span>{row.discount_amount} {getCurrency()}</span>;
          case 'ss': return (
            <span>{row.discount_spread_amount}% <br /><small>Avg. Spend : {row.spread_avg_spend} {getCurrency()}</small></span>
          );
          default: return 'none';
        }
      }
    },
    {
      dataField: 'openingTime',
      sort: true,
      text: 'Start Date',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        return cell ? moment(cell).format('DD/MM/YYYY') : 'N/A'; // Handle undefined or null dates
      }
    },
    {
      dataField: 'closingTime',
      sort: true,
      text: 'End Date',
      classes: 'vhalign',
      formatter: (cell, row, rowIndex) => {
        return cell ? moment(cell).subtract(1, 'days').format('DD/MM/YYYY') : 'N/A'; // Handle undefined or null dates
      }
    }
  ];
  

  setShowDeleteModal = (campaign) => {
    this.setState({ showDeleteModal: true, selectedCampaign: campaign });
  }

  handleDeleteCampaign = () => {
    let { deleteCampaign, successNotification } = this.props;
    let { selectedCampaign: campaign } = this.state;
    deleteCampaign(campaign, (err) => {
      if (err) {
        return
      }
      this.setState({ showDeleteModal: false, selectedCampaign: null }, () => {
        // show notification
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = 'Campaign deleted successfully'
          successNotification(notification)
        })
      });
      this.fetchData(this.props.page)
    })
  }

  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  }

  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { sizePerPage } = this.pagination;
    return parseInt(totalSize / sizePerPage) + (totalSize % sizePerPage ? 1 : 0)
  }

  handleEditCampaign = (campaign) => {
    let { setCampaignForEdit } = this.props;
    setCampaignForEdit(campaign)
    this.props.history.push(`/user/campaigns/edit`)
  }

  handleEditCampaignOverview = campaign => {
    this.props.history.push(`/user/campaign-overview/edit`)
  }

  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters }) => {
    let {
      setPastCampaignsPage,
      setPastCampaignsSort,
      setPastCampaignsSearch,
      setPastCampaignsFilters,
      getPastCampaignData
    } = this.props;
    let { page, limit } = this.pagination
    let { searchField } = this.state;
    let filterCriteria = {}
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    let customerType = this.props.user.customerType

    switch (type) {
      case 'pagination': {
        setPastCampaignsPage(page, sizePerPage)
        break;
      }
      case 'sort': {
        let sortCount = this.state.sortCount
        sortCount = sortCount == 0 ? sortCount + 1 : 0;
        filterCriteria.sortField = {
          direction: sortCount == 0 ? "desc" : 'asc',
          sortfield: sortField,
        }
        this.setState({ sortCount: sortCount })
        getPastCampaignData(page, limit, filterCriteria, 'sort', customerType, "", (res, pagination) => {
        })
        break
      }
      case 'search': {
        filterCriteria.globalSearch = {
          value: searchText,
          type: 'user'
        }
        getPastCampaignData(page, limit, filterCriteria, 'globalsearch', customerType, "", (res, pagination) => {
          this.setState({ campaigns: res })
          if (pagination && pagination.totalCount) {
            this.setState({ pagination: pagination })
          }
        })
        break
      }
      case 'filter': {
        setPastCampaignsFilters({ ...filters })
        setPastCampaignsPage(1, sizePerPage)
        break
      }
      default:
        return
    }
  }

  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }

  handleDateChange = (name, value) => {
    this.handleTableChange('filter', { filters: { [`${name}`]: value ? value.toISOString() : '' } })
  }

  getCurrentPageTotalPage = paginationProps => {
    let { page } = paginationProps
    let totalPages = this.getPageCount(paginationProps)
    if (!totalPages)
      return `0/0`;
    return `${page}/${totalPages}`
  }

  clearDate = props => {
    props.onChange({ target: { value: '' } })
  }

  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }

  onPageChange = async (page) => {
    this.pagination.page = page
  }

  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-1" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content">
          {
            options.map((option) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )

  exportCampaigns = () => {

    const workbook = XLSX.utils.book_new();

    let exportData = [];
    this.state.campaignList.forEach(element => {

      exportData.push({
        "Brand Name": element.brandName,
        "Campaign Date": element.campaignStart,
        // "Campaign End": element.campaignEnd,
        "Discount Percent": element.discountPercent,
        "SuccessFee": element.successFee,
        "ATR Discount": element["ATR Discount"],
        "ATR SuccessFee": element["ATR SuccessFee"],
        "Amex Discount": element["Amex Discount"],
        "Amex SuccessFee": element["Amex SuccessFee"],
        "BA Discount": element["BA Discount"],
        "BA SuccessFee": element["BA SuccessFee"],
        "Curve Discount": element["Curve Discount"],
        "Curve SuccessFee": element["Curve SuccessFee"],
        "Fidel Discount": element["Fidel Discount"],
        "Fidel SuccessFee": element["Fidel SuccessFee"],
        "LUX Discount": element["LUX Discount"],
        "LUX SuccessFee": element["LUX SuccessFee"],
        "Revolut Discount": element["Revolut Discount"],
        "Revolut SuccessFee": element["Revolut SuccessFee"],
        "Zilch Discount": element["Zilch Discount"],
        "Zilch SuccessFee": element["Zilch SuccessFee"]
      });

    });
    const sheet = XLSX.utils.json_to_sheet(exportData);
    XLSX.utils.book_append_sheet(workbook, sheet, "Campaign");
    XLSX.writeFile(workbook, "pastCampaignDetails.xlsx");
  }

  render() {
    const { showDeleteModal, selectedCampaign, distributorModalData, allDistributors } = this.state
    const { totalCount, filters, campaigns, isLoading } = this.props;
    let thisMonth = moment().startOf('month')
    let { page } = this.pagination
    let pagination = paginationFactory({
      sizePerPageRenderer: this.sizePerPageRenderer,
      page,
      sizePerPage: this.pagination.limit,
      totalSize: totalCount,
      hideSizePerPage: false,
      hidePageListOnlyOnePage: true,
      showTotal: true,
      alwaysShowAllBtns: true,
      onPageChange: (page, sizePerPage) => {
        this.onPageChange(page)
      }
    })
    if (this.props.user.role == Roles.bank) {
      this.columns = this.columns.filter(res => !["actions"].includes(res.dataField))
    }
    return (
      <div>
        <Loader loader={isLoading} />
        <ModalComponent
          close={this.onCloseDeleteModal}
          callback={this.handleDeleteCampaign}
          show={showDeleteModal}
          title={"Delete Camapaign"}
          message={<span>Are you sure you want to delete the <b>"{selectedCampaign ? selectedCampaign.name : ''}"</b> campaign ?</span>}
          action={"Delete"}
          actionType='danger'
        />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0">
                    <span>
                      <Icon className="heading-icon">fast_forward</Icon>
                    </span> Past Campaigns
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="mt-4 container-fluid">
                <div className="row">
                  <div className="col-md-4">
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder="Search Campaign Name" />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-1 offset-7 d-none">
                    <button className="btn pull-right btn-outline-primary" onClick={() => this.setState({ showFilter: !this.state.showFilter })}>
                      <i className="fa fa-filter"></i>
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Collapse
                      in={this.state.showFilter}
                    >
                      <div className="card">
                        <div className="content">
                          <div className="form-group mb-3">
                            <label>Date Range </label>
                            <br />
                            <div className="row">
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (filters && filters.end_date)
                                      return current.isSameOrBefore(filters.end_date, 'month') && current.isAfter(thisMonth, 'month')
                                    return current.isAfter(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('start_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="Start Month" className="form-control" name="start_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {filters && filters.start_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                              <div className="col-md-3">
                                <Datetime
                                  viewMode="months"
                                  timeFormat={false}
                                  dateFormat="MM/YYYY"
                                  isValidDate={current => {
                                    if (filters && filters.end_date)
                                      return current.isSameOrAfter(filters.end_date, 'month') && current.isAfter(thisMonth, 'month')
                                    return current.isAfter(thisMonth, 'month')
                                  }}
                                  onChange={(date) => this.handleDateChange('end_date', date)}
                                  renderInput={(props, openCalendar) => {
                                    return (
                                      <div className="input-group mb-3">
                                        <input {...props} type="text" placeholder="End Month" className="form-control" name="end_month" />
                                        <div className="input-group-append date-dropdown-btn">
                                          {filters && filters.end_date
                                            ? <span className="input-group-text" onClick={this.clearDate.bind(this, props)}><i className="fa fa-times" ></i></span>
                                            : ''
                                          }
                                          <span className="input-group-text" onClick={openCalendar}><i className="fa fa-chevron-down" ></i></span>
                                        </div>
                                      </div>
                                    )
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
              <div className="content mt-4 mb-3">

                <div className="row">
                  <div className="col-md-4">

                    <div className="form-group w-200 ib-block">
                      <label>Month </label>

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          inputVariant="outlined"
                          inputFormat="yyyy-MM"
                          views={['year', 'month']}
                          value={this.state.selectedMonth}
                          clearable={true}
                          maxDate={this.state.previousMonth}
                          InputProps={{
                            endAdornment: (
                              <IconButton onClick={() => {
                                this.setState({ selectedMonth: null });
                              }} >
                                {/* <ClearIcon /> */}
                              </IconButton>
                            )
                          }}
                          InputAdornmentProps={{
                            position: "start"
                          }}
                          onChange={(value, e) => {
                            this.setState({ selectedMonth: value });
                          }}>
                        </KeyboardDatePicker>
                      </MuiPickersUtilsProvider>
                    </div>


                    <button className={`btn btn-primary mb-3 m-l-5 ib-block m-t-25`} disabled={this.state.disableExport} onClick={() => { this.onExportCampaignClick() }} >
                      Export
                    </button>
                  </div>
                </div>


                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  data={campaigns}
                  columns={this.columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                />
              </div>
              <SessionExpiryModal
                isOpen={this.state.sessionExpiryModal}
              />

              <DistributorModal close={() => this.showHideDistributorModal({}, false)}
                show={distributorModalData.isOpenDistributorModal}
                distributorModalData={distributorModalData}
                allDistributors={allDistributors}
                hideSaveButton={true}
                key="distributor"
              />

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    campaigns: state.campaigns.pastCampaigns.campaigns,
    isLoading: state.campaigns.pastCampaigns.isLoading,
    page: state.campaigns.pastCampaigns.page,
    totalCount: state.campaigns.pastCampaigns.totalCount,
    sizePerPage: state.campaigns.pastCampaigns.sizePerPage,
    filters: state.campaigns.pastCampaigns.filters,
    user: state.user
  }),
  dispatch => ({
    getPastCampaignData: bindActionCreators(getCampaignAction('past'), dispatch),
    deleteCampaign: bindActionCreators(deleteCampaignAction, dispatch),
    setCampaignForEdit: bindActionCreators(setCampaignForEditAction, dispatch),
    setPastCampaignsPage: bindActionCreators(setCampaignsPageAction('past'), dispatch),
    setPastCampaignsSort: bindActionCreators(setCampaignsSortAction('past'), dispatch),
    setPastCampaignsSearch: bindActionCreators(setCampaignsSearchAction('past'), dispatch),
    setPastCampaignsFilters: bindActionCreators(setCampaignsFiltersAction('past'), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    getDistributor: bindActionCreators(getDistributorAction, dispatch),
    getAllCampaign: bindActionCreators(getAllCampaignAction, dispatch)
  })
)(PastCampaign);
