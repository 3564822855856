import { reducer as formReducer } from 'redux-form'
import ThemeOptions from './themeoptions';
import Layout from './layout';
import authReducer from './auth'
import campaignReducer from './campaign'
import branchReducer from './branch'
import entityListReducer from './entityList'
import entityDetailReducer from './entityDetail'
import selectEntitiesReducer from './selectEntities'
import campaignOverviewReducer from './campaignoverview'
import { combineReducers } from 'redux';
import { reducer as notifications } from 'react-notification-system-redux';
import employeeList from './employees';
import { newBrandBranchReducer } from '../newBrandBranch';
import { dashboardReducer } from '../dashboard';
import { campaignApprovalReducer } from '../campaignApproval';
import { brandActionCenterReducer } from '../brandActionCenter';

export default combineReducers({
  ThemeOptions,
  Layout,
  form: formReducer,
  user: authReducer,
  campaigns: campaignReducer,
  employeeList: employeeList,
  campaignOverview: campaignOverviewReducer,
  banks: entityListReducer('bank'),
  brands: entityListReducer('brand'),
  users: entityListReducer('user'),
  selectEntities: selectEntitiesReducer,
  bank: entityDetailReducer('bank'),
  brand: entityDetailReducer('brands'),
  notifications,
  employee: entityListReducer('employee'),
  activities: entityListReducer('activities'),
  OnBoardActivities: entityListReducer('OnBoardActivities'),
  documents: entityListReducer('SET_MANAGECREATIVE_DATA'),
  branches: entityListReducer('branch'),
  settings: authReducer,
  email: entityListReducer('email'),
  campaignTemplate: entityListReducer('campaignTemplate'),
  newBrandBranch: newBrandBranchReducer,
  dashboard: dashboardReducer,
  campaignApproval: campaignApprovalReducer,
  distributors: entityListReducer('distributor'),
  groupBrands: entityListReducer('groupBrand'),
  ingestionLogs: entityListReducer('ingestionLog'),
  brandActionCenter: brandActionCenterReducer
});