
const StatsCardConfig = {
  statistics : {
    'reach' : {
      icon : 'remove_red_eye',
      value : '265,881',
      label : "Reach",
      type : 'int'
    },
    'conversions' : {
      icon :'check_circle_outline',
      value : '10,122',
      label : "Conversions",
      type : 'int'
    },
    'revenue' : {
      icon : 'monetization_on',
      value : '129,047',
      label : "Revenue",
      type : 'int',
      isCurrency : true
    },
    'total_cost' : {
      icon : 'attach_money',
      value : '37,389',
      label : "Total Cost",
      type : 'int',
      isCurrency : true
    },
    'cpa' : {
      icon : 'poll',
      value : '3.69',
      label : "CPA",
      type : 'float',
      isCurrency : true
    },
    date : {
      icon : 'date_range',
      value : '4',
      label : "Day's left",
    }
  },
  insights : {
    'highest_spend' : {
      value : '26.64', 
      label : "Highest Spend",
      type : 'float',
      isCurrency : true
    },
    'lowest_spend' : {
      value : '4.34', 
      label : "Lowest Spend",
      type : 'float',
      isCurrency : true
    },
    'average_spend' : {
      value : '12.81', 
      label : "Average Spend",
      type : 'float',
      isCurrency : true
    },
    'repeat_customers' : {
      value : '24%', 
      label : "Repeat Customers",
    }
  },
}

export default StatsCardConfig;



