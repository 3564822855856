import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';

const Modal = ({
    close,
    show,
    bankEmployees,
    bankPartialUserModal,
    size = "md",
    cancelText = "Cancel",
    centered = false
}) => {
    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> Approval Status </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>

                <table className="table table-bordered">
                    <thead>
                        <tr> 
                            <td> Bank name </td>
                            <td> Bank status </td>
                        </tr>
                    </thead>
                    <tbody>
                        {bankEmployees.map((row, index) =>
                        { return ( bankPartialUserModal.selectedEntity && (bankPartialUserModal.selectedEntity.customerType == 'all'
                        || (row.customerType && row.customerType.includes(bankPartialUserModal.selectedEntity.customerType))) ?
                            <tr>
                                {/* {row.email} */}
                                <td> {row.displayName}  </td>
                                <td> { bankPartialUserModal && bankPartialUserModal.selectedEntity &&
                                 bankPartialUserModal.selectedEntity.bank_status_approvedby &&
                                bankPartialUserModal.selectedEntity.bank_status_approvedby.includes(row._id) ? "Approved" : "Pending"}  </td>
                            </tr>
                        : "")}
                        )}
                    </tbody>
                </table>

            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    {cancelText}
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default Modal