import { combineReduers } from 'redux';
const INITIAL_STATE_EMPLOYEE_LIST = {
    page: 1,
    sizePerPage: 0,
    employeeList: [],
    isLoading: false,
    totalCount: 0,
    filters: {}
}
const employeeList = (state = INITIAL_STATE_EMPLOYEE_LIST, action) => {
    switch (action.type) {
        case 'GET_DETAILS':
            return {
                ...state,
                ...action.payload,
                isLoading: false,
            };
        default:
            return {
                ...state
            }
    }

}
export default employeeList;