import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import { connect } from 'react-redux'
import FormikImageUpload from '../../utils/components/FormikImageUpload'
import configMessages from '../../config/configMessages'
import CreatableSelect from 'react-select/lib/Creatable';
import { getEntityDataByFilterAction } from '../../redux/actions';
import { bindActionCreators } from 'redux';

import { charandnumericRegex, websiteRegex } from '../../utils/constants/constant';
class BrandFormComponent extends Component {

  state = {
    selectedBrand: {},
    entityBrand: []
  }
  componentDidMount() {
    let { setFieldValue, options: { banks }, values } = this.props
    if (banks.length === 1) {
      setTimeout(() => setFieldValue('bank_id', banks[0].id))
    }
    this.getBrand(values?._id ? values?.name : "")
  }

  getBrand = (value) => {
    let { options, values } = this.props;
    let { getEntityDataByFilter, getEntityData } = options
    let brandId = {}
    let filterCriteria = {
      'criteria': [
        {
          'key': 'brandname',
          'value': value,
          'type': 'regexOr',

        },
        { "key": "active", "value": null, "type": "eq" }
      ]
    }
    if (value && value.length >= 3) {
      //get brand data
      getEntityDataByFilter(filterCriteria, res => {
        if (res && res.length) {
          let selectData = []
          res.forEach((item, index) => {
            selectData.push({ label: item.brandname, value: item.brand_id, item })
            if (item.brand_id == values.brandId) {
              brandId.value = item.brand_id
              brandId.label = item.brandname
            }
          })
          this.setState({
            entityBrand: selectData,
            selectedBrand: brandId
          })
        }
      })
    } else {
      getEntityData({
        'criteria': [
          { "key": "active", "value": null, "type": "eq" }
        ]
      }, res => {
        if (res && res.length) {
          let selectData = []
          res.forEach((item, index) => {
            selectData.push({ label: item.brandname, value: item.brand_id, item })
            if (item.brand_id == values.brandId) {
              brandId.value = item.brand_id
              brandId.label = item.brandname
            }
          })
          this.setState({
            entityBrand: selectData,
            selectedBrand: brandId
          })
        }
      })
    }

  }

  render() {
    let { entityBrand } = this.state;
    let { status, isSubmitting, values, options, setFieldValue } = this.props;
    let { isDisabled, initialValues, formType } = options;
    console.log(formType)

    return (
      <Form>
        <div className="form-group text-center">
          <FormikImageUpload
            image={values.photo}
            onChange={setFieldValue.bind(null, 'photo')}
            disabled={isSubmitting || isDisabled}
          />
        </div>
        {/* <div className="form-group">
          <label>Brand Name </label>
          <Field name="name" className="form-control" disabled={isSubmitting || isDisabled || (initialValues && initialValues._id)} style={{ textTransform: 'capitalize' }} />
          <ErrorMessage name="name" component="label" className="error" />
        </div> */}
        <div className="form-group">
          <label>Brand Name</label>
          <CreatableSelect
            disabled
            onChange={(value) => {
              console.log(value)
              //set value in form 
              this.setState({ selectedBrand: value || {} })
              setFieldValue('name', value?.label || "")
              if (!value?.__isNew__) {
                setFieldValue('brandId', value?.value || "")
              }
              setFieldValue('isNewEntity', value?.__isNew__ || false)
              setFieldValue("registeredAddress", value?.item?.address || "")
              setFieldValue("website", value?.item?.website_url || "")
            }}
            onInputChange={(value, actionMeta) => {
              if (actionMeta.action == "input-change") {
                this.getBrand(value)
              }
            }}
            isSearchable={!(initialValues && initialValues._id)}
            // onKeyDown={(value) => {
            //   console.log(value.target.value,values.name)
            //   this.getBrand(value.target.value)
            // }}
            options={entityBrand}
            value={this.state.selectedBrand}
          />
          {/* <Select
            value={this.state.selectedBrand}
            options={entityBrand}
            onChange={value => {
              console.log(value)
              this.setState({ selectedBrand: value })
              setFieldValue('name', value.label)
              setFieldValue('entityId', value.value)
            }}
            onKeyDown={(value) => {
              this.getBrand(value)
            }}

            name="name"
          /> */}
          <ErrorMessage name="name" component="label" className="error" />
          {/* <ErrorMessage name="brand" component="label" className="error" /> */}
        </div>
        <div className="form-group">
          <label>Registration Address </label>
          <Field name="registeredAddress" component='textarea' className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="registeredAddress" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Registration Id </label>
          <Field name="registrationId" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="registrationId" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Website </label>
          <Field name="website" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="website" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Description </label>
          <Field name="description" component='textarea' className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="description" component="label" className="error" />
        </div>
        <div className="d-flex">
          <div className="col-6 form-group pl-0">
            <label>Free Trial </label>
            <div className="d-block d-sm-block d-md-flex radio-container">
              <div className="form-check form-check-inline custom-align mr-4">
                <Field checked={values.isFreeTrial == "Yes"} name="isFreeTrial" type="radio" id="yes"
                  className="form-check-input mr-2" value={"Yes"}
                />
                <label className="form-check-label" htmlFor="yes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline custom-align mr-4">
                <Field
                  onClick={(e) => {
                    setFieldValue('freeTrialPeriod', 0)
                  }}
                  checked={values.isFreeTrial == "No"} name="isFreeTrial"
                  type="radio" id="no" className="form-check-input mr-2" value={"No"}
                />
                <label className="form-check-label" htmlFor="no">
                  No
                </label>
              </div>
            </div>
          </div>

          {values.isFreeTrial == "Yes" &&
            <div className="col-6 form-group ">
              <label>Trial Month</label>
              <select
                name="freeTrialPeriod"
                className="form-control"
                value={values.freeTrialPeriod}
                onChange={(e) => {
                  setFieldValue('freeTrialPeriod', e.target.value ? parseInt(e.target.value) : "")
                }}
              >
                <option value={1} >1</option>
                <option value={2} >2</option>
                <option value={3} >3</option>
              </select>
              <ErrorMessage name="type" component="label" className="error" />
            </div>
          }

        </div>
        <div className="d-flex">
          <div className="col-6 form-group pl-0">
            <label>New Customer </label>
            <div className="d-block d-sm-block d-md-flex radio-container">
              <div className="form-check form-check-inline custom-align mr-4">
                <Field
                  onClick={(e) => {
                    setFieldValue('freeTrialPeriod', 0)
                  }}
                  checked={values.isNewCustomer == true} name="isNewCustomer"
                  type="radio" id="Yes" className="form-check-input mr-2" value={true}
                />
                <label className="form-check-label" htmlFor="Yes">
                  Yes
                </label>
              </div>
              <div className="form-check form-check-inline custom-align mr-4">
                <Field checked={values.isNewCustomer == false} name="isNewCustomer" type="radio" id="No"
                  className="form-check-input mr-2" value={false}
                />
                <label className="form-check-label" htmlFor="No">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>

        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

class FormikForm extends Component {

  initialValues = {
    photo: '',
    name: '',
    registeredAddress: '',
    registrationId: '',
    website: '',
    description: '',
    isFreeTrial: "No",
    freeTrialPeriod: 1,
    isNewCustomer: false,
    // association: 'Revolut'
  }

  validationSchema = YupObject({
    name: YupString()
      .required(`Brand ${configMessages.name}`)
      .matches(charandnumericRegex, 'Brand name can only contain [A-Za-z_-\s@\.]')
      .min(3, `Brand ${configMessages.minName}`)
      .max(100, `Brand ${configMessages.maxName}`),
    registeredAddress: YupString()
      .required(configMessages.address),
    registrationId: YupString()
      .required(configMessages.registration),
    website: YupString()
      .required(configMessages.website)
      .matches(websiteRegex, 'Please enter a valid url'),
    description: YupString()
      .required(configMessages.description)
  })

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, banks, enableReinitialize, options, getEntityData, getEntityDataByFilter, formType } = this.props;
    console.log('-----brand propssss', this.props);
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <BrandFormComponent {...props} options={{ banks, ...options, getEntityData, getEntityDataByFilter, initialValues, formType }} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        enableReinitialize={enableReinitialize}
      />
    )

  }
}

export const BrandForm = connect(
  state => ({
    banks: state.selectEntities.banks
  }),
  dispatch => ({
    getEntityData: bindActionCreators(getEntityDataByFilterAction, dispatch),
    getEntityDataByFilter: bindActionCreators(getEntityDataByFilterAction, dispatch)
  }),
  null
)(FormikForm)


