import axiosMethodRequest from '../../config/service'
import { DELETE_USER } from '../../redux/newBrandBranch/actionTypes'
export const deleteUserByIdApiCall = (id) => {
    return dispatch => {
        const url = `users/${id}`
        return axiosMethodRequest('DELETE', url).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: DELETE_USER,
                    payload: { data: response.data, loader: false }
                })
            }
        })
    }
}


// export const getBranchListByBrandIdAction = (brand_id, callback) => {
//     return dispatch => {
//         return axiosMethodRequest('GET', `entity/getAllEntityBranch?`).then((response) => {
//             if (response && response.data && response.data.respCode) {
//                 callback(response.data.entityList)
//             } else {
//                 callback([])
//             }
//         })
//     }
// }