import axiosMethodRequest from '../../config/service'
import { GET_CAMPAIGN_APPROVAL_DATA } from '../../redux/campaignApproval/actionTypes'
export const getCampaignApprovalListApiCall = (page, pageSize, filters, type, customerType, status,user_id) => {
    return dispatch => {
        let campaignType = "future"
        let url = ''
        let filterCriteria = {};
        filterCriteria['limit'] = pageSize;
        filterCriteria['page'] = page;
        filterCriteria['criteria'] = [];
        filterCriteria['direction'] = 'desc';
        filterCriteria.sortfield = 'created';
        filterCriteria.customerType = customerType;
        filterCriteria.status = status || [];
        filterCriteria.user_id = user_id;
        ///////global search
        if (filters && filters.globalSearch && filters.globalSearch.value && filters.globalSearch.value.length > 3) {
            filterCriteria.globalSearch = filters.globalSearch
        }
        if (!page) {
            filterCriteria['page'] = 1;
        }
        if (!pageSize) {
            filterCriteria['limit'] = 10;
        }
        ////////sorting 
        if (type == "sort" && filters.sortField && filters.sortField.direction) {
            filterCriteria['limit'] = pageSize;
            filterCriteria['page'] = page;
            filterCriteria['criteria'] = [];
            filterCriteria['direction'] = filters.sortField.direction
            filterCriteria.sortfield = filters.sortField.sortfield;
        }

        url = `campaigns/campaignForApproval?filter=${JSON.stringify(filterCriteria)}${campaignType ? `&campaignType=${campaignType}` : ''}`;

        dispatch({
            type: GET_CAMPAIGN_APPROVAL_DATA,
            payload: { data: [], bankEmployees : [], loader: true }
        })

        return axiosMethodRequest('GET', url).then((response) => {
            console.log('response', response)
            if (response && response.data && response.data.respCode) {
                let data = response.data.campaigns;
                let bankEmployees = response.data.bankEmployees;
                let pagination = response.data.pagination
                dispatch({
                    type: GET_CAMPAIGN_APPROVAL_DATA,
                    payload: { data: data, bankEmployees : bankEmployees, loader: false }
                })

            }
        })
    }
}
