import React from 'react';
import { useSelector } from 'react-redux';
import { dashboardSelectors } from '../../redux/dashboard';
import Performance from '../../components/CampaignOverview/Performance';
import Demographic from '../../components/CampaignOverview/Demographic'

export default function ChartComponent(filterData) {

    const Brandstate = useSelector(
        dashboardSelectors.getDashboardstate
    );

   

    return <>
        <div className="row">
            <div className="col-xl-6 d-flex">
                <Performance key={new Date().getTime()} data={{ filterData : filterData.filter, 
                    conversions: Brandstate.data.chart, revenue: Brandstate.data.revenue,
                    cashbackAndCommissions : Brandstate.data.cashbackAndCommissions }} />
            </div>
            <div className="col-xl-6 d-flex">
                <Demographic key={new Date().getTime()} data={{
                    branches: Brandstate.data.brandDistribution || [], brandsCountPerMonth: Brandstate.data.brandsCountPerMonth || [],
                    numberCustomersPerMonths: Brandstate.data.numberCustomersPerMonths || [],
                    revenuePerPublisher: Brandstate.data.revenuePerPublisher || [],
                    filterData : filterData.filter
                }} />
            </div>
        </div>
    </>
}