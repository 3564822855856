
import axios from '../../utils/AxiosWrapper'
import { GetSessionValue, SetSessionValue } from '../../utils/sessionStorage'
import { formatCampaignOverviewData } from './helpers'

export const setCampaignOverviewSearchAction = search => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_SEARCH`,
    payload: { search }
  }
}

export const setCampaignOverviewSearchOptionsAction = options => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_SEARCH_OPTIONS`,
    payload: options
  }
}


const setCampaignOverviewData = data => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_DATA`,
    payload: data
  }
}
const setCampaignOverviewLoadingState = loadingState => {
  return {
    type: `SET_CAMPAIGNOVERVIEW_DATA_LOADINGSTATE`,
    payload: loadingState
  }
}

const setCampaignOverviewRawData = data => {
  return {
    type: `SET_RAW_CAMPAIGNOVERVIEW_RAW_DATA`,
    payload: data
  }
}

export const clearCampaignOverviewAction = () => {
  return dispatch => {
    dispatch(setCampaignOverviewData(null))
  }
}
export const getCampaignOverviewAction = (params) => {
  return dispatch => {
    setCampaignOverviewLoadingState(true)
    return axios
      .get('/campaignoverview/', { params })
      .then(res => {
        let data = formatCampaignOverviewData(res.data)
        dispatch(setCampaignOverviewData(data))
        setCampaignOverviewLoadingState(false)
      })
      .catch(err => {
        setCampaignOverviewLoadingState(false)
      })
  }
}
export const getCampaignOverviewRawAction = params => {
  return dispatch => {
    return axios
      .get('/campaignoverview/raw', { params })
      .then(res => {
        let data = res.data
        if (!data) {
          data = {}
        }
        dispatch(setCampaignOverviewRawData(data))
      })
      .catch(err => {
      })
  }
}

export const getCampaignOverviewSearchOptionsAction = (params, callback) => {
  return dispatch => {
    setCampaignOverviewLoadingState(true)
    return axios
      .get('/campaigns/search', { params })
      .then(res => {
        let data = res.data.campaigns
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(data)
      })
      .catch(err => {
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(null)
      })
  }
}

export const updateCampaignOverviewAction = (data, callback) => {
  return dispatch => {
    return axios
      .post('/campaignoverview/createfromdata', data)
      .then(res => {
        if (callback)
          callback(true)
      })
      .catch(err => {
        // console.log(err)
        if (callback)
          callback(null)
      })
  }
}

export const uploadDocsAction = ({ transactions, impressions, start_date, end_date }, callback) => {
  return dispatch => {
    let formData = new FormData()
    formData.append('transactions', transactions)
    formData.append('impressions', impressions)
    formData.append('start_date', start_date)
    formData.append('end_date', end_date)

    return axios
      .post('/campaignoverview/upload', formData)
      .then(res => {
        if (callback)
          callback()
      })
      .catch(err => {
        if (callback)
          callback(err)
      })
  }
}

export const triggerCOCreateAction = (data, callback) => {
  return dispatch => {
    setCampaignOverviewLoadingState(true)
    return axios
      .post('/campaignoverview/create', data)
      .then(res => {
        let data = res.data.campaigns
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(data)
      })
      .catch(err => {
        setCampaignOverviewLoadingState(false)
        if (callback)
          callback(err)
      })
  }
}

export const setCampaignOverviewToLocalAction = (data, id) => {
  let key = `campaignoverview_raw_${id}`
  SetSessionValue(key, data);
  return {
    type: ''
  }
}

export const getCampaignOverviewFromLocalAction = (id) => {
  let key = `campaignoverview_raw_${id}`
  let data = GetSessionValue(key);
  // if (data)
  //   data = JSON.parse(data)
  data = formatCampaignOverviewData(data)
  return {
    type: 'SET_CAMPAIGNOVERVIEW_FROM_LOCAL',
    payload: data
  }
}




