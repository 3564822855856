import React, { Component } from "react";
import { Pie } from 'react-chartjs-2';
import { numberWithCommas } from '../../utils/helpers'
import ToolTipContainer from '../../utils/components/ToolTipContainer';
import showToasterMessage from '../toasterMessage'
import { CampaignProjectionTips } from '../../i18n/tooltips'
import { AnimateOnChange } from 'react-animation'
import { reach } from "yup";
import CountUp from 'react-countup';
import { CreateCampaignFormTips, CreateCampaignTips } from '../../i18n/tooltips'
import SplitButton from '../../utils/components/SplitButton'
import { Link } from 'react-router-dom';

class CampaignSummary extends Component {
  state = {
    genderData: [0],
    ageGroupData: {
      labels: [],
      data: []
    },
    percentageData: {
      expectedReach: "0",
      expectedConversion: "0",
      expectedRevenue: "0",
      expectedCost: "0",
      cpa: '0'
    },
    prevPercentageData: {
      prevExpectedReach: "0",
      prevExpectedConversion: "0",
      prevExpectedRevenue: "0",
      prevExpectedCost: "0",
      prevCpa: '0'
    },
    projectionsMap: {},
    selectedMerchants: [],
    expectedReach: ''
  }
  gender = { male: true, female: true };
  age = 14
  rate = 1
  discount = 0
  avgSpend = 23.62
  updateMonth = (monthValue) => {
    let jsonArrayVal;
    monthValue = parseInt(monthValue);
    if (monthValue === 1 || monthValue === 0) {
      jsonArrayVal = window.json1OldUserVal;
    } else if (monthValue === 2) {
      jsonArrayVal = window.json2OldUserVal;
    } else if (monthValue === 3) {
      jsonArrayVal = window.json3OldUserVal;
    } else if (monthValue === 4) {
      jsonArrayVal = window.json4OldUserVal;
    } else if (monthValue === 5) {
      jsonArrayVal = window.json5OldUserVal;
    } else if (monthValue === 6) {
      jsonArrayVal = window.json6OldUserVal;
    } else if (monthValue === 7) {
      jsonArrayVal = window.json7OldUserVal;
    } else if (monthValue === 8) {
      jsonArrayVal = window.json8OldUserVal;
    } else if (monthValue === 9) {
      jsonArrayVal = window.json9OldUserVal;
    } else if (monthValue === 10) {
      jsonArrayVal = window.json10OldUserVal;
    } else if (monthValue === 11) {
      jsonArrayVal = window.json11OldUserVal;
    } else if (monthValue === 12) {
      jsonArrayVal = window.json12OldUserVal;
    } else if (monthValue === 13) {
      jsonArrayVal = window.json13OldUserVal;
    } else if (monthValue === 14) {
      jsonArrayVal = window.json14OldUserVal;
    } else {
      jsonArrayVal = window.jsonOldUserVal;
    }
    return jsonArrayVal
  }

  updateMonthTotal = (lastTransaction) => {
    var monthValue = parseInt(lastTransaction);
    let total;
    let zeroMonthTotal = 1346124
    total = zeroMonthTotal - (monthValue * 60178)
    return total;
  }

  getAgeGroupJson = (genderAgeGroup, minAge, maxAge) => {
    var jsonInfo = {
      "name": "flare",
      "children": []
    }
    var femaleChildren = [];
    for (let key in genderAgeGroup["F"]) {
      if (parseInt(key) >= minAge && parseInt(key) < maxAge) {
        femaleChildren.push({
          name: key + "0: " + genderAgeGroup["F"][key][0].total,
          size: genderAgeGroup["F"][key][0].total
        });
      }
    }
    if (femaleChildren.length) {
      jsonInfo.children.push({
        "name": "Female",
        "children": [{
          "name": "Age groups",
          "children": femaleChildren
        }]
      })
    }
    var maleChildren = [];
    for (let key in genderAgeGroup["M"]) {
      if (parseInt(key) >= minAge && parseInt(key) < maxAge) {
        maleChildren.push({
          name: key + "0: " + genderAgeGroup["M"][key][0].total,
          size: genderAgeGroup["M"][key][0].total
        });
      }
    }
    if (maleChildren.length) {
      jsonInfo.children.push({
        "name": "Male",
        "children": [{
          "name": "Age groups",
          "children": maleChildren
        }]
      })
    }
    return jsonInfo;
  }

  setGenderChartData = (femaleTotal, maleTotal, naTotal) => {
    let total = femaleTotal + maleTotal + naTotal;
    let na_total = naTotal / 2;
    let female_total = Number(((femaleTotal + na_total) / total) * 100).toFixed(1);
    let male_total = Number(((maleTotal + na_total) / total) * 100).toFixed(1);
    this.setState({
      genderData: [
        female_total,
        male_total,
        // naTotal
      ]
    })
  }

  getGenderChartData = () => {
    let { genderData } = this.state;
    return {
      labels: [
        'Female',
        'Male',
        'NA'
      ],
      datasets: [{
        data: genderData.slice(),
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56'
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56'
        ]
      }]
    };
  }

  setAgeGroupChartData = (labels, dataValue) => {
    let total = 0
    if (dataValue && dataValue.length && dataValue.length > 0) {
      total = dataValue.reduce((total, amount) => total + amount);
    }
    let naValue = dataValue[dataValue.length - 1] / (dataValue.length - 1)
    let data = []
    dataValue.pop(); labels.pop()
    for (let item of dataValue) {
      let newValue = Number(((item + naValue) / total) * 100).toFixed(1);
      data.push(newValue);
    }
    this.setState({
      ageGroupData: {
        labels,
        data
      }
    })
  }

  getAgeGroupChart = () => {
    let { ageGroupData } = this.state
    return {
      labels: ageGroupData.labels,
      datasets: [{
        data: ageGroupData.data,
        backgroundColor: [
          'steelblue',
          'cornflowerblue',
          'slateblue',
          'mediumslateblue',
          'cadetblue',
          'darkslateblue',
          'royalblue',
        ],
        hoverBackgroundColor: [
          'steelblue',
          'cornflowerblue',
          'slateblue',
          'mediumslateblue',
          'cadetblue',
          'darkslateblue',
          'royalblue',
        ]
      }]
    };
  }

  static getDerivedStateFromProps(props, state) {
    return { selectedMerchants: props.selectedMerchants, expectedReach: state.percentageData.expectedReach }
  }

  calculateSummary = async () => {
    let { percentageData } = this.state
    let prevTotalHolders = percentageData.totalCardHolders ? percentageData.totalCardHolders : 0
    let prevAdressableMarket = percentageData.addressableMarket ? percentageData.addressableMarket : 0
    let prevExpectedReach = percentageData.expectedReach ? percentageData.expectedReach : 0
    let prevExpectedRevenue = percentageData.expectedRevenue ? percentageData.expectedRevenue : 0
    let prevExpectedCost = percentageData.expectedCost ? percentageData.expectedCost : 0
    let prevCpa = percentageData.cpa ? percentageData.cpa : 0
    let prevExpectedConversion = percentageData.expectedConversion ? percentageData.expectedConversion : 0
    this.setState({
      prevPercentageData: {
        prevTotalHolders: prevTotalHolders,
        prevAdressableMarket: prevAdressableMarket,
        prevExpectedReach: prevExpectedReach,//
        prevExpectedConversion: prevExpectedConversion,
        prevExpectedRevenue: prevExpectedRevenue,
        prevExpectedCost: prevExpectedCost,
        prevCpa: prevCpa,
      }
    })
    let projectionsMap = await this.state.projectionsMap ? this.state.projectionsMap : this.props.projections;
    let {
      successFee = 0,
      budget = 1000000,
      discountPercent = 0,
      customerType = 'all',
      lastTransaction = 1,
      age_range: age = [0, 0],
      gender = {},
      budgetType,
      discountAmount = 0,
      discountValue = 0,
      discountType = ''
    } = this.props.campaignSummary;
    let { selectedMerchants, totalMerchants } = this.props
    // let trafficData = projectionsMap && projectionsMap[0] && projectionsMap[0].traffic ? projectionsMap[0].traffic : {}
    let trafficData = projectionsMap && projectionsMap[1] ? projectionsMap[1] : {}
    let jsonArrayVal;
    if (customerType === 'new' || customerType === 'all')
      jsonArrayVal = window.jsonNewUserVal
    else {
      jsonArrayVal = this.updateMonth(lastTransaction)
    }
    var maleTransacted = 0, femaleTransacted = 0, transacted = 0;
    var maleTotal = 0, femaleTotal = 0;
    var genderAgeGroup = {}, ageGroup = {},
      rate = parseInt(successFee),
      discount = parseInt(discountPercent);
    if (budget === '' || budgetType === 'na')
      budget = 1000000;
    if (discount >= 20 && discount < 40)
      discount = 10 + (discount - 20) * 0.25;
    else if (discount >= 40 && discount < 60)
      discount = 15 + (discount - 40) * 0.15;
    else if (discount >= 60 && discount <= 75)
      discount = 18 + (discount - 60) * 0.12;
    else
      discount = discount / 2;
    rate = rate + parseInt(discount);
    var decimal_discount = parseFloat(discount) - parseInt(discount);
    var extra_discount = 1 + (decimal_discount / 17)
    var minAge = parseInt(age[0]).toString()[0];
    var maxAge = parseInt(age[1]).toString()[0];
    if (parseInt(age[1]) === 100) {
      maxAge = 10
    }
    if (jsonArrayVal[rate]) {
      var item = jsonArrayVal;
      if (gender.male || (!gender.male && !gender.female)) {
        genderAgeGroup["M"] = item[rate]["M"];
        for (let i = minAge; i < maxAge; i++) {
          if (item[rate]["M"][i] && item[rate]["M"][i][0]) {
            maleTransacted += parseInt((item[rate]["M"][i][0].transacted) * extra_discount);
            maleTotal += parseInt((item[rate]["M"][i][0].total) * extra_discount);
          }
        }
      }
      if (gender.female || (!gender.male && !gender.female)) {
        genderAgeGroup["F"] = item[rate]["F"];
        for (let i = minAge; i < maxAge; i++) {
          if (item[rate]["F"][i] && item[rate]["F"][i][0]) {
            femaleTransacted += parseInt((item[rate]["F"][i][0].transacted) * extra_discount);
            femaleTotal += parseInt((item[rate]["F"][i][0].total) * extra_discount);

          }
        }
      }
      for (let i = minAge; i < maxAge; i++) {
        ageGroup[i] = 0;
        if ((gender.female || (!gender.male && !gender.female)) && item[rate]["F"][i] && item[rate]["F"][i][0]) {
          ageGroup[i] += parseInt((item[rate]["F"][i][0].total) * extra_discount);
        }
        if ((gender.male || (!gender.male && !gender.female)) && item[rate]["M"][i] && item[rate]["M"][i][0]) {
          ageGroup[i] += parseInt((item[rate]["M"][i][0].total) * extra_discount);
        }
      }

      transacted = maleTransacted + femaleTransacted;
    }
    /*------------------------------age Calculation starts here--------------------*/
    let ageArray = [];
    let ageData = {}, ageKeys = [];
    if (trafficData && trafficData.new_user_traffic && trafficData.new_user_traffic.male) {
      ageKeys = Object.keys(trafficData.new_user_traffic.male)
    }
    if (trafficData) {
      if (customerType == 'new') {
        ageData = trafficData.new_user_traffic ? trafficData.new_user_traffic : {};
      } else if (customerType == 'existing') {
        ageData = trafficData.old_user_traffic ? trafficData.old_user_traffic : {};
      } else if (customerType == 'all') {
        ageData = trafficData.all_user_traffic ? trafficData.all_user_traffic : {};
      }
      if (customerType == 'new' || customerType == 'all') {
        let data = 0;
        ageArray = []
        ageKeys.forEach(age => {
          for (let item in ageData) {
            for (let ele in ageData[item]) {
              if (ele === age) {
                data = data + ageData[item][ele]
              }
            }
          }
          ageArray.push(data);
          data = 0
        })
      } else if (customerType == 'existing') {
        let data = 0;
        ageArray = []
        ageKeys.forEach(age => {
          for (let item in ageData) {
            for (let ele in ageData[item]) {
              if (parseInt(ele) >= lastTransaction || ele == '12+') {
                for (let subele in ageData[item][ele]) {
                  if (subele == age)
                    data = data + ageData[item][ele][subele]
                }
              }
            }
          }
          ageArray.push(data);
          data = 0
        })
      }
    } else {
      showToasterMessage('SomeThing Went Wrong', 'error')
    }
    this.setAgeGroupChartData(ageKeys, ageArray);
    /*------------------------------age Calculation ends here--------------------*/


    /*------------------------------adressablmarket count Calculation starts here--------------------*/
    // let addressable_market = 0, addressable_market_Count = 0;
    let addressable_market = trafficData.addressable_market ? trafficData.addressable_market : 0;
    let addressable_market_Count = addressable_market;
    if (ageData) {
      for (let item in ageData) {
        for (let ele in ageData[item]) {
          if (customerType == 'new' || customerType == 'all') {
            addressable_market = addressable_market + ageData[item][ele]
          } else if (customerType == 'existing') {
            for (let subele in ageData[item][ele]) {
              if (parseInt(ele) >= lastTransaction || ele == '12+') {
                addressable_market = addressable_market + ageData[item][ele][subele]
              }
            }
          }
        }
      }
    }
    addressable_market_Count = addressable_market
    let oldCustomersData = trafficData.old_user_traffic ? trafficData.old_user_traffic : {};
    let existingCount = 0
    existingCount = trafficData.base_traffic ? trafficData.base_traffic : 0
    for (let item in oldCustomersData) {
      for (let ele in oldCustomersData[item]) {
        for (let subele in oldCustomersData[item][ele]) {
          // if (parseInt(ele) >= last_transaction || ele == '12+') {
          existingCount = existingCount + oldCustomersData[item][ele][subele]
          // }
        }
      }
    }
    /*------------------------------adressablmarket counte Calculation ends here--------------------*/


    /*------------------------------gender Calculation starts here--------------------*/
    let male_total = 0, female_total = 0, naTotal = 0;
    if (customerType == 'new' || customerType == 'all') {
      let new_user_traffic = trafficData && trafficData.new_user_traffic ? trafficData.new_user_traffic : {},
        male_total = 0; female_total = 0; naTotal = 0;
      for (let key in new_user_traffic) {
        for (let ele in new_user_traffic[key]) {
          if (key === 'male') {
            male_total = male_total + parseInt(new_user_traffic[key][ele])
          } else if (key === 'female') {
            female_total = female_total + parseInt(new_user_traffic[key][ele])
          } else if (key === 'NA') {
            naTotal = naTotal + parseInt(new_user_traffic[key][ele])
          }
        }
      }
      this.setGenderChartData(female_total, male_total, naTotal);
    } else if (customerType == 'existing') {
      let old_user_traffic = trafficData && trafficData.old_user_traffic ? trafficData.old_user_traffic : {};
      male_total = 0; female_total = 0; naTotal = 0;
      for (let key in old_user_traffic) {
        for (let ele in old_user_traffic[key]) {
          if (parseInt(ele) >= lastTransaction || ele == '12+') {
            for (let subele in old_user_traffic[key][ele]) {
              if (key == 'male') {
                male_total = male_total + parseInt(old_user_traffic[key][ele][subele])
              } else if (key == 'female') {
                female_total = female_total + parseInt(old_user_traffic[key][ele][subele])
              } else if (key == 'NA') {
                naTotal = naTotal + parseInt(old_user_traffic[key][ele][subele])
              }
            }
          }
        }
      }
      this.setGenderChartData(female_total, male_total, naTotal);
    }
    /*------------------------------gender Calculation ends here--------------------*/

    let key = customerType == 'new' ? customerType : `${customerType}-${lastTransaction}`
    /* -----------caluculation for expected reach,expectedConversion,expectedRevenue starts here------------*/
    let curveArray = {}
    if (projectionsMap && projectionsMap[0] && projectionsMap[0].curve) {
      let projectionsCurvedata = projectionsMap[0].curve;
      let reengamenet_window = projectionsMap[0].reengamenet_window
      if (customerType == 'new' || customerType == 'all') {
        curveArray = projectionsCurvedata.acquisition ? projectionsCurvedata.acquisition : {};
        if (!projectionsMap[0].acquisition) {
          // console.log('======acquisition data is not available =========')
        }
      } else if (customerType == 'existing' && lastTransaction <= reengamenet_window) {
        curveArray = projectionsCurvedata.reengagement ? projectionsCurvedata.reengagement : {}
        if (!projectionsMap[0].reengagement) {
          // console.log('======reengagement data is not available =========')
        }
      } else if (customerType == 'existing' && lastTransaction > reengamenet_window) {
        curveArray = projectionsCurvedata.reactivation ? projectionsCurvedata.reactivation : {};
        if (!projectionsMap.reactivation) {
          // console.log('======reactivation data is not available =========')
        }
      }
    }
    if (discountAmount && discountValue && discountType == 'dv') {
      discountPercent = (discountValue / discountAmount) * 100
    }

    // let itemData = this.state.projectionsMap[key] ? this.state.projectionsMap[key] : {};
    let avg_transaction_val = trafficData && trafficData.avg_transaction_val ? trafficData.avg_transaction_val : {};
    let expectedReach = 0; let expectedConversion = 0; let expectedReachValue = 0; let merchantsExist;
    let curveArrayKeys = Object.keys(curveArray)
    if (selectedMerchants && selectedMerchants.length > 0 && totalMerchants && totalMerchants.length > 0) {
      merchantsExist = true
    } else {
      merchantsExist = false
    }
    if (curveArray && curveArrayKeys && curveArrayKeys.length > 0) {
      if (curveArray[discountPercent.toString()]) {
        if (customerType == 'new' || customerType == 'all') {
          expectedReach = curveArray[discountPercent][0] * existingCount / 100;
        } else if (customerType == 'existing') {
          expectedReach = curveArray[discountPercent][0] * addressable_market_Count / 100;
        }
        expectedReachValue = merchantsExist ? (expectedReach / totalMerchants.length) * selectedMerchants.length : expectedReach
        expectedConversion = curveArray[discountPercent][1] * expectedReachValue / 100
      } else {
        let lowerLimit = 0, upperLimit = 0;
        curveArrayKeys.forEach((item, index) => {
          if (parseInt(curveArrayKeys[index]) < discountPercent && parseInt(curveArrayKeys[index + 1]) > discountPercent.toString()) {
            lowerLimit = curveArrayKeys[index];
            upperLimit = curveArrayKeys[index + 1];
            return
          }
        })
        if (upperLimit && lowerLimit) {
          let value = (curveArray[upperLimit][0] - curveArray[lowerLimit][0]) / (parseInt(upperLimit) - parseInt(lowerLimit));
          let calcValue = ((discountPercent - parseInt(lowerLimit)) * value) + curveArray[lowerLimit][0];
          if (customerType == 'new' || customerType == 'all') {
            expectedReach = calcValue * existingCount / 100;
          } else if (customerType == 'existing') {
            expectedReach = calcValue * addressable_market_Count / 100;
          }
          expectedReachValue = merchantsExist ? (expectedReach / totalMerchants.length) * selectedMerchants.length : expectedReach
          // expectedReach = calcValue * addressable_market_Count / 100;
          let conversionValue = (curveArray[upperLimit][1] - curveArray[lowerLimit][1]) / (parseInt(upperLimit) - parseInt(lowerLimit))
          let calcConversionValue = ((discountPercent - parseInt(lowerLimit)) * conversionValue) + curveArray[lowerLimit][1];
          expectedConversion = Number(calcConversionValue.toFixed(2)) * expectedReachValue / 100;
        } else {
          let percentage = curveArrayKeys[curveArrayKeys.length - 1]
          if (customerType == 'new' || customerType == 'all') {
            expectedReach = curveArray[percentage][0] * existingCount / 100;
          } else if (customerType == 'existing') {
            expectedReach = curveArray[percentage][0] * addressable_market_Count / 100;
          }
          expectedReachValue = merchantsExist ? (expectedReach / totalMerchants.length) * selectedMerchants.length : expectedReach
          expectedConversion = curveArray[percentage][1] * expectedReachValue / 100;
        }
      }
    }
    let expectedRevenue = expectedConversion * avg_transaction_val;
    let expectedCost = 0
    if (successFee && expectedRevenue) {
      expectedCost = ((successFee + discountPercent) * expectedRevenue) / 100
    }
    /* -----caluculation for expected reach,expectedConversion,expectedRevenue ends here------*/
    let total_card_holder_count = trafficData && trafficData.total_card_holder_count ? trafficData.total_card_holder_count : 0
    let nItem = this.state.projectionsMap['new'] ? this.state.projectionsMap['new'] : {}

    this.setState({
      percentageData: {
        ...this.state.percentageData,
        totalCardHolders: total_card_holder_count,
        addressableMarket: addressable_market_Count ? addressable_market_Count : 0,
        expectedReach: expectedReachValue ? Number(expectedReachValue.toFixed(0)) : 0,//
        expectedConversion: expectedConversion ? Number(expectedConversion.toFixed(0)) : 0,
        expectedRevenue: expectedRevenue ? Number(expectedRevenue.toFixed(0)) : 0,
        expectedCost: expectedCost ? Number(expectedCost.toFixed(0)) : 0,
        cpa: expectedConversion && expectedCost ? ((Number(expectedCost.toFixed(0)) / Number(expectedConversion.toFixed(0))).toFixed(2)) : 'N/A',
      }
    })
    // this.setState({
    //   percentageData: {
    //     ...this.state.percentageData,
    //     totalCardHolders: total_cardholders,
    //     addressableMarket: addressable_marketValue ? addressable_marketValue : 0,
    //     expectedReach: expected_reach ? Number(expected_reach.toFixed(0)) : 0,//
    //     expectedConversion: expected_conversion ? Number(expected_conversion.toFixed(0)) : 0,
    //     expectedRevenue: expected_revenue ? Number(expected_revenue.toFixed(0)) : 0,
    //     expectedCost: expected_cost ? Number(expected_cost.toFixed(0)) : 0,
    //     cpa: expectedConversion && expectedCost ? ((Number(expectedCost.toFixed(0)) / Number(expectedConversion.toFixed(0))).toFixed(2)) : 'N/A',
    //   }
    // })
  }

  // successdecay(x){
  //   return 0.1 + 0.9*Math.exp(-x/30);
  // }
  // discountdecay(x){
  // return 0.1 + 0.9 * Math.exp(-x/70);
  // }
  // updatePercentage = (/*transacted, total, discount, rate, budget, monthTotal*/) => {
  //   let { onCampaignSummaryData } = this.props
  //   var change = parseInt((transacted * this.avgSpend) * (1 - (discount / 100)) * (rate / 100) + (transacted * this.avgSpend) * (discount / 100))
  //   let data = {}
  //   data['totalCardHolders'] = '4,012,103'
  //   data['addressableMarket'] = monthTotal
  //   // var expectedCost = 0;
  //   // var expectedConversion = 1;
  //   // if (budget > change || budget === 0) {
  //   //   expectedConversion = parseInt(transacted * this.successdecay(rate) * this.discountdecay(discount) * 1.5 )
  //   //   expectedCost = parseInt((transacted * this.avgSpend) * (1 - (discount / 100)) * (rate / 100) + (transacted * this.avgSpend) * (discount / 100))
  //   //   data['expectedReach'] = parseInt(total*(rate*(3/10)))
  //   //   data['expectedConversion'] = expectedConversion
  //   //   data['expectedRevenue'] = parseInt((transacted * this.avgSpend))
  //   //   data['expectedCost'] = expectedCost
  //   //   data['cpa'] = expectedConversion ? (expectedCost/expectedConversion).toFixed(2) : 0
  //   // }
  //   // else {
  //   //   var ratio = budget / change;
  //   //   expectedConversion = parseInt(parseInt(transacted * this.successdecay(rate) * this.discountdecay(discount) * 1.5) * ratio)
  //   //   expectedCost = parseInt(((transacted * this.avgSpend) * (1 - (discount / 100)) * (rate / 100) + (transacted * this.avgSpend) * (discount / 100)) * ratio)
  //   //   data['expectedReach'] = parseInt(total * ratio)
  //   //   data['expectedConversion'] = expectedConversion
  //   //   data['expectedRevenue'] = parseInt((transacted * this.avgSpend) * ratio)
  //   //   data['expectedCost'] = expectedCost
  //   //   data['cpa'] = expectedConversion ? (expectedCost/expectedConversion).toFixed(2) : 0
  //   // }

  //   // // TODO : Calculate the actual value
  //   // data['spread_avg_spend'] = 700
  //   this.setState({percentageData : data}, () => {
  //     if (onCampaignSummaryData)
  //       onCampaignSummaryData(this.state.percentageData)
  //   })

  // }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this)
    }
    this.calculateSummary();
    // this.setRightSection();
  }
  setRightSection = async () => {
    if (this.props.brandData) {
      let brandData = this.props.brandData
      let total_cardholders = brandData && brandData.total_cardholders ? brandData.total_cardholders : 0,
        addressable_marketValue = brandData && brandData.addressable_market ? brandData.addressable_market : 0,
        expected_reach = brandData && brandData.expected_reach ? brandData.expected_reach : 0,
        expected_cost = brandData && brandData.expected_cost ? brandData.expected_cost : 0,
        expected_conversion = brandData && brandData.expected_conversion ? brandData.expected_conversion : 0,
        expected_revenue = brandData && brandData.expected_revenue ? brandData.expected_revenue : 0;

      this.setState({
        percentageData: {
          ...this.state.percentageData,
          totalCardHolders: total_cardholders,
          addressableMarket: addressable_marketValue ? addressable_marketValue : 0,
          expectedReach: expected_reach ? Number(expected_reach.toFixed(0)) : 0,//
          expectedConversion: expected_conversion ? Number(expected_conversion.toFixed(0)) : 0,
          expectedRevenue: expected_revenue ? Number(expected_revenue.toFixed(0)) : 0,
          expectedCost: expected_cost ? Number(expected_cost.toFixed(0)) : 0,
        }
      })
    } else if (this.props.brands) {
      let brandData = this.props.brands
      let total_cardholders = brandData && brandData.total_cardholders ? brandData.total_cardholders : 0,
        addressable_marketValue = brandData && brandData.addressable_market ? brandData.addressable_market : 0,
        expected_reach = brandData && brandData.expected_reach ? brandData.expected_reach : 0,
        expected_cost = brandData && brandData.expected_cost ? brandData.expected_cost : 0,
        expected_conversion = brandData && brandData.expected_conversion ? brandData.expected_conversion : 0,
        expected_revenue = brandData && brandData.expected_revenue ? brandData.expected_revenue : 0;

      this.setState({
        percentageData: {
          ...this.state.percentageData,
          totalCardHolders: total_cardholders,
          addressableMarket: addressable_marketValue ? addressable_marketValue : 0,
          expectedReach: expected_reach ? Number(expected_reach.toFixed(0)) : 0,//
          expectedConversion: expected_conversion ? Number(expected_conversion.toFixed(0)) : 0,
          expectedRevenue: expected_revenue ? Number(expected_revenue.toFixed(0)) : 0,
          expectedCost: expected_cost ? Number(expected_cost.toFixed(0)) : 0,
        }
      })
    }
  }
  componentDidUpdate = async (prevProps, prevState) => {
    if ((prevProps.projections !== this.props.projections && this.props.projections && this.props.projections.length)
      || (this.props.projections && this.props.projections.length && Object.keys(this.state.projectionsMap).length == 0)) {
      let projectionsMap = this.props.projections
      if (!projectionsMap || !projectionsMap[0] || !projectionsMap[0].traffic || !projectionsMap[0].conversion_curve) {
        showToasterMessage('Invalida Data')
      }
      await this.setState({ projectionsMap })
      this.calculateSummary();
    }
    if (prevProps.campaignSummary !== this.props.campaignSummary) {
      this.calculateSummary()
      // this.setRightSection();
    }


  }

  goToPreviewPage = (values) => {
    let { selectedMerchants } = this.state
    this.props.previewValues(values)
  }

  render() {
    let {
      onSubmit, campaignDelay, selectedDays } = this.props;
    let {
      percentageData: {
        totalCardHolders,
        addressableMarket,
        expectedReach,
        expectedConversion,
        expectedRevenue,
        expectedCost,
        cpa,
        ageGroupData,
        genderData,
      }, prevPercentageData: {
        prevCpa, prevExpectedConversion, prevExpectedCost, prevExpectedReach, prevExpectedRevenue, prevTotalHolders, prevAdressableMarket
      }
    } = this.state
    const { languageData, title, setShowTCModal, values, totalMerchants, selectedMerchants, formType } = this.props
    if (totalMerchants.length == selectedMerchants.length) {
      values.type = 'All'
      if (values.merchantIds) {
        delete values['merchantIds']
      }
    } else if (selectedMerchants.length > 0) {
      let merchantList = selectedMerchants
      let merchantIdList = []
      merchantList.forEach((item, index) => {
        if (item.merchantId) {
          merchantIdList.push(item.merchantId)
        }
      })
      values.merchantIds = merchantIdList
      if (values.type) {
        delete values['type']
      }
    }
    let disabledSubmit
    if (selectedMerchants && selectedMerchants.length == 0 && !this.props.withoutBranch) {
      disabledSubmit = true
    } else {
      disabledSubmit = false
    }
    //json data values right side
    let summaryValues = {
      Total_cardholders: totalCardHolders,
      Addressable_market: addressableMarket,
      Expected_reach: expectedReach,
      Expected_conversion: expectedConversion,
      Expected_revenue: expectedRevenue,
      Expected_cost: expectedCost,
      Expected_cpa: Number(cpa)
    }
    return (
      <div className="form">
        <div className="card pt-1 campagnCard">
          <div className="card-body campaignBody">
            <div className="row">
              <div className="col-md-5 col-5 text-center">
                <label className='mb-0'>
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['totalCardHolder']}
                    containerClassNames="ml-2"
                    id="totalCardHolder"
                    alignment={true}
                  >
                    {`${languageData && languageData['totalCardHolders'] ? languageData['totalCardHolders'] : 'Total Cardholders'}`}

                  </ToolTipContainer>
                </label>
                <span className="btn  w-100 text-primary ">
                  <CountUp
                    start={prevTotalHolders ? Number(prevTotalHolders) : 0}
                    end={totalCardHolders ? Number(totalCardHolders) : 0}
                    separator=","
                    className='text-primary'
                    style={{ fontSize: 20 }}
                    delay={campaignDelay}
                  />

                </span>
              </div>
              <div className="col-md-2 col-2 text-center d-flex justify-content-center align-items-end">
              </div>
              <div className="col-md-5 col-5 text-center">
                <label className='mb-0'>
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['addressableMarket']}
                    containerClassNames="ml-2"
                    id="addressableMarket"
                    alignment={true}
                  >
                    {`${languageData && languageData['addressableMarket'] ? languageData['addressableMarket'] : 'Addressable Market'}`}

                  </ToolTipContainer>
                </label>
                <span className="btn  w-100 ">
                  <CountUp
                    start={prevAdressableMarket ? Number(prevAdressableMarket) : 0}
                    end={addressableMarket ? Number(addressableMarket) : 0}
                    delay={campaignDelay}
                    separator=","
                    className='text-primary'
                    style={{ fontSize: 20 }}
                    redraw={true}
                  />

                </span>
              </div>
            </div>
            <hr className="divider small" />

            <div className="row ">
              <div className="col-md-5 col-5 text-center">
                <label className='mb-0'>
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedReach']}
                    containerClassNames="ml-2"
                    id="expectedReach"
                    alignment={true}
                  >
                    {`${languageData && languageData['expectedReach'] ? languageData['expectedReach'] : 'Expected Reach'}`}

                  </ToolTipContainer>
                </label>
                <span className="btn  w-100 text-primary">
                  <CountUp
                    start={parseInt(prevExpectedReach)}
                    end={parseInt(expectedReach)}
                    // delay={campaignDelay}
                    duration={1}
                    separator=","
                    className='text-primary'
                    style={{ fontSize: 20 }}
                  />
                </span>
              </div>
              <div className="col-md-2 col-2 text-center d-flex justify-content-center align-items-end">
              </div>
              <div className="col-md-5 col-5 text-center">
                <label className='mb-0'>
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedConversion']}
                    containerClassNames="ml-2"
                    id="expectedConversion"
                    alignment={true}
                  >
                    {`${languageData && languageData['expectedConversion'] ? languageData['expectedConversion'] : 'Expected Conversion'}`}
                  </ToolTipContainer>
                </label>
                <span className="btn  w-100 text-secondary blurText comingSoonFontSize">
                  {/* <CountUp
                    start={prevExpectedConversion ? Number(prevExpectedConversion) : 0}
                    end={expectedConversion ? Number(expectedConversion) : 0}
                    delay={campaignDelay}
                    separator=","
                    className='text-secondary blurText'
                    style={{ fontSize: 20 }}
                  /> */}

                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedConversion']}
                    containerClassNames="ml-2"
                    id="expectedConversion"
                    alignment={true}
                  >
                    257
                  </ToolTipContainer>
                </span>
              </div>
            </div>
            <hr className="divider small" />
            <div className="row ">
              <div className="col-md-5 col-5 text-center">
                <label
                  className="campain_label mb-0"
                >
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedRevenue']}
                    containerClassNames="ml-2"
                    id="expectedRevenue"
                    alignment={true}
                  >
                    {`${languageData && languageData['expectedRevenue'] ? languageData['expectedRevenue'] : 'Expected Revenue'}`}
                    <span>&nbsp;(GBP)</span>
                  </ToolTipContainer>
                </label>
                <span className="btn  w-100 text-secondary blurText comingSoonFontSize">
                  {/* <CountUp
                    start={prevExpectedRevenue ? Number(prevExpectedRevenue) : 0}
                    end={expectedRevenue ? Number(expectedRevenue) : 0}
                    delay={campaignDelay}
                    separator=","
                    className='text-secondary blurText'
                    style={{ fontSize: 20 }}
                  /> */}
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedRevenue']}
                    containerClassNames="ml-2"
                    id="expectedRevenue"
                    alignment={true}
                  >
                    6589
                  </ToolTipContainer>

                </span>
              </div>
              <div className="col-md-2  col-2 text-center d-flex justify-content-center align-items-end">
              </div>
              <div className="col-md-5 col-5 text-center">
                <label
                  className="campain_label mb-0"
                >
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedCost']}
                    containerClassNames="ml-2"
                    id="expectedCost"
                    alignment={true}
                  >
                    {`${languageData && languageData['expectedCost'] ? languageData['expectedCost'] : 'Expected Cost'}`}
                    <span>&nbsp;(GBP)</span>
                  </ToolTipContainer>
                </label>
                <span className="btn  w-100 text-secondary blurText comingSoonFontSize">
                  {/* <CountUp
                    start={prevExpectedCost ? Number(prevExpectedCost) : 0}
                    end={expectedCost ? Number(expectedCost) : 0}
                    delay={campaignDelay}
                    separator=","
                    className='text-secondary blurText'
                    style={{ fontSize: 20 }}
                  /> */}
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['expectedCost']}
                    containerClassNames="ml-2"
                    id="expectedCost"
                    alignment={true}
                  >
                    754
                  </ToolTipContainer>

                </span>
              </div>
            </div>
            <hr className="divider small" />
            <div className="row align-items-center d-none " style={{ visibility: 'hidden' }}>
              <div className="col-md-3 col-sm-6 col-6 text-center mb-0">
                <label className='mb-0'>
                  {`${languageData && languageData['gender'] ? languageData['gender'] : 'Gender'} `}
                </label>
                <div>
                  <Pie
                    data={this.getGenderChartData()}
                    options={{
                      maintainAspectRatio: false,
                      plugins: { datalabels: false },
                    }}
                    height={100}
                    width={100}
                    legend={{ display: false }}
                  />
                </div>
                {genderData && genderData.data && ageGroupData.data == {} ? 'Incorrect Age Data ' : ''}
              </div>
              <div className="col-md-3 col-sm-6 col-6  text-center mb-0">
                <label className='mb-0'>
                  {`${languageData && languageData['age'] ? languageData['age'] : 'Age'}`}
                </label>
                <div>
                  <Pie
                    data={this.getAgeGroupChart()}
                    options={{
                      maintainAspectRatio: false,
                      plugins: { datalabels: false },
                    }}
                    height={100}
                    width={100}
                    legend={{ display: false }}
                  />
                </div>
                {ageGroupData && ageGroupData.data && ageGroupData.data == {} ? 'Incorrect Age Data ' : ''}
              </div>
              <div className="col-md-6 text-center mb-0">
                <label className='mb-0'>
                  <ToolTipContainer
                    toolTipText={CampaignProjectionTips['cpa']}
                    containerClassNames="ml-2"
                    id="cpa"
                    alignment={true}
                  >
                    {`${languageData && languageData['cpa'] ? languageData['cpa'] : 'CPA'}`}
                    <span>&nbsp;(GBP)</span>
                  </ToolTipContainer>
                </label>
                <span className="btn  w-100">
                  <CountUp
                    start={Number(prevCpa)}
                    end={Number(cpa)}
                    delay={campaignDelay}
                    separator=","
                    decimals={2}
                    decimal="."
                    className='text-primary'
                    style={{ fontSize: 20 }}
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="card pl-0 pr-0 pb-0 pt-2">
          <div className="card-body campaignBody">
            <div className="form-group col-lg-12 col-md-12 mb-0">
              <input type='checkbox' name='recurring'
                defaultChecked={values.recurring}
                id='recurring'
                onChange={(e) => {
                  values.recurring = e.target.checked;
                }} />
              <label className='pl-2 checkBoxAlignMent'>
                Do you want this to be a monthly recurring campaign?
            </label>
            </div>
          </div>
        </div>
        <div className="row justify-content-end pt-1 pb-5 px-lg-4 px-md-0 px-sm-0 px-0">
          {!this.props.withoutBranch && <div className='col-lg-5 col-md-12 col-sm-12 col-12 d-none' >
            <ToolTipContainer toolTipText={CreateCampaignTips['previewCapaign']}>
              <SplitButton
                primary="btn-info"
                onClick={e => onSubmit('preview', summaryValues)}
                icon={<i className="fa fa-chevron-right"></i>}
                disabled={disabledSubmit}
                text='Preview'
                style={{ width: 168 }}
              />
            </ToolTipContainer>
          </div>}
          {!this.props.withoutBranch && <div className='col-lg-5 col-md-12 col-sm-12 col-12 d-none mb-3 ' >
            <ToolTipContainer toolTipText={CreateCampaignTips['previewCapaign']}>
              <SplitButton
                primary="btn-info"
                onClick={e => onSubmit('preview', summaryValues)}
                icon={<i className="fa fa-chevron-right"></i>}
                disabled={disabledSubmit}
                text='Preview'
                style={{ width: '100%' }}
              />
            </ToolTipContainer>
          </div>}
          <div className='col-lg-4 col-md-12 col-sm-12 col-12' >
            <ToolTipContainer toolTipText={CreateCampaignTips['createCampaign1']}>
              <SplitButton
                primary="btn-primary"
                onClick={e => onSubmit('create', summaryValues)}
                icon={<i className="fa fa-chevron-right"></i>}
                text={`${formType == 'edit' ? 'Update' : 'Create'} Campaign`}
                disabled={disabledSubmit}
                // disabled={disabledSubmit ? disabledSubmit : selectedDays && selectedDays.length == 0 ? true : false}
                style={{ width: '100%' }}
              />
            </ToolTipContainer>
          </div>
        </div>
      </div >
    )
  }
}

export default CampaignSummary;
