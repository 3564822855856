import React, { Component } from 'react';
import capitalize from 'lodash/capitalize'
import BarChart from '../Charts/BarChart'
import { Bar } from 'react-chartjs-2';

const getCurrency = () => {
  // return $
  return "£"
  // return "AED"
}

class Performance extends Component {
  categories = [
    // {key : 'reach', xLabel : 'Year-Week', yLabel : 'Customers'},
    { key: 'conversions', xLabel: 'Year-Week', yLabel: 'Customers' },
    { key: 'revenue', xLabel: 'Year-Week', yLabel: `GMV (${getCurrency()})` },
    { key: 'Collections', xLabel: 'Year-Week', yLabel: `Total Amount` }
  ]
  state = {
    activeTab: this.categories[0],
    chartData: null
  };


  stackBarChartOptions = {
    plugins: {
      title: {
        display: false
      },
      datalabels: {
        formatter: function (value, index, values) {
          value = value.toLocaleString('en-US');
          return "";
        },
      },
    },
    responsive: true,
    scales: {
      xAxes: [{
        stacked: true,
        barThickness: 15,
        scaleLabel: {
          display: true,
          // labelString: `Cashback / Comissions`
          labelString: `Cashback / Success Fee`
        },
      }],
      yAxes: [{
        stacked: true,
        barThickness: 15,
        scaleLabel: {
          display: true,
          // labelString: `Collections (${getCurrency()})`
          labelString: `${getCurrency()}`
        },
      }]
    }
  };

  getChartData = () => {

    let labels = [], cashBackList = [], commissionsList = [];
    if (this.props.data.cashbackAndCommissions) {
      this.props.data.cashbackAndCommissions.forEach(element => {
        labels.push(element._id);
        cashBackList.push(element.totalCashbackAmount);
        commissionsList.push(element.totalCommissions);
      });
    }

    let stackBarData = {
      labels,
      datasets: [
        {
          label: 'Cashback',
          data: cashBackList,
          backgroundColor: "#1e90ff",
        },
        {
          label: 'Success Fee',
          data: commissionsList,
          backgroundColor: "#e77e95",
        }
      ]
    };
    return stackBarData;
  }



  onClickTabItem = (tab) => {
    let { data } = this.props;
    let chartData = data[tab.key]
    this.setState({ activeTab: tab, chartData });
  }
  componentDidMount() {
    let { data } = this.props;
    let chartData = data[this.state.activeTab.key];
    this.setState({ chartData });
  }
  render() {
    let { chartData, activeTab } = this.state;
    if (this.props.data.filterData && this.props.data.filterData.year) {
      activeTab.xLabel = "Year-Week";
    } else {
      activeTab.xLabel = "Year-Month";
    }

    if(this.props.data.cashbackAndCommissions && this.props.data.cashbackAndCommissions.length == 0) {
      let cat = JSON.parse(JSON.stringify(this.categories));
      let index_1 = cat.findIndex(a => a.key == "Collections");
      if(index_1 != -1) {
        cat.splice(index_1,1)
        this.categories = cat;
      }
    }

    return (
      <div className="card col performance">
        <div className="card-title">
          <div className="row align-items-baseline">
            {/* <div className="col-md-4">
              Performance
            </div> */}
            <div className="col-md-12">
              <ul className="mt-2 mb-3 nav nav-pills">
                {
                  this.categories.map((category, i) => {
                    return (
                      <li key={i} className="nav-item col pl-1 pr-1" onClick={() => this.onClickTabItem(category)}>
                        <button
                          className={`btn btn-sm nav-link tab-selector w-100 ${activeTab.key === category.key ? 'active btn-primary' : ''}`}
                        >
                          {category.key == "revenue" ? "GMV" : category.key == "Collections" ? "Success Fee" : capitalize(category.key)}
                        </button>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="card-body text-center">
          <div className="performance-charts">
            {activeTab.key == "conversions" || activeTab.key == "revenue" ?
              <div className="chart-content">
                {
                  chartData && <BarChart {...activeTab} data={chartData} dontSort={true} />
                }
              </div> :
              <div className="chart-content">
                {
                  this.props.data.cashbackAndCommissions && <Bar options={this.stackBarChartOptions} data={this.getChartData()} />
                }
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Performance