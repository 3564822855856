import axiosMethodRequest from '../../config/service'
import { CREATE_BRAND, UPLOAD_BRAND } from '../../redux/newBrandBranch/actionTypes'
export const CreateBrandApiCall = (data, type) => {
    return dispatch => {
        let url = "", method = "";
        if (type == "create") {
            url = "brands"
            method = "POST"
        } else {
            url = `brands/${data._id}`
            method = "PUT"
        }
        return axiosMethodRequest(method, url, data).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: CREATE_BRAND,
                    payload: { data: response.data, error: {}, loader: false }
                })
            } else if (response && response.data && response.data.errorCode) {

                dispatch({
                    type: CREATE_BRAND,
                    payload: { error: response.data, data: "", loader: false }
                })
            }
        })
    }
}

export const BulkUploadBrandApicall = (data) => {
    return dispatch => {
        dispatch({
            type: UPLOAD_BRAND,
            payload: { data: {}, error: {}, loader: true }
        })
        return axiosMethodRequest('POST', "brands/bulkUploadBrand", data).then((response) => {
            if (response && response.data && response.data.respCode) {
                dispatch({
                    type: UPLOAD_BRAND,
                    payload: { data: response.data, error: {}, loader: false }
                })
            } else if (response && response.data && response.data.errorCode) {
                dispatch({
                    type: UPLOAD_BRAND,
                    payload: { error: response.data, data: "", loader: false }
                })
            }

        })
    }
}


export const updateBrandWhiteLabelAction = (data, callback) => {
    return dispatch => {    
        return axiosMethodRequest('POST', "brands/updateBrandWhiteLabel", data).then((response) => {
            callback(response);
        })
    }
}