import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { generateId } from '../../utils/helpers';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import capitalize from 'lodash/capitalize'
import Icon from '@material-ui/core/Icon'
import { pluralize } from '../../utils/helpers'
import { Redirect } from 'react-router-dom'
import Select from 'react-select';
import {
  getEntityAction,
  createEntityAction,
  createEntityMetadataAction,
  deleteEntityAction,
  editEntityAction,
  confirmMerchantAction,
  sendMail,
  setEntityPageAction,
  setEntitySortAction,
  setEntitySearchAction,
  setEntityFiltersAction,
  uploadCSVForEntityAction,
  multiAction
} from '../../redux/actions/entityListActions';
import { getUserDetailsAction } from '../../redux/actions'
import { createGroupUserAction } from '../../services/newBrandBranch/createUserApiCall';

import { Row } from 'reactstrap'

import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { MerchantForm } from "../../components/Forms/MerchantComments";
import ViewModal from '../../components/ViewModal'
//session expiry modal
import SessionExpiryModal from './../../components/sessionExpiryModal'
import moment from 'moment'
import { Badge } from "react-bootstrap";
import { GetSessionValue } from "../../utils/sessionStorage";
import { Roles } from "../../utils/constants/constant";
import CampaignAssets from "../BrandsForBank/campaignAssets";
import BulkBrandUploadModal from '../../components/BulkBrandUploadModal';
import { getDistributorListAction } from "../../services/newBrandBranch/checkBrandExistApiCall";
import { getWhitelabels } from '../../services/newBrandBranch/checkBrandExistApiCall';
import { encodeSpecialCharacters } from "../../utils/helpers";
class EntityList extends Component {

  state = {
    showDeleteModal: false,
    disablePrev: true,
    disableNext: false,
    showFilter: false,
    showPasswordResetModal: false,
    showAddNewModal: false,
    showEditModal: false,
    showUploadModal: false,
    showApproveModal: false,
    showSendMailModal: false,
    // tableData: [],
    isLoading: false,
    sortCount: 0,
    // paginationData: {
    //   limit: 10,
    //   page: 1,
    //   totalCount: 0
    // },
    approvalAction: '',
    searchField: '',
    sessionExpiryModal: false,
    loginRedirect: false,
    openViewModal: false,
    selectedArray: [],
    actions: [
      { label: 'Delete', value: 'DELETE' },
      { label: 'Approve', value: 'APPROVE' },
      { label: 'Reject', value: 'REJECT' },
    ],
    status: [
      { label: 'Status (All)', value: null },
      { label: 'Approved', value: 'Approved' },
      { label: 'Rejected', value: 'Rejected' },
      { label: 'Pending', value: 'Pending' },
      { label: 'Updated', value: 'Updated' },
      { label: 'Deleted', value: 'Deleted' },
    ],
    // amexPhaseList: [
    //   { label: 'All', value: null },
    //   { label: 'Amex Phase 3', value: 'amex_phase_3' },
    //   { label: 'Amex Phase 3.5', value: 'amex_phase_3_5' },
    //   { label: 'Amex Phase 4', value: 'amex_phase_4' }
    // ],
    distributorList: [],
    tempDistributorList: [],
    selectedStatus: { label: 'Status (All)', value: null },
    selectedAmexPhase: { label: 'Phase (All)', value: null },
    selectedDistributor: { label: 'Publisher (All)', value: null },
    selectedWhiteLabel: { label: 'White Label (All)', value: null },
    selectedAction: '',
    globalSearch: '',
    assetsModel: { data: {}, isOpen: false },
    isOpenbulkBrandUploadModal: false,
    whitelabels: []
  };
  pagination = {
    limit: 10,
    page: 1
  }
  fetchData = () => {
    let {
      getEntityData,
      // page,
      filters,
      apiUrl,
      successNotification,
      errorNotification
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField, selectedStatus } = this.state
    if (searchField) {
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    // if (this.props.user.role == Roles.bank) {
    //   filters.customerType = this.props.user.customerType
    // }
    if (selectedStatus && selectedStatus.value) {
      filters.dropDown = { "key": "status", "value": selectedStatus.value, "type": "eq" }
    }
    // let { limit } = this.state.paginationData
    this.setState({ isLoading: true })
    // page, pageSize, filters,apiUrl,type,callback
    getEntityData(page, limit, filters, apiUrl, '', (res, pagination, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (type == 'success') {
        // if (pagination && pagination.totalCount) {
        //   this.setState({ paginationData: pagination })
        // }
        // this.setState({ tableData: res })
      }
      if (type == 'error') {
        if (sessionExpiryModal) {
          return
        }
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          errorNotification(notification)
        })
      }
    })
  }
  componentDidMount() {
    let { preset, setEntityFilters, page, getDistributorList, heading, getWhitelabels } = this.props;

    if (heading == 'brand') {
      getDistributorList(response => {
        if (response.length) {
          let list = [{ label: 'Publisher (All)', value: null }];
          response.forEach(element => {
            list.push({ label: element.distributors_display_name, value: element.distributors_name });
          });
          this.setState({ distributorList: list, tempDistributorList : response });
        }
      });

      getWhitelabels(response => {
        this.setState({ whitelabels: response });
      });
    }

    if (preset) {
      setEntityFilters(preset)
      return
    }
    this.fetchData(page || this.pagination.page)
    let { getBrandData } = this.props;

  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      this.fetchData()
      // console.log('comes to updated')
    }
  }
  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }
  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { sizePerPage } = this.pagination;
    return parseInt(totalSize / sizePerPage) + (totalSize % sizePerPage ? 1 : 0)
  }
  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters, status }) => {
    let {
      setEntityPage,
      setEntitySort,
      setEntitySearch,
      setEntityFilters,
      getEntityData,
      apiUrl
    } = this.props;
    let { page, limit } = this.pagination
    let { searchField, selectedStatus, selectedAmexPhase, selectedDistributor, selectedWhiteLabel } = this.state;
    let filterCriteria = {}
    // if (this.props.user.role == Roles.bank) {
    //   filterCriteria.customerType = this.props.user.role
    // }
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    if (selectedStatus && selectedStatus.value) {
      filterCriteria.dropDown = { "key": "status", "value": selectedStatus.value, "type": "eq" }
    }
    if (selectedAmexPhase && selectedAmexPhase.value) {
      filterCriteria.dropDown = { "key": "is_amex_phase", "value": selectedAmexPhase.value, "type": "eq" }
    }
    if (selectedDistributor && selectedDistributor.value) {
      filterCriteria.publisherDropDown = { "key": "selectedPublisher", "value": selectedDistributor.value, "type": "eq" }
    }
    if (selectedWhiteLabel && selectedWhiteLabel.value) {
      filterCriteria.whiteLabelDropDown = { "key": "selectedWhiteLabel", "value": selectedWhiteLabel.value, "type": "eq" }
    }

    if (type === 'pagination') {
      setEntityPage(page, sizePerPage)
      return;
    }
    if (type === 'sort') {


      let sortCount = this.state.sortCount
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      filterCriteria.sortField = {
        direction: sortCount == 0 ? "desc" : 'asc',
        sortfield: sortField,
      }
      this.setState({ sortCount: sortCount })
      getEntityData(page, limit, filterCriteria, apiUrl, 'sort', (response, pagination) => {
        // this.setState({ tableData: response })
        // if (pagination && pagination.totalCount) {
        //   this.setState({ pagination: pagination })
        // }
      })
      return
    }
    if (type === 'search') {
      filterCriteria.globalSearch = {
        value: searchText,
        type: 'user'
      }
      // page, pageSize, filters,apiUrl,type,callback
      getEntityData(page, limit, filterCriteria, apiUrl, 'globalsearch', (response, pagination) => {
        this.setState({ tableData: response })
        if (pagination && pagination.totalCount) {
          this.setState({ pagination: pagination })
        }
      })



      // setEntitySearch(searchText)
    }
    if (type === 'filter') {
      setEntityFilters({ ...filters })
    }
    if (type === 'dropdown') {

      getEntityData(page, limit, filterCriteria, apiUrl, 'dropdown', (response, pagination) => {

      })
    }
  }
  setShowAddNewModal = () => {
    let { heading } = this.props
    if (heading == "brand" || heading == "branch" || heading == "user") {
      this.props.history.push('/user/create/brand')
    } else {
      this.setState({ showAddNewModal: true })
    }
  }
  onCloseAddNewModal = () => {
    this.setState({ showAddNewModal: false, selectedEntity: null })
  }
  setShowUploadModal = () => {
    this.setState({ showUploadModal: true })
  }
  onCloseUploadModal = () => {
    this.setState({ showUploadModal: false, selectedEntity: null })
  }
  setSendMailModal = (selectedEntity, type) => {
    this.setState({ showSendMailModal: true, selectedEntity })
  }
  onCloseSendMailModal = () => {
    this.setState({ showSendMailModal: false, selectedEntity: null })
  }
  setShowEditModal = (selectedEntity) => {
    console.log("Entity Model   :   "+JSON.stringify(selectedEntity))
    let { heading, getUserDetails } = this.props
    if (heading == "brand") {
      // const encodedName = encodeSpecialCharacters(selectedEntity.name);
      // this.props.history.push(`/user/edit/brand/${selectedEntity._id}/${encodedName}/${selectedEntity.brandId}`)
      this.props.history.push({
        pathname: `/user/edit/brand/${selectedEntity._id}/${selectedEntity.name}/${selectedEntity.brandId}`,
        state: selectedEntity.name
      });
    } else if (heading == "branch") {
      const encodedName = encodeSpecialCharacters(selectedEntity.brandName);
      this.props.history.push(`/user/edit/brand/${selectedEntity.brandObjId || selectedEntity._id}/${encodedName}/${selectedEntity.brandId}`)
    } else if (heading == "user") {
      const encodedName = encodeSpecialCharacters(selectedEntity.brandName);
      this.props.history.push(`/user/edit/brand/${selectedEntity.brandObjId || selectedEntity._id}/${encodedName}/${selectedEntity.brandId}`)
    } else if (heading == "groupBrand") {
      if (selectedEntity.userObjId) {
        getUserDetails(selectedEntity.userObjId, callBack => {
          if (callBack && callBack[0]) {
            selectedEntity.firstName = callBack[0].firstName;
            selectedEntity.lastName = callBack[0].lastName;
            selectedEntity.phone = callBack[0].phone;
            selectedEntity.brandId = callBack[0].brandId;
            selectedEntity.brandName = callBack[0].brandName;
            selectedEntity.userObjId = callBack[0]._id;
          }
          this.setState({ showEditModal: true, selectedEntity });
        })
      } else {
        this.setState({ showEditModal: true, selectedEntity });
      }
    } else {
      if (selectedEntity) {
        this.setState({ showEditModal: true, selectedEntity })
        return
      }
      this.setState({ showEditModal: true })
    }
  }
  setApproveModal = (selectedEntity, type) => {
    this.setState({ showApproveModal: true, selectedEntity, approvalAction: type })
  }
  onCloseApproveModal = () => {
    this.setState({ showApproveModal: false, selectedEntity: null, approvalAction: null })
  }
  onCloseViewModal = () => {
    this.setState({ openViewModal: false })
    this.setShowEditModal()
  }
  onCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedEntity: null })
  }
  setShowDeleteModal = (selectedEntity) => {
    this.setState({ showDeleteModal: true, selectedEntity })
  }
  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedEntity: null })
  }
  setShowPasswordResetModal = () => {
    this.setState({ showPasswordResetModal: true })
  }
  onClosePasswordResetModal = () => {
    this.setState({ showPasswordResetModal: false, selectedEntity: null })
  }
  showHidebulkBrandUploadModal = (flag) => {
    this.setState({ isOpenbulkBrandUploadModal: flag })
  }

  uploadedFile = (data) => {

    let { page, successNotification } = this.props;
    this.fetchData(page);
    setTimeout(() => {
      let notification = { ...notificationOpts };
      notification.message = "Uploaded successfully";
      successNotification(notification);
    })
    // this.setState({ isOpenbulkBrandUploadModal : false});
  }

  //for multiselect
  multiAction = () => {
    let { multiAction, successNotification } = this.props
    let { selectedArray, selectedAction } = this.state
    let values = {}
    values.selectedIds = selectedArray
    multiAction(values, selectedAction.value, (res, type) => {
      let notification = { ...notificationOpts }
      notification.message = res
      setTimeout(() => {
        successNotification(notification)
      })
      if (type == 'success') {
        selectedArray.forEach((item, index) => {
          document.getElementById(item).checked = false
        })
        selectedArray.forEach((item, index) => {
          return this.handleCheckBox(item, type)
        })
        this.setState({ selectedArray: [] })
        this.fetchData()
      }
    })
  }

  //for multiselect checkbox
  handleCheckBox = (e, type) => {
    let selectedId
    if (!type) {
      selectedId = e.target.value
    } else {
      selectedId = e
    }
    let selectedArray = this.state.selectedArray
    let selectedIdFound = false
    let selectedIdIndex
    selectedArray.forEach((item, index) => {
      if (item == selectedId) {
        selectedIdFound = true
        selectedIdIndex = index
      }
    })
    if (selectedIdFound) {
      selectedArray.splice(selectedIdIndex, 1)
    } else {
      selectedArray.push(selectedId)
    }
    this.setState({ selectedArray: selectedArray })
  }

  //view modal
  openViewModal = async (rowData) => {
    let keys = Object.keys(rowData);
    let viewFields = this.props.viewFields ?
      await this.props.viewFields : [];
    if (viewFields) {
      if (rowData) {
        let values;
        viewFields.forEach((item, index) => {
          if (item.name == 'created') {
            let val = rowData[item.name]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          }
          else if (item.name == 'activatedDate' && rowData[item.name]) {
            let val = rowData[item]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          }
          else {
            item.value = rowData[item.name]
          }
        })
      }
      await this.setState({
        openViewModal: true,
        selectedEntity: rowData
      });
      this.viewModalRef.getRowData(viewFields);
    }
  }

  // edit api call brand,merchant and user 
  onSubmitEdit = (values, actions) => {
    let { editEntity, createUser, page, successNotification, entityType, apiUrl } = this.props;
    if (apiUrl == 'users') {
      let body = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        brandName: '',
        guestUser: false
      }
      if (values) {
        body['email'] = values['email'];
        body['firstName'] = values['firstName'];
        body['lastName'] = values['lastName'];
        body['phone'] = values['phone'];
        body['brandName'] = values['brandName'];
        body['guestUser'] = values['guestUser'];
      }
      if (!values.merchantIds) {
        values.merchantIds = []
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = body;
    }
    if (apiUrl == 'merchants') {
      let body = {
        name: '',
        merchantId: '',
        amexMerchantID: '',
        contactName: '',
        contactPhone: '',
        contactEmail: '',
        address: '',
        brandName: '',
      }
      if (values) {
        body['name'] = values['name'];
        body['merchantId'] = values['merchantId'];
        body['amexMerchantID'] = values['amexMerchantID'];
        body['contactName'] = values['contactName'];
        body['contactEmail'] = values['contactEmail'];
        body['contactPhone'] = values['contactPhone'];
        body['address'] = values['address'];
        body['brandName'] = values['brandName'];
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = body;
    }
    if (apiUrl == 'brands') {
      let body = {
        name: '',
        registeredAddress: '',
        website: '',
        description: '',
        photo: '',
      }
      if (values) {
        body['name'] = values['name'];
        body['registeredAddress'] = values['registeredAddress'];
        body['website'] = values['website'];
        body['description'] = values['description'];
        body['photo'] = values['photo'];
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = body;
    }
    if (apiUrl == 'employees') {
      let body = {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
      }
      if (values) {
        body['email'] = values['email'];
        body['firstName'] = values['firstName'];
        body['lastName'] = values['lastName'];
        body['phone'] = values['phone'];
        body['role'] = values['role'];
        body['customerType'] = values['customerType'];
        body['distributor_id'] = values['distributor_id']
      }
      if (values['_id']) {
        body['_id'] = values['_id']
        body['brandId'] = values['brandId']
      }
      values = body;
    }
    values.emailText = values.email;
    if (apiUrl != "groupBrand") {
      editEntity(values, apiUrl, (res, type) => {
        actions.setSubmitting(false)
        if (type == 'error') {
          actions.setStatus(res)
          return
        }
        // notification
        this.onCloseEditModal()
        this.fetchData(page)
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
      })
    } else if (apiUrl == "groupBrand") {
      let userData = [{
        "firstName": values.firstName, "lastName": values.lastName, "email": values.email, "phone": values.phone, "brandName": values.group_name,
        "brandId": values.brandId, "revenueCount": "85", "role": values.role, "from": "Edit",
        "_id": values.userObjId
      }];
      //"isNew":true
      createUser(userData, callBack => {

        if (callBack.errorCode) {
          actions.setSubmitting(false);
          actions.setStatus(callBack.errorMessage)
          return;
        }

        if (callBack.respCode) {
          values.userObjId = callBack.saved_userId;
        }
        editEntity(values, apiUrl, (res, type) => {
          actions.setSubmitting(false)
          if (type == 'error') {
            actions.setStatus(res)
            return
          }
          // notification
          this.onCloseEditModal()
          this.fetchData(page)
          setTimeout(() => {
            let notification = { ...notificationOpts }
            notification.message = res
            successNotification(notification)
          })
        });

      });
    }
  }

  // Add New Brand submit event (form - brand.js)
  onSubmitAddNew = (values, actions) => {
    let { createEntity, createUser, createEntityMetadata, page, successNotification, entityType, apiUrl } = this.props;

    if (!values.isNewEntity) {

      if (apiUrl != "groupBrand") {
        createEntity(values, apiUrl, (res, type) => {
          actions.setSubmitting(false)
          if (type == 'error') {
            actions.setStatus(res)
            return
          }
          // notification
          this.onCloseAddNewModal()
          this.fetchData(page)
          setTimeout(() => {
            let notification = { ...notificationOpts }
            notification.message = res
            successNotification(notification)
          })
        })
      } else if (apiUrl == "groupBrand") {

        let userData = [{
          "firstName": values.firstName, "lastName": values.lastName, "email": values.email, "phone": values.phone, "brandName": values.group_name,
          "guestUser": true, "isNew": true, "revenueCount": "85", "role": values.role, brandId: generateId(9), "from": "Add"
        }];

        createUser(userData, callBack => {

          if (callBack.errorCode) {
            actions.setSubmitting(false);
            actions.setStatus(callBack.errorMessage)
            return;
          }

          if (callBack.respCode) {
            values.userObjId = callBack.saved_userId;
            values.groupId = userData[0].brandId;
          }
          createEntity(values, apiUrl, (res, type) => {
            actions.setSubmitting(false)
            if (type == 'error') {
              actions.setStatus(res)
              return
            }
            // notification
            this.onCloseAddNewModal()
            this.fetchData(page)
            setTimeout(() => {
              let notification = { ...notificationOpts }
              notification.message = res
              successNotification(notification)
            })
          })
        });
      }
    } else {
      values.created_by = "UI";
      createEntityMetadata(values, apiUrl, (res, type) => {
        actions.setSubmitting(false)
        if (type == 'error') {
          actions.setStatus(res)
          return
        }
        // notification
        this.onCloseAddNewModal()
        this.fetchData(page)
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
      })
    }
  }
  onSubmitUpload = (values, actions) => {
    let { uploadCSVForEntity, page, successNotification, entityType, apiUrl } = this.props;
    uploadCSVForEntity(values.file, apiUrl, (res, type) => {
      actions.setSubmitting(false)
      if (type == 'error') {
        actions.setStatus(res)
        return
      }
      // notification
      this.onCloseUploadModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }
  handleDeleteEntity = () => {
    let { deleteEntity, page, successNotification, entityType, apiUrl } = this.props;
    let { selectedEntity } = this.state
    deleteEntity(selectedEntity, apiUrl, (res, type) => {
      if (type == 'error') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
        return
      }
      // notification
      this.onCloseDeleteModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }
  handleConfirmMerchant = (values, actions) => {
    let { confirmMerchant, page, successNotification, entityType } = this.props;
    let { selectedEntity, approvalAction } = this.state
    values.status = `${approvalAction}d`
    values.MId = selectedEntity._id
    let url = approvalAction == 'Approve' ? `users/approveMerchantId` : approvalAction == 'Reject' ? `users/approveMerchantId` : ''
    confirmMerchant(values, url, (res, type) => {

      // notification
      this.onCloseApproveModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        if (type == 'error') {
          notification.message = res
        } else {
          notification.message = `Merchant ${approvalAction == 'Approve' ? 'approved' : 'rejected'} successfully`
        }

        successNotification(notification)
      })
    })
  }
  handleSendMail = () => {
    let { sendMailconfirm, page, successNotification, entityType } = this.props;
    let { selectedEntity, approvalAction } = this.state
    let url = `users/resendMail?emailid=${selectedEntity.email}`
    sendMailconfirm(selectedEntity, url, (res, type) => {
      if (type == "error") {
        return res
      }
      // notification
      this.onCloseSendMailModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }
  submitForm = () => {

    if (this.formik)
      this.formik.submitForm()
  }
  formikRef = async (e) => {
    console.log(e)
    this.formik = e
    if (this.formik) {
      if (this.formik.uploadProps) {
        await this.setState({
          isSubmitDisabled: this.formik.uploadProps.isDisabled
        })
        console.log(this.formik.isDisabled)
      }
    }
  }

  //for insert check box in table
  checkBoxFormatter = (cell, row) => {
    let { user, heading } = this.props
    return (
      <div className="input-group justify-content-center">
        <input type="checkbox" className="table_select_checkbox" onChange={this.handleCheckBox} value={row._id} id={row._id} />
      </div>)
  }

  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }
  actionsFormatter = (cell, row) => {
    let { user, heading } = this.props
    let userId = user && user._id ? user._id : '';
    let rowId = row && row.createdBy && row.createdBy.employee ? row.createdBy.employee : ''
    return (
      user.role === 'systemadmin' || user.role === 'admin' || user.role === 'superAdmin' || user.role === 'salesAdmin' ||
        user.role === 'opsAdmin'
        ?
        <React.Fragment key={row && row._id ? row._id : ''} >
          {/* {heading != 'merchant' ? */}

          <div className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
          </div>
          {/* : '' */}

          {heading != 'activities' ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setShowEditModal(row)} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Edit">border_color</Icon>
            </span>
            : ''}
          {heading == 'user' && (row.status == 'Pending') ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }}
              onClick={() => this.setSendMailModal(row, 'Approve')}
            >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success" data-toggle="tool-tip" title="Quick Activation">email</Icon>
            </span> : ''}
          {heading == 'branch' && (row.status == 'Pending' || row.status == 'Rejected') && row && row.createdBy && !row.createdBy.employee ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Approve')} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success" data-toggle="tool-tip" title="Approve">done</Icon>
            </span> : ''}
          {heading == 'branch' && (row.status == 'Pending' || row.status == 'Active' || row.status == 'Approved') && !(userId == rowId) ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.setApproveModal(row, 'Reject')} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Reject">clear</Icon>
            </span> : ''}
          {heading == 'branch' || heading == 'activities' ?

            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.openViewModal(row)} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success">visibility</Icon>
            </span>
            : ''}
          {heading == 'brand' ?
            <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.openAssetsModal(row, true)} >
              <Icon style={{ fontSize: "1.5rem" }} className="text-success">preview</Icon>
            </span> : null}

        </React.Fragment>
        : '')
  }

  hasEditPermission = () => {
    let { user } = this.props;
    // we may need to do this on resource type
    return user.role === 'systemadmin' || user.role === 'admin' || user.role === 'superAdmin' || user.role === 'salesAdmin' ||
      user.role === 'opsAdmin'
  }
  sessionExpired = async () => {
    await this.setState({ loginRedirect: true })
    //  DeleteSessionValue('user')
  }
  setPageLimit = (limit) => {
    // let paginationData = this.state.paginationData
    // paginationData.limit = limit
    // this.setState({ paginationData: paginationData })
    this.pagination.limit = limit
    this.fetchData()
  }
  handleChange = (e) => {
  }
  pageChange = (page) => {
    // let pagination=this.state.paginationData
    // pagination.page=page
    // this.setState({paginationData:pagination})
    this.pagination.page = page
    this.fetchData()
  }

  //when performing multi action , to select action
  changeStatus = async (selectedStatus) => {
    await this.setState({ selectedStatus })
    let status = selectedStatus.value
    this.handleTableChange('dropdown', { status })
  }

  onChangeAmexPhase = async (selectedAmexPhase) => {
    await this.setState({ selectedAmexPhase })
    let is_amex_phase = selectedAmexPhase.value
    this.handleTableChange('dropdown', { is_amex_phase })
  }

  onChangeDistributor = async (selectedDistributor) => {
    await this.setState({ selectedDistributor })
    let selectedPublisher = selectedDistributor.value
    this.handleTableChange('dropdown', { selectedPublisher })
  }

  onChangeWhiteLabel = async (selectedWhiteLabel) => {
    if (selectedWhiteLabel && selectedWhiteLabel.phases) {
      let phaseList = [{ label: 'Phase (All)', value: null }];
      selectedWhiteLabel.phases.forEach(element => {
        phaseList.push({
          label: element.phaseNumber,
          value: element.phaseNumber
        });
      });
      selectedWhiteLabel.phaseList = phaseList;
    }
    await this.setState({ selectedWhiteLabel })
    let selectedLabel = selectedWhiteLabel.value
    this.handleTableChange('dropdown', { selectedLabel })
  }

  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-1" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content">
          {
            options.map((option, index) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)} key={index}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )
  openAssetsModal = (row, status) => {
    this.setState({
      assetsModel: {
        data: row, isOpen: status
      }
    })
  }
  closeAssetsModal = () => {
    this.setState({
      assetsModel: {
        data: {}, isOpen: false
      }
    })
  }

  showMessage = (message, type) => {
    let notification = { ...notificationOpts };
    let { successNotification } = this.props;
    notification.message = message;
    successNotification(notification);
  }

  render() {
    const { items, totalCount, heading, entityType, icon, columns, EntityForm, prefix = '', uploadCSV, EntityUploadForm = 'div', globalsearchPlaceHolder, CommentsForm, isLoading } = this.props;
    const { approvalAction, searchField, loginRedirect, selectedArray, actions, selectedAction, selectedStatus,
      selectedAmexPhase, status, assetsModel, distributorList, selectedDistributor, whitelabels, selectedWhiteLabel } = this.state
    let index = columns.findIndex(c => c.dataField === 'actions' && c.isDummyField === true)
    if (columns[index]) {
      columns[index].formatter = this.actionsFormatter;
    }

    let checkBoxIndex = columns.findIndex(c => c.dataField === 'checkBox' && c.isDummyField === true)
    if (columns[checkBoxIndex]) {

      columns[checkBoxIndex].formatter = this.checkBoxFormatter
    }
    const { page } = this.pagination
    let pagination = paginationFactory(
      {
        sizePerPageRenderer: this.sizePerPageRenderer,
        page,
        sizePerPage: this.pagination.limit,
        totalSize: totalCount,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        show: true,
        onPageChange: (page, sizePerPage) => {
          this.pageChange(page)
        }
      }
    )
    console.log('searchField', this.state.searchField)

    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : ''}
        <Loader loader={isLoading} />
        <ModalComponent close={() => this.closeAssetsModal()}
          callback={() => this.closeAssetsModal()}
          show={assetsModel.isOpen}
          title={<span><b>Campaign Assets </b></span>}
          message={<CampaignAssets data={assetsModel.data} />}
          hideCancelBtn={true}
          action={"Close"}
          actionType={'danger'}
          size={"lg"}
          key="assets"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0 ">
                    <span>
                      <Icon className="heading-icon ">{icon}</Icon>
                    </span>
                    {/* {capitalize(prefix.toLowerCase())} {capitalize(pluralize(entityType.toLowerCase()))} */}

                    {heading && heading == 'branch' ?
                      `${capitalize(heading.toLowerCase())}es` :
                      heading == 'campaignTemplate' ? 'Campaign Templates' :
                        heading == 'activities' ? 'Activities' :
                          heading == 'ingestionLog' ? 'Ingestion Logs' :
                            heading == 'groupBrand' ? 'Groups' : `${capitalize(heading.toLowerCase())}s`}
                    {/* {capitalize(heading.toLowerCase())} */}
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              {heading != 'ingestionLog' &&
                <div className="mt-4 container-fluid">
                  <div className="row">
                    <div className="col-md-4" style={{ height: 67 }}>
                      <div className="input-group mb-3">
                        <input type="text" className="form-control" onChange={this.handleInputChange} placeholder={globalsearchPlaceHolder ? globalsearchPlaceHolder : ''} />
                        <div className="input-group-append">
                          <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                        </div>
                      </div>
                      {searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                    </div>
                  </div>
                </div>
              }
              <div className="content mb-3">
                {
                  this.hasEditPermission()
                    ?
                    // <React.Fragment >
                    <div className='d-flex'>
                      {heading != 'activities' && heading != 'ingestionLog' ? <button className="btn btn-primary mb-3" style={{ verticalAlign: 'bottom' }} onClick={() => this.setShowAddNewModal()}>
                        Add New
                        <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">add_circle_outline</Icon>
                      </button> : ''}

                      {heading == 'brand' ? <button className="btn btn-primary mb-3 m-l-5" style={{ verticalAlign: 'bottom' }} onClick={() => this.showHidebulkBrandUploadModal(true)}>
                        Upload
                        <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">upload_file</Icon>
                      </button> : ''}

                      {/* {
                        uploadCSV
                          ? (
                            <button className="btn btn-primary mb-3 ml-3" style={{ verticalAlign: 'bottom' }} onClick={this.setShowUploadModal}>
                              Upload CSV
                              <span className="cursor-pointer align-middle ml-2" >
                                <Icon className="ml-2" style={{ cursor: 'pointer', verticalAlign: 'bottom' }} fontSize="small">cloud_upload</Icon>
                              </span>
                            </button>
                          ) : ''
                      } */}
                      {heading && heading == 'branch' ?
                        <div

                          // className={selectedArray && selectedArray.length > 1 ? 'pl-3' : 'd-none'}
                          className='ml-auto'
                          style={{ width: '13%' }}>
                          <Select
                            options={status}
                            onChange={this.changeStatus}
                            value={selectedStatus}
                            className='action_Selecter'
                          />
                        </div> : null}

                      {heading && heading == 'brand' ?
                        <div
                          // className={selectedArray && selectedArray.length > 1 ? 'pl-3' : 'd-none'}
                          className='ml-auto m-r-10'
                          style={{ width: '15%' }}>
                          <Select
                            options={whitelabels}
                            onChange={this.onChangeWhiteLabel}
                            value={selectedWhiteLabel}
                            className='action_Selecter'
                          />
                        </div> : null}

                      {heading && heading == 'brand' && selectedWhiteLabel.phaseList &&
                      selectedWhiteLabel.phaseList.length > 0 ?
                        <div
                          // className={selectedArray && selectedArray.length > 1 ? 'pl-3' : 'd-none'}
                          className='ml-auto m-r-10 m-l-0'
                          style={{ width: '15%' }}>
                          <Select
                            options={selectedWhiteLabel.phaseList}
                            onChange={this.onChangeAmexPhase}
                            value={selectedAmexPhase}
                            className='action_Selecter'
                          />
                        </div> : null}

                      {heading && heading == 'brand' ?
                        <div
                          // className={selectedArray && selectedArray.length > 1 ? 'pl-3' : 'd-none'}
                          className='ml-auto m-l-0'
                          style={{ width: '15%' }}>
                          <Select
                            options={distributorList}
                            onChange={this.onChangeDistributor}
                            value={selectedDistributor}
                            className='action_Selecter'
                          />
                        </div> : null}

                    </div>

                    // {/* </React.Fragment> */}
                    : ''
                }
                {/* <PaginationProvider
  pagination={ paginationFactory(options) }
>
  {
    ({
      paginationProps,
      paginationTableProps
    }) => (
      <div>
        <SizePerPageDropdownStandalone
          { ...paginationProps }
        />
        <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  data={this.state.tableData}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                  // pagination={paginationFactory()}

                />
      </div>
    )
  }
</PaginationProvider> */}
                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  key='id'
                  data={items}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                // pagination={paginationFactory()}

                />
                <ModalComponent close={this.onCloseDeleteModal}
                  callback={this.handleDeleteEntity}
                  show={this.state.showDeleteModal}
                  title={<span>Delete <b>{heading == "groupBrand" ? "Group" : heading}</b></span>}
                  message={<span>Are you sure you want to delete the <b>{heading == "groupBrand" ? "Group" : heading} {heading === 'brand' && ', all related branches would be deleted, do you want to continue'}</b> ?</span>}
                  action={"Delete"}
                  actionType={'danger'}
                />
                <ModalComponent close={this.onCloseSendMailModal}
                  callback={this.handleSendMail}
                  show={this.state.showSendMailModal}
                  message={<span>{`Are you sure you want to send mail`}</span>}
                  action={"Confirm"}
                  actionType='success'
                  title={'Confirmation Mail'}
                />
                {heading != 'activities' ?
                  <ModalComponent close={this.onCloseEditModal}
                    callback={this.submitForm}
                    show={this.state.showEditModal}
                    title={`Edit ${heading == 'campaignTemplate' ? 'Campaign Template' :
                      heading == 'groupBrand' ? 'Group' : capitalize(heading)}`}
                    message={<EntityForm
                      initialValues={this.state.selectedEntity}
                      prefix={prefix}
                      onSubmit={this.onSubmitEdit}
                      formikRef={this.formikRef}
                      formType='edit'
                    />}
                    // size={heading=='campaignTemplate'?'lg':'sm'}
                    action={"Save"}
                  />
                  : null}
                {heading != 'activities' ?
                  <ModalComponent close={this.onCloseAddNewModal}
                    callback={this.submitForm}
                    show={this.state.showAddNewModal}
                    title={`Add New ${heading == 'campaignTemplate' ? 'Campaign Template' :
                      heading == 'groupBrand' ? 'Group' : capitalize(heading)}`}
                    message={<EntityForm
                      formikRef={this.formikRef}
                      onSubmit={this.onSubmitAddNew}
                      prefix={prefix}
                    />}
                    action={"Add"}
                  // size={heading=='campaignTemplate'?'lg':'sm'}
                  />
                  : null}
                {heading == 'activities' ?
                  <ModalComponent close={this.onCloseAddNewModal}
                    callback={this.submitForm}
                    show={this.state.showAddNewModal}
                    title={`Add New ${heading == 'campaignTemplate' ? 'Campaign Template' : capitalize(heading)}`}
                    message={<EntityForm
                      formikRef={this.formikRef}
                      onSubmit={this.onSubmitAddNew}
                      prefix={prefix}
                    />}
                    action={"Add"}
                  // size={heading=='campaignTemplate'?'lg':'sm'}
                  />
                  : null}

                <ModalComponent close={this.onCloseUploadModal}
                  callback={this.submitForm}
                  show={this.state.showUploadModal}
                  title={`Upload CSV file for ${capitalize(heading)}`}
                  message={<EntityUploadForm formikRef={this.formikRef} onSubmit={this.onSubmitUpload} prefix={prefix} />}
                  isDisabled={this.state.isSubmitDisabled}
                  action={"Upload"}
                />
                {/* show merchant approval modall */}

                <ModalComponent close={this.onCloseApproveModal}
                  callback={this.submitForm}
                  show={this.state.showApproveModal}
                  title={`${approvalAction}`}
                  message={CommentsForm ? <CommentsForm
                    formikRef={this.formikRef}
                    onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  /> : null}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Confirm"}
                  actionType={approvalAction == 'Approve' ? 'success' : 'danger'}
                />
                {/* session expiry modal */}
                <ModalComponent close={this.onCloseDeleteModal}
                  callback={this.sessionExpired}
                  // show={this.state.sessionExpiryModal}
                  show={false}
                  title={`Session Expired`}
                  message={<span>Session Expired , Please Login Again</span>}
                  action={"Go to Login Page"}
                  actionType={'success'}
                  hideCancelBtn={true}
                />
                {/* show view modall */}
                <ModalComponent close={e => this.setState({ openViewModal: false })}
                  callback={this.onCloseViewModal}
                  show={this.state.openViewModal}
                  title={`${heading == 'branch' ? 'Branch' : heading == 'activities' ? 'Activity' : ''} View`}
                  message={<ViewModal
                    onRef={(ref) => this.viewModalRef = (ref)}
                    // onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  />}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Edit"}
                  actionType={'primary'}
                  size='lg'
                  cancelText={'Close'}
                />
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                />

                {heading == 'brand' && this.state.isOpenbulkBrandUploadModal ?
                  <BulkBrandUploadModal close={() => this.showHidebulkBrandUploadModal(false)}
                    show={this.state.isOpenbulkBrandUploadModal}
                    callback={(data) => this.uploadedFile(data)}
                    whitelabels={this.state.whitelabels}
                    tempDistributorList={this.state.tempDistributorList}
                  // bulkBrandUploadModalData={bulkBrandUploadModalData}
                  />
                  : null}

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state, ownProps) => ({
    user: state.user,
    sample: state,
    items: state[pluralize(ownProps.heading)].items,
    isLoading: state[pluralize(ownProps.heading)].isLoading,
    page: state[pluralize(ownProps.heading)].page,
    totalCount: state[pluralize(ownProps.heading)].totalCount,
    sizePerPage: state[pluralize(ownProps.entityType)].sizePerPage,
    filters: state[pluralize(ownProps.entityType)].filters,
  }),
  (dispatch, ownProps) => ({
    getEntityData: bindActionCreators(getEntityAction(ownProps.heading), dispatch),
    uploadCSVForEntity: bindActionCreators(uploadCSVForEntityAction, dispatch),
    createEntity: bindActionCreators(createEntityAction, dispatch),
    createEntityMetadata: bindActionCreators(createEntityMetadataAction, dispatch),
    deleteEntity: bindActionCreators(deleteEntityAction, dispatch),
    confirmMerchant: bindActionCreators(confirmMerchantAction, dispatch),
    sendMailconfirm: bindActionCreators(sendMail, dispatch),
    editEntity: bindActionCreators(editEntityAction(ownProps.entityType), dispatch),
    setEntityPage: bindActionCreators(setEntityPageAction(ownProps.heading), dispatch),
    setEntitySort: bindActionCreators(setEntitySortAction(ownProps.entityType), dispatch),
    setEntitySearch: bindActionCreators(setEntitySearchAction(ownProps.entityType), dispatch),
    setEntityFilters: bindActionCreators(setEntityFiltersAction(ownProps.entityType), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
    multiAction: bindActionCreators(multiAction, dispatch),
    createUser: bindActionCreators(createGroupUserAction, dispatch),
    getUserDetails: bindActionCreators(getUserDetailsAction, dispatch),
    getDistributorList: bindActionCreators(getDistributorListAction, dispatch),
    getWhitelabels: bindActionCreators(getWhitelabels, dispatch)
  })
)(EntityList));
