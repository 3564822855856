import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';

const Modal = ({
    close,
    show,
    size = "md",
    cancelText = "Cancel",
    centered = false,
    ingestionLogList
}) => {
    console.log("View Last txn Modal   :    "+JSON.stringify(ingestionLogList))
    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> Last Transaction </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>

                <div className="row col-12 form distributor-modal">
                    <div className="row col-12 form distributor-box">
                        <table className="table table-bordered">
                            <thead>
                                <tr>
                                    <td> Distributors Name </td>
                                    {/* <td> Last Updated </td> */}
                                    {/* <td> Next Update </td> */}
                                    <td> Last Transaction </td>
                                </tr>
                            </thead>
                            <tbody>
                                {ingestionLogList && ingestionLogList.map(row => {
                                    return (
                                        <>
                                            {row.uploadedDate &&
                                                <tr className="distributors-row">
                                                    <td> {row.data_source == "Fidel" ? "NextJump" : 
                                                      row.data_source == "LUX" ? "Mastercard" : row.data_source}  </td>
                                                    {/* <td> {row.uploadedDate}  </td> */}
                                                    {/* <td> {row.next_ingestion_date} </td> */}
                                                    <td> {new Date(row.uploadedDate).toISOString().split('T')[0]} </td>
                                                </tr>
                                            }
                                        </>
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </ModalComponent.Body>
            <ModalComponent.Footer>
                <Button variant="default" onClick={close}>
                    {cancelText}
                </Button>
            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default Modal