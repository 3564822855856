import axiosMethodRequest from '../../config/service'
import { CREATE_USER } from '../../redux/newBrandBranch/actionTypes'
export const createUserApiCall = (data, from) => {
    return dispatch => {
        return axiosMethodRequest('POST', "users/createMultipleUser", data).then((response) => {
            if (from != "bulkUpload" && response && response.data && response.data.respCode) {
                dispatch({
                    type: CREATE_USER,
                    payload: { data: response.data, error: {}, loader: false }
                })
            } else if (from != "bulkUpload" && response && response.data && response.data.errorCode) {
                dispatch({
                    type: CREATE_USER,
                    payload: { error: response.data, data: "", loader: false }
                })
            }
        })
    }
}

export const createUserBulkUploadApiCall = (data, from, callBack) => {
    return dispatch => {
        return axiosMethodRequest('POST', "users/createMultipleUser", data).then((response) => {
            if (from != "bulkUpload" && response && response.data && response.data.respCode) {
                dispatch({
                    type: CREATE_USER,
                    payload: { data: response.data, error: {}, loader: false }
                })
            } else if (from != "bulkUpload" && response && response.data && response.data.errorCode) {
                dispatch({
                    type: CREATE_USER,
                    payload: { error: response.data, data: "", loader: false }
                })
            }
            callBack();
        })
    }
}

export const createGroupUserAction = (data, callback) => {
    // return (data, callback) => {
        return dispatch => {
            return axiosMethodRequest('POST', "users/createMultipleUser", data).then((response) => {
                if (response && response.data && response.data.respCode) {
                    callback(response.data);
                } else if (response && response.data && response.data.errorCode) {
                    callback(response.data);
                }
            })
        }
    // }
}

export const createGroupAction = (data, callback) => {
        return dispatch => {
            return axiosMethodRequest('POST', "groupBrand", data).then((response) => {
                callback(response.data);
            })
        }
}

export const getAllCampaignAction = (query, callback) => {
    return dispatch => {
        return axiosMethodRequest('GET', 'campaigns/getAllCampaign' + query).then((response) => {
            callback(response.data);
        })
    }
}