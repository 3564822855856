import axiosMethodRequest from '../../config/service'
import { CREATE_BRANCH } from '../../redux/newBrandBranch/actionTypes'
export const CreateBranchApiCall = (data, from) => {
    return dispatch => {
        return axiosMethodRequest('POST', "merchants/createMultiple", data).then((response) => {
            if (from != "bulkUpload" && response && response.data && response.data.respCode) {
                dispatch({
                    type: CREATE_BRANCH,
                    payload: { data: response.data, error: {}, loader: false }
                })
            } else if (from != "bulkUpload" && response && response.data && response.data.errorCode) {

                dispatch({
                    type: CREATE_BRANCH,
                    payload: { error: response.data, data: "", loader: false }
                })
            }
        })
    }
}

export const updateBranchWhiteLabelAction = (data, callback) => {
    return dispatch => {    
        return axiosMethodRequest('POST', "merchants/updateMerchantWhiteLabel", data).then((response) => {
            callback(response);
        })
    }
}