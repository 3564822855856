import React, { Component } from "react";
import StatCard from '../../components/CampaignOverview/StatCard'
import BarChart from '../../components/Charts/BarChart'
import DoughnutChart from '../../components/Charts/DoughnutChart'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getCampaignOverviewAction } from '../../redux/actions'
import StatsCardConfig from '../../components/CampaignOverview/statsconfig'
import moment from 'moment'

class CampaignOverviewPrintPage extends Component {
  printInitiated = false

  constructor(props) {
    super(props);
    this.state = {
      dateValue: '',
      dateLabel: 'Date'
    }
  }


  componentDidMount() {
    let { getCampaignOverview, match } = this.props;
    let { id, start_date, end_date } = match.params
    if (id > 0)
      getCampaignOverview({ id })
    else {
      // Campaign overview aggregates
      getCampaignOverview({ start_date, end_date })
    }
    document.body.classList.add('print')
  }
  componentWillUnmount() {
    document.body.classList.remove('print')
  }
  componentDidUpdate(prevProps, nextProps) {
    if (this.props.campaignOverview && !this.printInitiated) {
      this.printInitiated = true;
      document.title = `Campaign Overview - ${this.props.campaignOverview.name}`
      let dateLabel, dateValue;
      let { campaignOverview } = this.props
      let { campaignOverview: nCampaignOverview } = nextProps
      if (!campaignOverview || nCampaignOverview === campaignOverview)
        return;
      if (campaignOverview.count > 1) {
        dateValue = campaignOverview.count
        dateLabel = 'Campaigns'
      } else {
        let current = moment()
        let start_date = moment(campaignOverview.start_date, 'YYYY-MM-DD')
        let end_date = moment(campaignOverview.end_date, 'YYYY-MM-DD')
        if (current.diff(start_date) >= 0 && current.diff(end_date) <= 0) {
          // current campaign
          dateValue = end_date.diff(current, 'days')
          dateLabel = 'Days Left'
        } else if (current.diff(start_date) < 0) {
          // Future
          dateValue = current.diff(start_date, 'days')
          dateLabel = 'Days To Start'
        } else if (current.diff(end_date) > 0) {
          // Past
          dateValue = start_date.format('MM/YY')
          dateLabel = 'Date'
        }
      }
      this.setState({ dateValue, dateLabel }, () => {
        window.print()
      })

    }
  }
  render() {
    let { campaignOverview } = this.props
    let statisticsConfig = StatsCardConfig.statistics
    let insightsConfig = StatsCardConfig.insights
    return (
      campaignOverview
        ? (
          <div className="content pt-5">
            <div className="container-fluid">
              <div className="row">
                <h1 className="text-center col-12">{campaignOverview.name}</h1>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h2>Statistics</h2>
                </div>
                {
                  campaignOverview.statistics
                    ? (
                      <React.Fragment>
                        {
                          campaignOverview.statistics.map((stat, i) => {
                            return (
                              <div className="col-xl-4 col-lg-4 col-md-4" key={`statistics-${i}`}>
                                <div className="card pill">
                                  <div className="card-body text-center pr-0 pl-0">
                                    <StatCard {...statisticsConfig[stat.name]} value={stat.value} />
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        }
                        <div className="col-xl-4 col-lg-4 col-md-4" key={`statistics-date`}>
                          <div className="card pill">
                            <div className="card-body text-center pr-0 pl-0">
                              <StatCard {...statisticsConfig['date']} label={this.state.dateLabel} value={this.state.dateValue} type="string" />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : ''
                }
              </div>
              <div className="row">
                <div className="col-md-12">
                  <h2>Insights</h2>
                </div>
                {
                  campaignOverview.insights && campaignOverview.insights.map((insight, i) => {
                    return (
                      <div key={`insights-${i}`} className="col-xl-6 col-lg-6 col-md-6">
                        <div className="card pill">
                          <div className="card-body text-center">
                            <StatCard {...insightsConfig[insight.name]} value={insight.value} />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <br />
              <br />
              <div className="row last-row">
                <div className="col-md-12">
                  <h2>Performance</h2>
                </div>
                {
                  campaignOverview.performance && Object.keys(campaignOverview.performance).map((key, i) => {
                    return (
                      <div key={i} className={classNames("col-md-6", { 'break-before': i === 2 })}>
                        <div className="card">
                          <div className="header">
                            {capitalize(key)}
                          </div>
                          <div className="content">
                            <BarChart
                              data={campaignOverview.performance[key]}
                              options={{
                                animation: { duration: 0 }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className="row last-row">
                <div className="col-md-12">
                  <h2>Demographic</h2>
                </div>
                {
                  campaignOverview.demographic && Object.keys(campaignOverview.demographic).map((key, i) => {
                    return (
                      <div key={i} className="col-md-6">
                        <div className="card">
                          <div className="header">
                            {capitalize(key)}
                          </div>
                          <div className="content">
                            <DoughnutChart
                              data={campaignOverview.demographic[key]}
                              height={200}
                              options={{
                                animation: { duration: 0 }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        )
        : ''
    )
  }
}
export default connect(
  state => ({
    campaignOverview: state.campaignOverview.data
  }),
  dispatch => ({
    getCampaignOverview: bindActionCreators(getCampaignOverviewAction, dispatch),
  })
)(CampaignOverviewPrintPage)

