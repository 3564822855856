import React from 'react'
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';

const Modal = ({
    close,
    show,
    callback,
    actionType = "success",
    size = "md",
    centered = false,
    data = {}
}) => {
    return (
        <ModalComponent show={show} onHide={close} size={size} centered={centered}>
            <ModalComponent.Header closeButton>
                <ModalComponent.Title> Approve Campaign </ModalComponent.Title>
            </ModalComponent.Header>
            <ModalComponent.Body>
                <div className="row col-12">
                    <div className="row col-6 m-t-5">  <b> Brand Name : </b>&nbsp;&nbsp;{data.brandName} </div>
                    <div className="row col-6 m-t-5"> <b> Offer : </b>&nbsp;&nbsp;{data.discountPercent}% </div>
                    <div className="row col-6 m-t-5"> <b> Fees : </b>&nbsp;&nbsp;{data.successFee}% </div>
                    <div className="row col-6 m-t-5"> <b> Period : </b>&nbsp;&nbsp;{data.period} </div>
                    <div className="row col-6 m-t-5"> <b> Recurring Campaign : </b>&nbsp;&nbsp;Yes </div>
                </div>
            </ModalComponent.Body>
            <ModalComponent.Footer>

                <Button variant={actionType} onClick={callback} >
                    Approve
                </Button>

            </ModalComponent.Footer>
        </ModalComponent>
    )
}

export default Modal

