import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString } from 'yup';
import Loader from '../Loader';
import { Link } from 'react-router-dom';
// import { setLoaderAction } from '../../redux/actions/authActions'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class LoginForm extends Component {
  state = {
    loader: true
  }
  initialValues = {
    email: '',
    password: '',
  }

  validationSchema = YupObject({
    email: YupString()
      .required('Email is required')
      .email('Please provide a valid email'),
    password: YupString()
      .required('Password is required')

  })


  getForm = props => {
    let { status, isSubmitting } = props;
    return (
      <Form className="form">
        <div className="form-group">
          <label>Email</label>
          <Field name="email" className="form-control" disabled={isSubmitting} autocomplete="off" />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Password</label>
          <Field name="password" className="form-control" type="password" disabled={isSubmitting} />
          <ErrorMessage name="password" component="label" className="error" />
        </div>
        {/* <div className=" text-left mb-2">
          <Link to='/forgotpassword' className="text-primary">Forgot Password</Link>
        </div> */}

        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )


        }
        <div className="form-group text-center">
          <button className="btn btn-info btn-fill btn-wd" type="submit" disabled={isSubmitting}>Login</button>
        </div>

      </Form>
    )
  }

  render() {
    let { initialValues, onSubmit, loader } = this.props;

    return (
      <div>
        <Loader loader={loader} />
        <Formik
          initialValues={initialValues || this.initialValues}
          render={props => this.getForm(props)}
          onSubmit={onSubmit}
          validationSchema={this.validationSchema}
        />
      </div>

    )
  }
}

// Lets do the mapping here going forward we'll split if required


const mapDispatchToProps = (dispatch) => {
  return {

    // loaderMethod: bindActionCreators(setLoaderAction, dispatch)
  }
}

export default connect(null, mapDispatchToProps)(LoginForm);











