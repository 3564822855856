import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Icon from '@material-ui/core/Icon';
import { GetPublisherActionCenterApicall } from '../../services/brandActionCenter/createBrandActionCenter';
import { brandActionCenterSelectors } from '../../redux/brandActionCenter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GetSessionValue } from '../../utils/sessionStorage';
import moment from 'moment'
import config from '../../config/config';
import ApproveBrandFromPublisherModal from '../../components/ApproveBrandFromPublisherModal';
import Select from 'react-select';

export default function PublisherActionCenter() {

    const [userDetails, setUserDetails] = useState({});
    const [distributorDetail, setDistributorDetail] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const [publisherBrandActionList, setPublisherBrandActionList] = useState([]);
    const [approveBrandData, setApproveBrandData] = useState({ showApproveBrandModal: false, selectedData: {} });
    const [selectedStatus, setSelectedStatus] = useState();
    const [statusList, setStatusList] = useState([
        { label: 'All', value: 'All' },
        { label: 'New', value: 'New' },
        { label: 'Onboarded', value: 'Onboarded' }
    ]);

    const [pageAndLimitValue, setPageAndLimitValue] = useState({
        limit: 10,
        page: 1,
        totalCount: 10
    });
    const [pagination, setPagination] = useState();
    const dispatch = useDispatch();

    const getPublisherBrandActionCenterState = useSelector(
        brandActionCenterSelectors.getPublisherBrandActionCenterState
    );

    const sizePerPageRenderer = ({
        options,
        currSizePerPage,
        onSizePerPageChange
    }) => (
        <div className="btn-group pt-1" role="group">
            <div className="dropdown">
                <button className="btn btn-primary dropbtn">{pageAndLimitValue.limit}  <ExpandMoreIcon /></button>
                <div className="dropdown-content">
                    {
                        options.map((option, index) => {
                            const isSelect = currSizePerPage === `${option.page}`;
                            return (
                                <a href="#" onClick={() => setPageLimit(option.page)} key={index}> {option.text}</a>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    )

    useEffect(() => {
        let userDetails = GetSessionValue('user');
        setUserDetails(userDetails);
        var distr = {};
        if (userDetails.role == "bank") {
            distr = GetSessionValue('distributor');
            setDistributorDetail(distr)
        }
        setTimeout(() => {
            getPublisherActionCenter(distr.distributors_name);
        }, 2000);
        return () => {
            setPublisherBrandActionList([]);
        };
    }, []);

    const getPublisherActionCenter = (dis_name) => {
        let distributors_name = dis_name || distributorDetail.distributors_name;
        let filterCriteria = { limit: pageAndLimitValue.limit, page: pageAndLimitValue.page, distributors_name: distributors_name };
        if (selectedStatus && selectedStatus.value != "All") {
            filterCriteria.status = selectedStatus.value;
        }
        setIsLoader(true);
        dispatch(GetPublisherActionCenterApicall(filterCriteria));
    }

    const pageChange = (page) => {
        let pageData = pageAndLimitValue;
        pageData.page = page
        setPageAndLimitValue(pageData);
        getPublisherActionCenter("");
    }

    const setPageLimit = (limit) => {
        let pageData = pageAndLimitValue;
        pageData.limit = limit
        setPageAndLimitValue(pageData);
        getPublisherActionCenter("");
    }

    useEffect(() => {
        if (getPublisherBrandActionCenterState && getPublisherBrandActionCenterState.data.length) {
            setPublisherBrandActionList(getPublisherBrandActionCenterState.data);
            setPageAndLimitValue(getPublisherBrandActionCenterState.pagination);
            setPagination(paginationFactory(
                {
                    sizePerPageRenderer: sizePerPageRenderer,
                    page: getPublisherBrandActionCenterState.pagination.page,
                    sizePerPage: getPublisherBrandActionCenterState.pagination.limit,
                    totalSize: getPublisherBrandActionCenterState.pagination.totalCount,
                    hideSizePerPage: false,
                    hidePageListOnlyOnePage: true,
                    showTotal: true,
                    alwaysShowAllBtns: true,
                    show: true,
                    onPageChange: (page, sizePerPage) => {
                        pageChange(page)
                    }
                }
            ));
            setIsLoader(false);
        } else if (getPublisherBrandActionCenterState && getPublisherBrandActionCenterState.data.length == 0) {
            setIsLoader(false);
        }

        return () => {
            setPublisherBrandActionList([]);
        };

    }, [getPublisherBrandActionCenterState && getPublisherBrandActionCenterState.data]);

    const downloadFile = (rowData) => {
        window.open(`${config.apiUrl}brandActionCenter/downloadPublisherFile/${rowData._id}`, '_blank').focus();
    }

    const onboardedBrand = () => {
        setApproveBrandData({ showApproveBrandModal: false, selectedData: {} });
        getPublisherActionCenter("");
    }

    useEffect(() => {
        if (selectedStatus && selectedStatus.value) {
            getPublisherActionCenter("");
        }
    }, [selectedStatus && selectedStatus.value]);

    const onChangeStatus = (event) => {
        setSelectedStatus(event);
    }


    const columns = [
        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Actions',
            classes: 'vhalign',
            formatter: (cell, row) => {
                return (
                    <React.Fragment>
                        {row.status == "New" && userDetails.role == "bank" &&
                            <div className="future-action m-r-5" style={{ display: 'inline-block', cursor: 'pointer' }}
                                onClick={() => setApproveBrandData({ showApproveBrandModal: true, selectedData: JSON.parse(JSON.stringify(row)) })} >
                                <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Onboard">done</Icon>
                            </div>
                        }
                        <div className="future-action m-r-5" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => downloadFile(row)}>
                            <Icon style={{ fontSize: "1.5rem" }} className="text-info" data-toggle="tool-tip" title="Download Brand Detail File">download</Icon>
                        </div>
                    </React.Fragment>
                )
            }
        },
        {
            dataField: 'status',
            sort: false,
            text: 'Status',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.status;
            }
        },
        {
            dataField: 'requestCode',
            sort: false,
            text: 'Request Code',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.requestCode;
            }
        },
        {
            dataField: 'requestedDate',
            sort: false,
            text: 'Requested Date',
            classes: 'vhalign',
            formatter: (col, row) => {
                if (row.requestedDate) {
                    row.requestedDate = moment(new Date(row.requestedDate)).format('YYYY-MM-DD hh:mm A')
                };
                return row.requestedDate;
            }
        },
        {
            dataField: 'effectiveFrom',
            sort: false,
            text: 'Effective From',
            classes: 'vhalign',
            formatter: (col, row) => {
                if (row.effectiveFrom) {
                    row.effectiveFrom = moment(new Date(row.effectiveFrom)).format('YYYY-MM-DD hh:mm A')
                };
                return row.effectiveFrom;
            }
        }
    ]

    if (userDetails.role != "bank") {
        columns.splice(1, 0, {
            dataField: 'distributors_name',
            sort: false,
            text: 'Publisher',
            classes: 'vhalign',
            formatter: (col, row) => {
                return row.distributors_name;
            }
        });
    }

    return (
        <>
            <div>
                <div className="row action-center">
                    <div className="col-md-12">
                        <div className="heading">
                            <div className="row">
                                <div className="col-md-8 d-flex align-items-center">
                                    <h2 className="m-0 ">
                                        <span>
                                            <Icon className="heading-icon">pending_actions</Icon>
                                            Action Center
                                        </span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="card">

                            <div className="row col-12 m-t-15 action-center-search">
                                <div className="col-3 h-35">
                                    {userDetails.role == "bank" &&
                                        <button className="btn btn-primary"
                                            onClick={() => setApproveBrandData({ showApproveBrandModal: true, selectedData: {} })}>  Onboard All Brand  </button>
                                    }
                                </div>
                                <div className="col-3 action-center-search-box">
                                    <Select options={statusList}
                                        onChange={(event) => { onChangeStatus(event) }}
                                        value={selectedStatus}
                                        className='action_Selecter' />
                                </div>
                            </div>

                            <div className="col-12 m-t-25 m-b-25">
                                <BootstrapTable
                                    bootstrap4={true}
                                    keyField='id'
                                    data={publisherBrandActionList}
                                    columns={columns}
                                    wrapperClasses="table-responsive"
                                    remote
                                    // onTableChange={this.handleTableChange}
                                    noDataIndication={() => <span>No data found.</span>}
                                    pagination={pagination}
                                />
                            </div>


                        </div>
                    </div>

                </div>

                {isLoader &&
                    <div className="loading">Loading...</div>}

                {approveBrandData.showApproveBrandModal &&
                    <ApproveBrandFromPublisherModal
                        close={() => { setApproveBrandData({ showApproveBrandModal: false, selectedData: {} }) }}
                        callback={() => { onboardedBrand() }}
                        show={approveBrandData.showApproveBrandModal}
                        modalData={approveBrandData.selectedData}
                    />
                }

            </div>

        </>
    )
};
