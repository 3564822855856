import React, { Component } from 'react';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'rc-slider/assets/index.css';
import 'react-datetime/css/react-datetime.css'
import { Switch } from 'react-router-dom'
import PublicRoute from './utils/components/PublicRoute'
import PrivateRoute from './utils/components/PrivateRoute'
import PublicPages from './pages/PublicPages/PublicPages.component'
import Main from './pages/Main'
import CKEditor from 'ckeditor4-react';

class App extends Component {

  componentDidMount() { }

  render() {
    return (
      <div className="wrapper">
        <Switch>
          <PrivateRoute path='/user' to="/login" component={Main} />
          <PublicRoute path='/' to="/user" component={PublicPages} />
        </Switch>
      </div>
    );
  }
}

export default App;
