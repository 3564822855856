import React, { Component } from "react";

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { BrandForm } from '../../components/Forms/Brand'
import { UploadForm } from '../../components/Forms/FileUpload'
import EntityList from '../EntityList'
import { getEntityForSelectAction } from '../../redux/actions'
import capitalize from 'lodash/capitalize'
import config from '../../config/config'
import placeHolderImg from '../../assets/images/placeholder150.png'
import { Roles } from "../../utils/constants/constant";

class Brands extends Component {

  columns = [
    {
      dataField: 'actions',
      isDummyField: true,
      text: 'Actions',
      classes: 'vhalign'
    },
    // {
    //   dataField: 'photo',
    //   text: 'Brand Image',
    //   classes: 'vhalign',
    //   formatter: cell => {
    //     if (cell) {
    //       return <img onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImg }} src={`${config.imgUrl}brands/${cell}`} className='dropdown__img' />
    //     }
    //   }
    // },
    {
      dataField: 'name',
      sort: true,
      text: 'Brand Name',
      classes: 'vhalign',
      formatter: (col, row) => {
        return capitalize(col)
      }
    },

    {
      dataField: 'registeredAddress',
      sort: true,
      text: 'Registered Address',
      classes: 'vhalign'
    },
    // {
    //   dataField: 'description',
    //   sort: true,
    //   text: 'Description',
    //   classes: 'vhalign'
    // },
    {
      dataField: 'website',
      sort: true,
      text: 'Website',
      classes: 'vhalign',
      formatter: (col, row) => {
        return <a href={`https://${col}`} target="_blank" style={{ textDecoration: 'underline', color: 'blue' }}>{col}</a>
      }
    },
    {
      dataField: 'created',
      sort: true,
      text: 'Created',
      classes: 'vhalign',
      formatter: (col, row) => {
        if (col) {
          let date = new Date(col);
          // adjust 0 before single digit date
          let dd = ("0" + date.getDate()).slice(-2);
          // current month
          let mm = ("0" + (date.getMonth() + 1)).slice(-2);
          // current year
          let yy = date.getFullYear();
          return `${dd}/${mm}/${yy}`
        }
      }
    }
  ]

  componentDidMount() {
    let { getEntityForSelect } = this.props;
    getEntityForSelect('bank')
  }

  render() {
    if (this.props.user.role == Roles.bank) {
      this.columns = this.columns.filter(res => !["actions"].includes(res.dataField))
    }
    return <EntityList
      entityType="brand"
      preset={{ type: 'brand' }}
      icon="domain"
      heading="brand"
      prefix="brand"
      EntityForm={BrandForm}
      columns={this.columns}
      apiUrl="brands"
      uploadCSV={true}
      EntityUploadForm={UploadForm}
      globalsearchPlaceHolder='Search Brand Name'
    />
  }
}

export default connect(
  state => ({
    user: state.user
  }),
  dispatch => ({
    getEntityForSelect: bindActionCreators(getEntityForSelectAction, dispatch)
  })
)(Brands);

