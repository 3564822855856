import React from 'react';
import Button from 'react-bootstrap/Button';
import { Modal as ModalComponent } from 'react-bootstrap';
import { useState } from 'react';
import { getUserActivitiesAction } from './../services/brandActionCenter/createBrandActionCenter';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment'
import config from './../config/config';
import BootstrapTable from 'react-bootstrap-table-next';

const Modal = ({
    close,
    show,
    callback,
    size = "xl",
    cancelText = "Cancel",
    centered = false,
    modalData
}) => {

    const dispatch = useDispatch();
    const [activitiyList, setActivitiyList] = useState([]);

    useEffect(() => {
        getUserActivities();
    }, []);

    const getUserActivities = () => {         
        dispatch(getUserActivitiesAction(modalData, response => {
            if(response.respCode) {
                setActivitiyList(response.activities);
            }
        }));
    }

    const columns = [
        {
          dataField: 'created',
          sort: true,
          text: 'Created',
          classes: 'vhalign',
          formatter: (col, row) => {
            if (col) {
              let date = moment(col);
              return date.format(config.dateTimeFormat)
            }
          }
        },
        {
          dataField: 'brandName',
          sort: true,
          text: 'Brand/Group Name',
          classes: 'vhalign'
        },
        {
          dataField: 'contextType',
          sort: true,
          text: 'Context Type',
          classes: 'vhalign'
        },
        {
          dataField: 'userName',
          sort: true,
          text: 'User Name',
          classes: 'vhalign'
        },
        {
          dataField: 'email',
          sort: true,
          text: 'Email',
          classes: 'vhalign'
        },
        {
          dataField: 'desc',
          sort: true,
          text: 'Description',
          classes: 'vhalign'
        },
    
        {
          dataField: 'affectedUser',
          sort: true,
          text: 'Affected Object',
          classes: 'vhalign'
        },
        {
          dataField: 'ipAddress',
          text: 'Ip Address',
          classes: 'vhalign'
        },
        {
          dataField: 'deviceType',
          text: 'Device Type',
          classes: 'vhalign'
        },
        {
          dataField: 'browserName',
          text: 'Browser Name',
          classes: 'vhalign'
        },
        {
          dataField: 'osName',
          text: 'Os Name',
          classes: 'vhalign'
        },
        {
          dataField: 'osVersion',
          text: 'Os Version',
          classes: 'vhalign'
        }
      ]

    return (
        <>

            <ModalComponent show={show} onHide={close} size={size} centered={centered}>
                <ModalComponent.Header closeButton>
                    <ModalComponent.Title>
                        OnBoarding Activitiy
                    </ModalComponent.Title>
                </ModalComponent.Header>
                <ModalComponent.Body>

                    <div className="row col-12 form edit-action-center-modal">

                        <div className="col-12">
                            <BootstrapTable
                                bootstrap4={true}
                                keyField='id'
                                key='id'
                                data={activitiyList}
                                columns={columns}
                                wrapperClasses="table-responsive"
                                remote
                                noDataIndication={() => <span>No data found.</span>}
                            />
                        </div>

                    </div>

                </ModalComponent.Body>
                <ModalComponent.Footer>
                    <Button variant="default" onClick={close}>
                        {cancelText}
                    </Button>

                </ModalComponent.Footer>
            </ModalComponent>

        </>
    )
}

export default Modal