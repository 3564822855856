
import axios from 'axios';
// import mockAPIs from '../mocks/mockAPIs'

import { setUserAction } from '../redux/actions/authActions'
import { removeAll } from 'react-notification-system-redux';
import store from '../redux/store'


// let Axios = mockAPIs(axios)

let Axios = axios.create({
    baseURL: '/api',
    withCredentials: true
})

Axios.interceptors.response.use((response) => {
    return response;
}, error => {
    // Do something with response error
    if (error.response && error.response.status === 401) {
        // console.log('unauthorized, logging out ...');
        store.dispatch(setUserAction(null))
        store.dispatch(removeAll())
    }
    return Promise.reject(error.response);
});

export default Axios;