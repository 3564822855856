export const GET_BRAND_ENTITY_LIST = 'GET_BRAND_ENTITY_LIST';
export const CREATE_BRAND_ENTITY = 'CREATE_BRAND_ENTITY';
export const CREATE_BRAND = 'CREATE_BRAND';
export const CREATE_BRANCH = 'CREATE_BRANCH';
export const GET_BRANCH_ENTITY_LIST = 'GET_BRANCH_ENTITY_LIST';
export const GET_EXISTING_BRANCH_LIST = 'GET_EXISTING_BRANCH_LIST';
export const CLEAR_BRANDBRANCH_DATA = 'CLEAR_BRANDBRANCH_DATA';
export const CREATE_USER = 'CREATE_USER';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_BRANCH = 'DELETE_BRANCH';
export const GET_GROUP_LIST = 'GET_GROUP_LIST';
export const GET_DISTRIBUTORS = 'GET_DISTRIBUTORS';
export const UPLOAD_BRAND = 'UPLOAD_BRAND';
export const GET_UPLOADED_DATE = 'GET_UPLOADED_DATE';