import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef, boolean as YupBool } from 'yup';
import { connect } from 'react-redux'
import { pluralize } from '../../utils/helpers'
import EmailTemplateEditorForm from './EmilTemplateEditorForm';
import CKEditor from 'ckeditor4-react';
import Autosuggest from 'react-autosuggest';
const templateVars = [
    {
        id: 7,
        name: 'Bank'
    },
    {
        id: 8,
        name: 'Business Name'
    },
    {
        id: 5,
        name: 'Discount'
    },
    {
        id: 6,
        name: 'Discount %'
    },
    {
        id: 4,
        name: 'Minimum Spend'
    },
    {
        id: 3,
        name: 'Spend'
    },
    {
        id: 1,
        name: 'Valid For'
    },
    {
        id: 2,
        name: 'Valid Until'
    },
    {
        id: 9,
        name: 'Landing Page'
    },
];

const variables = templateVars.map((templateVar) => {
    return {
        name: templateVar.name,
    };
});

//This func, onChange, and onSuggestionSelected/Highlight are the important
//parts. We essentially grab the full input string, then slice down to our 
//autocomplete token and do the same for the search so it filters as you type
const getSuggestions = (value) => {
    const sliceIndex = value
        .trim()
        .toLowerCase()
        .lastIndexOf('<'); //activate autocomplete token
    const inputValue = value
        .trim()
        .toLowerCase()
        .slice(sliceIndex + 1); //+2 to skip over the {{
    const inputLength = inputValue.length;
    //show every template variable option once '{{' is typed, then filter as 
    //they continue to type
    return inputLength === 0
        ? variables
        : variables.filter(
            (variable) => variable.name.toLowerCase().slice(0, inputValue.length) === inputValue
        );
};

const getSuggestionValue = (suggestion) => suggestion.name;
const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;
class CampaignTemplate extends Component {

    state = {
        templateText: '',
        value: '',
        suggestions: getSuggestions('')
    }

    handleShowPassword = () => {
        let { values, setFieldValue } = this.props;
        setFieldValue('editPassword', !values.editPassword)
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this)
        }
        let { setFieldValue, options, values } = this.props
        if (values && values['type'] == 'sms') {
            this.campaignTextHandler(values['campaignTemplateText'])
        }
        let { prefix } = options
        if (options[pluralize(prefix)].length === 1) {
            setTimeout(() => setFieldValue('entityId', options[pluralize(prefix)][0].id), 200)
        }

    }
    campaignTextHandler = (value) => {
        if (value) {
            // let sampleText = data.campaignTemplateText;
            let count = 0;

            for (let i in value) {
                if (value[i] == '&') {
                    count++;
                }
            }

            for (let i = 0; i <= count; i++) {
                value = value.replace('&lt;', '<');
                value = value.replace('&gt;', '>');
            }
            this.setState({ value: value })
        }
    }
    onChange = (event, { newValue }) => {
        this.setState({
            value: newValue
        });
    }

    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: getSuggestions(value),
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        });
    };

    onSuggestionSelected = (event, { suggestionValue }) => {
        const { value } = this.state;
        const sliceIndex = value.lastIndexOf('<') + 1;
        const currentVal = value.slice(0, sliceIndex);
        const newValue = currentVal.concat(suggestionValue) + '>';
        this.setState({ value: newValue });
    };

    onSuggestionHighlighted = ({ suggestion }) => {
        if (!suggestion) {
            return;
        }
        const { value } = this.state;
        const sliceIndex = value.lastIndexOf('<') + 1;
        const currentVal = value.slice(0, sliceIndex);
        const newValue = currentVal.concat(suggestion.name) + '>';
        this.setState({ value: newValue });
    };

    render() {
        let { status, isSubmitting, values, handleChange, handleBlur, options } = this.props;
        let { prefix, mode } = options
        const { value, suggestions } = this.state;
        const inputProps = {
            value: this.state.value,
            onChange: this.onChange,
            type: 'textarea',
            style: { width: '100%' },
            className: 'form-control',
            name: 'campaignTemplateText'
        };
        // values['campaignTemplateText'] = this.state.value;
        return (
            <Form>
                <div className="form-group">
                    <label>Name</label>
                    <Field name="name" className="form-control" disabled={isSubmitting} />
                    <ErrorMessage name="name" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Type</label>
                    <select
                        name="type"
                        className="form-control"
                        defaultValue={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={mode == 'edit'}
                    >
                        <option value="" disabled>Select Type</option>
                        <option value="email" >Email</option>
                        <option value="sms" >Sms</option>
                        <option value="app" disabled>App</option>
                    </select>
                    <ErrorMessage name="type" component="label" className="error" />
                </div>
                <div className="form-group">
                    <label>Template Text</label>
                    {values && values.type == 'email' ?
                        < EmailTemplateEditorForm
                            className="form-control"
                            data={values && values.campaignTemplateText ? values.campaignTemplateText : ''}
                            component={EmailTemplateEditorForm} disabled={isSubmitting} editorData={(e) => {
                                this.setState({ templateText: e })
                                values.emailText = e
                            }}
                        />
                        :
                        <Autosuggest
                            suggestions={this.state.suggestions}
                            onSuggestionSelected={this.onSuggestionSelected}
                            onSuggestionHighlighted={this.onSuggestionHighlighted}
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                        // style={{ width: 200 }}
                        />
                        // <Field name="campaignTemplateText" className="form-control" component={'textarea'} disabled={isSubmitting} id='autocompleteEditor' onLoad={this.handleNamespaceLoaded} onFocus={focus => {
                        //     console.log('focsss', focus)
                        //     // this.handleNamespaceLoaded(focus)
                        // }} />
                    }
                    <label>{'on type \' < \' you can find suggestions'} </label>
                    <ErrorMessage name="emailText" component="label" className="error" />
                </div>
                {
                    status && (
                        <div className="form-group text-center">
                            <label className="error">{status}</label>
                        </div>
                    )
                }
            </Form>
        )
    }
}

export class FormikForm extends Component {
    initialValues = {
        'campaignTemplateText': '',
        'type': '',
        'emailText': ""
    }

    validationSchemaWithoutPassword = YupObject({
        type: YupString()
            .required('Type is required'),
    })

    noop = () => { }

    render() {
        let { initialValues, onSubmit, formikRef = this.noop, brands, banks, prefix } = this.props;
        let defaultValues;
        if (prefix && !initialValues) {
            defaultValues = { ...this.initialValues }
            defaultValues.entityType = prefix
        } else {
            defaultValues = { ...initialValues, password: '', confirmPassword: '' }
        }
        let validationSchema = this.validationSchemaWithoutPassword
        let mode = initialValues ? 'edit' : ''
        return (
            <Formik
                initialValues={defaultValues}
                render={props => <CampaignTemplate
                    {...props}
                    options={{ banks, brands, prefix, mode }}
                    onRef={ref => this.campaignTemplateRef = (ref)}
                /> /*this.getForm(props, {banks, brands, prefix, mode})*/}
                onSubmit={(values, actions) => {
                    console.log('events', values)
                    if (values && values.type == 'sms') {
                        values['campaignTemplateText'] = this.campaignTemplateRef.state.value;
                    }
                    onSubmit(values, actions)
                    console.log('cmapaign remokaeye', this.campaignTemplateRef)
                }}
                validationSchema={validationSchema}
                ref={formikRef}
            />
        )
    }
}

export const CampaignTemplateForm = connect(
    state => ({
        banks: state.selectEntities.banks,
        brands: state.selectEntities.brands
    }),
    null
)(FormikForm)
