
export const arrayObjToObjArray = (array) => {
	return array.reduce((result, item) => {
		result['labels'].push(item.label)
		result['data'].push(item.value)
		return result
	}, {
		labels: [],
		data: []
	})
}

export const formatCampaignOverviewData = (data) => {
	try {
		let performance = Object.keys(data.performance).reduce((result, item) => {
			result[item] = arrayObjToObjArray(data.performance[item])
			return result
		}, {})
		let demographic = Object.keys(data.demographic).reduce((result, item) => {
			result[item] = arrayObjToObjArray(data.demographic[item])
			return result
		}, {})
		return { ...data, performance, demographic }
	} catch (err) {
		return {}
	}

}
