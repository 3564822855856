import React, { Component } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  loginAction,
} from '../../redux/actions/authActions'
import Login from '../../components/Login/Login.component'
import KrowdLogo from '../../assets/images/krowd_logo_login.png';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader'
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceDetect,
  browserName,
  osName, osVersion, mobileModel, deviceType, getUA
} from "react-device-detect";
import { DeleteSessionValue } from "../../utils/sessionStorage";
const publicIp = require('public-ip');
const macaddress = require('macaddress');
const imgStyle = {
  'display': 'block',
  'height': "6rem",
  'textAlign': "center",
  'margin': 'auto',
  'marginTop': '2em',
  marginBottom: '1em'
}
const logoContainerStyle = {
  marginBottom: '2em',
  textAlign: 'center'
}

class LoginPage extends Component {

  state = {
    loader: false,
    deviceInfo: {},
    successLogin: false
  }

  onSubmit = (values, actions) => {
    let deviceInfo = this.state.deviceInfo
    values.deviceInfo = deviceInfo
    this.setState({ loader: true })
    this.props.login(values, (err) => {
      this.setState({ loader: false })
      if (err) {
        this.setState({ loader: false, successLogin: true })
        actions.setSubmitting(false)
        let errorMessage = err;
        actions.setStatus(errorMessage)
        return
      }
    })
  }

  componentDidMount = async () => {
    DeleteSessionValue('sessionExpired')
    await this.setState({ loader: false })
    let deviceInfo = {}
    publicIp.v4().then((res) => {
      deviceInfo.ipAddress = res
    })
    deviceInfo.browserName = browserName
    deviceInfo.osName = osName
    deviceInfo.osVersion = osVersion
    deviceInfo.deviceType = deviceType
    this.setState({ deviceInfo: deviceInfo })
    await macaddress.all().then((res) => {
    })
  }

  render() {
    const { successLogin } = this.state;
    return (
      <div>
        <Loader loader={this.state.loader} />
        <div style={logoContainerStyle}>
          <img src={KrowdLogo} style={imgStyle} alt="krowd-logo" />
          <h5>Customer Acquisition Marketplace</h5>
        </div>
        <div className="row form justify-content-center">
          <div className="col-md-4 col-lg-3" >
            <div className="card">
              <div className="content">
                <Login onSubmit={this.onSubmit}
                  loader={this.state.loader}
                />
              </div>
            </div>
            {/* <div className="text-center mb-4">
              Don't have an account ? <Link to='/signup' className="text-primary">Signup</Link>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  loader: state.loader
})
const mapDispatchToProps = (dispatch) => {
  return {
    login: bindActionCreators(loginAction, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);


