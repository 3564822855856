import {
  GET_BRAND_ENTITY_LIST,
  CREATE_BRAND_ENTITY,
  CREATE_BRAND,
  CREATE_BRANCH,
  GET_BRANCH_ENTITY_LIST,
  GET_EXISTING_BRANCH_LIST,
  CLEAR_BRANDBRANCH_DATA,
  CREATE_USER,
  DELETE_USER,
  DELETE_BRANCH,
  GET_GROUP_LIST,
  GET_DISTRIBUTORS,
  UPLOAD_BRAND,
  GET_UPLOADED_DATE
} from './actionTypes';

export const initialState = {
  brandList: {
    loader: false,
    data: []
  },
  existingBrand: {
    loader: false,
    data: {
      brands: [],
      branch: [],
      userList: []
    }
  },
  createBrandEntity: {
    loader: false,
    data: {},
    error: {}
  },
  createBrand: {
    loader: false,
    data: [],
    error: {}
  },
  branchList: {
    loader: false,
    data: [],
    type: ""
  },
  createBranch: {
    loader: false,
    data: [],
    error: {}
  },
  createUser: {
    loader: false,
    data: {},
    error: {}
  },
  deleteUser: {
    loader: false,
    data: {},
    error: {}
  },
  deleteBranch: {
    loader: false,
    data: {},
    error: {}
  },
  groupList: {
    data: {
      groupList: []
    }
  },
  distributors: {
    data: {
      distributors: []
    }
  },
  uploadBrand: {
    loader: false,
    data: {},
    error: {}    
  }
};

export default (
  state = initialState,
  action
) => {
  switch (action.type) {

    case GET_BRAND_ENTITY_LIST: {
      return {
        ...state,
        brandList: {
          ...state.brandList,
          loader: action.payload.loader,
          data: action.payload.data
        },
      };
    }
    case CREATE_BRAND_ENTITY: {
      return {
        ...state,
        createBrandEntity: {
          ...state.createBrandEntity,
          loader: action.payload.loader,
          data: action.payload.data,
          error: action.payload.error || {}
        },
      };
    }
    case CREATE_BRAND: {
      return {
        ...state,
        createBrand: {
          ...state.createBrand,
          loader: action.payload.loader,
          data: action.payload.data,
          error: action.payload.error || {}
        },
      };
    }
    case GET_BRANCH_ENTITY_LIST: {
      return {
        ...state,
        branchList: {
          ...state.branchList,
          loader: action.payload.loader,
          data: action.payload.data,
          type: action.payload.type
        },
      };
    }

    case GET_EXISTING_BRANCH_LIST: {
      return {
        ...state,
        existingBrand: {
          ...state.existingBrand,
          loader: action.payload.loader,
          data: {
            brands: action.payload.data.brands,
            branch: action.payload.data.branch,
            userList: action.payload.data.userList,
            amexDiningSignup: action.payload.data.amexDiningSignup
          }
        },
      };
    }

    case GET_GROUP_LIST: {
      return {
        ...state,
        groupList: {
          ...state.groupList,
          data: {
            groupList: action.payload.data
          }
        },
      };
    }

    case CREATE_BRANCH: {
      return {
        ...state,
        createBranch: {
          ...state.createBranch,
          loader: action.payload.loader,
          data: action.payload.data,
          error: action.payload.error || {}
        },
      };
    }

    case CLEAR_BRANDBRANCH_DATA: {
      return {
        ...state,
        brandList: initialState.brandList,
        existingBrand: initialState.existingBrand,
        createBrandEntity: initialState.createBrandEntity,
        createBrand: initialState.createBrand,
        branchList: initialState.branchList,
        createBranch: initialState.createBranch,
        createUser: initialState.createUser,
        deleteUser: initialState.deleteUser,
        deleteBranch: initialState.deleteBranch
      };
    }

    case CREATE_USER: {
      return {
        ...state,
        createUser: {
          ...state.createUser,
          loader: action.payload.loader,
          data: action.payload.data,
          error: action.payload.error || {}
        },
      };
    }

    case DELETE_USER: {
      return {
        ...state,
        deleteUser: {
          ...state.deleteUser,
          loader: action.payload.loader,
          data: action.payload.data,
          error: action.payload.error || {}
        },
      };
    }
    case DELETE_BRANCH: {
      return {
        ...state,
        deleteBranch: {
          ...state.deleteBranch,
          loader: action.payload.loader,
          data: action.payload.data,
          error: action.payload.error || {}
        },
      };
    }
    case GET_DISTRIBUTORS: {
      return {
        ...state,
        distributors: {
          ...state.distributors,
          data: {
            distributors: action.payload.data
          }
        },
      };
    }
    case UPLOAD_BRAND: {
      return {
        ...state,
        uploadBrand: {
          ...state.uploadBrand,
            data: action.payload.data,
            loader: action.payload.loader,
            error: action.payload.error
        },
      };
    }
    case GET_UPLOADED_DATE: {
      return {
        ...state,
        ingestionLogDate : {
          ...state.ingestionLogDate,
          data: {
            ingestionLogDate: action.payload.data,
            ingestionLogList: action.payload.ingestionLogList
          }
        },
      };
    }
    default:
      return state;
  }
};
