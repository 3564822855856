import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, string as YupString, ref as YupRef, boolean as YupBool, number as YupNumber, } from 'yup';
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import { Collapse } from 'react-bootstrap';
import { bindActionCreators } from 'redux'
import { getEntityDetailAction } from '../../redux/actions/index'
import configMessages from '../../config/configMessages'
import Select from 'react-select';
import { onlycharRegex, passwordRegex, phoneRegex } from '../../utils/constants/constant'

class UserFormComponent extends Component {

  state = {
    brands: [],
    selectedBrand: {}
  }

  handleShowPassword = () => {
    let { values, setFieldValue } = this.props;
    setFieldValue('editPassword', !values.editPassword)
  }

  componentDidMount() {
    let { status, isSubmitting, values, handleChange, handleBlur, options } = this.props;
    let { prefix, mode, brands } = options
    brands = brands && brands.brands ? brands.brands : []
  }

  onChange = (value) => {

    this.setState({ selectedBrand: value })
    this.props.values.brandId = value.value
    this.props.values.brandName = value.label
  }
  render() {
    let { status, isSubmitting, values, handleChange, handleBlur, options } = this.props;
    let { prefix, mode, brands } = options
    let { selectedBrand } = this.state
    brands = brands && brands.brands ? brands.brands : []
    // let selectedBrand={}
    if (brands && brands.length) {
      brands.forEach((item, index) => {
        item.label = item.name
        item.value = item.brandId
        if (values && (item.brandId == values.brandId) && !selectedBrand.value) {
          this.state.selectedBrand = item
        }
      })
    }

    // if(values && selectedBrand.value && selectedBrand.label){
    //   values.brandId=selectedBrand.value
    // }
    return (
      <Form>
        <div className="form-group">
          <label>Brand</label>
          <Select
            options={brands}
            value={selectedBrand}
            onChange={this.onChange}
          />
          <ErrorMessage name="role" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>First Name </label>
          <Field name="firstName" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="firstName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Last Name </label>
          <Field name="lastName" className="form-control" disabled={isSubmitting} />
          <ErrorMessage name="lastName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Email </label>
          <Field name="email" className="form-control" disabled={isSubmitting || mode == 'edit'} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Phone</label>
          <Field name="phone" type='text' className="form-control"
            // maxLength='12'
            disabled={isSubmitting} />
          <ErrorMessage name="phone" component="label" className="error" />
        </div>

        <div className="form-group">
          <label>Is Guest User </label>
          <div className="d-block d-sm-block d-md-flex radio-container">
            <div className="form-check form-check-inline custom-align mr-4">
              <Field
                checked={values.guestUser === true || values.guestUser === 'true'}
                name="guestUser" type="radio" id="enableMails1" className="form-check-input mr-2" value={true} />
              <label className="form-check-label" htmlFor="enableMails1">
                True
              </label>
            </div>
            <div className="form-check form-check-inline custom-align">
              <Field
                checked={values.guestUser === false || values.guestUser === 'false'}
                name="guestUser" type="radio" id="guestUser" className="form-check-input mr-2" value={false} disabled={true} />
              <label className="form-check-label" htmlFor="guestUser">
                False
              </label>
            </div>
          </div>
          <ErrorMessage name="guestUser" component="label" className="error" />
        </div>

        <input
          value={prefix}
          onChange={handleChange}
          onBlur={handleBlur}
          name="entityType"
          type="hidden"
          className="form-control" />
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

export class FormikForm extends Component {

  initialValues = {
    'firstName': '',
    'lastName': '',
    'email': '',
    'phone': '',
    'brandId': '',
    'guestUser': true
  }

  validationSchemaWithPassword = YupObject({
    firstName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    lastName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    email: YupString()
      .required(configMessages.email)
      .email(configMessages.validEmail),
    phone: YupString()
      .matches(phoneRegex, `Enter a valid contact number`)
      .required(configMessages.phone),
    brandId: YupString()
      .required(configMessages.brandId),
  })

  validationSchemaWithoutPassword = YupObject({
    firstName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    lastName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    email: YupString()
      .required(configMessages.email)
      .email(configMessages.validEmail),
    phone: YupString()
      .matches(phoneRegex, `Enter a valid contact number`)
      .required(configMessages.phone),
    // .matches(/[\d]+/, configMessages.phoneValidation),
    // phone:YupNumber()
    //       .typeError("That doesn't look like a phone number")
    //       .positive("A phone number can't start with a minus")
    //       .integer("A phone number can't include a decimal point")
    //       .min(8)
    //       .required('A phone number is required'),
    brandId: YupString()
      .required(configMessages.brandId),
  })

  noop = () => { }

  componentDidMount() {
    let { getBrandData } = this.props
    getBrandData('brands', 'brands')
  }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, brands, banks, prefix } = this.props;
    let defaultValues;
    if (prefix && !initialValues) {
      defaultValues = { ...this.initialValues }
      defaultValues.entityType = prefix
    } else {
      defaultValues = { ...initialValues, password: '', confirmPassword: '' }
    }
    let validationSchema = initialValues ? this.validationSchemaWithoutPassword : this.validationSchemaWithPassword
    let mode = initialValues ? 'edit' : ''
    return (
      <Formik
        initialValues={defaultValues}
        render={props => <UserFormComponent {...props} options={{ banks, brands, prefix, mode }} /> /*this.getForm(props, {banks, brands, prefix, mode})*/}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        ref={formikRef}
      />
    )
  }
}
export const UserForm = connect(
  state => ({
    banks: state.selectEntities.banks,
    brands: state.brand,
  }),
  dispatch => ({
    getBrandData: bindActionCreators(getEntityDetailAction, dispatch)
  })
)(FormikForm)

class SimpleUserFormComponent extends Component {

  handleShowPassword = () => {
    let { values, setFieldValue, setFieldTouched } = this.props;
    setFieldValue('editPassword', !values.editPassword)
    setFieldTouched('password', false)
    setFieldTouched('confirmPassword', false)
  }

  render() {
    let { status, isSubmitting, values, options } = this.props;
    let { isDisabled } = options
    return (
      <Form className="form">
        <div className="form-group">
          <label>Email</label>
          <Field name="email" className="form-control" disabled={true} />
          <ErrorMessage name="email" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>First Name</label>
          <Field name="firstName" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="firstName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <Field name="lastName" className="form-control" disabled={isSubmitting || isDisabled} />
          <ErrorMessage name="lastName" component="label" className="error" />
        </div>
        <div className="form-group">
          <label>Contact Phone</label>
          <Field name="phone" className="form-control" disabled={isSubmitting || isDisabled} maxLength='12' />
          <ErrorMessage name="contactPhone" component="label" className="error" />
        </div> <div className="form-group">
          <label>Address</label>
          <Field name="address" className="form-control" disabled={isSubmitting || isDisabled} component="textarea" />
          <ErrorMessage name="address" component="label" className="error" />
        </div>
        <React.Fragment>
          <Collapse in={values.editPassword} >
            <div key={values.editPassword} className="pt-3">
              <div className="form-group">
                <label>Password</label>
                <Field name="password" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
                <ErrorMessage name="password" component="label" className="error" />
              </div>
              <div className="form-group">
                <label>New Password</label>
                <Field name="newPassword" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
                <ErrorMessage name="newPassword" component="label" className="error" />
              </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <Field name="confirmPassword" className="form-control" type="password" disabled={isSubmitting || isDisabled} />
                <ErrorMessage name="confirmPassword" component="label" className="error" />
              </div>
            </div>
          </Collapse>
        </React.Fragment>
        {
          status && (
            <div className="form-group text-center">
              <label className="error">{status}</label>
            </div>
          )
        }
      </Form>
    )
  }
}

export class SimpleUserForm extends Component {

  initialValues = {
    name: '',
    contactPhone: '',
    password: '',
    confirmPassword: '',
  }

  validationSchema = YupObject({
    firstName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    lastName: YupString()
      .required(configMessages.name)
      .matches(onlycharRegex, 'Name can only contain [A-Za-z_-\s@\.]')
      .min(3, configMessages.minName)
      .max(100, configMessages.maxName),
    phone: YupString()
      .matches(phoneRegex, `Enter a valid contact number`)
      .required(configMessages.phone),
    editPassword: YupBool(),
    password: YupString()
      .when('editPassword', {
        is: true,
        then: YupString()
          .required(configMessages.password)
          .matches(passwordRegex, 'password must contain One special characters, upper case and number.')
          .min(8, configMessages.minPassword)
          .max(20, configMessages.maxPassword)
      }),
    confirmPassword: YupString()
      .when('editPassword', {
        is: true,
        then: YupString()
          .required(`Confirm ${configMessages.password}`)
          .oneOf([YupRef('password'), null], configMessages.passwordMatch),
      }),
  })

  noop = () => { }

  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options } = this.props;
    let defaultValues;
    if (!initialValues) {
      defaultValues = { ...this.initialValues }
    } else {
      defaultValues = { ...initialValues, password: '', confirmPassword: '' }
    }
    return (
      <Formik
        initialValues={defaultValues}
        render={props => <SimpleUserFormComponent {...props} options={options} />}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
      />
    )
  }
}
