import React, { Component } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { object as YupObject, mixed as YupMixed } from 'yup';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import config from './../../config/config'
export class FileInputComponent extends Component {

  handleFileChange = e => {
    let { setFieldValue,setFileName } = this.props;
    let file = e.target.files[0]
    let name = this.props.field.name
    setFieldValue(name, file)
    setFileName(name,file)
  }

  render() {
    let { field: { name }, className, onBlur, accept, handleFileRef } = this.props;
    return <input
      ref={e => handleFileRef(name, e)}
      name={name}
      className={className}
      onBlur={onBlur}
      accept={accept}
      type='file'
      onChange={this.handleFileChange}
    />
  }
}

class UploadFormComponent extends Component {

  state = {
    formName: ''
  }

  fileUploadRef = {}

  handleFileRef = (name, ref) => {
    this.fileUploadRef[name] = ref;
  }

  componentDidMount = () => {
    let url = window.location.pathname
    let urlArray = url.split('/')
    let formName = urlArray[urlArray.length - 1]
    if (formName == 'brands') {
      this.setState({ formName: 'brand1' })
    } else if (formName == 'branches') {
      this.setState({ formName: 'branch' })
    }
  }

  handleFormReset = () => {
    Object.keys(this.fileUploadRef).forEach(it => {
      this.fileUploadRef[it].value = ''
    })
  }
  setFileName=async(name,file)=>{
    console.log(file.type)
    if(file.type != 'text/csv'){
      await this.setState({
        status:'File uploaded is not with .csv extension'
      })
      this.props.getFileType('wrong')
    }else{
      await this.setState({
        status:''
      })
      this.props.getFileType('csv')
    }
  }
  getErrorBlock = status => {
    if (!status)
      return ''
    if (status instanceof Array) {
      return (
        <div className="form-group text-center">
          <ul className="error">
            {
              status.map((item, i) => {
                return (
                  <li key={i}>
                    <label>{item}</label>
                  </li>
                )
              })
            }
          </ul>
        </div>
      )
    } else {
      return (
        <div className="form-group text-center">
          <label className="error">{status}</label>
        </div>
      )
    }
  }

  //download images
  download() {
    let { formName } = this.state
    let fileName = `${formName}.csv`
    let url = `${config.docsUrl}docs/${fileName}`
    // let fileName='1607660463942_butterfly.png'
    // let url='http://api.krowd.dosystemsinc.com/images/employee/1607660463942_butterfly.png'
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(this.response);
      var tag = document.createElement('a');
      tag.href = imageUrl;
      tag.download = fileName;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
    }
    xhr.send();
  }
  render() {
    let { status, isSubmitting, setFieldValue, touched, handleBlur } = this.props;
    const { formName } = this.state
    let fileName = `${formName}.csv`
    let url = `${config.docsUrl}docs/${fileName}`
    return (
      <Form>
        <div className="form-group">
          <label className="pr-3">Sample File</label>
          <a
            // href={require('./../../assets/csv/merchant.csv')}
            // href={require('./../../assets/csv/brand.csv')}
            className='badge bg-info'
            href={url}
            //  onClick={e=>this.download()} 
            download><ArrowDownwardIcon /></a>
          {/* {formName=='brand'?<a href={require('./../../assets/csv/brand.csv')} className='badge bg-info' download={true}><ArrowDownwardIcon /></a>:formName=='branch'?<a href={require('./../../assets/csv/merchant.csv')} className='badge bg-info' download={true}><ArrowDownwardIcon /></a>:''} */}

        </div>
        <div className="form-group">
          <label>CSV File</label>
          <Field
            name="file"
            className="form-control h-100"
            disabled={isSubmitting}
            accept="text/csv"
            setFieldValue={setFieldValue}
            setFileName={this.setFileName}
            component={FileInputComponent}
            touched={touched["file"]}
            onBlur={handleBlur}
            handleFileRef={this.handleFileRef}
          />
          <ErrorMessage name="file" component="label" className="error" />
        </div>
        {this.getErrorBlock(status?status:this.state.status)}
      </Form>
    )
  }
}

export class UploadForm extends Component {
  
  initialValues = {
    file: ''
  }
  state = {
    isDisabled: false
  }
  validationSchema = YupObject({
    file: YupMixed()
      .required('File is required')
      .test('fileSize', 'File is empty', value => value && value.size > 0)
  })
  
  noop = () => { 
  }
  getFileType=async(item)=>{
    console.log(item)
    if(item === 'csv'){
    await  this.setState({
        isDisabled:false
      })
    }else{
    await  this.setState({
        isDisabled:true
      })
    }
    this.props.formikRef({'uploadProps':{'isDisabled':this.state.isDisabled}})
  }
  render() {
    let { initialValues, onSubmit, formikRef = this.noop, options, enableReinitialize } = this.props;
    return (
      <Formik
        initialValues={initialValues || this.initialValues}
        render={props => <UploadFormComponent {...props} options={options} getFileType={this.getFileType}/>}
        onSubmit={onSubmit}
        validationSchema={this.validationSchema}
        ref={formikRef}
        isDisabled={this.state.isDisabled}
        enableReinitialize={enableReinitialize}
      />
    )
  }
}

