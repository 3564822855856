import React, { Component } from "react";
import ModalComponent from '../../components/Modal'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import Icon from '@material-ui/core/Icon'
import { pluralize } from '../../utils/helpers'
import { Redirect } from 'react-router-dom'
import {
  getEntityAction,
  deleteEntityAction,
  setEntityPageAction,
  setEntitySortAction,
  setEntitySearchAction,
  setEntityFiltersAction,
} from '../../redux/actions/entityListActions'

import {
  success as successNotificationAction,
  error as errorNotificationAction,
} from 'react-notification-system-redux';

import { notificationOpts } from '../../config'
import Loader from '../../components/Loader'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ViewModal from '../../components/ViewModal'
//session expiry modal
import SessionExpiryModal from '../../components/sessionExpiryModal'
import moment from 'moment'
import { GetSessionValue } from "../../utils/sessionStorage";
import { Roles } from "../../utils/constants/constant";
class OnboardingActivity extends Component {

  state = {
    showAddNewModal: false,
    showEditModal: false,
    showUploadModal: false,
    showApproveModal: false,
    isLoading: false,
    sortCount: 0,
    approvalAction: '',
    searchField: '',
    sessionExpiryModal: false,
    loginRedirect: false,
    openViewModal: false,
    globalSearch: ''
  };
  pagination = {
    limit: 10,
    page: 1
  }
  fetchData = () => {
    let {
      getEntityData,
      // page,
      filters,
      apiUrl,
      successNotification,
      errorNotification
    } = this.props;
    let { limit, page } = this.pagination
    let { searchField, selectedStatus } = this.state
    if (searchField) {
      filters.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }
    if (this.props.user.role == Roles.bank) {
      filters.customerType = this.props.user.customerType
    }
    // let { limit } = this.state.paginationData
    this.setState({ isLoading: true })
    // page, pageSize, filters,apiUrl,type,callback

    filters.reqestType = "onBoarding"
    getEntityData(page, limit, filters, apiUrl, '', (res, pagination, type) => {
      let sessionExpiryModal = false
      let sessionExpired = GetSessionValue('sessionExpired')
      if (sessionExpired && (sessionExpired == 'true' || sessionExpired == true)) {
        sessionExpiryModal = true
      }
      this.setState({ sessionExpiryModal: sessionExpiryModal, isLoading: false })
      if (type == 'success') {
      }
      if (type == 'error') {
        if (sessionExpiryModal) {
          return
        }
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          errorNotification(notification)
        })
      }
    })
  }
  componentDidMount() {
    let { preset, setEntityFilters, page } = this.props;

    if (preset) {
      setEntityFilters(preset)
      return
    }
    this.fetchData(page || this.pagination.page)
    let { getBrandData } = this.props
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.isLoading === false
      && this.props.isLoading === false
      && (
        prevProps.page !== this.props.page
        || prevProps.filters !== this.props.filters
      )) {
      this.fetchData()
      // console.log('comes to updated')
    }
  }
  previousPage = ({
    page,
    onPageChange
  }) => () => {
    if (page - 1 > 0)
      onPageChange(page - 1);
  }

  nextPage = ({
    page,
    onPageChange,
    totalSize
  }) => () => {
    if (page < this.getPageCount({ totalSize }))
      onPageChange(page + 1);
  }
  getPageCount = (paginationProps) => {
    let { totalSize } = paginationProps;
    let { sizePerPage } = this.pagination;
    return parseInt(totalSize / sizePerPage) + (totalSize % sizePerPage ? 1 : 0)
  }
  handleTableChange = (type, { sizePerPage, sortField, sortOrder, searchText, filters, status }) => {
    let {
      setEntityPage,
      setEntitySort,
      setEntitySearch,
      setEntityFilters,
      getEntityData,
      apiUrl
    } = this.props;
    let { page, limit } = this.pagination
    let { searchField } = this.state;
    let filterCriteria = {}
    filterCriteria.reqestType = "onBoarding"
    if (this.props.user.role == Roles.bank) {
      filterCriteria.customerType = this.props.user.role
    }
    if (searchField) {
      filterCriteria.globalSearch = {
        value: searchField,
        type: 'user'
      }
    }

    if (type === 'pagination') {
      setEntityPage(page, sizePerPage)
      return;
    }
    if (type === 'sort') {


      let sortCount = this.state.sortCount
      sortCount = sortCount == 0 ? sortCount + 1 : 0;
      filterCriteria.sortField = {
        direction: sortCount == 0 ? "desc" : 'asc',
        sortfield: sortField,
      }
      this.setState({ sortCount: sortCount })
      getEntityData(page, limit, filterCriteria, apiUrl, 'sort', (response, pagination) => {
      })
      return
    }
    if (type === 'search') {
      filterCriteria.globalSearch = {
        value: searchText,
        type: 'user'
      }
      // page, pageSize, filters,apiUrl,type,callback
      getEntityData(page, limit, filterCriteria, apiUrl, 'globalsearch', (response, pagination) => {
        this.setState({ tableData: response })
        if (pagination && pagination.totalCount) {
          this.setState({ pagination: pagination })
        }
      })



      // setEntitySearch(searchText)
    }
    if (type === 'filter') {
      setEntityFilters({ ...filters })
    }
    if (type === 'dropdown') {

      getEntityData(page, limit, filterCriteria, apiUrl, 'dropdown', (response, pagination) => {

      })
    }
  }

  onCloseAddNewModal = () => {
    this.setState({ showAddNewModal: false, selectedEntity: null })
  }

  onCloseViewModal = () => {
    this.setState({ openViewModal: false })
  }
  onCloseEditModal = () => {
    this.setState({ showEditModal: false, selectedEntity: null })
  }
  setShowDeleteModal = (selectedEntity) => {
    this.setState({ showDeleteModal: true, selectedEntity })
  }
  onCloseDeleteModal = () => {
    this.setState({ showDeleteModal: false, selectedEntity: null })
  }

  //view modal
  openViewModal = async (rowData) => {
    let keys = Object.keys(rowData);
    let viewFields = this.props.viewFields ?
      await this.props.viewFields : [];
    if (viewFields) {
      if (rowData) {
        let values;
        viewFields.forEach((item, index) => {
          if (item.name == 'created') {
            let val = rowData[item.name]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          }
          else if (item.name == 'activatedDate' && rowData[item.name]) {
            let val = rowData[item]
            let date = new Date(val);
            item.value = moment(date).format('DD/MM/YYYY')
          }
          else {
            item.value = rowData[item.name]
          }
        })
      }
      await this.setState({
        openViewModal: true,
        selectedEntity: rowData
      });
      this.viewModalRef.getRowData(viewFields);
    }
  }

  handleDeleteEntity = () => {
    let { deleteEntity, page, successNotification, entityType, apiUrl } = this.props;
    let { selectedEntity } = this.state
    deleteEntity(selectedEntity, apiUrl, (res, type) => {
      if (type == 'error') {
        setTimeout(() => {
          let notification = { ...notificationOpts }
          notification.message = res
          successNotification(notification)
        })
        return
      }
      // notification
      this.onCloseDeleteModal()
      this.fetchData(page)
      setTimeout(() => {
        let notification = { ...notificationOpts }
        notification.message = res
        successNotification(notification)
      })
    })
  }

  handleInputChange = e => {
    let searchText = e.target.value
    this.setState({ searchField: searchText })
    this.pagination.page = 1
    this.handleTableChange('search', { searchText })
  }

  actionsFormatter = (cell, row) => {
    let { user } = this.props
    return (
      user.role === 'systemadmin' || user.role === 'admin' || user.role === 'superAdmin' || user.role === 'salesAdmin' ||
      user.role === 'opsAdmin'
        ?
        <React.Fragment key={row && row._id ? row._id : ''} >
          <div className="future-action" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => this.setShowDeleteModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-danger" data-toggle="tool-tip" title="Delete">delete</Icon>
          </div>
          <span className="future-action" style={{ display: 'inline-block', marginLeft: '5px', cursor: 'pointer' }} onClick={() => this.openViewModal(row)} >
            <Icon style={{ fontSize: "1.5rem" }} className="text-success">visibility</Icon>
          </span>
        </React.Fragment>
        : '')
  }

  sessionExpired = async () => {
    await this.setState({ loginRedirect: true })
  }
  setPageLimit = (limit) => {
    this.pagination.limit = limit
    this.fetchData()
  }

  pageChange = (page) => {
    this.pagination.page = page
    this.fetchData()
  }

  sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
  }) => (
    <div className="btn-group pt-1" role="group">
      <div className="dropdown">
        <button className="btn btn-primary dropbtn">{this.pagination.limit}  <ExpandMoreIcon /></button>
        <div className="dropdown-content">
          {
            options.map((option, index) => {
              const isSelect = currSizePerPage === `${option.page}`;
              return (
                <a href="#" onClick={() => this.setPageLimit(option.page)} key={index}> {option.text}</a>
              );
            })
          }
        </div>
      </div>
    </div>
  )
  render() {
    const { items, totalCount, heading, icon, columns, prefix = '', globalsearchPlaceHolder, isLoading } = this.props;
    const { searchField, loginRedirect, } = this.state
    let index = columns.findIndex(c => c.dataField === 'actions' && c.isDummyField === true)
    if (columns[index]) {
      columns[index].formatter = this.actionsFormatter
    }

    const { page } = this.pagination
    let pagination = paginationFactory(
      {
        sizePerPageRenderer: this.sizePerPageRenderer,
        page,
        sizePerPage: this.pagination.limit,
        totalSize: totalCount,
        hideSizePerPage: false,
        hidePageListOnlyOnePage: true,
        showTotal: true,
        alwaysShowAllBtns: true,
        show: true,
        onPageChange: (page, sizePerPage) => {
          this.pageChange(page)
        }
      }
    )
    console.log('searchField', this.state.searchField)

    return (
      <div>
        {loginRedirect ? <Redirect to='/' /> : ''}
        <Loader loader={isLoading} />
        <div className="row">
          <div className="col-md-12">
            <div className="heading">
              <div className="row">
                <div className="col-md-8 d-flex align-items-center">
                  <h2 className="m-0 ">
                    <span>
                      <Icon className="heading-icon ">{icon}</Icon>
                    </span>
                    OnBoarding Activitiy
                  </h2>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="mt-4 ">
                <div className="row">
                  <div className="col-md-4 no-gutters" style={{ height: 30 }}>
                    <div className="input-group mb-3">
                      <input type="text" className="form-control" onChange={this.handleInputChange} placeholder={globalsearchPlaceHolder ? globalsearchPlaceHolder : ''} />
                      <div className="input-group-append">
                        <span className="input-group-text" id="basic-addon2"><i className="fa fa-search"></i></span>
                      </div>
                    </div>
                    {searchField && searchField.length < 4 ? 'Search field contain min of 3 characters' : ''}
                  </div>
                </div>
              </div>
              <div className="content mb-3">
                <BootstrapTable
                  bootstrap4={true}
                  keyField='id'
                  key='id'
                  data={items}
                  columns={columns}
                  wrapperClasses="table-responsive"
                  remote
                  onTableChange={this.handleTableChange}
                  noDataIndication={() => <span>No data found.</span>}
                  pagination={pagination}
                />
                <ModalComponent close={this.onCloseDeleteModal}
                  callback={this.handleDeleteEntity}
                  show={this.state.showDeleteModal}
                  title={<span>Delete <b>{heading}</b></span>}
                  message={<span>Are you sure you want to delete the <b>{heading}, {heading === 'brand' && 'all related branches would be deleted, do you want to continue'}</b> ?</span>}
                  action={"Delete"}
                  actionType={'danger'}
                />

                <ModalComponent close={this.onCloseDeleteModal}
                  callback={this.sessionExpired}
                  // show={this.state.sessionExpiryModal}
                  show={false}
                  title={`Session Expired`}
                  message={<span>Session Expired , Please Login Again</span>}
                  action={"Go to Login Page"}
                  actionType={'success'}
                  hideCancelBtn={true}
                />
                {/* show view modall */}
                <ModalComponent close={e => this.setState({ openViewModal: false })}
                  callback={this.onCloseViewModal}
                  show={this.state.openViewModal}
                  title={`Onboarding Activity View`}
                  message={<ViewModal
                    onRef={(ref) => this.viewModalRef = (ref)}
                    // onSubmit={this.handleConfirmMerchant}
                    prefix={prefix}
                  />}
                  // message={<span>{`Are you sure you want to ${approvalAction == 'Approve' ? 'approve' : approvalAction == 'Reject' ? 'reject' : ''} the Merchant`}</span>}
                  action={"Edit"}
                  actionType={'primary'}
                  size='lg'
                  cancelText={'Close'}
                />
                <SessionExpiryModal
                  isOpen={this.state.sessionExpiryModal}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(connect(
  (state, ownProps) => ({
    user: state.user,
    items: state[pluralize(ownProps.heading)].items,
    isLoading: state[pluralize(ownProps.heading)].isLoading,
    page: state[pluralize(ownProps.heading)].page,
    totalCount: state[pluralize(ownProps.heading)].totalCount,
    sizePerPage: state[pluralize(ownProps.entityType)].sizePerPage,
    filters: state[pluralize(ownProps.entityType)].filters,
  }),
  (dispatch, ownProps) => ({
    getEntityData: bindActionCreators(getEntityAction(ownProps.heading), dispatch),
    deleteEntity: bindActionCreators(deleteEntityAction, dispatch),
    setEntityPage: bindActionCreators(setEntityPageAction(ownProps.heading), dispatch),
    setEntitySort: bindActionCreators(setEntitySortAction(ownProps.entityType), dispatch),
    setEntitySearch: bindActionCreators(setEntitySearchAction(ownProps.entityType), dispatch),
    setEntityFilters: bindActionCreators(setEntityFiltersAction(ownProps.entityType), dispatch),
    successNotification: bindActionCreators(successNotificationAction, dispatch),
    errorNotification: bindActionCreators(errorNotificationAction, dispatch),
  })
)(OnboardingActivity));
