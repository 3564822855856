import {
    CREATE_ACTIONS_CENTER,
    GET_ACTIONS_CENTER,
    UPDATE_ACTIONS_CENTER,
    GET_PUBLISHER_ACTIONS_CENTER,
    GET_BRAND_ACTIVITY
} from './actionTypes';

export const initialState = {

    getBrandActionCenter: {
        loader: false,
        data: [],
        pagination: {},
        error: {}
    },
    createActionCenter: {
        loader: false,
        data: [],
        error: {}
    },
    updateActionCenter: {
        loader: false,
        data: [],
        error: {}
    },
    getPublisherBrandActionCenter: {
        loader: false,
        data: [],
        pagination: {},
        error: {}
    },
    getBrandActivity: {
        loader: false,
        data: [],
        pagination: {},
        error: {}
    }
};

export default (
    state = initialState,
    action
) => {
    switch (action.type) {

        case GET_ACTIONS_CENTER: {
            return {
                ...state,
                getBrandActionCenter: {
                    ...state.getBrandActionCenter,
                    loader: action.payload.loader,
                    data: action.payload.data,
                    pagination: action.payload.pagination,
                    error: action.payload.error || {}
                },
            };
        }
        case CREATE_ACTIONS_CENTER: {
            return {
                ...state,
                createActionCenter: {
                    ...state.createActionCenter,
                    loader: action.payload.loader,
                    data: action.payload.data,
                    error: action.payload.error || {}
                },
            };
        }
        case UPDATE_ACTIONS_CENTER: {
            return {
                ...state,
                updateActionCenter: {
                    ...state.updateActionCenter,
                    loader: action.payload.loader,
                    data: action.payload.data,
                    error: action.payload.error || {}
                },
            };
        }
        case GET_PUBLISHER_ACTIONS_CENTER: {
            return {
                ...state,
                getPublisherBrandActionCenter: {
                    ...state.getPublisherBrandActionCenter,
                    loader: action.payload.loader,
                    data: action.payload.data,
                    pagination: action.payload.pagination,
                    error: action.payload.error || {}
                },
            };
        }
        case GET_BRAND_ACTIVITY: {
            return {
                ...state,
                getBrandActivity: {
                    ...state.getBrandActivity,
                    loader: action.payload.loader,
                    data: action.payload.data,
                    pagination: action.payload.pagination,
                    error: action.payload.error || {}
                },
            };
        }
        default:
            return state;
    }
};
