
import React, { useEffect, useState } from 'react';
import { TextField, Button, Modal, Box, InputAdornment, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { listLoyaltyPoints, updateLoyaltyPoints, deleteLoyaltyPoints } from '../../services/rewards/rewardsApi';
import moment from 'moment';
import { LocalizationProvider, DesktopDateTimePicker, DateTimePicker } from '@mui/x-date-pickers';
import DayPicker from './day-picker';

const ITEMS_PER_PAGE = 8;

const FetchOffersPage = () => {
    // Form states
    const [pan, setPan] = useState('');
    const [offer_type, setOfferType] = useState('discount');
    const [offer_value, setOfferValue] = useState('');
    const [from_date, setFromDate] = useState(null);
    const [to_date, setToDate] = useState(null);
    const [message, setMessage] = useState(null);
    const [kmid, setKmid] = useState('DISC123');
    const [loyaltyPointsList, setLoyaltyPointsList] = useState([]);
    const [editingIndex, setEditingIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [min_txn_amount, setMin_txn_amount] = useState('');
    const [max_offer_amount, setMax_offer_amount] = useState('');
    const [id, setId] = useState('');
    const [isPanDisabled, setIsPanDisabled] = useState(false);
    const [max_txns, setMax_txns] = useState("")
    // Modal state
    const [openModal, setOpenModal] = useState(false);

    const [selectedDays, setSelectedDays] = useState([]);
    const [txnFrequency, setTxnFrequency] = useState("");
    const [panError, setPanError] = useState(false);
    const [offerValueError, setOfferValueError] = useState(false);
    const [minTxnAmountError, setMinTxnAmountError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const [toDateError, setToDateError] = useState(false);
    useEffect(() => {
        listLoyaltyPoints()
            .then((response) => {
                setLoyaltyPointsList(response);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error occurred:', error);
            });
    }, []);

    const paginatedList = loyaltyPointsList.slice(
        currentPage * ITEMS_PER_PAGE,
        (currentPage + 1) * ITEMS_PER_PAGE
    );

    const handleNextPage = () => {
        if ((currentPage + 1) * ITEMS_PER_PAGE < loyaltyPointsList.length) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleAddPoints = () => {
        if (validateFields()) {
            const newEntry = {
                pan,
                kmid,
                offer_type,
                offer_value: Number(offer_value),       // Convert offer_value to a number
                min_txn_amount: Number(min_txn_amount), // Convert min_txn_amount to a number
                max_offer_amount: Number(max_offer_amount), // Convert max_offer_value to a number
                from_date: formatToISOString(from_date),
                to_date: formatToISOString(to_date),
                txn_frequency: Number(txnFrequency),
                selected_days: selectedDays,
                max_redemption: max_txns,
                mode: "insert"
            };
            console.log("Handle Add Points   :    " + JSON.stringify(newEntry))
            updateLoyaltyPoints(newEntry)
                .then(() => {
                    // setLoyaltyPointsList([newEntry, ...loyaltyPointsList]);
                    setMessage('Offer added successfully!');
                    clearForm();
                    setOpenModal(false);
                    window.location.reload();
                })
                .catch((err) => {
                    console.error('Error adding points:', err);
                });
        }
    };

    const handleUpdatePoints = () => {
        if (validateFields()) {
            console.log("Selected Days   :    " + JSON.stringify(selectedDays))
            const updatedEntry = {
                id,
                pan,
                kmid,
                offer_type,
                offer_value: Number(offer_value),        // Convert offer_value to a number
                min_txn_amount: Number(min_txn_amount),  // Convert min_txn_amount to a number
                max_offer_amount: Number(max_offer_amount), // Convert max_offer_amount to a number
                from_date: formatToISOString(from_date),
                to_date: formatToISOString(to_date),
                txn_frequency: Number(txnFrequency),
                selected_days: selectedDays,
                max_redemption: max_txns,
                mode: "update",
            };
            updateLoyaltyPoints(updatedEntry)
                .then(() => {
                    const updatedList = loyaltyPointsList.map((item, index) =>
                        index === editingIndex ? updatedEntry : item
                    );
                    // setLoyaltyPointsList(updatedList);
                    setMessage('Offer updated successfully!');
                    clearForm();
                    setOpenModal(false);
                    setEditingIndex(null);
                    window.location.reload()
                })
                .catch((err) => {
                    console.error('Error updating points:', err);
                });
        }
    };

    const handleEdit = (index) => {
        setIsPanDisabled(true)
        const selectedEntry = loyaltyPointsList[index];
        console.log("Edit Loyalty Points   :   " + JSON.stringify(selectedEntry.selected_days[0]))
        setId(selectedEntry.id)
        setPan(selectedEntry.pan);
        setKmid(selectedEntry.kmid);
        setOfferType(selectedEntry.offer_type);
        setOfferValue(selectedEntry.offer_value);
        setTxnFrequency(selectedEntry.txn_frequency);
        setSelectedDays(selectedEntry.selected_days[0] !== null ? selectedEntry.selected_days : [])
        setMin_txn_amount(selectedEntry.min_txn_amount);
        setMax_offer_amount(selectedEntry.max_offer_amount);
        setFromDate(new Date(selectedEntry.from_date));
        setToDate(new Date(selectedEntry.to_date));
        setMax_txns(selectedEntry.max_redemption)
        setEditingIndex(index);
        setOpenModal(true);
        setMessage(null);
        console.log("Edit Loyalty Points   :   " + selectedDays)
    };

    const handleDeletePoints = (id) => {
        deleteLoyaltyPoints({ id })
            .then(() => {
                // setLoyaltyPointsList(loyaltyPointsList.filter((item) => item.pan !== pan));
                setMessage('Offer deleted successfully!');
                window.location.reload()
            })
            .catch((err) => {
                console.error('Error deleting points:', err);
            });
    };

    const clearForm = () => {
        setPan('');
        setOfferValue('');
        setMin_txn_amount('');
        setMax_offer_amount('');
        setFromDate(null);
        setToDate(null);
        setEditingIndex(null);
        setMax_txns('')
    };

    const openAddModal = () => {
        clearForm();
        setIsPanDisabled(false)
        setOpenModal(true);
    };

    const closeModal = () => {
        setOpenModal(false);
        clearForm();
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleFromDateChange = (newValue) => {
        if (newValue) {
            setFromDate(new Date(newValue))
            setFromDateError(true);
        }
        else {
            setFromDateError(false);
        }
    };
    const formatToISOString = (date) => {
        // Extract the local date components to construct the ISO string manually
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, add 1
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0'); // Local hours
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        // Construct the exact ISO string format in local time (without Z for UTC)
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
    };


    const handleToDateChange = (newValue) => {
        if (newValue) {
            setToDate(new Date(newValue))
            setToDateError(true);
        } else {
            setToDateError(false);
        }
    };

    const validateFields = () => {
        let valid = true;

        if (pan === '') {
            setPanError(true);
            valid = false;
        } else {
            setPanError(false);
        }

        if (offer_value === '') {
            setOfferValueError(true);
            valid = false;
        } else {
            setOfferValueError(false);
        }

        // if (min_txn_amount === '') {
        //     setMinTxnAmountError(true);
        //     valid = false;
        // } else {
        //     setMinTxnAmountError(false);
        // }

        if (!from_date) {
            setFromDateError(true);
            valid = false;
        } else {
            setFromDateError(false);
        }

        if (!to_date) {
            setToDateError(true);
            valid = false;
        } else {
            setToDateError(false);
        }

        return valid;
    };

    return (
        <div className="form-container">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb: 2  // Adds some margin-bottom to separate the header section from the rest of the content
                }}
            >
                {/* Heading */}
                <h2>Offers Management</h2>

                {/* Add Offer Button */}
                <Button variant="contained" color="primary" onClick={openAddModal}>
                    Add Offer
                </Button>
            </Box>
            {/* Other content goes here */}

            <Modal open={openModal} onClose={closeModal}>
                <Box sx={modalStyle}>
                    <h2>{editingIndex === null ? 'Add Offer' : 'Edit Offer'}</h2>
                    <TextField
                        style={{ paddingBottom: '15px' }}
                        fullWidth
                        disabled={isPanDisabled}
                        margin="normal"
                        label="PAN"
                        required={true}
                        value={pan}
                        error={panError}
                        helperText={panError ? 'PAN is required' : ''}
                        onChange={(e) => setPan(e.target.value)}
                        sx={{ mb: 2 }}
                    />

                    {/* KMID Input */}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <TextField
                            style={{ paddingBottom: '15px' }}
                            fullWidth
                            margin="normal"
                            label="KMID"
                            value={kmid}
                            disabled
                            sx={{ mb: 2 }}
                        />

                        {/* Offer Type Input */}
                        <TextField
                            style={{ paddingBottom: '15px', paddingLeft: '10px' }}
                            fullWidth
                            margin="normal"
                            label="Offer Type"
                            value={offer_type}
                            disabled
                            sx={{ mb: 2 }}
                        />
                    </div>

                    {/* Offer Value Input */}
                    <TextField
                        style={{ paddingBottom: '15px' }}
                        fullWidth
                        margin="normal"
                        label="Offer Value (%)"
                        value={offer_value}
                        required={true}
                        error={offerValueError}
                        helperText={offerValueError ? 'Offer Value is required' : ''}
                        onChange={(e) => setOfferValue(e.target.value)}
                        sx={{ mb: 2 }}
                    />

                    {/* Min Txn Amount Input */}
                    <TextField
                        fullWidth
                        type='number'
                        style={{ paddingBottom: '15px' }}
                        margin="normal"
                        label="Min Txn Amount"
                        error={minTxnAmountError}
                        helperText={minTxnAmountError ? 'Min Txn Amount is required' : ''}
                        value={min_txn_amount}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">£</InputAdornment>,  // Pound symbol
                        }}
                        onChange={(e) => setMin_txn_amount(e.target.value)}
                        sx={{ mb: 2 }}
                    />

                    {/* Max Offer Value Input */}
                    <TextField
                        fullWidth
                        // type='number'
                        style={{ paddingBottom: '15px' }}
                        margin="normal"
                        label="Max Offer Amount"
                        value={max_offer_amount}
                        InputProps={{
                            startAdornment: <InputAdornment position="end">£</InputAdornment>,  // Pound symbol
                        }}
                        onChange={(e) => setMax_offer_amount(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        type='number'
                        style={{ paddingBottom: '15px' }}
                        margin="normal"
                        label="Max Txns"
                        value={max_txns}
                        onChange={(e) => setMax_txns(e.target.value)}
                        sx={{ mb: 2 }}
                    />

                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2, flexDirection: 'column' }}>
                            <DateTimePicker
                                label="From Date & Time"
                                value={from_date}
                                 onChange={handleFromDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        required={true}
                                        onError={true}
                                        error
                                        helperText={fromDateError ? 'From Date is required' : ''}
                                    />
                                )}
                            />
                            {fromDateError && (
                                <Typography variant="caption" color="error">
                                    From Date is required
                                </Typography>
                            )}
                            <DateTimePicker
                                label="To Date & Time"
                                value={to_date}
                                required={true}
                                onChange={handleToDateChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        required={true}
                                        onError={true}
                                        error
                                        helperText={toDateError ? 'To Date is required' : ''}
                                    />
                                )}
                            />
                                {toDateError && (
                                <Typography variant="caption" color="error">
                                    To Date is required
                                </Typography>
                            )}
                        </Box>
                    </LocalizationProvider>

                    <DayPicker txnFrequency={txnFrequency} setTxnFrequency={setTxnFrequency} selectedDays={selectedDays} setSelectedDays={setSelectedDays} />
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Button onClick={closeModal} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                        <Button
                            onClick={editingIndex === null ? handleAddPoints : handleUpdatePoints}
                            color="primary"
                            variant="contained"
                        >
                            {editingIndex === null ? 'Submit' : 'Update'}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Display Message */}
            {message && <p className="text-success">{message}</p>}

            {loading && <p>Loading...</p>}

            {/* Loyalty Points List */}
            {loyaltyPointsList.length > 0 && (
                <div>
                    <table className="table table-bordered mt-4">
                        <thead>
                            <tr>
                                <th>PAN</th>
                                <th>KMID</th>
                                <th>Offer Type</th>
                                <th>Offer Value</th>
                                <th>Min Txn Amount</th>
                                <th>Max Offer Amount</th>
                                <th>From Date</th>
                                <th>To Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedList.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.pan}</td>
                                    <td>{item.kmid}</td>
                                    <td>{item.offer_type}</td>
                                    <td>{item.offer_value}%</td>
                                    <td>{item.min_txn_amount}  £</td>
                                    <td>{item.max_offer_amount} £</td>
                                    <td>{moment(item.from_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    <td>{moment(item.to_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                    {/* <td>{new Date(item.from_date).toLocaleDateString()}</td>
                                    <td>{new Date(item.to_date).toLocaleDateString()}</td> */}
                                    <td>
                                        <button className="btn btn-warning mr-2" onClick={() => handleEdit(index)}>
                                            Edit
                                        </button>
                                        <button className="btn btn-danger" onClick={() => handleDeletePoints(item.id)}>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div className="pagination-controls" style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
                        <button className="btn btn-secondary" onClick={handlePreviousPage} disabled={currentPage === 0}>
                            Previous
                        </button>
                        <button className="btn btn-secondary" onClick={handleNextPage} disabled={(currentPage + 1) * ITEMS_PER_PAGE >= loyaltyPointsList.length}>
                            Next
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FetchOffersPage;
