
import axios from '../../utils/AxiosWrapper'
import { pluralize } from '../../utils/helpers'
import { getEntityDetails } from './index'
import apiCalls from '../../config/apiCalls'
import axiosMethodRequest from '../../config/service'
import { setUserAction } from './authActions'
import { GetSessionValue, SetSessionValue } from '../../utils/sessionStorage'

const setEntityDetailData = (type, data) => {
  return {
    type: `SET_${type.toUpperCase()}_DETAIL_DATA`,
    payload: data
  }
}
const setEntityLoadingState = (type, loadingState) => {
  return {
    type: `SET_${type.toUpperCase()}_DETAIL_DATA_LOADINGSTATE`,
    payload: loadingState
  }
}


export const getEntityDetailAction = (entity, apiUrl) => {
  return dispatch => {
    let url = ''
    if (apiUrl == 'users') {
      let filterCriteria = {};
      //   filterCriteria['limit'] = pageSize;
      //   filterCriteria['page'] = page;
      filterCriteria['criteria'] = [
        { key: 'active', value: true, type: 'eq' }, { key: 'role', value: 'companyAdmin', type: 'eq' },
      ];
      filterCriteria['direction'] = 'desc';
      filterCriteria.sortfield = 'created';
      url = `users?filter=${JSON.stringify(filterCriteria)}`;
    } else {
      url = apiUrl
    }
    return axiosMethodRequest('GET', url).then((response) => {
      if (response && response.data && response.data.respCode) {
        let data = response.data[apiUrl]
        dispatch(setEntityDetailData(entity, data))
      }
      // } else {
      //   if (callback) {
      //     callback(response.data.errorMessage,'error')
      //   }
      // }
    })
  }
}
export const editEntityDetailAction = type => {
  return (data, callback) => {
    return dispatch => {
      return axios.put(`/${pluralize(type.toLowerCase())}/${data.id}`, data)
        .then(res => {
          dispatch(getEntityDetails(type))
          callback(null)
        })
        .catch(err => {
          callback(err)
        })
    }
  }
}

export const confirmBranchesAction = (userEntityType, brandId, callback) => {
  return dispatch => {
    return axios.put(`/brands/verifybranches/${brandId}`)
      .then(res => {
        dispatch(getEntityDetails(userEntityType, false, callback))
      })
  }
}

export const confirmBrandAction = (userEntityType, brandId, callback) => {
  return dispatch => {
    return axios.put(`/brands/verifybrand/${brandId}`)
      .then(res => {
        dispatch(getEntityDetails(userEntityType, false, callback))
      })
  }
}
export const profileImgUpdate = (value, callback) => {
  return dispatch => {
    axiosMethodRequest('POST', apiCalls.updateUserProfile, value, 'upload').then((response) => {
      if (response && response.data && response.data.respCode) {
        let userDetails = GetSessionValue('user')
        userDetails.photo = response.data.fileName
        SetSessionValue('user', userDetails)
        SetSessionValue('loginCredentials', userDetails)
        dispatch(setUserAction(userDetails))
      }
      if (callback)
        callback()
    })
    // return axios.put(`/brands/verifybrand/${brandId}`)
    // .then(res => {
    //   dispatch(getEntityDetails(userEntityType, false, callback))
    // })
  }
}
