import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { notificationOpts } from '../../config';
import {
    success as successNotificationAction,
    error as errorNotificationAction,
} from 'react-notification-system-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Icon } from '@mui/material';
import ModalComponent from '../../components/Modal'
import { StartPauseAllCampaignApiCall } from '../../services/campaignSettings/startPauseCampaignApiCall';
import moment from 'moment'

const PuaseCampaignGlobal = (props) => {

    const [submitDialog, setSubmitDialog] = useState({ isSubmitDialog: false });
    const [showDeleteModal, setshowDeleteModal] = useState({ isOpen: false, index: null });
    const [dateList, setDateList] = useState([]);
    const dispatch = useDispatch();


    useEffect(() => {

        if (props.pausedDateWithPublisher) {            
            let dateList = [];
            props.pausedDateWithPublisher.forEach(element => {
                let date = element.daterange ? element.daterange.split("-") : [];
                let fromDate = moment(date[0]).format('DD-MMM-YYYY');
                let toDate = moment(date[1]).format('DD-MMM-YYYY');
                let allDistributors = JSON.parse(JSON.stringify(props.allDistributors));
                allDistributors.forEach(e3 => {
                    if(e3.distributors_name && element.selectedPublisher &&
                        element.selectedPublisher.includes(e3.distributors_name)) {
                        e3.checked = true;
                    }
                });

                dateList.push({
                    fromDate: new Date(fromDate), toDate: new Date(toDate),
                    distributors: allDistributors
                });
                setDateList(dateList);
            });
        }
    }, []);


    const confirmSubmit = () => {

        let isValid = true;
        dateList.forEach(element => {
            if (!element.fromDate || !element.toDate) {
                isValid = false;
            }
        });
        if (isValid) {
            setSubmitDialog({ isSubmitDialog: true });
        } else {
            let notification = { ...notificationOpts };
            notification.message = "Please select the date";
            dispatch(errorNotificationAction(notification))
        }
    }

    const updateDate = (newValue, filed, index) => {
        let list = JSON.parse(JSON.stringify(dateList));
        list[index][filed] = newValue;
        list.forEach(element => {
            element.fromDate = element.fromDate ? new Date(element.fromDate) : "";
            element.toDate = element.toDate ? new Date(element.toDate) : "";
        });
        setDateList(list);
    }

    const disableWeekends = (date, rowIndex, from) => {
        let dates = JSON.parse(JSON.stringify(dateList || []));
        let dateArray = [];
        dates.forEach((res, index) => {
            if (rowIndex != index && res.toDate && res.fromDate && (date <= new Date(res.toDate) && date >= new Date(res.fromDate))) {
                dateArray.push(date);
            } else if (rowIndex === index && from === "toDate" && new Date(res.fromDate) >= date) {
                dateArray.push(date);
            }
        })
        return dateArray.includes(date);
    }

    const checkCutoffDate = () => {
        let openingTime = new Date()

        let month = new Date(openingTime.getFullYear(), openingTime.getMonth(), 25);
        let date = new Date();
        let y = date.getFullYear();
        let m = date.getMonth();
        if (new Date() < month) {
            return new Date(y, m + 1, 1);
        } else {
            return new Date(y, m + 2, 1);
        }
    }

    const deleteDate = (res, index) => {
        if (!res.fromDate || !res.toDate) {
            addRemoveRangeDate(index);
        } else {
            setshowDeleteModal({ isOpen: true, index })
        }
    }

    const showMessage = (message, type) => {
        let notification = { ...notificationOpts };
        notification.message = message;
        if (type == "error") {
            dispatch(errorNotificationAction(notification))
        } else {
            dispatch(successNotificationAction(notification))
        }
    }

    const addRemoveRangeDate = (index) => {
        let list = JSON.parse(JSON.stringify(dateList));
        list.forEach(element => {
            element.fromDate = element.fromDate ? new Date(element.fromDate) : "";
            element.toDate = element.toDate ? new Date(element.toDate) : "";
            element.distributors = element.distributors ? element.distributors : JSON.parse(JSON.stringify(props.allDistributors))
        });
        if (index === "") {
            list.push({ fromDate: "", toDate: "", distributors: JSON.parse(JSON.stringify(props.allDistributors)) });
        } else {
            let removed = list.splice(index, 1);
            apiCall(list, "delete", removed);
        }
        setDateList(list);
    }

    const onCloseDeleteModal = () => {
        setshowDeleteModal({ isOpen: false, index: null })
    }

    const handleDeleteCampaign = () => {
        addRemoveRangeDate(showDeleteModal.index);
        setshowDeleteModal({ isOpen: false, index: null })
    }

    const updatePublisher = (index, index2, value) => {
        let list = JSON.parse(JSON.stringify(dateList));
        list[index]["distributors"][index2].checked = value;
        list.forEach(element => {
            element.fromDate = element.fromDate ? new Date(element.fromDate) : "";
            element.toDate = element.toDate ? new Date(element.toDate) : "";
        });
        setDateList(list);
    }

    const submitForm = () => {
        apiCall(dateList, "add", []);
    }

    const apiCall = (finalData, from, removed) => {

        let newAddedDate = [];
        let pausedDateWithPublisher = [];
        finalData.forEach(element => {
            let fromDate = moment(element.fromDate).format('YYYY/MM/DD').toString();
            let toDate = moment(element.toDate).format('YYYY/MM/DD').toString();
            let cam_date = fromDate + " - " + toDate;

            let selectedPublisher = [];
            if (element.distributors) {
                element.distributors.forEach(e2 => {
                    if (e2.checked) {
                        selectedPublisher.push(e2.distributors_name);
                    }
                });
            }
            pausedDateWithPublisher.push({ daterange: cam_date, selectedPublisher: selectedPublisher });
            if (props.pausedDateWithPublisher && props.pausedDateWithPublisher.length > 0) {
                let flag = false;
                props.pausedDateWithPublisher.forEach(e2 => {
                    if (e2.daterange != cam_date) {
                        flag = true;
                    }
                });
                if (flag) {
                    newAddedDate.push(cam_date)
                }
            } else {
                newAddedDate.push(cam_date)
            }
        });

        if (from == "delete" && removed.length > 0) {
            newAddedDate = [];
            removed.forEach(element => {
                let fromDate = moment(element.fromDate).format('YYYY/MM/DD').toString();
                let toDate = moment(element.toDate).format('YYYY/MM/DD').toString();
                newAddedDate.push(fromDate + " - " + toDate)
            });
        }

        let param = {
            pausedDateWithPublisher: pausedDateWithPublisher,
            action: from,
            newAddedDate: newAddedDate
        }
        dispatch(StartPauseAllCampaignApiCall(param,
            (res) => {
                showMessage(res.respMessage, "");
                setSubmitDialog({ isSubmitDialog: false });
                if (from == "add") {
                    props.close();
                }
            }))
    }

    return <>
        <div className='campaign-settings'>

            <div className="col-12 col-lg-12">
                <div className="card">
                    <div>
                        <table key={new Date().getTime()} className="table table-bordered">

                            <tr>
                                <th scope="col text-center"> Paused Duration</th>
                            </tr>

                            <tr>
                                <td className='va-m text-center'>
                                    <ModalComponent
                                        key={submitDialog.isSubmitDialog}
                                        close={() => { setSubmitDialog({ isSubmitDialog: false }) }}
                                        callback={() => { submitForm() }}
                                        show={submitDialog.isSubmitDialog}
                                        title={<span>Paused Campaign </span>}
                                        message={<span>Are you sure you want to pause Campaign ?</span>}
                                        action={"Yes"}
                                        actionType={'primary'}
                                    />
                                    <ModalComponent
                                        close={onCloseDeleteModal}
                                        callback={handleDeleteCampaign}
                                        show={showDeleteModal.isOpen}
                                        title={"Delete Campaign"}
                                        message={<span>Are you sure you want to delete?</span>}
                                        action={"Delete"}
                                        actionType='danger'
                                    />


                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                        {(dateList || []).map((res1, index) =>

                                            <div className="row col-12 m-t-10 date-rang-box" key={index + "datepicker"}>

                                                <div className="row col-12 m-b-25 m-t-10 text-align-left">

                                                    {(res1.distributors || []).map((res, index2) =>
                                                        <div className="col-3 m-t-5 p-0" key={"checkbox-distributor" + index2}>
                                                            <input type='checkbox' name={"checkbox-distributor" + res.distributors_name}
                                                                defaultChecked={res.checked} className="distributor-checkbox"
                                                                id={"checkbox-distributor" + res.distributors_name}
                                                                onChange={(e) => {
                                                                    updatePublisher(index, index2, e.target.checked);
                                                                }}
                                                            />
                                                            <span className='m-l-5'> {res.distributors_display_name} </span>
                                                        </div>
                                                    )}

                                                </div>
                                                <div className="col-5">
                                                    <DatePicker label="From"
                                                        value={res1.fromDate}
                                                        onChange={(newValue) => updateDate(newValue, 'fromDate', index)}
                                                        format="dd-MM-yyyy"
                                                        shouldDisableDate={(date) => { return disableWeekends(date, index, "fromDate") }}
                                                        minDate={checkCutoffDate()}
                                                    />
                                                </div>
                                                <div className="col-5">
                                                    <DatePicker
                                                        label="To"
                                                        value={res1.toDate}
                                                        onChange={(newValue) => updateDate(newValue, 'toDate', index)}
                                                        format="dd-MM-yyyy"
                                                        shouldDisableDate={(date) => { return disableWeekends(date, index, "toDate") }}
                                                        minDate={checkCutoffDate()}
                                                    />
                                                </div>
                                                <div className="col-1 p-t-10">

                                                    <span className="future-action mr-2" style={{ display: 'inline-block', cursor: 'pointer' }} onClick={() => deleteDate(res1, index)}>
                                                        <Icon style={{ fontSize: "1.5rem" }} className="text-danger" title="Delete">delete_outline</Icon>
                                                    </span>


                                                </div>

                                            </div>
                                        )}
                                        <div className="row col-12 text-center">
                                            <div className='d-flex w-100 justify-content-center'  >
                                                <div className='range-link cursor-pointer' onClick={() => addRemoveRangeDate("")}>  Add Range </div></div>
                                        </div>

                                    </LocalizationProvider>

                                </td>
                            </tr>

                        </table>
                    </div>

                </div>
            </div>


            <div className="col-12 col-lg-12 text-right">
                <Button variant="success" disabled={dateList.length == 0} onClick={(e) => { confirmSubmit(); }}>
                    Update
                </Button>
            </div>

        </div>
    </>
}

export default PuaseCampaignGlobal