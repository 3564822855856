import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux'

const PublicRoute = ({ component: Component, isAuthenticated, to, ...rest }) => (

  <Route
    {...rest}
    render={props =>
      !isAuthenticated ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: to,
              state: { redirect: props.location.pathname, isAuthenticated },
            }}
          />
        )
    }
  />
);


PublicRoute.defaultProps = {
  to: '/',
};

export default connect(
  state => ({
    isAuthenticated: !!(state.user && state.user.loadComplete)
  }),
  null
)(PublicRoute);


